import React, { useState, useEffect } from "react";
import { Row, Col, Form, Checkbox, Input, notification } from "antd";
import { TwitterPicker } from 'react-color';

import { useStateValue } from '../../state/stateProvider';
import api from '../../services/api';
import { listagemActions } from "../../actions";

export default function ManutencaoArea({ form, carregando, aoSalvar }) {

    const [, dispatch] = useStateValue();

    const [corSelecionada, setCorSelecionada] = useState(form.getFieldValue('cor') || '#FFFFFF');

    useEffect(() => {

    }, [])

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+descricao' } });
    };

    const salvarRegistro = async (values) => {
        if (!values.id) values.id = null;
        carregando(true);
        let retorno = values.id ? await api.put('/Area', values) : await api.post('/Area', values);
        if (retorno.status === 200) {
            notification.success({ message: 'Operação concluída', description: "Registro salvo com sucesso!" });
            retorno = retorno.data.id;
        }
        else notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(retorno.data) });
        atualizarPagina();
        carregando(false);
        aoSalvar(retorno);
    }

    return (
        <Form layout="vertical" name="formManutencaoArea" form={form} onFinish={salvarRegistro}>
            <Form.Item name="id" hidden />
            <Row gutter={[8, 0]}>
                <Col span={24}>
                    <Form.Item label="Descrição"
                        name="descricao" rules={[
                            {
                                required: true,
                                message: 'Informe a descrição',
                            },
                        ]}>
                        <Input.TextArea placeholder="Informe um Título" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Cor da Área" name="cor">
                        <TwitterPicker className="w-100" color={form.getFieldValue('cor') || '#FFFFFF'} onChangeComplete={({ hex }) => {
                            setCorSelecionada(hex);
                            form.setFieldsValue({ cor: hex });
                        }} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="ativa" valuePropName="checked"
                        hidden={(!(form.getFieldValue(['id']) > 0))} className="t-mob-573">
                        <Checkbox>Area Ativa?</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}