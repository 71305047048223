import React from "react";
import { Row, Col, Form, Button, notification, Modal, Popover, Tag } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from '../../services/api';
import DrawerSolucoesModulo from "./drawer";
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbIncluirDados, Search, TabelaDados } from "../../components";
import { drawerActions, manutencaoActions, listagemActions } from "../../actions";

export default function SolucoesModulo() {

    const [formSolucoesModulo] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    
    function excluirRegistro(id) {
        api.delete(`/SolucaoModulo/${id}`).then((res) => {
            if (res.status === 200) notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" });
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        }, erro => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
        }).finally(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+descricao' } }));
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    return (
        <div className="col-paginas">
            <BreadcrumbIncluirDados pagina="Soluções / Módulos" />
            <Form layout="vertical" form={formSolucoesModulo} name="solucoesModulo">
                <Row>
                    <Col span={24}>
                        <Search />
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url="/SolucaoModulo" colunas={[
                        {
                            title: 'Código',
                            width: 75,
                            render: ({ id, ativo }) => (
                                <Popover content={ativo ? 'Ativo' : 'Inativo'} placement="right">
                                    <Tag color={ativo ? 'processing' : 'default'} className="tag-w-100">
                                        <b>{id}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Sistema',
                            render: ({ sistema }) => (
                                <div>
                                    <b> {sistema.descricao || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Descrição',
                            render: ({ descricao }) => (
                                <div>
                                    <b>{descricao} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                               dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                               dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => { modalExcluir(record.id) }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />            
                </div>
            </Form>
            <DrawerSolucoesModulo />     
        </div>
    );
}