import React, { useState } from "react";
import { Row, Col, Form, Button, Table, Divider, DatePicker } from "antd";
import { ExclamationCircleOutlined, EyeOutlined } from "@ant-design/icons";
import moment from 'moment';

import DrawerDetalhes from "./drawer";
import { BreadcrumbPage, Search } from "../../components";

export default function PaginaLogs() {

    const [formLog] = Form.useForm();
    const [listaLog, setListaLog] = useState([]);
    const [carregando, setCarregando] = useState(false);
    const [openDetalhes, setOpenDetalhes] = useState(false);

    for (let i = 0; i < 10; i++) {
        listaLog.push({
            key: i,
            mensagem: `Atualizou uma Agenda.`,
            usuario: 'Recepção Abase',
        });
    }

    return (
        <div className="col-paginas m-t-2">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col>
                    <BreadcrumbPage />
                </Col>
            </Row>
            <Form layout="vertical" form={formLog} name="log" className="m-t-2">
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                        <Search />
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item label="Data Inicial" name="data_inicial">
                            <DatePicker showTime placeholder="DD/MM/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item label="Data Final" name="data_final">
                            <DatePicker showTime placeholder="DD/MM/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <Table loading={carregando} columns={[   
                        {
                            title: 'Mensagem',
                            render: ({ mensagem }) => (
                                <div>
                                    <b> {mensagem || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Usuário',
                            render: ({ usuario }) => (
                                <div>
                                    <b> {usuario || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Data',
                            width: 148,
                            render: ({ data }) => (
                                <div>
                                    <b>
                                        {moment(data).format('DD/MM/YYYY HH:mm:ss')}
                                    </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EyeOutlined />} onClick={() => {setOpenDetalhes(true);}} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]}
                        locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} scroll={{ x: 920 }} dataSource={listaLog} 
                    />            
                </div>
            </Form>
            <DrawerDetalhes exibirDetalhes={openDetalhes} fecharDetalhes={() => {setOpenDetalhes(false)}}/>
        </div>
    );
}