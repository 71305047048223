import React, { useEffect, useState } from "react";
import { Row, Col, Form, Collapse, Button, Dropdown, Select, Radio, Divider, Tag, Menu, notification, Checkbox, Input } from "antd";
import { CheckOutlined, CloseOutlined, SearchOutlined, ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, ExclamationCircleOutlined, EyeOutlined, EditOutlined, MoreOutlined, StopOutlined } from "@ant-design/icons";
import moment from 'moment';

import { useStateValue } from '../../state/stateProvider';
import api from "../../services/api";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbPage, Search, TabelaDados, Data, ComboData } from "../../components";
import SelectPaginado from "../../components/selectPaginado";
import AgendaEmail from "../agendaEmail";
import { getIdSupervisor, setIdAgenda } from "../../services/auth";
import DrawerDetalhe from "../../components/agendaDetalhe/drawerDetalhe";
import DrawerAgenda from "../../components/agendaCadastro/drawerAgenda";


export default function Agenda() {
    const [formAgenda] = Form.useForm();
    const [listaStatus, setListaStatus] = useState([]);
    const [open, setOpen] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [formPesquisaAvancada] = Form.useForm();
    const [openDetalhes, setOpenDetalhes] = useState(false);
    const [openAgenda, setOpenAgenda] = useState(false);
    const [openAgendaEmail, setOpenAgendaEmail] = useState(false);
    const [openAutorizarUsuario, setOpenAutorizarUsuario] = useState(false);
    const dayName = new Array("DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB");


    useEffect(() => {
        buscarAgendaEmail();
        carregarDados();
    }, []);

    async function buscarAgendaEmail() {
        let url = window.location.href;
        if (url.indexOf('?') > -1) {
            let idAgenda = url.substr(url.lastIndexOf('?') + 1);
            setIdAgenda(idAgenda);
            let req = await api.get(`Agenda/${atob(idAgenda)}`);
            let participantes = [];
            req.data.agendaParticipantes?.forEach(p => { if (!!p?.usuario?.id) participantes.push(p.usuario.id) });
            req.data.listaAgendaParticipantes = participantes;
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: req.data } });
            setOpenAgendaEmail(true);
        }
        buscarStatus();
    }

    function buscarStatus() {
        api.get(`Enumeravel/StatusAgenda`).then(
            res => {
                let dados = res.data;
                setListaStatus(dados);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function carregarDados() {
        let data = new Date().getDate();
        if (!formPesquisaAvancada.getFieldValue().dataInicial) {
            formPesquisaAvancada.setFieldsValue({ dataInicial: moment(new Date().setHours(0, 0, 0, 0)).format('YYYY-MM-DD HH:mm') });
        }
        if (!formPesquisaAvancada.getFieldValue().dataFinal) {
            formPesquisaAvancada.setFieldsValue({ dataFinal: moment(new Date(new Date().setDate((data + 15))).setHours(23, 59, 0, 0)).format('YYYY-MM-DD HH:mm') });
        }
        aplicarFiltrosAvancados();
    };

    const togglePesquisa = () => {
        setOpen(!open);
    };

    function aplicarFiltrosAvancados() {
        let filtros = '';
        filtros += (!!formAgenda.getFieldValue().exibirFerias) ? `&exibirFerias=${formAgenda.getFieldValue().exibirFerias}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().tipoAgendaId) ? `&agendaTipo=${formPesquisaAvancada.getFieldValue().tipoAgendaId}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().tipoAtendimento) ? `&tipoAtendimento=${formPesquisaAvancada.getFieldValue().tipoAtendimento}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().dataInicial) ? `&dataInicial=${moment(formPesquisaAvancada.getFieldValue().dataInicial).format("YYYY-MM-DD HH:mm")}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().dataFinal) ? `&dataFinal=${moment(formPesquisaAvancada.getFieldValue().dataFinal).format("YYYY-MM-DD HH:mm")}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().empresaId) ? `&empresa=${formPesquisaAvancada.getFieldValue().empresaId}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().sistemaId) ? `&sistema=${formPesquisaAvancada.getFieldValue().sistemaId}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().solucaoModuloId) ? `&solucaoModulo=${formPesquisaAvancada.getFieldValue().solucaoModuloId}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().usuarioId) ? `&usuario=${formPesquisaAvancada.getFieldValue().usuarioId}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().clienteId) ? `&pessoa=${formPesquisaAvancada.getFieldValue().clienteId}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().localId) ? `&localId=${formPesquisaAvancada.getFieldValue().localId}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().statusValor) ? `&status=${formPesquisaAvancada.getFieldValue().statusValor}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().recursoId) ? `&recursoLocado=${formPesquisaAvancada.getFieldValue().recursoId}` : '';
        dispatch({ type: listagemActions.CHANGE, data: { filtro: filtros, ordem: '+DataInicial,Id', pagina: 1 } });
    };

    function removerFiltros() {
        formPesquisaAvancada.setFieldsValue({
            tipoAgendaId: null, tipoAtendimento: null, empresaId: null,
            sistemaId: null, solucaoModuloId: null, usuarioId: null, clienteId: null, localId: null, statusValor: null, recursoId: null
        });
        aplicarFiltrosAvancados();
    }

    function retornarParticipante(p) {

        return (
            <Col span={20}>
                <Row align="middle">
                    <Col>
                        <b>{p.usuario?.nome}</b>
                    </Col>
                </Row>
            </Col>
        );
    };

    function retornarRecursos(r) {
        let listaRecursos = '';
        if (r.length === 0) {
            listaRecursos = '-';
        }
        for (const [i, item] of r.entries()) {
            if (!item.recurso?.formulario && !!item.recurso?.descricao) {
                listaRecursos += ' ' + item.recurso.descricao;
            } else if (!!item.recurso?.formulario) {
                let recurso = JSON.parse(item.recurso.formulario);
                let recursoTipo = JSON.parse(item.recurso.recursoTipo.formulario);
                if (recursoTipo.length > 0) {
                    recursoTipo.map((campo) => {
                        listaRecursos += " " + recurso.filter(res => !!res[campo.id])[0][campo.id];
                    })
                }
            }
            if (i < r.length - 1) listaRecursos += ";";
        }
        listaRecursos = listaRecursos.trim();

        return (
            <Col span={20}>
                <Row align="middle">
                    <Col>
                        <span>{listaRecursos}</span>
                    </Col>
                </Row>
            </Col>
        )
    };

    const quebrarTitulo = (titulo) => {
        const comprimentoMaximo = 35;
        const palavras = titulo.split(' ');
        let linhas = [];
        let linhaAtual = '';

        palavras.forEach((palavra) => {
            if (linhaAtual.length + palavra.length <= comprimentoMaximo) {
                linhaAtual += `${palavra} `;
            } else {
                linhas.push(linhaAtual.trim());
                linhaAtual = `${palavra} `;
            }
        });

        if (linhaAtual.length > 0) {
            linhas.push(linhaAtual.trim());
        }

        return linhas;
    };

    function abrirDrawer(record) {
        let dados = { ...record };
        dados.dataFinal = !!dados.dataFinal ? moment(dados.dataFinal) : null;
        dados.dataInicial = !!dados.dataInicial ? moment(dados.dataInicial) : null;
        dados.dataInclusao = !!dados.dataInclusao ? moment(dados.dataInclusao) : null;
        dados.dataAlteracao = !!dados.dataAlteracao ? moment(dados.dataAlteracao) : null;

        let participantes = [];
        dados.agendaParticipantes?.forEach(p => { if (!!p?.usuario?.id) participantes.push(p.usuario.id) });
        dados.listaAgendaParticipantes = participantes;

        delete dados.pessoa;
        delete dados.sistema;
        delete dados.usuario;
        delete dados.local;
        delete dados.empresa;
        delete dados.agendaTipo;

        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
    };

    function abrirDrawerDetalhes(record) {
        let dados = { ...record };
        dados.dataFinal = !!dados.dataFinal ? moment(dados.dataFinal) : null;
        dados.dataInicial = !!dados.dataInicial ? moment(dados.dataInicial) : null;
        dados.dataInclusao = !!dados.dataInclusao ? moment(dados.dataInclusao) : null;
        dados.dataAlteracao = !!dados.dataAlteracao ? moment(dados.dataAlteracao) : null;
        dados.titulo = dados.titulo.toUpperCase();
        let participantes = [];
        dados.agendaParticipantes?.forEach(p => { if (!!p?.usuario?.id) participantes.push(p.usuario.id) });
        dados.listaAgendaParticipantes = participantes;

        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
    };

    function cancelarAgendaOS(record) {
        let dados = record;
        delete dados.usuario;
        dados?.agendaParticipantes.map(p => {
            delete p.usuario;
        });
        dados?.ordemServico?.ordensTarefas.map(p => {
            delete p.produto;
            delete p.tarefa;
        })
        delete dados.agendaTimeline;
        api.post(`Agenda/Cancelar`, record).then(
            (res) => {
                notification.success({ message: 'Aviso', description: 'Agenda cancelada com sucesso!' });
                aplicarFiltrosAvancados();
            }).catch(
                (erro) => {
                    console.error(erro);
                })
    };

    function confirmarAgendaOS(record) {
        if (record.status !== 1) {
            notification.warn({ message: 'Aviso', description: 'Apenas agendas com o status de "Aguardando" podem ser confirmadas!' });
        } else {
            let dados = record;
            delete dados.usuario;
            dados.agendaParticipantes.map(p => {
                delete p.usuario;
            });
            delete dados.agendaTimeline;
            api.post(`Agenda/ConfirmarAgenda`, record).then(
                (res) => {
                    console.log(res.data);
                    if (res.data.search("não informado") > -1 || res.data.search("não tem autorização") > -1) {
                        notification.error({ message: 'Aviso', description: res.data });
                    } else {
                        notification.success({ message: 'Aviso', description: res.data });
                        aplicarFiltrosAvancados();
                    }
                }).catch(
                    (erro) => {
                        console.error(erro);
                    })
        }
    };


    function menuTable(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1" hidden={record.status !== 1}>
                    <Button type="text" icon={<CheckOutlined style={{ color: '#015801' }} />} onClick={() => {
                        confirmarAgendaOS(record);
                    }}>Confirmar Agenda</Button>
                </Menu.Item>
                <Menu.Item key="2" hidden={record.status === 3}>
                    <Button type="text" icon={<StopOutlined style={{ color: '#e21313' }} />} onClick={() => {
                        cancelarAgendaOS(record);
                    }}>Cancelar </Button>
                </Menu.Item>
            </Menu>
        )
    }

    return (
        <div className="col-paginas">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col>
                    <BreadcrumbPage pagina="Agenda" />
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={() => {
                        var idSupervisor = getIdSupervisor();
                        if (idSupervisor !== "null") {
                            setOpenAgenda(true);
                        } else {
                            notification.warn({ description: "Para incluir uma agenda é necessário informar o seu supervisor em: Cadastros > Usuários", message: "Aviso" });
                        }
                    }}>
                        Adicionar
                    </Button>
                </Col>
            </Row>
            <Form layout="vertical" form={formAgenda} name="agenda" >
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={14} lg={13} xl={14}>
                        <Form.Item label="Pesquisar por" name="pesquisa">
                            <Input.Search placeholder="Pesquisar por"
                                onSearch={valor => {
                                    let filtros = '';
                                    filtros += (!!valor ? `&filtro=${valor}` : '');
                                    filtros += (!!formPesquisaAvancada.getFieldValue().dataInicial) ? `&dataInicial=${moment(formPesquisaAvancada.getFieldValue().dataInicial).format("YYYY-MM-DD HH:mm")}` : '';
                                    filtros += (!!formPesquisaAvancada.getFieldValue().dataFinal) ? `&dataFinal=${moment(formPesquisaAvancada.getFieldValue().dataFinal).format("YYYY-MM-DD HH:mm")}` : '';
                                    dispatch({ type: listagemActions.CHANGE, data: { filtro: filtros, ordem: '+DataInicial,Id', pagina: 1 } })
                                }} allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={7} md={7} lg={4} xl={4} xxl={3}>
                        <ComboData form={formPesquisaAvancada} eventoAoAlterar={() => {
                            let dataI = formPesquisaAvancada.getFieldValue().dataI;
                            formPesquisaAvancada.setFieldsValue({ DataInicial: dataI ? moment((dataI).setHours(23, 59, 0, 0)).format("YYYY-MM-DD HH:mm") : moment(new Date().setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm") });
                            formPesquisaAvancada.setFieldsValue({ DataFinal: moment((formPesquisaAvancada.getFieldValue().dataPesquisa).setHours(23, 59, 0, 0)).format("YYYY-MM-DD HH:mm") });
                            formPesquisaAvancada.setFieldsValue({ dataInicial: dataI ? moment((dataI).setHours(23, 59, 0, 0)).format("YYYY-MM-DD HH:mm") : moment(new Date().setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm") });
                            formPesquisaAvancada.setFieldsValue({ dataFinal: moment((formPesquisaAvancada.getFieldValue().dataPesquisa).setHours(23, 59, 0, 0)).format("YYYY-MM-DD HH:mm") });
                            aplicarFiltrosAvancados();
                        }} valueInicial={4} />
                    </Col>
                    <Col xs={24} sm={7} md={7} lg={4} xl={4} xxl={3} className="m-t-21">
                        <Form.Item valuePropName="checked" name="exibirFerias">
                            <Checkbox onChange={aplicarFiltrosAvancados}> Exibir Férias</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Form name="formularioPesqAvancadaAgenda" form={formPesquisaAvancada}>
                    <Row>
                        <Col span={24}>
                            <Collapse className="collapse-pages" accordion ghost destroyInactivePanel={true}>
                                <div className="colDet"></div>
                                <Collapse.Panel key="1" showArrow={false} extra={
                                    <Button type="primary" onClick={togglePesquisa}>
                                        {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Mais Filtros
                                    </Button>
                                }>
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                            <Form.Item label="Tipo de Agenda" name="tipoAgendaId">
                                                <SelectPaginado url="AgendaTipo" placeholder="Selecione o Tipo de Agenda" form={formPesquisaAvancada} name="tipoAgendaId" conteudo={
                                                    agt => (<Select.Option value={agt.id} key={agt.key}>{agt.descricao}</Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                                            <Form.Item label="Tipo de Atendimento" name="tipoAtendimento" >
                                                <Radio.Group>
                                                    <Radio value={1}>Externo</Radio>
                                                    <Radio value={2}>Interno</Radio>
                                                    <Radio value={3}>Remoto</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={8} lg={8} xl={3}>
                                            <Data label="Data Inicial" name="dataInicial" format="DD/MM/YYYY HH:mm" />
                                        </Col>
                                        <Col xs={12} md={8} lg={8} xl={3}>
                                            <Data label="Data Final" name="dataFinal" format="DD/MM/YYYY HH:mm" />
                                        </Col>
                                        <Col xs={24} md={8} lg={8} xl={5}>
                                            <Form.Item label="Empresa" name="empresaId">
                                                <SelectPaginado url="EmpresasUsuario" placeholder="Selecione a Empresa" form={formPesquisaAvancada} name="empresaId" conteudo={
                                                    emp => (<Select.Option value={emp.id} key={emp.id}>{emp.nomeFantasia} - {emp.razaoSocial}</Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={8} lg={8} xl={4}>
                                            <Form.Item label="Sistema" name="sistemaId">
                                                <SelectPaginado url="Sistema" placeholder="Selecione um Sistema" form={formPesquisaAvancada} name="sistemaId" conteudo={
                                                    sis => (<Select.Option value={sis.id} key={sis.id}>{sis.descricao}</Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={8} lg={8} xl={5}>
                                            <Form.Item label="Soluções / Módulos" name="solucaoModuloId">
                                                <SelectPaginado url="SolucaoModulo" placeholder="Selecione uma Solução" form={formPesquisaAvancada} name="solucaoModuloId" conteudo={
                                                    solucao => (<Select.Option value={solucao.id} key={solucao.id}>{solucao.descricao}</Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8} lg={8} xl={5}>
                                            <Form.Item label="Participantes" name="usuarioId">
                                                <SelectPaginado url="Usuarios?Ativo=true&Sac=true" placeholder="Selecione o Participantes" form={formPesquisaAvancada} name="usuarioId" conteudo={
                                                    usu => (<Select.Option value={usu.id} key={usu.id}>{usu.nome}</Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8} lg={8} xl={5}>
                                            <Form.Item label="Cliente" name="clienteId">
                                                <SelectPaginado url="Cliente" placeholder="Selecione o Cliente" form={formPesquisaAvancada} name="clienteId" order="+pessoaId" conteudo={
                                                    cli =>
                                                    (<Select.Option value={cli.pessoaId} key={cli.id}>
                                                        <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'Razão Social:' : 'Nome:'}</span> {cli.pessoa?.nome} <br />
                                                        <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'Nome Fantasia:' : 'Apelido:'} </span> {cli.pessoa?.pessoaJuridica ? cli.pessoa?.pessoaJuridica?.nomeFantasia : cli.pessoa?.pessoaFisica?.apelido}  <br />
                                                        <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'CNPJ:' : 'CPF:'} </span> {cli.pessoa?.pessoaJuridica ? cli.pessoa?.pessoaJuridica?.cnpj : cli.pessoa?.pessoaFisica?.cpf} <span style={{ fontWeight: "bold" }}> {!cli.ativo ? '(Inativo)' : ''}</span> <br />
                                                    </Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                            <Form.Item label="Local" name="localId">
                                                <SelectPaginado url="Localizacao" placeholder="Selecione o Local" form={formPesquisaAvancada} name="localId" conteudo={
                                                    loc => (<Select.Option value={loc.id} key={loc.id}>{loc.endereco}</Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                                            <Form.Item label="Status" name="statusValor">
                                                <Select showSearch optionFilterProp="children" placeholder="Selecione o Status" >
                                                    {listaStatus.map((sts) => (
                                                        <Select.Option value={sts.valor} key={sts.valor}>{sts.descricao}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                            <Form.Item label="Recursos Locados" name="recursoId">
                                                <SelectPaginado url="Recurso" placeholder="Selecione um Recurso" form={formPesquisaAvancada} name="recursoId" conteudo={
                                                    rec => (<Select.Option value={rec.id} key={rec.id}>{rec.descricao}</Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col className="col-w">
                                            <Row align="middle" justify="end" gutter={[8, 0]} className="btn-col-21">
                                                <Col>
                                                    <Button icon={<CloseOutlined />} onClick={removerFiltros} block>
                                                        Limpar Filtros
                                                    </Button>
                                                </Col>
                                                <Col>
                                                    <Button type="primary" icon={<SearchOutlined />} onClick={aplicarFiltrosAvancados} block>
                                                        Aplicar Filtros
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Form>
            </Form>
            <div className="tabela-pages"  >
                <TabelaDados url={"Agenda/ListarAgendas"} condicao={true} colunas={[
                    /*  {
                          title: 'ID',
                          width: 120,
                          render: ({ id }) => (
                              <Tag >
                                  <b name="id">
                                      {id}
                                  </b>
                              </Tag>
                          ),
                      },*/
                    {
                        title: 'Data',
                        width: 112,
                        render: ({ dataExibir }) => (
                            <Tag >
                                <span name="dataInicial" form={formAgenda}>
                                    {moment(dataExibir).format('DD/MM/YYYY HH:mm')}
                                </span>
                            </Tag>
                        ),
                    },
                    {
                        title: '',
                        width: 40,
                        render: ({ dataExibir }) => (
                            <div >
                                <span name="semana" form={formAgenda}>
                                    {dayName[new Date(dataExibir).getDay()]}
                                </span>
                            </div>
                        ),
                    },
                    {
                        title: 'Tipo Agenda',
                        width: 80,
                        render: ({ agendaTipo }) => (
                            <div>
                                <span> {agendaTipo?.descricao || 'Não Informado'} </span>
                            </div>
                        ),
                    },
                    {
                        title: 'Sistema',
                        width: 75,
                        render: ({ sistema }) => (
                            <div className="tag-w-100">
                                <span style={{ color: sistema?.cor }}>{sistema?.descricao}</span>
                            </div>
                        ),
                    },
                    {
                        title: 'Participantes',
                        width: 160,
                        render: ({ agendaParticipantes }) => (
                            <div id="divParticipantes">
                                {agendaParticipantes?.map(p => {
                                    if (!!p.usuario) {
                                        return <div style={{ height: 20 }}><span>{p.usuario?.nome}</span><br></br></div>
                                    }
                                }
                                )}
                            </div>
                        ),
                    },
                    {
                        title: 'Título',
                        width: 180,
                        render: ({ titulo }) => (
                            <div>
                                {quebrarTitulo(titulo).map((linha, index) => (
                                    <p key={index}>{linha}</p>
                                ))}
                                {/* Restante dos dados da tabela */}
                            </div>
                        ),
                    },

                    {
                        title: 'Cliente',
                        width: 160,
                        render: ({ pessoa }) => (
                            <div>
                                <span>
                                    {pessoa?.nome || 'Não Informado'}
                                </span>
                            </div>
                        ),
                    },
                    {
                        title: 'Recursos',
                        width: 130,
                        render: ({ agendaRecursos }) => (
                            <div>
                                {retornarRecursos(agendaRecursos)}
                            </div>
                        ),
                    },
                    {
                        title: 'Local',
                        width: 200,
                        render: ({ local }) => (
                            <div>
                                <span> {local?.endereco || '-'} </span>
                            </div>
                        ),
                    },
                    {
                        title: 'Status',
                        width: 90,
                        render: ({ status }) => (
                            <div>
                                {status === 1 && <b className="c-primary">Aguardando</b>}
                                {status === 2 && <b className="c-green">Confirmada</b>}
                                {status === 3 && <b className="c-red">Cancelada</b>}
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        width: 100,
                        align: 'center',
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => {
                                            abrirDrawer(record);
                                            setOpenAgenda(true);
                                        }} />
                                    </Col>
                                    <Col>
                                        <Button icon={<EyeOutlined />} onClick={() => {
                                            abrirDrawerDetalhes(record);
                                            setOpenDetalhes(true);
                                        }} />
                                    </Col>
                                    <Col>
                                        <Dropdown disabled={record.status === 3} overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]}
                    colunaClassName={(record) => record.cor}
                    locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }}
                    expandir={({ titulo, solucoes_modulos, dataInicial, dataFinal, atendimento }) => {
                        return (
                            <div>
                                <Row gutter={[16, 0]}>
                                    <Col>
                                        Soluções / Módulos: {solucoes_modulos || 'Não Informado'}
                                    </Col>
                                    <Col>
                                        Título: {titulo || 'Não Informado'}
                                    </Col>
                                </Row>
                                <Row gutter={[16, 0]} justify="space-between" className="m-t-5">
                                    <Col>
                                        Atendimento: {atendimento}
                                    </Col>
                                    <Col>
                                        Início: {moment(dataInicial).format('DD/MM/YYYY HH:mm:ss')}
                                    </Col>
                                    <Col>
                                        Fim:  {moment(dataFinal).format('DD/MM/YYYY HH:mm:ss')}
                                    </Col>
                                </Row>
                            </div>
                        )
                    }}
                />
            </div>
            <DrawerAgenda openDrawerAgenda={openAgenda} closeDrawerAgenda={() => setOpenAgenda(false)} />
            <DrawerDetalhe openDrawerDetalhe={openDetalhes} closeDrawerDetalhe={() => setOpenDetalhes(false)} />
            <AgendaEmail openDrawerAgendaEmail={openAgendaEmail} closeDrawerAgendaEmail={() => setOpenAgendaEmail(false)} />
        </div>
    );
}