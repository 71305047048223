import React from "react";
import { Row, Col, Descriptions, Divider, Typography, Table, Tag, Alert } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { FormatFone } from "../../valueObjects";
import { useStateValue } from "../../state/stateProvider";
import { useEffect } from "react";

export default function DetalheCliente({ listaContatos, temContrato}) {

    const [{ ui, manutencao }, dispatch] = useStateValue();

    return (
        <div>
            <Row align="middle" gutter={[8, 8]}>
                <Col span={24}>
                    <Alert message="As informações dos clientes não podem ser alteradas a partir do SAC-G." type="warning" showIcon />
                </Col>
                <Col span={24}>
                    <Tag color={temContrato.temContrato ? 'green' : 'default'}>
                        {temContrato.temContrato ? 'Contrato Ativo' : 'Contrato Inativo'}
                    </Tag>
                </Col>
            </Row>
            <Row>
                <div className="divDetalhes" >
                    <Col span={24}>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Empresa">
                                <b>{manutencao.dados?.pessoa?.empresa?.razaoSocial || 'Não Informado'}</b>
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Gestor Código Cliente">
                                <b>{manutencao.dados?.codigo || 'Não Informado'}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="CPF/CNPJ">
                                <b>{manutencao.dados?.cpfCnpj || 'Não Informado'}</b>
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Nome">
                                <b>{manutencao.dados?.nomeFantasia || 'Não Informado'}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Razão Social">
                                <b>{manutencao.dados?.pessoa?.nome || 'Não Informado'}</b>
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Atividade">
                                <b>{manutencao.dados?.atividade?.descricao || 'Não Informado'}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Categoria">
                                <b>{manutencao.dados?.categoria?.descricao || 'Não Informado'}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </div>
            </Row>
            <Row>
                <div className="divDetalhes" >
                    <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Inscrição Estadual">
                                <b>{manutencao.dados?.pessoa?.pessoaJuridica?.inscricaoEstadual || 'Não Informado'}</b>
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions size="small">
                            <Descriptions.Item label="RG">
                                <b>{manutencao.dados?.pessoa?.pessoaFisica?.rg || 'Não Informado'}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Tipo de Pessoa">
                                <b>{manutencao.dados?.tipoPessoa || 'Não Informado'}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="E-mail">
                                <b>{manutencao.dados?.email || 'Não Informado'}</b>
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions size="small">
                            <Descriptions.Item label="CEP">
                                <b>{manutencao.dados?.endereco?.logradouroCidade?.cep?.numeroCep|| 'Não Informado'}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Cidade">
                                <b>{manutencao.dados?.endereco?.logradouroCidade?.cidade?.descricao || 'Não Informado'}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="UF">
                                <b>{manutencao.dados?.endereco?.logradouroCidade?.cidade?.estado?.sigla || 'Não Informado'}</b>
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions size="small">
                            <Descriptions.Item label="Logradouro">
                                <b>{manutencao.dados?.endereco?.logradouroCidade?.logradouro?.descricaoLogradouro || 'Não Informado'}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Bairro">
                                <b>{manutencao.dados?.endereco?.logradouroCidade?.bairro?.nome || 'Não Informado'}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Número">
                                <b>{manutencao.dados?.endereco?.numero || 'Não Informado'}</b>
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions size="small">
                            <Descriptions.Item label="Complemento">
                                <b>{manutencao.dados?.endereco?.complemento || 'Não Informado'}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </div>
            </Row>
            <Col span={24} className="m-t-8">
                <Typography.Title level={5}>
                    <img src={require("../../assets/i_contato.png").default} alt="Contatos" /> Contatos
                </Typography.Title>
            </Col>
            <Col span={24}>
                <div className="tabela-pages mt-zero">
                    <Table columns={[
                        {
                            title: 'Nome',
                            render: ({ contato }) => (
                                <div>
                                    <b> {contato?.nome || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'E-mail',
                            render: ({ contato }) => (
                                <div>
                                    <b> {contato?.pessoaEmail?.length > 0 ? contato?.pessoaEmail.find(p => p.principal == true)?.email : '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Telefone',
                            width: 120,
                            render: ({ contato }) => (
                                <div>
                                    <b>{contato?.pessoaTelefone?.length > 0 ? FormatFone(contato?.pessoaTelefone.find(p => p.principal == true)?.numero) : '-'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ramal',
                            width: 85,
                            render: ({ contato }) => (
                                <div>
                                    <b> {contato?.pessoaTelefone?.length > 0 ? contato?.pessoaTelefone.find(p => p.principal == true)?.ramal : '-'} </b>
                                </div>
                            ),
                        },
                    ]} locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }}
                        dataSource={listaContatos.listaContatos} scroll={{ x: 920 }} />
                </div>
            </Col>
        </div>
    );
}