import React, { useEffect } from "react";
import { Form, Input } from "antd";

import { useStateValue } from '../../state/stateProvider';
import { listagemActions } from '../../actions';

export default function Search({ labelInput, placeholderInput, campoFiltro = "filtro", nameInput = "", form = null }) {

    const [{ listagem }, dispatch] = useStateValue();

    useEffect(() => { dispatch({ type: listagemActions.CHANGE, data: { filtro: null, campoFiltro: campoFiltro, pagina: 1 } }) }, []);

    return (
        <Form.Item label={labelInput || "Pesquisar por"} name={nameInput}>
            <Input.Search placeholder={placeholderInput || "Pesquisar por..."} onSearch={valor => {
                dispatch({ type: listagemActions.CHANGE, data: { filtro: valor, campoFiltro: campoFiltro, pagina: 1 } })
            }
            } onBlur={valor => {
                if (!!form && !!nameInput) {
                    form.setFieldsValue({ [nameInput]: valor.target.value });
                }
            }} allowClear />
        </Form.Item>
    );

}