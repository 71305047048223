import React, { useState } from "react";
import { Row, Col, Form, Button, Drawer, Modal } from "antd";
import { SaveOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import ManutencaoArea from "./manutencao";
import { useStateValue } from '../../state/stateProvider';
import { drawerActions, manutencaoActions } from '../../actions';

export default function DrawerArea() {

    const [formularioArea] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();

    if (manutencao.dados !== null) {
        formularioArea.setFieldsValue(manutencao.dados);
    }
    else {
        formularioArea.setFieldsValue({ id: null });
    }

    function fecharDrawer() {
        formularioArea.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer
            width="50%"
            title="Manutenção Área"
            open={ui.showDrawer}
            onClose={() => { fecharDrawer() }}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={onClose} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit" onClick={() => formularioArea.submit()} loading={carregando}>
                            Salvar
                        </Button>
                    </Col>
                </Row>
            }>
            <ManutencaoArea form={formularioArea} carregando={setCarregando} aoSalvar={fecharDrawer} />
        </Drawer>
    );
}