import { parseToken } from "./funcoes";

export const TOKEN_KEY = "@SacG-Token";
export const TELAANTERIOR_KEY = "@SacG-TelaAnterior";
export const EMPRESA_KEY = "@SacG-Empresa";
export const LOCAL_KEY = "@SacG-Local";
export const IDAGENDA_KEY = "@SacG-IdAgenda";
export const IDORDEM_KEY = "@SacG-IdOrdem";
export const IDUSUARIO_KEY = "@SacG-IdUsuario";
export const EMAILUSUARIO_KEY = "@SacG-EmailUsuario";
export const NOMEUSUARIO_KEY = "@SacG-NomeUsuario";
export const FOTOUSUARIO_KEY = "@SacG-FotoUsuario";
export const IDSUPERVISOR_KEY = "@SacG-IdSupervisor";
//export const ID_KEY = "@SacG-Id";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getTelaAnterior = () => localStorage.getItem(TELAANTERIOR_KEY);
export const getEmpresa = () => localStorage.getItem(EMPRESA_KEY);
export const getLocal = () => localStorage.getItem(LOCAL_KEY);
export const getIdAgenda = () => localStorage.getItem(IDAGENDA_KEY);
export const getIdOrdem = () => localStorage.getItem(IDORDEM_KEY);
export const getEmailUsuario = () => localStorage.getItem(EMAILUSUARIO_KEY);
export const getNomeUsuario = () => localStorage.getItem(NOMEUSUARIO_KEY);
export const getIdUsuario = () => localStorage.getItem(IDUSUARIO_KEY);
export const getFotoUsuario = () => localStorage.getItem(FOTOUSUARIO_KEY);
export const getIdSupervisor = () => localStorage.getItem(IDSUPERVISOR_KEY);


//export const getIdUsuario = () => localStorage.getItem(ID_KEY);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TELAANTERIOR_KEY);
  localStorage.removeItem(EMPRESA_KEY);
  localStorage.removeItem(LOCAL_KEY);
  localStorage.removeItem(EMAILUSUARIO_KEY);
  localStorage.removeItem(NOMEUSUARIO_KEY);
  localStorage.removeItem(IDUSUARIO_KEY);
  localStorage.removeItem(FOTOUSUARIO_KEY);
  localStorage.removeItem(IDSUPERVISOR_KEY);
  if (window.location.pathname !== '/') window.location = '/';
};
export const getUserInfo = () => {
  let token = localStorage.getItem(TOKEN_KEY);
  if (token === null) return null;
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
export const getEntidadeLogado = () => {
  let dados = getUserInfo();
  return dados?.data.entidade_acesso;
}

export const getNomeEntidadeLogado = () => {
  let dados = getUserInfo();
  return dados.data.entidade_nome;
}

export const getEmpresaLogada = () => {
  let token = parseToken(getToken());
  return parseInt(token.Empresa);
}

export const getLocalLogado = () => {
  let token = parseToken(getToken());
  return parseInt(token.Local);
}

export const getUsuarioLogado = () => {
  let token = parseToken(getToken());
  return parseInt(token.Id);
}

export const setTelaAnterior = tela => {
  localStorage.setItem(TELAANTERIOR_KEY, tela);
};

export const removerTelaAnt = () => {
  localStorage.removeItem(TELAANTERIOR_KEY);
}
export const setEmpresa = empresa => {
  localStorage.setItem(EMPRESA_KEY, empresa);
};
export const setLocal = local => {
  localStorage.setItem(LOCAL_KEY, local);
};

export const setIdAgenda = idAgenda => {
  localStorage.setItem(IDAGENDA_KEY, idAgenda);
};

export const setIdOrdem = idOrdem => {
  localStorage.setItem(IDORDEM_KEY, idOrdem);
};

export const setEmailUsuario = emailUsuario => {
  localStorage.setItem(EMAILUSUARIO_KEY, emailUsuario);
}

export const setNomeUsuario = nomeUsuario => {
  localStorage.setItem(NOMEUSUARIO_KEY, nomeUsuario);
}

export const setIdUsuario = idUsuario => {
  localStorage.setItem(IDUSUARIO_KEY, idUsuario);
}

export const setFotoUsuario = fotoUser => {
  localStorage.setItem(FOTOUSUARIO_KEY, fotoUser);
}

export const setIdSupervisor = idSupervisor => {
  localStorage.setItem(IDSUPERVISOR_KEY, idSupervisor);
}