import React, { useEffect } from "react";
import { Row, Col, Descriptions, Tag, Divider, Checkbox, Alert, Form, Select } from "antd";
import { useStateValue } from "../../state/stateProvider";
import moment from "moment";
import numeral from 'numeral';

export default function DetalheEmail({ cobrancaAprovada }) {

    const [{ manutencao }] = useStateValue();
    const opcoes = [
        { label: 'Sim', value: 3 },
        { label: 'Não', value: 4 },
    ];


    function retornarParticipante(listaParticipantes) {
        let participantes = '';
        if (listaParticipantes?.length > 0) {
            listaParticipantes.forEach((participante) => {
                participantes += (!!participante?.usuario?.nome ? participante.usuario.nome + ", " : '');
            });
            participantes = participantes.substring(0, participantes.length - 2)
        }
        return participantes;
    };

    function retornarRecursos(r) {
        let recurso = '';
        if (!r.recurso.formulario) {
            recurso = r.recurso.descricao;
        } else {
            let recursoForm = JSON.parse(r.recurso.formulario);
            let recursoTipoForm = JSON.parse(r.recurso?.recursoTipo?.formulario);
            if (recursoTipoForm?.length > 0) {
                recursoTipoForm.map((campo) => {
                    recurso += " " + recursoForm.filter(res => !!res[campo.id])[0][campo.id];
                })
            }
            recurso = recurso.trim();
        }

        return (
            <div className="divDetalhes" >
                <Col span={24}>
                    <Row align="middle">
                        <Col>
                            <Descriptions column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }} size="small">
                                <Descriptions.Item label="Tipo">
                                    <b>{r.recurso?.recursoTipo?.descricao}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Nome">
                                    <b>{recurso}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </Col>
            </div>
        )
    };

    const handleChange = (value) => {
        cobrancaAprovada.setCobrancaAprovada(value?.target.checked ? 3 : 4);
    };

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Descriptions column={1} size="small" title={
                        <Row gutter={[8, 0]} className="tituloDetalhe">
                            <Col>{manutencao.dados?.titulo}</Col>
                        </Row>
                    }>
                    </Descriptions>
                </Col>
            </Row>
            <Row>
                <div className="divDetalhes" >
                    <Col span={24}>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Empresa">
                                <b>{manutencao.dados?.empresa?.razaoSocial}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Participantes">
                                <Row>
                                    <Col span={24}>
                                        <b>{manutencao.dados?.agendaParticipantes ? retornarParticipante(manutencao.dados?.agendaParticipantes) : ""}</b>
                                    </Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>

                    <Col span={24}>
                        <Descriptions size="small" >
                            <Descriptions.Item label="Área">
                                <Tag color={manutencao.dados?.sistema?.cor} className="tag-w-100">
                                    <b>{manutencao.dados?.sistema?.descricao}</b>
                                </Tag>
                            </Descriptions.Item>
                            <Descriptions.Item label="Status">
                                {manutencao.dados?.status === 1 && <b className="c-primary">Aguardando</b>}
                                {manutencao.dados?.status === 2 && <b className="c-green">Confirmada</b>}
                                {manutencao.dados?.status === 3 && <b className="c-red">Cancelada</b>}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Usuário">
                                <b>{manutencao.dados?.usuario?.nome}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Tipo de Agenda" className="w-50">
                                <b>{manutencao.dados?.agendaTipo?.descricao}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </div>
            </Row>
            {
                manutencao.dados?.agendaRecursos?.length > 0 ?
                    <Row align="middle" className="m-t-16" >
                        <Col >
                            <Descriptions size="small" title={
                                <Row gutter={[8, 0]} className="tituloDetalhe" >
                                    <Col>RECURSOS: </Col>
                                </Row>
                            }>
                            </Descriptions>
                        </Col>
                        {manutencao.dados?.agendaRecursos.map(r =>
                            retornarRecursos(r)
                        )}
                    </Row>
                    : <Row></Row>
            }
            {manutencao.dados?.ordemServico &&
                <Row>
                    <Col >
                        <Descriptions size="small" title={
                            <Row gutter={[8, 0]} className="tituloDetalhe" >
                                <Col>ORDEM SERVICO - {manutencao.dados?.ordemServico.numero}  </Col>
                            </Row>
                        }>
                        </Descriptions>
                    </Col>
                    <div className="divDetalhes" >
                        <Col span={24}>
                            <Descriptions size="small">
                                <Descriptions.Item label="Cliente">
                                    <Row>
                                        <Col span={24}>
                                            <b>{manutencao.dados?.ordemServico?.pessoa?.nome}</b>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={24}>
                            <Descriptions size="small">
                                <Descriptions.Item label="Usuário">
                                    <Row>
                                        <Col span={24}>
                                            <b>{manutencao.dados?.ordemServico?.usuario?.nome}</b>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>

                        <Col span={24}>
                            <Descriptions size="small" >
                                <Descriptions.Item label="Status">
                                    {manutencao.dados?.ordemServico?.status === 1 && <b className="c-blue">OS em Aberto</b>}
                                    {manutencao.dados?.ordemServico?.status === 2 && <b className="c-yellow">OS à Faturar</b>}
                                    {manutencao.dados?.ordemServico?.status === 3 && <b className="c-red">OS Cancelada</b>}
                                    {manutencao.dados?.ordemServico?.status === 4 && <b className="c-orange">OS Faturada</b>}
                                    {manutencao.dados?.ordemServico?.status === 5 && <b className="c-green">OS Fechada</b>}
                                    {manutencao.dados?.ordemServico?.status === 6 && <b className="c-red">OS Pendente</b>}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        {!!manutencao.dados?.ordemServico?.observacao ? <Col span={24}>
                            <Descriptions size="small">
                                <Descriptions.Item label="Observação">
                                    <Row>
                                        <Col span={24}>
                                            <b>{manutencao.dados?.ordemServico?.observacao}</b>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col> : <Col></Col>}

                    </div>
                </Row>

            }
            {
                manutencao?.dados?.ordemServico?.ordensTarefas?.length > 0 ?
                    <Row align="middle" className="m-t-16" >
                        <Col >
                            <Descriptions size="small" title={
                                <Row gutter={[8, 0]} className="tituloDetalhe" >
                                    <Col>SERVIÇOS: </Col>
                                </Row>
                            }>
                            </Descriptions>
                        </Col>
                        <div className="divDetalhes" >
                            {manutencao?.dados?.ordemServico?.ordensTarefas?.map(o => {
                                return <Row align="middle">
                                    <Col>
                                        <Descriptions column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }} size="small">
                                            <Descriptions.Item label="Tipo Tarefa">
                                                <b>{o.tarefa?.tarefaTipo?.descricao}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Serviço">
                                                <b>{o.produto?.descricao}</b>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            }
                            )}
                        </div>
                    </Row>
                    : <Row></Row>
            }
            {/*
                manutencao?.dados?.ordemServico?.itensCobranca?.length > 0 ?
                    <Row align="middle" className="m-t-16" >
                        <Col >
                            <Descriptions size="small" title={
                                <Row gutter={[8, 0]} className="tituloDetalhe" >
                                    <Col>ITENS DE COBRANÇA: </Col>
                                </Row>
                            }>
                            </Descriptions>
                        </Col>
                        <div className="divDetalhes" >
                            {manutencao?.dados?.ordemServico?.itensCobranca?.map(o => {
                                return <Row align="middle" >
                                    <Col style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Descriptions column={{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 2, xs: 1 }} >
                                            <Descriptions.Item label="Serviço">
                                                <b>{o.produto?.descricao}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Qtde">
                                                <b>{o.quantidade}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Valor Unitário">
                                                <b>{numeral(o?.valorUnitario).format('0,0.00')}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Valor Total">
                                                <b>{numeral(o?.valorTotal).format('0,0.00')}</b>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            }
                            )}
                        </div>
                    </Row>
                    : <Row></Row>
                        */ }
            <Row hidden={!!manutencao.dados?.ordemServico ? (!manutencao.dados?.ordemServico?.possuiCobranca || manutencao.dados?.ordemServico?.cobrancaAprovada === 3) : true} align="middle" className="m-t-16" >
                <Col span={24}>
                    <Alert message="Agenda com cobrança! Para a cobrança ser aplicada é necessário confirmar a mesma no campo abaixo:" type="warning" showIcon />
                </Col>
            </Row>
            <div style={{ height: 10 }}></div>
            <Row hidden={!!manutencao.dados?.ordemServico ? (!manutencao.dados?.ordemServico?.possuiCobranca || manutencao.dados?.ordemServico?.cobrancaAprovada === 3) : true} align="middle">
                <Col xs={24}>
                    <Checkbox  name="cobrancaAprovada" onChange={(value) => handleChange(value)}> Confirmar Cobrança</Checkbox>
                </Col>
            </Row>
        </div>
    );
}