import React, { useEffect, useState } from "react";
import { Row, Col, Table, Button, Typography, Divider, Alert, Modal, Dropdown, Menu, notification } from "antd";
import { EyeOutlined, HighlightOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, AuditOutlined, EditOutlined, MoreOutlined, DollarOutlined } from "@ant-design/icons";

import DrawerManutencaoTarefa from "./drawer";
import { useStateValue } from '../../state/stateProvider';
import { drawerActions, manutencaoActions } from '../../actions';
import api from "../../services/api";
import moment from "moment";
import { isNotNullOrEmpty } from "../../services/funcoes";
import { ModalTermoAceite } from "../modals";
import ModalAssinarTermo from "../modals/modalAssinarTermo";

export default function ItensTarefas({ listaItens, listaAtividade, contatosEmail, formAgenda, formOS, listaTermoAceite, listaCobranca, filtroParticipantes, pessoaId, exibirTodos, assinado, gedInfo, numeroOS }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);
    const [pendenciasFinanceiras, setPendenciasFinanceiras] = useState(false);
    const [cobrancaAdicional, setCobrancaAdicional] = useState(false);
    const [listaStatus, setListaStatus] = useState([]);
    const [openModalTermo, setOpenModalTermo] = useState(false);
    const [openModalAssinar, setOpenModalAssinar] = useState(false);
    // const [pessoaId, setPessoaId] = useState(0);
    const [responsavel, setResponsavel] = useState([]);
    const [statusAtual, setStatusAtual] = useState(1);
    const [enviarAssinar, setEnviarAssinar] = useState(false);
    const [botaoVisivel, setBotaoVisivel] = useState(true);
    const [porcentagemAtual, setPorcentagemAtual] = useState(0);
    // const [assinado, setAssinado] = useState(1);
    const assinados = new Array("Sem Assinatura", "Enviado para Assinar", "Assinado", "Rejeitado");
    const assinadosCor = new Array("DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB");
    const [assinadoTermo, setAssinadoTermo] = useState([]);


    useEffect(() => {

        popularListaStatus();
    }, []);

    function popularListaStatus() {
        api.get("/Enumeravel/StatusTarefa").then(
            res => {
                if (!!res.data) {
                    setListaStatus(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function abrirDrawerTarefa() {
        if (!!formAgenda && listaItens.listaItens.length === 0) {
            Modal.confirm({
                title: 'Aviso!',
                icon: <ExclamationCircleOutlined />,
                content: 'Gerar Tarefas automaticamente?',
                okText: 'Sim',
                cancelText: 'Não',
                centered: true,
                onOk() {
                    gerarTarefas();
                },
                onCancel() {
                    setarDadosEditar();
                    dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerItens: true } });
                }
            });
        } else {
            if (!!formAgenda) {
                setarDadosEditar();
            } else {
                if (!!formOS.getFieldValue().pessoaId) {
                    pessoaId.setPessoaId(formOS.getFieldValue().pessoaId);
                }
                let dados = {};
                dados.key = listaItens.listaItens.length > 0 ? (parseInt(listaItens.listaItens.length) + parseInt(1)) : 1
                dados.idOrdemServico = !!formOS?.getFieldValue().idOrdemServico ? formOS?.getFieldValue().idOrdemServico : manutencao.dados?.idOrdemServico;
                dados.usuarioId = !!formOS?.getFieldsValue().usuarioId ? formOS?.getFieldsValue().usuarioId : manutencao.dados?.usuarioId;
                dados.possuiCobranca = !!formOS?.getFieldsValue().possuiCobranca ? formOS?.getFieldsValue().possuiCobranca : manutencao.dados?.possuiCobranca;
                dados.descricao = !!formOS?.getFieldsValue().observacao ? formOS?.getFieldsValue().observacao : "";
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
            }
            dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerItens: true } });
        }
    };

    function setarDadosEditar() {
        let dados = { ...manutencao.dados };
        dados.descricao = formAgenda.getFieldValue().titulo;
        dados.data = moment(formAgenda.getFieldValue().dataInicial);
        dados.OrdemServicoId = formOS?.getFieldsValue().OrdemServicoId;
        dados.possuiCobranca = formOS?.getFieldsValue().possuiCobranca;
        dados.observacao = formOS?.getFieldsValue().observacao;
        dados.key = listaItens.listaItens.length > 0 ? (parseInt(listaItens.listaItens.length) + parseInt(1)) : 1
        filtroParticipantes.setFiltroParticipantes(formAgenda?.getFieldsValue().listaAgendaParticipantes);

        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });

    };

    function gerarTarefas() {
        let dados = {};
        dados.descricao = formAgenda.getFieldValue().titulo;
        dados.Data = moment(formAgenda.getFieldValue().dataInicial);
        dados.Participantes = formAgenda.getFieldValue().listaAgendaParticipantes;
        if (dados?.Participantes?.length > 0) {

            api.post(`OrdemServico/GerarTarefas`, dados).then(
                res => {
                    listaItens.setListaItens(res.data.map((item, idx) => ({ key: idx + 1, ...item })));
                }
            ).catch(
                error => {
                    console.log(error);
                }
            )
        } else {
            notification.warning({ message: 'Aviso', description: `Participantes não foi informado!` });
        }
    };

    function excluirTarefa(record) {
        let dadosAtividade = [...listaAtividade.listaAtividade];
        dadosAtividade = dadosAtividade.filter(a => a.key !== record.key);
        for (const [idx, item] of dadosAtividade.entries()) {
            item.key = idx + 1;
        }
        listaAtividade.setListaAtividade(dadosAtividade);

        let dados = [...listaItens.listaItens];
        dados = dados.filter(t => t !== record);
        for (const [idx, item] of dados.entries()) {
            item.key = idx + 1;
        }
        listaItens.setListaItens(dados)


    };


    async function buscarRowGrid(idOrdemServico, idTermoAceite) {
        let req = await api.get(`Ged/BuscarRowGrid?idOrdemServico=${idOrdemServico}&tipoAnexo=2&idTermoAceite=${idTermoAceite}`);
        if (req.status === 200) {
            return req.data;
        }
        return false;
    };

    function menuTable(record) {
        record.assinaturaGed = (record.termoAceite?.length > 0 ? (record.termoAceite[record.termoAceite.length - 1].gedInfo?.length > 0 ? record.termoAceite[record.termoAceite.length - 1]?.gedInfo[0].assinaturaGed : 1) : 1);
        record.hiddenTermoAceite = (!record.idOrdemTarefa || record.status !== 4);
        record.hiddenAssinarTermo = !record.idOrdemTarefa || record.status !== 4 || assinado !== 3 || (record?.termoAceite?.length > 0 ? (record.termoAceite[record.termoAceite.length - 1].gedInfo?.length > 0) : false);
        record.hiddenVisualizarTermo = (record.termoAceite?.length > 0 ? record?.termoAceite[record.termoAceite.length - 1]?.gedInfo?.length === 0 : true);


        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<AuditOutlined />} onClick={() => {
                        if (!validarObjeto(record?.termoAceite)) {
                            let dadosTermoAceite = {};
                            dadosTermoAceite.key = record.key;
                            dadosTermoAceite.idOrdemServico = record.idOrdemServico;
                            dadosTermoAceite.idOrdemTarefa = record.idOrdemTarefa;
                            dadosTermoAceite.tecnicoResponsavel = record.responsavel.nome;
                            listaTermoAceite.setListaTermoAceite(dadosTermoAceite);
                        } else {
                            record.termoAceite[0].key = record.key;
                            record.termoAceite[0].tecnicoResponsavel = record.responsavel.nome;
                            listaTermoAceite.setListaTermoAceite(record.termoAceite[0]);
                        }

                        setOpenModalTermo(true);
                    }
                    } >
                        Gerar Termo de Aceite Implantação</Button>
                </Menu.Item>
                {botaoVisivel && <Menu.Item key="2" hidden={record?.hiddenAssinarTermo}>
                    <Button type="text" icon={<HighlightOutlined />} onClick={() => {
                        if (!validarObjeto(record?.termoAceite)) {
                            let dadosTermoAceite = {};
                            dadosTermoAceite.key = record.key;
                            dadosTermoAceite.idOrdemServico = record.idOrdemServico;
                            dadosTermoAceite.idOrdemTarefa = record.idOrdemTarefa;
                            dadosTermoAceite.tecnicoResponsavel = record.responsavel.nome;
                            listaTermoAceite.setListaTermoAceite(dadosTermoAceite);
                        } else {
                            record.termoAceite[0].key = record.key;
                            record.termoAceite[0].tecnicoResponsavel = record.responsavel.nome;
                            listaTermoAceite.setListaTermoAceite(record.termoAceite[0]);
                        }
                        setResponsavel(record.responsavel);
                        setOpenModalAssinar(true);
                    }} >
                        Assinar Termo de Aceite Implantação</Button>
                </Menu.Item>}
                <Menu.Item key="3" hidden={record?.hiddenVisualizarTermo}>
                    <Button type="text" icon={<EyeOutlined />} onClick={async () => {
                        let req = await buscarRowGrid(record.idOrdemServico, record.termoAceite[0].id);
                        if (req) {
                            window.open('https://gedapi.abase.com.br/Ged/docPublico?RowGuid={' + record.termoAceite[0]?.gedInfo[0]?.anexo + '}', '_blank');
                        } else {
                            notification.warning({ description: "Esta ordem de serviço não foi enviada para assinar ainda.", message: "Aviso" });
                        }
                    }}>Visualizar Termo de Aceite
                        {record.assinaturaGed === 1 && <b className="c-red"> (Sem Assinatura) </b>}
                        {record.assinaturaGed === 2 && <b className="c-blue"> (Aguardando) </b>}
                        {record.assinaturaGed === 3 && <b className="c-green"> (Assinado)</b>}
                        {record.assinaturaGed === 4 && <b className="c-red"> (Rejeitado)</b>}
                    </Button>
                </Menu.Item>
                <Menu.Item key="4" style={{ display: formOS ? 'block' : 'none' }} >
                    <Button type="text" icon={<DollarOutlined />} onClick={() => {
                        let dados = {};
                        dados.produtoId = record.produtoId;
                        let anoMesCobranca = new Date();
                        dados.anoMesCobranca = anoMesCobranca.setMonth(new Date().getMonth() + 1)
                        dados.descricao = formOS?.getFieldsValue().observacao;
                        dados.sequencia = parseInt(listaCobranca.listaCobranca.length) + parseInt(1);
                        dispatch({ type: manutencaoActions.CHANGE, data: { dadosCobranca: dados } });
                        dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerExtra: true } });
                    }} > Criar Cobrança da Tarefa</Button>
                </Menu.Item>
            </Menu>
        )
    };

    function validarObjeto(objeto) {
        // Verifica se o objeto não é nulo e não é indefinido
        if (objeto && typeof objeto === 'object' && Object.keys(objeto).length > 0) {
            return true; // O objeto tem dados válidos
        }

        return false; // O objeto não tem dados válidos
    }

    return (
        <>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={5}>
                        <img src={require("../../assets/i_itens.png").default} alt="Itens" /> Itens
                    </Typography.Title>
                    <Divider orientation="left" plain className="m-t-0 m-b-5">
                        Informe os Itens abaixo.
                    </Divider>
                </Col>
                <Col>
                    <div className="i-Position">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => {
                            abrirDrawerTarefa();
                        }} />
                    </div>
                </Col>
            </Row>
            <div className="tabela-pages">
                <Table loading={carregando} columns={[
                    {
                        title: 'Sequência',
                        align: 'center',
                        width: 100,
                        render: ({ key }) => (
                            <div>
                                <b>
                                    {key}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Tarefas',
                        fixed: 'left',
                        render: ({ tarefaTipo }) => (
                            <div>
                                <b>
                                    {tarefaTipo?.descricao}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Usuário',
                        render: ({ responsavel }) => (
                            <div>
                                <b>
                                    {responsavel?.nome}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Serviço',
                        render: ({ produto }) => (
                            <div>
                                <b>
                                    {produto?.descricao}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Porc.%',
                        width: 80,
                        align: 'center',
                        render: ({ porcentagemAndamento }) => (
                            <div>
                                <b>
                                    {isNotNullOrEmpty(porcentagemAndamento) ? porcentagemAndamento : '-'}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Status',
                        width: 110,
                        render: ({ status }) => (
                            <div>
                                <b>
                                    {listaStatus.find(s => s.valor === status)?.descricao}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        width: 95,
                        align: 'center',
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => {
                                            debugger;
                                            let dados = { ...record };
                                            dados.dataInicio = !!moment(record.DataInicio) ? moment(record.DataInicio) : moment(record.dataInicio);
                                            dados.dataFim = !!moment(record.DataFim) ? moment(record.DataFim) : moment(record.dataFim);
                                            dados.usuarioId = !!formOS?.getFieldsValue().usuarioId ? formOS?.getFieldsValue().usuarioId : manutencao.dados?.usuarioId;
                                            dados.possuiCobranca = !!formOS?.getFieldsValue().possuiCobranca ? formOS?.getFieldsValue().possuiCobranca : manutencao.dados?.possuiCobranca;
                                            dados.status = !!formOS?.getFieldsValue().status ? formOS?.getFieldsValue().status : manutencao.dados?.status;
                                            dados.produtoId = !!record.ProdutoId ? record.ProdutoId : record.produtoId;
                                            dados.tarefaTipoId = !!record.TarefaTipoId ? record.TarefaTipoId : record.tarefaTipoId;

                                            if (!!formAgenda) {
                                                filtroParticipantes.setFiltroParticipantes(formAgenda?.getFieldsValue().listaAgendaParticipantes);
                                                if (!dados.descricao) {
                                                    dados.descricao = formAgenda.getFieldValue().titulo;
                                                }
                                                if (!dados.data) {
                                                    dados.data = moment(formAgenda.getFieldValue().dataInicial);
                                                } else {
                                                    dados.data = !!record.Data ? moment(record.Data) : moment(record.data);
                                                }
                                            } else {
                                                dados.data = !!record.Data ? moment(record.Data) : moment(record.data);
                                            }

                                            setStatusAtual(!!record?.status ? record.status : 1);
                                            setPorcentagemAtual(!!record?.porcentagemAndamento ? record.porcentagemAndamento : 0);
                                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                                            dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerItens: true } });
                                        }} />
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => excluirTarefa(record)} />
                                    </Col>
                                    <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button /*hidden={record.hiddenTermoAceite && record.hiddenAssinarTermo && record.hiddenVisualizarTermo}*/ onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]}
                    locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }} scroll={{ x: 920 }} dataSource={listaItens.listaItens}
                />
            </div>
            <Row align="middle" gutter={[0, 8]} className="m-t-8">
                {pendenciasFinanceiras && <Col span={24}>
                    <Alert message={
                        <Row gutter={[8, 0]}>
                            <Col>
                                <b>Pendências Financeiras.</b>
                            </Col>
                            <Col>
                                Este cliente possui pendências financeiras no sistema.
                            </Col>
                        </Row>
                    } type="error" showIcon />
                </Col>}
                {cobrancaAdicional && <Col span={24}>
                    <Alert message={
                        <Row gutter={[8, 0]}>
                            <Col>
                                <b>Cobrança Adicional.</b>
                            </Col>
                            <Col>
                                Conforme Contrato 062/2017 ...
                            </Col>
                        </Row>
                    } type="info" showIcon />
                </Col>}
            </Row>
            <DrawerManutencaoTarefa form={formAgenda} listaItens={listaItens} listaAtividade={listaAtividade} formOS={formOS}
                statusAtual={{ statusAtual, setStatusAtual }} porcentagemAtual={{ porcentagemAtual, setPorcentagemAtual }}
                filtroParticipantes={filtroParticipantes} pessoaId={pessoaId} exibirTodos={exibirTodos} />
            <ModalTermoAceite form={formAgenda} exibirModal={openModalTermo} fecharModal={() => setOpenModalTermo(false)} listaTermoAceite={listaTermoAceite} formOS={formOS} listaItens={listaItens} listaAtividade={listaAtividade} />
            <ModalAssinarTermo botaoVisivel={{ botaoVisivel, setBotaoVisivel }} responsavel={responsavel} contatosEmail={contatosEmail} numeroOS={numeroOS} form={formAgenda} exibirModal={openModalAssinar} fecharModal={() => setOpenModalAssinar(false)} listaTermoAceite={listaTermoAceite} formOS={formOS} listaItens={listaItens} listaAtividade={listaAtividade} />
        </>
    );
}