import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table, notification, Modal } from "antd";
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, ExclamationOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import DrawerRestaurante from "./drawer";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import { FormatFone, FormatNumber } from "../../valueObjects";
import { BreadcrumbIncluirDados, Search, TabelaDados } from "../../components";
import api from "../../services/api";

export default function Restaurante() {

    const [formRestaurante] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+sgp_nome' } }), []);

    function excluirRegistro(record) {
        api.post(`Restaurante/Excluir?=${record.id}`).then(
            (res) => {
                notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" });
            }).catch((erro) => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            }).finally(
                () => {
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+id' } });
                });
    }

    const modalExcluir = (record) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(record);
            }
        });
    };

    return (
        <div className="col-paginas">
            <BreadcrumbIncluirDados pagina="Restaurantes" />
            <Form layout="vertical" form={formRestaurante} name="restaurante">
                <Row>
                    <Col span={24}>
                        <Search />
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url="Restaurante" colunas={[
                        {
                            title: 'Nome',
                            render: ({ nome }) => (
                                <div>
                                    <b>{nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Telefone',
                            width: 105,
                            render: ({ telefone }) => (
                                <div>
                                    <b>{FormatFone(telefone) || 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Endereço',
                            render: ({ endereco }) => (
                                <div>
                                    <b>{endereco || 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Cidade',
                            render: ({ cidade }) => (
                                <div>
                                    <b>{cidade || 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Preferência',
                            align: 'center',
                            render: ({ preferencia }) => (
                                <div>
                                    <b>{preferencia || 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor Buffet Livre (R$)',
                            align: 'right',
                            render: ({ valor_buffet_livre }) => (
                                <div>
                                    <b>{FormatNumber(!!valor_buffet_livre ? valor_buffet_livre : 0, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor por KG (R$)',
                            align: 'right',
                            render: ({ valor_buffet_kg }) => (
                                <div>
                                    <b>{FormatNumber(!!valor_buffet_kg ? valor_buffet_kg : 0, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Tipo',
                            render: ({ tipo }) => (
                                <div>
                                    <b>{tipo || 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Café da Manhã',
                            align: 'center',
                            width: 105,
                            render: ({ cafe_da_manha }) => (
                                <div>
                                    {!!cafe_da_manha ? <CheckOutlined /> : <CloseOutlined />}
                                </div>
                            ),
                        },
                        {
                            title: 'Almoço',
                            align: 'center',
                            width: 58,
                            render: ({ almoco }) => (
                                <div>
                                    {!!almoco ? <CheckOutlined /> : <CloseOutlined />}
                                </div>
                            ),
                        },
                        {
                            title: 'Jantar',
                            align: 'center',
                            width: 55,
                            render: ({ jantar }) => (
                                <div>
                                    {!!jantar ? <CheckOutlined /> : <CloseOutlined />}
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                            }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={modalExcluir(record)} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
                </div>
            </Form>
            <DrawerRestaurante />
        </div>
    );
}