const drawerActions = {
    CHANGE: 'changeDrawer'
}

const manutencaoActions = {
    CHANGE: 'changeManutencao'
}

const listagemActions = {
    CHANGE: 'changeListagem'
}

const tributacaoActions = {
    CHANGE: 'changeTributacao'
}

const parametrosEmpresaActions = {
    CHANGE: 'changeParametrosEmpresa'
}

const selectPaginadoActions = {
    CHANGE: 'changeSelectPaginado'
}

const listarSelectPaginado = {
    CHANGE: 'changeSelectPaginado'
}


// const manutencaoEditar = {
//     CHANGE: 'changeManutencaoEditar'
// }
export { 
    drawerActions, manutencaoActions, listagemActions, tributacaoActions, parametrosEmpresaActions, selectPaginadoActions, listarSelectPaginado
}