import React, { useState } from "react";
import { Row, Col, Button, Typography, Divider, Card } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { ModalAdicionarUsuario } from "../../components/modals/";

export default function TabUsuarioGrupo() {

    const [openModal, setOpenModal] = useState(false);

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={5}>
                        <img src={require("../../assets/i_settings.png").default}  alt="Usuário do Grupo" /> Informações Usuário do Grupo
                    </Typography.Title>
                    <Divider orientation="left" plain className="m-t-0 m-b-5">
                        Adicione um novo usuário.
                    </Divider>
                </Col>
                <Col>
                    <div className="i-Position">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => setOpenModal(true)}/>
                    </div>
                </Col>
            </Row>
            <div className="colUsers">
                <Row justify="center" gutter={[16,16]}>
                                        <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={4}>
                        <Card hoverable
                            cover={
                                <img alt="Usuário" src={``} />
                            }
                        >
                            <Row align="middle" justify="center" gutter={[0, 8]}>
                                <Col span={24}>
                                    <Card.Meta title="Nome Usuário" description="E-mail usuário" />
                                </Col>
                                <Col span={24}>
                                    <Button type="primary" block>
                                        Incluir no Grupo
                                    </Button>
                                </Col>
                                <Col span={24}>
                                    <Button type="primary" block danger>
                                        Remover do Grupo
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ModalAdicionarUsuario exibirModal={openModal} fecharModal={() => setOpenModal(false)} />
        </div>
    );

}