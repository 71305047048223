import { Row, Col, Button, Drawer, Modal, notification } from "antd";
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined, StopOutlined } from "@ant-design/icons";
import { listagemActions, manutencaoActions } from "../../actions";
import DetalheOS from "./detalhe";
import { useStateValue } from "../../state/stateProvider";
import { useState } from "react";
import api from "../../services/api";
import moment from "moment";

export default function DrawerDetalhe({ openDrawerDetalhe, closeDrawerDetalhe }) {

    const [carregando, setCarregando] = useState(false);
    const [{ manutencao }, dispatch] = useStateValue();
    const [openAgenda, setOpenAgenda] = useState(false);

    function fecharDrawer() {
        
        let url = window.location.href;
        if (url.indexOf('?') > -1) {
            window.location.href = (url.substring(0, url.indexOf('?')));
        }
        let date = new Date();
        let dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);
        let dataFinal = new Date(new Date().setDate(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()));
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&DataInicial=${moment(dataInicial).format("YYYY-MM-DD HH:mm")}&DataFinal=${moment(dataFinal).format("YYYY-MM-DD HH:mm")}`, ordem: '-Numero', pagina: 1 } });
        closeDrawerDetalhe();
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    async function ConfirmarCobranca(record) {
        if (record.status !== 6) {
            notification.warn({ message: 'Aviso', description: 'Só é possível confirmar cobrança para Ordens com status "OS Pendente"' });
        } else {
            await api.post(`OrdemServico/ConfirmarOrdem`, record).then(
                (res) => {
                    console.log(res.data);
                    if (res.data.search("não informado") > -1 || res.data.search("não tem autorização") > -1) {
                        notification.error({ message: 'Aviso', description: res.data });
                    } else {
                        notification.success({ message: 'Aviso', description: res.data });
                    }
                }).catch(
                    (erro) => {
                        console.error(erro);
                    }).finally(() => {
                        fecharDrawer();
                    })

        }
    };


    function RejeitarCobranca(record) {
        if (record.status !== 6) {
            notification.warn({ message: 'Aviso', description: 'Só é possível confirmar cobrança para Ordens com status "OS Pendente"' });
        } else {
            api.post(`OrdemServico/RejeitarOrdem`, record).then(
                (res) => {
                    console.log(res.data);
                    if (res.data.search("não informado") > -1 || res.data.search("não tem autorização") > -1) {
                        notification.error({ message: 'Aviso', description: res.data });
                    } else {
                        notification.success({ message: 'Aviso', description: res.data });
                        fecharDrawer();
                    }
                }).catch(
                    (erro) => {
                        console.error(erro);
                    })
        }
    };

    return (
        <Drawer
            width="65%"
            title="Detalhes"
            open={openDrawerDetalhe}
            onClose={fecharDrawer}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={fecharDrawer} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<CheckOutlined />} size="large" type="primary" onClick={async () =>  {
                            await ConfirmarCobranca(manutencao?.dados);
                        }}>
                            Confirmar Cobrança
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<StopOutlined />} size="large" type="primary" danger onClick={() => {
                            RejeitarCobranca(manutencao?.dados);
                        }}>
                            Rejeitar Cobrança
                        </Button>
                    </Col>
                </Row>

            }>
            <DetalheOS openDrawerDetalhe={openDrawerDetalhe} />
        </Drawer>
    );
}