import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Modal, Typography, Select, notification } from "antd";
import { ExclamationCircleOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import MaskedInput from 'antd-mask-input';
import SelectPaginado from "../../selectPaginado";
import { getEmpresa, getLocal, getIdUsuario } from "../../../services/auth";
import { useStateValue } from "../../../state/stateProvider";

import 'moment/locale/pt-br';
import moment from 'moment';
import api from "../../../services/api";
import { listagemActions, manutencaoActions } from "../../../actions";
import { downloadRelatorioJasper } from "../../../services/funcoes";

export default function ModalTermoAceite({ form, exibirModal, fecharModal, listaTermoAceite, formOS, listaItens }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [formTermoAceite] = Form.useForm();

    useEffect(() => {
        formTermoAceite.resetFields();
        if (validarObjeto(listaTermoAceite.listaTermoAceite)) {
            formTermoAceite.setFieldsValue(listaTermoAceite.listaTermoAceite);
        }
    }, [exibirModal]);

    function validarObjeto(objeto) {
        // Verifica se o objeto não é nulo e não é indefinido
        if (objeto && typeof objeto === 'object' && Object.keys(objeto).length > 0) {
            return true; // O objeto tem dados válidos
        }

        return false; // O objeto não tem dados válidos
    }


    const onCloseModalTermoAceite = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                formTermoAceite.resetFields();
                fecharModal();
            }
        });
    };

    async function salvarTermo(values) {
        if (!form?.getFieldValue().pessoaId && !formOS?.getFieldValue().pessoaId) {
            notification.warn({ message: "Aviso", description: "Para gerar o termo de aceite é necessário que um cliente seja informado." });
            fecharModal();
            return;
        }

        let arrayDadosTermo = [];
        let dadosTermoAceite = {};

        dadosTermoAceite.id = formTermoAceite.getFieldValue().id;
        dadosTermoAceite.idOrdemServico = !!formTermoAceite?.getFieldValue().idOrdemServico ? formTermoAceite?.getFieldValue().idOrdemServico : formOS?.getFieldValue().idOrdemServico;
        dadosTermoAceite.idOrdemTarefa = !!formTermoAceite?.getFieldValue().idOrdemTarefa ? formTermoAceite?.getFieldValue().idOrdemTarefa : formOS?.getFieldValue().idOrdemServico;
        dadosTermoAceite.empresaId = getEmpresa();
        dadosTermoAceite.modeloId = formTermoAceite.getFieldValue().modeloId;
        dadosTermoAceite.pessoaId = !!form?.getFieldValue().pessoaId ? form?.getFieldValue().pessoaId : formOS.getFieldValue().pessoaId;
        dadosTermoAceite.usuarioId = !!formOS?.getFieldValue().usuarioId ? formOS?.getFieldValue().usuarioId : getIdUsuario();
        dadosTermoAceite.nomeSistema = formTermoAceite.getFieldValue().nomeSistema;
        dadosTermoAceite.tecnicoResponsavel = formTermoAceite.getFieldsValue().tecnicoResponsavel;
        dadosTermoAceite.dataGeracao = moment(new Date());
        if (validarObjeto(listaTermoAceite.listaTermoAceite)) {
            dadosTermoAceite.gedInfo = !!listaTermoAceite.listaTermoAceite?.gedInfo ? listaTermoAceite.listaTermoAceite.gedInfo : [];
        }

        listaTermoAceite.setListaTermoAceite(dadosTermoAceite);
        arrayDadosTermo.push(dadosTermoAceite);
        let dados = [...listaItens.listaItens];
        for (var i = 0; i < dados.length; i++) {
            if (dados[i].key === values.key) {
                dados[i].termoAceite = arrayDadosTermo;
            }
        }
        listaItens.setListaItens(dados);

        gerarRelatorio(dadosTermoAceite);
        fecharModal();
        form?.setFieldsValue({ nomeSistema: null, modeloId: null });
        formOS?.setFieldsValue({ nomeSistema: null, modeloId: null });
    };

    function gerarRelatorio(values) {
        let relatorioId = "", nomeRelatorio = "";
        let filtros = retornaFiltros(values);

        switch (values.modeloId) {
            case 1:
                relatorioId = 44;
                nomeRelatorio = "Termo Aceite - Abase";
                break;
            case 3:
                relatorioId = 46;
                nomeRelatorio = "Termo Aceite - GOV";
                break;
        }
        downloadRelatorioJasper(relatorioId, filtros, "PDF", nomeRelatorio);
    };

    function retornaFiltros(values) {
        return [{ "nome": "pessoa_id", "valor": values.pessoaId },
        { "nome": "tecnico_responsavel", "valor": values.tecnicoResponsavel },
        { "nome": "sistema_nome", "valor": values.nomeSistema },
        { "nome": "emp_id", "valor": values.empresaId }];
    }


    return (

        <Modal centered
            title="Termo de Aceite"
            open={exibirModal}
            onCancel={onCloseModalTermoAceite}
            onOk={() => formTermoAceite.submit()}
            maskClosable={false}
            width={700}
        >
            <Form layout="vertical" name="manutencaoTermoAceite" form={formTermoAceite} onFinish={salvarTermo} >
                <Row gutter={[0, 8]}>
                    <Col span={24}>
                        <Form.Item hidden name="key" form={formTermoAceite}></Form.Item>
                        <Form.Item name="id" hidden form={formTermoAceite} />
                        <Form.Item name="idOrdemServico" hidden form={formTermoAceite} />
                        <Form.Item name="idOrdemTarefa" hidden form={formTermoAceite} />
                        <Form.Item name="tecnicoResponsavel" hidden form={formTermoAceite} />
                        <Form.Item label="Modelo Termo" name="modeloId" rules={[{ required: true, message: 'Informe o Modelo do termo de aceite' }]}>
                            <SelectPaginado url="ModeloTermoAceite/Listar" form={formTermoAceite} name="modeloId" conteudo={
                                modelo => (<Select.Option value={modelo.id} key={modelo.id}>{modelo.descricao}</Select.Option>)
                            } />
                        </Form.Item>
                        <Form.Item form={formTermoAceite} label="Nome Sistema" name="nomeSistema" rules={[{ required: true, message: 'Informe o Nome do Sistema' }]}>
                            <Input placeholder="Informe o nome do sistema" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>

    );
}