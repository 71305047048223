import React, { useState } from "react";
import { Row, Col, Drawer, Modal, Button, notification } from "antd";
import { useStateValue } from "../../state/stateProvider";

import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { CloseOutlined, EditOutlined, ExclamationCircleOutlined, CheckOutlined } from "@ant-design/icons";
import Detalhe from "../../components/agendaDetalhe/detalhe";
import { AgendaCadastro } from "../../components";
import DrawerAgenda from "../../components/agendaCadastro/drawerAgenda";
import api from "../../services/api";
import moment from 'moment';
import DetalheEmail from "./detalheEmail";

export default function AgendaEmail({ openDrawerAgendaEmail, closeDrawerAgendaEmail }) {
    const [{ manutencao }, dispatch] = useStateValue();
    const [openAgenda, setOpenAgenda] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [cobrancaAprovada, setCobrancaAprovada] = useState(4);


    function fecharDrawer() {
        setCarregando(false);
        window.location.href = (window.location.href.substring(0, window.location.href.indexOf('?')));
        closeDrawerAgendaEmail();
        let data = new Date().getDate();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: listagemActions.CHANGE, data: { filtro: `&dataInicial=${moment(new Date().setHours(0, 0, 0, 0)).format('YYYY-MM-DD HH:mm')}&dataFinal=${moment(new Date(new Date().setDate((data + 15))).setHours(23, 59, 0, 0)).format('YYYY-MM-DD HH:mm')}`, ordem: '+DataInicial,Id' } });
    }
    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();

            }
        });
    };

    function confirmarAgendaOS(record) {
        debugger;
        if (!!record?.ordemServico) {
            //Se não possui cobrança, automaticamente é sem cobrança
            if (!record.ordemServico.possuiCobranca){
                record.ordemServico.cobrancaAprovada = 1;    
            } else {
                record.ordemServico.cobrancaAprovada = cobrancaAprovada;
            }
            //Se for rejeitado, não vai possuir cobrança 
            if (record.ordemServico.cobrancaAprovada === 4) {
                record.ordemServico.possuiCobranca = false;
            }
            record.ordemServico.status = 1;
            delete record.ordemServico.itensCobranca;
        }

        if (record.status !== 1) {
            notification.warn({ message: 'Aviso', description: 'Apenas agendas com o status de "Aguardando" podem ser confirmadas!' });
        } else {
            let dados = record;
            delete dados.usuario;
            dados.agendaParticipantes.map(p => {
                delete p.usuario;
            });
            delete dados.agendaTimeline;
            api.post(`Agenda/ConfirmarAgenda`, record).then(
                (res) => {
                    console.log(res.data);
                    if (res.data.search("não informado") > -1 || res.data.search("não tem autorização") > -1) {
                        notification.error({ message: 'Aviso', description: res.data });
                    } else {
                        notification.success({ message: 'Aviso', description: res.data });
                        fecharDrawer();

                    }
                }).catch(
                    (erro) => {
                        console.error(erro);
                    })
        }
    };

    return (
        <Drawer
            width="65%"
            title="Detalhes"
            open={openDrawerAgendaEmail}
            onClose={() => { fecharDrawer() }}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={onClose} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button hidden={manutencao?.dados?.status !== 1} loading={carregando} icon={<CheckOutlined />} size="large" type="primary" onClick={() => {
                            confirmarAgendaOS(manutencao?.dados); setCarregando(true);
                        }}>
                            Confirmar Agenda
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<EditOutlined />} size="large" type="primary" onClick={() => {
                            fecharDrawer();
                            setOpenAgenda(true);

                        }}>
                            Editar
                        </Button>
                    </Col>
                </Row>
            }>
            <DetalheEmail cobrancaAprovada={{ cobrancaAprovada, setCobrancaAprovada }} />
            <DrawerAgenda openDrawerAgenda={openAgenda} closeDrawerAgenda={() => setOpenAgenda(false)} />
        </Drawer>
    )
}