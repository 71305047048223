import React, { useState } from "react";
import { Row, Col, Form, Input, Typography, Modal, Button } from "antd";
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from "@ant-design/icons";

export default function ModalAdicionarUsuario({ exibirModal, fecharModal }) {

    const [listaLocais, setListaLocais] = useState([]);
    const [form] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Realmente deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
                form.resetFields();
                setListaLocais([]);
            }
        });
    };

    return (

        <Modal centered
            title="Novo Usuário"
            open={exibirModal}
            onCancel={onCloseModal}
            destroyOnClose
            footer={listaLocais.length > 0 ?
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onCloseModal} icon={<CloseOutlined />} size="middle" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => form.submit()} icon={<SaveOutlined />} size="middle" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
                : null
            }
            maskClosable={false}
        >
            <Form layout="vertical" initialValues={{ remember: true }} form={form}>
                <Row gutter={[0, 8]}>
                    <Col span={24}>
                        <Typography.Text>
                            Adicione um novo usuário.
                        </Typography.Text>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="E-mail" name="usu_email" rules={[{ required: true, message: 'Informe o E-mail do Usuário' }]}>
                            <Input placeholder="Informe o E-mail do Usuário" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Nome" name="usu_nome" rules={[{ required: true, message: 'Informe o Nome do Usuário' }]}>
                            <Input placeholder="Informe o Nome do Usuário" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>

    );
}