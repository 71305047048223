import React, { useState } from "react";
import { Row, Col, Form, Input, Select, Slider } from "antd";

import { InputPreco, InputTelefone } from "../../components";

export default function ManutencaoHotel({form}) {

    const valueMaximo = 10;
    const [valorPreferencia, setValorPreferencia] = useState(0);
    const [valuePreferenciaSlider, setValuePreferenciaSlider] = useState(0);

    const handleChange = (value) => {
        setValorPreferencia(value);
        setValuePreferenciaSlider(value);
    };

    return (
        <Form layout="vertical" name="formManutencaoHotel" form={form}>
            <Row gutter={[8, 0]}>    
                <Col xs={24} md={12} lg={12} xl={12}>
                    <Form.Item label="Nome" name="nome">
                        <Input placeholder="Informe o Nome" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={14} md={8} lg={8} xl={8}>
                    <Form.Item label="E-mail" name="email">
                        <Input placeholder="Informe o E-mail" />
                    </Form.Item>
                </Col>
                <Col xs={12} sm={10} md={4} lg={4} xl={4}>
                    <InputTelefone label="Telefone" name="telefone" />
                </Col>
                <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                    <InputTelefone label="Celular" name="celular" />
                </Col>
                <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                    <Form.Item label="Tipo" name="tipo">
                        <Select placeholder="Selecione o Tipo" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={14} md={12} lg={7} xl={7}>
                    <Form.Item label="Site" name="site">
                        <Input placeholder="Informe o Site" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={10} md={8} lg={5} xl={5}>
                    <Form.Item>
                        <label> 
                            Preferência: <span>{valorPreferencia}</span> 
                        </label>
                        <Slider tipFormatter={null} min={0} max={valueMaximo} onChange={handleChange} value={valuePreferenciaSlider} />
                    </Form.Item>
                </Col>                
                <Col xs={12} sm={8} md={8} lg={5} xl={5}>
                    <InputPreco label="Valor Standard" name='valor_standard' />
                </Col>
                <Col xs={12} sm={8} md={8} lg={5} xl={5}>
                    <InputPreco label="Valor Duplo" name='valor_duplo' />
                </Col>
                <Col xs={24} sm={8} md={8} lg={5} xl={5}>
                    <InputPreco label="Valor Triplo" name='valor_triplo' />
                </Col>
                <Col xs={24} sm={24} md={16} lg={9} xl={9}>
                    <Form.Item label="Endereço" name="endereco">
                        <Input placeholder="Informe o Endereço" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={5} xl={5}>
                    <Form.Item label="Referência" name="referencia">
                        <Input placeholder="Informe uma Referência" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={5} xl={5}>
                    <Form.Item label="Estado" name="estado">
                        <Select placeholder="Selecione um Estado" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={5} xl={5}>
                    <Form.Item label="Cidade" name="cidade">
                        <Select placeholder="Selecione uma Cidade" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={16} lg={9} xl={9}>
                    <Form.Item label="Coordenadas" name="coordenadas">
                        <Input placeholder="Informe as Coordenadas" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>    
                <Col span={24} className="m-t-8">
                    MAPA
                </Col>
            </Row>
        </Form>  
    );
}