
import React from "react";
import { Row, Col } from "antd";

import { BreadcrumbPage, BotaoIncluirDados } from "../../components";

export default function BreadcrumbIncluirDados(props) {

    return (
        <div>
            <Row align="middle" justify="space-between" gutter={[8, 0]}>
                <Col>
                    <BreadcrumbPage {...props} />
                </Col>
                <Col>
                    <BotaoIncluirDados {...props} />
                </Col>
            </Row>
        </div>
    );

}