import React, { useEffect, useState } from "react";
import { Row, Col, Form, Checkbox, Typography, Divider, Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import DrawerLocalizacao from "./drawerLocalizacao";
import { useStateValue } from "../../state/stateProvider";
import { drawerActions, selectPaginadoActions } from "../../actions";
import SelectPaginado from "../selectPaginado";
import MapCadastro from "../mapaCadastro";

export default function Local({ form, coordenadas, coordenadasCEP}) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [exibeMapa, setExibeMapa] = useState(false);
    const [coordenadasTeste, setCoordenadasTeste] = useState([-27.772189914285715, -54.23928487142857]);
    

    useEffect(() => {
        let lista = [];
        if (!!form.getFieldsValue().localId) {
            lista.push({ name: "localId", campo: "id", value: form.getFieldsValue().localId });
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: lista });
        }
    }, [])

    function verMapa() {
        setExibeMapa(!exibeMapa);
    };

    function vincularLocal(id) {
        if (!!id) {
            dispatch({ type: selectPaginadoActions.CHANGE, data: [{ name: "localId", campo: "id", value: id }] });
            form.setFieldsValue({ localId: id });
        }
    };

    function vincularCoordenadas(posicaoMapa){
        if (!!posicaoMapa) {
            coordenadas.setCoordenadas(posicaoMapa);
        }
    }

    function buscarCoordenadas(valor, lista) {
        if (lista.find(l => l.id == valor)) {
            coordenadas.setCoordenadas([lista.find(l => l.id == valor).posicaoMapa.x, lista.find(l => l.id == valor).posicaoMapa.y]);
        }
    }


    return (
        <>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={5}>
                        <img src={require("../../assets/i_local.png").default} alt="Local" /> Local
                    </Typography.Title>
                    <Divider orientation="left" plain className="m-t-0 m-b-5">
                        Informe o local da agenda.
                    </Divider>
                </Col>
                <Col>
                    <div className="i-Position">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => {
                            dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerLocalizacao: true } });
                        }} />
                    </div>
                </Col>
            </Row>
            <Row gutter={[8, 0]}>
                <Col xs={24} md={20} lg={21} xl={21}>
                    <Form.Item label="Local" name="localId" rules={[{ required: true, message: 'Informe um Local' }]}>
                        <SelectPaginado form={form} url="Localizacao" name="localId" setarPrimeiroReg={true} placeholder="Selecione um Local"
                            onChangeList={(valor, lista) => (buscarCoordenadas(valor, lista))}
                            conteudo={
                                local => (<Select.Option value={local.id} key={local.id}>{local.endereco}</Select.Option>)
                            } />
                    </Form.Item>
                </Col>
                <Col xs={24} md={4} lg={3} xl={3} className="btn-col-21">
                    <Form.Item valuePropName="checked" name="mapa">
                        <Checkbox onChange={() => { verMapa() }}> Ver Mapa </Checkbox>
                    </Form.Item>
                </Col>
                {exibeMapa &&
                    <Col span={24}>
                        <MapCadastro coordenadas={coordenadas}/>
                    </Col>
                }
            </Row>
            <DrawerLocalizacao formLocalizacao={form} vincularLocal={vincularLocal} vincularCoordenadas={vincularCoordenadas} coordenadasCEP={coordenadasCEP}/>
        </>
    );
}