import React from "react";
import { Row, Col, Form, Select, Input, notification, Checkbox } from "antd";

import api from "../../services/api";
import { listagemActions } from "../../actions";
import { parseToken } from "../../services/funcoes";
import { useStateValue } from "../../state/stateProvider";

export default function ManutencaoTipoTarefa({ form, carregando, aoSalvar }) {

    const [, dispatch] = useStateValue();

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+descricao' } });
    };

    const salvarRegistro = async (values) => {
        let token = parseToken();
        if (!values.id) values.id = null;
        values.EmpresaId = token.Empresa;
        carregando(true);
        let retorno = values.id ? await api.put('/TarefaTipo', values) : await api.post('/TarefaTipo', values);
        if (retorno.status === 200) {
            notification.success({ message: 'Operação concluída', description: "Registro salvo com sucesso!" });
            retorno = retorno.data.id;
        }
        else notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(retorno.data) });
        atualizarPagina();
        carregando(false);
        aoSalvar(retorno);
    }

    return (
        <Form layout="vertical" name="formManutencaoTipoTarefa" form={form} onFinish={salvarRegistro}>
            <Row gutter={[8, 0]}>
                <Form.Item name="id" hidden />
                <Col xs={24} sm={14} md={16} lg={18} xl={16} xxl={18}> 
                    <Form.Item label="Descrição" name="descricao" rules={[{ required: true }]}>
                        <Input placeholder="Informe uma Descrição" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={10} md={8} lg={6} xl={8} xxl={6}>
                    <Form.Item label="Unidade de Medida" name="unidadeMedida" initialValue="Nenhum">
                        <Select placeholder="Selecione a unidade">
                            <Select.Option value="Nenhum">Nenhum</Select.Option>
                            <Select.Option value="Horas">Horas</Select.Option>
                            <Select.Option value="Dias">Dias</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="ativo" initialValue={true} valuePropName="checked" hidden={(!(form.getFieldValue(['id']) > 0))}>
                        <Checkbox>
                            Ativo?
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}