import React, { useState } from 'react';
import { Row, Col, Table, Divider } from "antd";
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { BreadcrumbPage } from "../../components";

export default function Notificacao() {

    const [ pagina, setPagina ] = useState(1);
    const [ carregando, setCarregando ] = useState(false);
    const [ listaNotificacao, setListaNotificacao ] = useState([
        {
            key: '1',
            not_datahora: 28092021,
            not_descricao: 'teste descrição',
        },
    ]);
    const [ registrosPorPagina, setRegistrosPorPagina ] = useState(10);
    
    return (
        <div className="col-paginas">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col>
                    <BreadcrumbPage />
                </Col>
            </Row>
            <div className="tabela-pages m-t-25">
                <Table loading={carregando} columns={[
                    {
                        title: 'Data',
                        width: 120,
                        render: ({ not_datahora }) => (
                            <div>
                                <b>
                                    {moment(not_datahora).format('DD/MM/YYYY HH:mm')}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Notificações',
                        render: ({ not_descricao }) => (
                            <div>
                                <b> {not_descricao} </b>
                            </div>
                        ),
                    },
                    {
                        title: <CheckOutlined className="cursor-pointer" />,
                        width: 40,
                        align: 'center',
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <CheckOutlined className="cursor-pointer" />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]}
                    locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }}
                    scroll={{ x: 920 }} dataSource={listaNotificacao} pagination={{
                        current: listaNotificacao.page,
                        pageSize: listaNotificacao.perPage,
                        total: listaNotificacao.total,
                        onChange: (pg) => setPagina(pg),
                        onShowSizeChange: (atual, porPagina) => setRegistrosPorPagina(porPagina) 
                    }}
                />
            </div>
        </div>
    );

}