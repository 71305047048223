import React, { useEffect, useState } from "react";
import { Row, Col, Form, Checkbox, Input, Select, Slider, notification } from "antd";

import { InputPreco, InputTelefone } from "../../components";
import api from "../../services/api";
import { useStateValue } from "../../state/stateProvider";

export default function ManutencaoRestaurante({ formulario, aoSalvar, carregando }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const valueMaximo = 10;
    const [valorPreferencia, setValorPreferencia] = useState(0);
    const [tiposRestaurante, setTipoRestaurante] = useState([]);
    const [listaPessoas, setListaPessoas] = useState([]);
    const [valuePreferenciaSlider, setValuePreferenciaSlider] = useState(0);

    const handleChange = (value) => {
        setValorPreferencia(value);
        setValuePreferenciaSlider(value);
    };

    function carregarDados() {
        api.get('Enums/TipoRestaurante').then(
            res => {
                setTipoRestaurante(res.data.items);
            }
        ).catch(error => {
            console.log(error);
        });
        api.get(`Pessoa`).then(
            res=> {
                setListaPessoas(res.data);
            }
        ).catch(error => console.log(error));
    }

    useEffect(() => {
        api.get(`/Pessoa?pageNumber=1&pageSize=100&ordem=+id`).then(res => {
            console.log(res.data.items);
        }).catch(error => { console.log(error) });
    }, [])

    function salvarManutencao(values) {
        values.notaAvaliacao = valuePreferenciaSlider;
        carregando(true);
        if (!!values.id) {
            api.put(`Restaurante`, values).then(
                res => {
                    notification.success({ message: `Sucesso!`, description: `Restaurante editado com sucesso!` });
                }
            ).catch(
                error => {
                    notification.warning({ message: `Aviso!`, description: `Erro ao editar o Restaurante` });
                }
            ).finally(() => {
                aoSalvar();
            })

        } else {
            api.post(`Restaurante`, values).then(
                res => {
                    notification.success({ message: `Sucesso!`, description: `Restaurante salvo com sucesso!` });
                }
            ).catch(
                error => {
                    notification.warning({ message: `Aviso!`, description: `Erro ao salvar o Restaurante` });
                }).finally(() => {
                    aoSalvar();
                });
        }
    }

    useEffect(() => {
        if (!!manutencao.dados) {
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [manutencao.dados])

    useEffect(() => {
        carregarDados();
    }, [])

    return (
        <Form layout="vertical" name="formManutencaoRestaurante" form={formulario} onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Form.Item name='id' hidden />
                <Col xs={24} md={12} lg={12} xl={12}>
                    <Form.Item label="Nome" name="nome">
                        <Input placeholder="Informe o Nome" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={14} md={8} lg={8} xl={8}>
                    <Form.Item label="E-mail" name="email">
                        <Input placeholder="Informe o E-mail" />
                    </Form.Item>
                </Col>
                <Col xs={12} sm={10} md={4} lg={4} xl={4}>
                    <InputTelefone label="Telefone" name="telefone" />
                </Col>
                <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                    <InputTelefone label="Celular" name="celular" />
                </Col>
                <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                    <Form.Item label="Tipo" name="tipo">
                        <Select
                            placeholder="Selecione o Tipo"
                            allowClear
                            optionFilterProp="children"
                            showSearch >
                            {tiposRestaurante.map((res) => (
                                <Select.Option value={res.id} key={res.id}>{res.descricao}</Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={14} md={12} lg={7} xl={7}>
                    <Form.Item label="Site" name="site">
                        <Input placeholder="Informe o Site" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={10} md={8} lg={5} xl={5}>
                    <Form.Item>
                        <label>
                            Preferência: <span>{valorPreferencia}</span>
                        </label>
                        <Slider tipFormatter={null} min={0} max={valueMaximo} onChange={handleChange} value={valuePreferenciaSlider} />
                    </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8} lg={5} xl={5}>
                    <InputPreco label="Valor Buffet Livre" name='precoBuffetLivre' />
                </Col>
                <Col xs={12} sm={12} md={8} lg={5} xl={5}>
                    <InputPreco label="Valor por Kilo" name='precoBuffetKg' />
                </Col>
                <Col xs={24} sm={24} md={16} lg={9} xl={9}>
                    <Form.Item label="Endereço" name="endereco">
                        <Input placeholder="Informe o Endereço" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={5} xl={5}>
                    <Form.Item label="Referência" name="pes_id">
                        <Select
                            placeholder="Selecione o Tipo"
                            allowClear
                            optionFilterProp="children"
                            showSearch >
                            {listaPessoas.map((pes) => (
                                <Select.Option value={pes.id} key={pes.id}>{pes.pes_nome}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={5} xl={5}>
                    <Form.Item label="Cidade" name="cidade">
                        <Input placeholder="Informe a Cidade" />
                    </Form.Item>
                </Col>
                <Col className="top-mobile">
                    <Row>
                        <Col>
                            <Form.Item valuePropName="checked" name="almoco">
                                <Checkbox> Almoço </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item valuePropName="checked" name="janta">
                                <Checkbox> Jantar </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item valuePropName="checked" name="cafeManha">
                                <Checkbox> Café da Manhã </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={6} lg={7} xl={7} xxl={5}>
                    <Form.Item label="Coordenadas" name="coordenadas">
                        <Input placeholder="Informe as Coordenadas" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="m-t-8">
                    MAPA
                </Col>
            </Row>
        </Form>
    );
}