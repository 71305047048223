import React from 'react';
import moment from 'moment'
import 'moment/locale/pt-br';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import './App.less';
import { Login, Home, AgendaEmail } from "./pages";
import { reducer } from './state/reducer';
import { isAuthenticated } from './services/auth';
import { StateProvider } from './state/stateProvider';

/*const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
            <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
        }
    />
);*/

function App() {

    moment.locale('pt-br');

    const initialState = {
        userConfig: { size: 'small' },
        ui: { showDrawer: false, showDrawerRecurso: false, showDrawerItens: false, showDrawerExtra: false, showDrawerLocalizacao: false },
        manutencao: { dados: null }
    };
  
    return (
        <StateProvider initialState={initialState} reducer={reducer}>
            <ConfigProvider locale={ptBR} componentSize="small">
                <Router>
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/home" component={Home} />
                    </Switch>
                </Router>
            </ConfigProvider>
        </StateProvider>
    );
}

export default App;