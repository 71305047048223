import React from "react";
import { Row, Col, Button, Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import Detalhes from "./detalhes";

export default function DrawerLog({ exibirDetalhes, fecharDetalhes }) {

    return (
        <Drawer 
            width="40%"
            title="Detalhes do Log"
            open={exibirDetalhes}
            onClose={fecharDetalhes}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={fecharDetalhes} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                </Row>
            }>
            <Detalhes />
        </Drawer>
    );
}