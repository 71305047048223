import React from 'react';
import moment from 'moment';
import { Form, Input } from 'antd';

export default function Data(props, onBlurFunction) {

    function subtrairSomarDias(data, dias, soma) {
        if (soma) {
            return new Date(data.getTime() +
                (dias * 24 * 60 * 60 * 1000));
        } else {
            return new Date(data.getTime() -
                (dias * 24 * 60 * 60 * 1000));
        }
    }
    class NovaData extends React.Component {
        onChange = e => {
            const { value } = e.target;
            let data = value;
            if (!!value) {
                /*let ano = moment(value).format("YYYY");
                if (ano > 1000) {
                    let dataAtual = new Date();
                    let dataHora = new Date(moment(value));
                    dataHora.setHours(dataAtual.getHours());
                    dataHora.setMinutes(dataAtual.getMinutes());
                    dataHora.setSeconds(dataAtual.getSeconds());
                    data = moment(dataHora);
                }*/
            }
            this.props.onChange(data);
        };

        onBlur = (e) => {
            if (!!props.onBlur) {
                props.onBlur();
            }
        }

        onKeyUp = e => {
            let key = e.which || e.keyCode;

            if (key === 46) {
                this.props.onChange(null);
                return;
            }

            let data = null;
            if (key === 68) { // tecla D
                //passa a data atual
                data = new Date();
            }
            if (key === 79) { // tecla O
                //passa a data anterior
                data = subtrairSomarDias(new Date(), 1, false);
            }
            if (key === 65) { // tecla A
                //passa a data posterior
                data = subtrairSomarDias(new Date(), 1, true);
            }
            if (key === 73) {
                //passa a data inicial do mês
                let date = new Date();
                data = new Date(date.getFullYear(), date.getMonth(), 1);
            }
            if (key === 70) {
                //passa a data final do mês
                let date = new Date();
                data = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            }

            if (!!data) {
                this.props.onChange(moment(data));
            }

            if (!!props.onKeyUp) {
                props.onKeyUp();
            }
        }

        onPressEnter = () => {
            if (!!props.onPressEnter) {
                props.onPressEnter();
            }
        };

        onFocus = () => {
            if (!!props.onFocus) {
                props.onFocus();
            }
        };

        render() {
            const { value } = this.props;
            let data = value;
            if (!!!value && !!props.initialValue) {
                data = props.initialValue;
            }
            if (typeof value === 'object' && moment(data).isValid()) {
                data = !!props.month ? moment(data).format('YYYY-MM') : moment(data);
            } else if (!(!!data)) {
                data = moment(null);
            }
            return (
                <Input className="text-uppercase" disabled={!!props.disabled ? props.disabled : false} type={!!props.month ? "month" : "datetime-local"} value={data} onChange={this.onChange} onBlur={this.onBlur} onKeyUp={this.onKeyUp} onPressEnter={this.onPressEnter} onFocus={this.onFocus} />
            );
        }
    };

    class DataInput extends React.Component {
        constructor(props) {
            super(props);
            this.state = { value: '' };
        }

        onChange = value => {
            this.setState({ value });
            if (!!props.onChange) {
                props.onChange();
            }
        };

        onBlur = value => {
            this.setState({ value });
            if (!!props.onBlur) {
                props.onBlur();
            }
        };

        render() {
            return (
                <div className="form-data">
                    <Form.Item name={props.name} label={props.label} initialValue={props.initialValue} style={!!props.style ? props.style : {}} className={!!props.className ? props.className : ''} hidden={!!props.hidden} rules={!!props.rules ? props.rules : []}>
                        <NovaData onChange={this.onChange} onBlur={this.onBlur} />
                    </Form.Item>
                </div>
            )
        }
    };

    function onBlur(valor) {
        if (!!onBlurFunction) {
            onBlurFunction(valor);
        }
    };

    return (
        
        <DataInput
        onBlur={(valor) => { onBlur(valor); }}
        />
    );
};