import React, { useState } from "react";
import { Row, Col, Form, Avatar, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import moment from 'moment';

import DrawerDetalhes from "./drawer";
import { drawerActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbPage, Search, TabelaDados } from "../../components";

export default function UsuarioLocal() {

    const [formUsuarioLocal] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();

    return (
        <div className="col-paginas m-t-2">
            <BreadcrumbPage />
            <Form layout="vertical" form={formUsuarioLocal} name="usuario_local" className="m-t-2">
                <Row>
                    <Col span={24}>
                        <Search />
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url="" colunas={[                      
                        {
                            title: 'Localizar Pessoas',
                            render: ({ usuario }) => (
                                <div>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col>
                                            <Avatar size="small" src={require("../../assets/logo_abase.svg").default} />
                                        </Col>
                                        <Col>
                                            {usuario} 
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                        {
                            title: 'Local',
                            width: 300,
                            render: ({ local }) => (
                                <div>
                                    <b> {local || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Última Atualização',
                            width: 148,
                            render: ({ data }) => (
                                <div>
                                    <b>
                                        {moment(data).format('DD/MM/YYYY HH:mm:ss')}
                                    </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EyeOutlined />} onClick={() => {
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true} });
                                            }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />            
                </div>
            </Form>
            <DrawerDetalhes />
        </div>
    );
}