import React, { useEffect, useState } from "react";
import { Row, Col, Form, Radio, List, notification } from "antd";
import api from "../../services/api";

export default function Recurso({ form, listaTodosRecursos }) {

    function retornaRecursos(item) {
        let recursoTipo = JSON.parse(item.recursoTipo.formulario);
        let recurso = JSON.parse(item.formulario);

        return (
            <Col span={8}>
                {recursoTipo?.map((campo) => (
                    <Row align="middle">
                        <Col>
                            {campo.label}: <b>{recurso.filter(res => !!res[campo.id])[0][campo.id]}</b>
                        </Col>
                    </Row>
                ))}
            </Col>
        )
    };

    /* VER PRA POPULAR SOMENTE NA HORA DE SALVAR, DAÍ ISSO SERIA FEITO JUNTO COM O MÉTODO DE SALVAR */
    function selecionarItem(item) {
        if (item.locado) {
            notification.warn({ description: "Recurso não pode ser selecionado, pois o mesmo já se encontra locado!", message: "Aviso" });
        } else {
            let descricaoRecurso = '';
            let recursoTipo = JSON.parse(item.recursoTipo.formulario);
            let recurso = JSON.parse(item.formulario);
            if (recursoTipo?.length > 0) {
                recursoTipo.map((campo) => {
                    descricaoRecurso += recurso.filter(res => !!res[campo.id])[0][campo.id] + " ";
                })
            }
            descricaoRecurso += item.descricao;
            form.setFieldsValue({ recurso: descricaoRecurso })
        }
    };

    return (
        <Row>
            <Col span={24}>
                Selecione abaixo o recurso que você deseja
            </Col>
            <Col span={24} className="m-t-8">
                <Form.Item name="recursoId">
                    <Radio.Group className="w-100">
                        <List
                            itemLayout="vertical"
                            size="small"
                            pagination={{
                                pageSize: 6,
                            }}
                            dataSource={listaTodosRecursos}
                            renderItem={item => (
                                <List.Item>
                                    <Row align="middle" gutter={[8, 0]} className="m-t-b-8">
                                        <Col span={8}>
                                            <Radio value={item.id} disabled={item.locado} onClick={() => { selecionarItem(item) }}>
                                                {item.descricao}
                                            </Radio>
                                        </Col>
                                        {retornaRecursos(item)}
                                        <Col span={8}>
                                            Locado: <b>{item.locado ? 'Sim' : 'Não'}</b>
                                        </Col>
                                    </Row>
                                </List.Item>
                            )}
                        />
                    </Radio.Group>
                </Form.Item>
            </Col>
        </Row>
    );
}