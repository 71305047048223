import React from "react";
import { Row, Col, Descriptions, Divider } from "antd";

export default function Detalhes() {

    return (
        <Row gutter={[8, 8]}>
            <Col span={24}>
                <Descriptions size="small" title={
                    <Row gutter={[8, 0]}>
                        <Col>Mensagem: </Col>
                        <Col>Atualizou um Agenda </Col>
                    </Row>
                } column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Momento">
                        <b>14/10/2021 07:05</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Usuário">
                        <b>Recepção Abase</b>
                    </Descriptions.Item>
                </Descriptions>
                <Divider />
            </Col>
        </Row>
    );
}