import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Layout, Row, Col, Typography, Form, Input, Button, message, Select } from 'antd';
import { SendOutlined, UserOutlined, MailOutlined, UnlockFilled, UserSwitchOutlined, GoogleOutlined } from '@ant-design/icons';

import api from "../../services/api";
import { signInWithGoogle, logOut } from "../../services/firebase";
import { getIdAgenda, login, setEmpresa, setIdAgenda, setLocal, setNomeEmpresa, setNomeLocal, setStatusFilial, setEmailUsuario, setIdUsuario, setNomeUsuario, setFotoUsuario, setIdSupervisor, getIdOrdem, setIdOrdem } from "../../services/auth";
import { Slide, ClienteParceiro } from "./pages";

import './index.less';

const PAGE = {
    LOGIN: 1,
    SELECIONA_EMPRESA: 2,
    LOGIN_CLIENTE_PARCEIRO: 3,
}

function Login() {

    const history = useHistory();

    const [formulario] = Form.useForm();
    const [formularioEmpresaLocal] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [formularioExibir, setFormularioExibir] = useState(PAGE.LOGIN);
    const [empresasUsuario, setEmpresasUsuario] = useState([]);
    const [locaisUsuario, setLocaisUsuario] = useState([]);

    async function googleLogin() {
        setCarregando(true);
        let usuario = await signInWithGoogle();
        usuario.user.getIdToken().then(async token => {
            login(token);
            setFotoUsuario(usuario?.user?.photoURL);
            let retornoLoginSistema = await api.post(`/LoginWithGoogle`);
            let tokenSistema = retornoLoginSistema.data.token;
            setIdUsuario(retornoLoginSistema.data.codigo);
            setEmailUsuario(retornoLoginSistema.data.email);
            setNomeUsuario(retornoLoginSistema.data.nome);
            setIdSupervisor(retornoLoginSistema.data?.idSupervisor);
            await api.put(`LoginWithGoogle/UpdateAvatar?avatar=${usuario?.user?.photoURL}`);
            if (!!tokenSistema) processarLogin(tokenSistema);
            else setCarregando(false);
        });
    }

    async function carregarLocaisUsuario(empresaId) {
        let retorno = await api.get(`/LocaisUsuario?EmpresaId=${empresaId}&Ativo=true&PageNumber=1&PageSize=500`);
        setLocaisUsuario(retorno.data.items);
        return retorno.data;
    }

    async function selecionarEmpresaLocal(empresaId, localId) {
        let retorno = await api.post('/SelecionaEmpresaLocal', {
            empresa: empresaId,
            local: localId
        });

        login(retorno.data);
        setEmpresa(empresaId);
        setLocal(localId);
        
        debugger;
        if (getIdAgenda() != 0) {
            history.push("/home#/agenda?" + getIdAgenda())
            setIdAgenda(0);
            setIdOrdem(0);
         } else if (getIdOrdem() != 0){
            history.push("/home#/ordemservico?" + getIdOrdem())
            setIdAgenda(0);
            setIdOrdem(0);
         } else {
            history.push("/home");
        }
    }

    const onFinish = values => {
        setCarregando(true);
        api.post("/Login", { usuario: values.email, senha: values.senha }).then(res => {
            processarLogin(res.data.token);
            setIdUsuario(res.data.codigo);
            setEmailUsuario(res.data.email);
            setNomeUsuario(res.data.nome);
            setIdSupervisor(res.data?.idSupervisor);
        }, err => {
            message.error('Erro ao efetuar o login, verifique o usuário e senha informados e tente novamente.');
            setCarregando(false);
        });
    };

    function processarLogin(jwtToken) {
        login(jwtToken);
        setTimeout(() => {
            api.get('/EmpresasUsuario?Ativa=true&PageNumber=1&PageSize=500').then(async res => {
                setEmpresasUsuario(res.data.items);
                if (res.data.totalItems === 1) {
                    let retornoLocais = await carregarLocaisUsuario(res.data.items[0].id);
                    if (retornoLocais.totalItems === 1) {
                        await selecionarEmpresaLocal(res.data.items[0].id, retornoLocais.items[0].id);
                    }
                    else if (retornoLocais.totalItems > 1) setFormularioExibir(PAGE.SELECIONA_EMPRESA);
                }
                else if (res.data.totalItems > 1) setFormularioExibir(PAGE.SELECIONA_EMPRESA);
                if (res.data.totalItems === 0) {
                    message.error('Erro ao carregar os dados de empresas do usuário');
                }
            }, err => {
                message.error('Erro ao carregar os dados de empresas do usuário');
            }).finally(() => { setCarregando(false) });
        }, 500);
    }

    const onFinishFailed = errorInfo => {
        console.log('Erro ao efetuar o login:', errorInfo);
    };

    return (
        <Layout className="vh100">
            <Layout.Content>
                <Row justify="center" className="vh100">
                    <Col className="vh100 fixed-left">
                        <Slide />
                    </Col>
                    <Col className="vh100 fixed-right">
                        <Row align="middle" justify="center" className="vh100" gutter={[16, 8]}>
                            <Col span={18}>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <img src={require("../../assets/logo_sacg.svg").default} width="185" alt="SacG" />
                                    </Col>
                                </Row>
                                {formularioExibir === PAGE.LOGIN &&
                                    <Form layout="vertical" name="basic" form={formulario} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                                        <Row align="middle" justify="center" className="m-t-12">
                                            <Col span={24}>
                                                <Typography.Text>
                                                    Identifique abaixo suas credenciais para realizar o acesso.
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="m-t-12">
                                                <Form.Item name="email" placeholder="Informe seu e-mail" rules={[{ required: true, message: 'Informe seu e-mail!' }]} >
                                                    <Input prefix={<MailOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item placeholder="Informe sua senha" name="senha" rules={[{ required: true, message: 'Informe sua senha de acesso!' }]} >
                                                    <Input.Password prefix={<UnlockFilled />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Row align="middle" justify="end" gutter={[8, 0]}>
                                                    <Col>
                                                        <Button type="primary" loading={carregando} htmlType="submit" size="middle">
                                                            Acessar <SendOutlined />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24} className="m-t-5">
                                                {carregando === false &&
                                                    <Row align="middle" justify="center" gutter={[8, 12]}>
                                                        <Col>
                                                            <Button icon={<UserOutlined />} block onClick={() => setFormularioExibir(PAGE.LOGIN_CLIENTE_PARCEIRO)}>
                                                                Entrar como Cliente
                                                            </Button>
                                                        </Col>
                                                        <Col>
                                                            <Button icon={<GoogleOutlined />} type="primary" block onClick={() => googleLogin()}>
                                                                Entrar com Gmail
                                                            </Button>
                                                        </Col>
                                                        <Col>
                                                            <Button icon={<UserSwitchOutlined />} block onClick={() => setFormularioExibir(PAGE.LOGIN_CLIENTE_PARCEIRO)}>
                                                                Entrar como Parceiro
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                    </Form>
                                }
                                {formularioExibir === PAGE.SELECIONA_EMPRESA &&
                                    <Form layout="vertical" name="selecionaEmpresaLocal" form={formularioEmpresaLocal}
                                        onFinish={({ empresaId, localId }) => {
                                            selecionarEmpresaLocal(empresaId, localId);
                                        }} onFinishFailed={(errorInfo) => { console.log(errorInfo) }} autoComplete="off">
                                        <Row className="m-t-12">
                                            <Col span={24}>
                                                <Form.Item name="empresaId" label="Empresa">
                                                    <Select allowClear placeholder="Selecione a empresa" showSearch filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    } onChange={(selecionado) => carregarLocaisUsuario(selecionado)}>
                                                        {empresasUsuario.map((empresa, index) => {
                                                            return (
                                                                <Select.Option value={empresa.id} key={index}>
                                                                    {empresa.nomeFantasia}
                                                                </Select.Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="localId" label="Local">
                                                    <Select allowClear placeholder="Selecione o local" showSearch filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    } onChange={() => { formularioEmpresaLocal.submit(); }}
                                                    >
                                                        {locaisUsuario.map((filial) => {
                                                            return (
                                                                <Select.Option value={filial.id} key={filial.id}>
                                                                    {filial.descricao}
                                                                </Select.Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                }
                                {formularioExibir === PAGE.LOGIN_CLIENTE_PARCEIRO &&
                                    <ClienteParceiro formulario={formulario} onAcessar={() => setFormularioExibir(PAGE.LOGIN)} />
                                }
                            </Col>
                        </Row>
                        <Row align="middle" justify="center" gutter={[0, 8]} className="position-logo">
                            <Col>
                                <img src={require("../../assets/logo_abase.svg").default} width="50" alt="Abase Sistemas e Soluções" />
                            </Col>
                            <Col span={24} align="center">
                                Todos os direitos reservados - SacG ®
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
}
export default Login;