import React, { useEffect } from "react";
import { Row, Col, Form, Button, notification, Modal, Popover, Tag } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import api from '../../services/api';
import DrawerGrupoUsuario from "./drawer";
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbIncluirDados, Search, TabelaDados } from "../../components";
import { drawerActions, manutencaoActions, listagemActions } from "../../actions";

export default function GrupoUsuario() {

    const [formHotel] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    
    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+gru_nome' } }), []);

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Excluir o grupo de usuários ${dados.gru_nome}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    function excluirRegistro(dados) {
        api.post(`GrupoUsuario/Excluir?grupoUsuarioId=${dados.gru_id}`).then((res) => {
            if (res.status === 200) {
                notification.success({ message: 'Operação concluída', description: "Grupo de usuários excluído com sucesso!" });
            }
        }, (err) => {
            modalInativarGrupo(dados);
        }).finally(() => {
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+gru_id' } });
        });
    }

    const modalInativarGrupo = (dados) => {
        Modal.confirm({
            title: `Não foi possível excluir o grupo de usuários ${dados.gru_nome}!`,
            icon: <CloseCircleOutlined />,
            content: `Deve conter referências com outros registros!`,
            okText: 'Inativar Registro',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                dados.gru_ativo = false;
                dados.usuarios = [];
                dados.usuarios = [];
                api.post(`GrupoUsuario/Editar`, dados).then((res) => {
                    if (res.status === 200) {
                        notification.success({ message: 'Operação concluída', description: "Grupo de Usuários inativado com sucesso!" });
                    }
                }, err => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
                }).finally(() => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+gru_id' } });
                });
            }
        });
    };

    return (
        <div className="col-paginas">
            <BreadcrumbIncluirDados pagina="Hotel" />
            <Form layout="vertical" form={formHotel} name="hotel">
                <Row>
                    <Col span={24}>
                        <Search campoFiltro="Descricao" />
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url="grupousuario/listar" colunas={[
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ gru_ativo, gru_id }) => (
                                <Popover content={gru_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                    <Tag color={gru_ativo ? 'processing' : 'default'} className="tag-w-100">
                                        <b>{gru_id}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Grupos de Usuários',
                            render: ({ gru_nome }) => (
                                <div>
                                    <b>{gru_nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 98,
                            fixed: 'right',
                            render: (record, dados) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>   
                                            <Button icon={<DeleteOutlined />} onClick={() => modalExcluir(dados)} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
                </div>
            </Form>
            <DrawerGrupoUsuario />
        </div>
    );
}