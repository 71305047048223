import React from "react";
import { Row, Col, Card, Typography } from "antd";
import { Link } from "react-router-dom";

import { BreadcrumbPage } from "../../components";

export default function Inicial() {

    return (
        <div className="col-paginas">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col>
                    <BreadcrumbPage pagina="Home" />
                </Col>
            </Row>
            <Row justify="center" gutter={[16, 16]} className="m-t-16">
                <Col xs={24} md={12} lg={12} xl={12}>
                    <Link to="/agenda">
                        <Card hoverable style={{ backgroundImage: `url(${require("../../assets/img-agenda.png").default})` }} className="card-dashboard">
                            <Row>
                                <Col>
                                    <Typography.Title level={2}> Agenda </Typography.Title>
                                </Col>
                            </Row>
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                    <Link to="/calendario">
                        <Card hoverable style={{ backgroundImage: `url(${require("../../assets/img-calendario.png").default})` }} className="card-dashboard">
                            <Row>
                                <Col>
                                    <Typography.Title level={2}> Calendário </Typography.Title>
                                </Col>
                            </Row>
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                    <Link to="/ordemServico">
                        <Card hoverable style={{ backgroundImage: `url(${require("../../assets/img-ordem-servico.png").default})` }} className="card-dashboard">
                            <Row>
                                <Col>
                                    <Typography.Title level={2}> Ordem de Serviço </Typography.Title>
                                </Col>
                            </Row>
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} md={12} lg={12} xl={12}>
                    <Link to="/recursoLocacao">
                        <Card hoverable style={{ backgroundImage: `url(${require("../../assets/img-reserva-recurso.png").default})` }} className="card-dashboard">
                            <Row>
                                <Col>
                                    <Typography.Title level={2}> Reserva de Recursos </Typography.Title>
                                </Col>
                            </Row>
                        </Card>
                    </Link>
                </Col>
            </Row>
        </div>
    );
}