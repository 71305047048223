import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Drawer, Modal } from "antd";

import { CloseOutlined, FileDoneOutlined, ExclamationCircleOutlined, SendOutlined } from "@ant-design/icons";
import ManutencaoEnviarOS from './detalhe';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';
import { useStateValue } from '../../state/stateProvider';
import ManutencaoAssinarOS from "./detalhe";

export default function DrawerAssinarOS({ openDrawerAssinar, listaEmail, pessoaEmail, dadosRecord, closeDrawerAssinar }) {

    const [formularioEnviarOS] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    function fecharDrawer() {
        setCarregando(false);
        closeDrawerAssinar();
        formularioEnviarOS.resetFields();
        dispatch({ type: listagemActions.CHANGE, data: { ordem: "-Numero" } });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };


    return (
        <Drawer
            width="40%"
            title="Assinar Ordem de Serviço"
            open={openDrawerAssinar}
            onClose={onClose}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={fecharDrawer} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<SendOutlined />} loading={carregando} size="large" type="primary" htmlType="submit" onClick={() => { setCarregando(true);formularioEnviarOS.submit(); }}>
                            Enviar
                        </Button>
                    </Col>
                </Row>
            }>
            <ManutencaoAssinarOS listaEmail={listaEmail} carregando={setCarregando} pessoaEmail={pessoaEmail} form={formularioEnviarOS} dadosRecord={dadosRecord} aoSalvar={fecharDrawer} />
        </Drawer>
    );
}