import React, { useEffect } from "react";
import { Row, Col, Form, Button,  Input,Collapse, Select } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { BreadcrumbPage, Data, SelectPaginado, TabelaDados } from "../../components";
import { useStateValue } from "../../state/stateProvider";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import moment from 'moment';
import { useState } from "react";
import api from "../../services/api";

export default function ConsultarTermoAceite() {

    const [formConsultarTermo] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [open, setOpen] = useState(false);
    const [listaAssinatura, setListaAssinatura] = useState([]);

    const togglePesquisa = () => {
        setOpen(!open);
    };


    useEffect(() => {
        buscarDadosAssinar();
        let date = new Date();
        let dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);
        let dataFinal = new Date(new Date().setDate(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()));
        formConsultarTermo.setFieldsValue({ dataInicial: moment(dataInicial.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm") });
        formConsultarTermo.setFieldsValue({ dataFinal: moment(dataFinal.setHours(23, 59, 0, 0)).format("YYYY-MM-DD HH:mm") });
        aplicaFiltros();
    }, []);

    function buscarDadosAssinar() {
        api.get("Enumeravel/AssinaturaGed").then(
            res => {
                let dados = res.data;
                let indice = 0;
                dados.forEach(dado => {
                    dado.key = indice++;
                })
                setListaAssinatura(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    function aplicaFiltros() {
        let outrosFiltros = '';
        outrosFiltros += (!!formConsultarTermo.getFieldValue().filtro) ? `&filtro=${formConsultarTermo.getFieldValue().filtro}` : '';
        outrosFiltros += (!!formConsultarTermo.getFieldValue().tecnico) ? `&Tecnico=${formConsultarTermo.getFieldValue().tecnico}` : '';
        outrosFiltros += (!!formConsultarTermo.getFieldValue().cliente) ? `&Cliente=${formConsultarTermo.getFieldValue().cliente}` : '';
        outrosFiltros += (!!formConsultarTermo.getFieldValue().numero) ? `&Numero=${formConsultarTermo.getFieldValue().numero}` : '';
        outrosFiltros += (!!formConsultarTermo.getFieldValue().statusAssinatura) ? `&StatusAssinatura=${formConsultarTermo.getFieldValue().statusAssinatura}` : '';
        outrosFiltros += !!formConsultarTermo.getFieldValue().dataInicial ? `&DataInicial=${moment(formConsultarTermo.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : '';
        outrosFiltros += !!formConsultarTermo.getFieldValue().dataFinal ? `&DataFinal=${moment(formConsultarTermo.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : '';
        dispatch({ type: listagemActions.CHANGE, data: { filtro: outrosFiltros, ordem: '' } });
    };

    function removerFiltros() {
        formConsultarTermo.setFieldsValue({
            filtro: null,  tecnico: null, cliente:null, numero: null, statusAssinatura: null
        });
        let outrosFiltros = '';
        outrosFiltros += !!formConsultarTermo.getFieldValue().dataInicial ? `&DataInicial=${moment(formConsultarTermo.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : '';
        outrosFiltros += !!formConsultarTermo.getFieldValue().dataFinal ? `&DataFinal=${moment(formConsultarTermo.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : '';
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: outrosFiltros, filtro: '', ordem: "-Numero" } });
    }

    return (
        <div className="col-paginas">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col>
                    <BreadcrumbPage pagina="Termo de Aceite" />
                </Col>
            </Row>
            <Form layout="vertical" form={formConsultarTermo} name="termoAceite" onFinish={aplicaFiltros}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={11} lg={12} xl={13} xxl={14}>
                        <Form.Item label="Pesquisar por" name="pesquisar">
                            <Input.Search placeholder="Pesquisar por..." onSearch={aplicaFiltros} allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={8} xl={3}>
                        <Data label="Data Inicial" name="dataInicial" format="DD/MM/YYYY HH:mm" onBlur={aplicaFiltros} />
                    </Col>
                    <Col xs={12} md={8} lg={8} xl={3}>
                        <Data label="Data Final" name="dataFinal" format="DD/MM/YYYY HH:mm" onBlur={aplicaFiltros} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Collapse className="collapse-pages col-ordem" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa}>
                                    {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Mais Filtros
                                </Button>
                            }>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Técnico" name="tecnico">
                                            <SelectPaginado url="Usuarios?Ativo=true&Sac=true" placeholder="Selecione o Técnico" form={formConsultarTermo} name="tecnico" conteudo={
                                                usu => (<Select.Option value={usu.id} key={usu.id}>{usu.nome}</Select.Option>)
                                            } />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Cliente" name="cliente">
                                            <SelectPaginado url="Cliente" placeholder="Selecione o Cliente" form={formConsultarTermo} name="cliente" conteudo={
                                                cli =>
                                                (<Select.Option value={cli.pessoaId} key={cli.id}>
                                                    <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'Razão Social:' : 'Nome:'} </span> {cli.pessoa?.nome} <br />
                                                    <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'Nome Fantasia:' : 'Apelido:'} </span> {cli.pessoa?.pessoaJuridica ? cli.pessoa?.pessoaJuridica?.nomeFantasia : cli.pessoa?.pessoaFisica?.apelido}  <br />
                                                    <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'CNPJ:' : 'CPF:'} </span> {cli.pessoa?.pessoaJuridica ? cli.pessoa?.pessoaJuridica?.cnpj : cli.pessoa?.pessoaFisica?.cpf} <span style={{ fontWeight: "bold" }}> {!cli.ativo ? '(Inativo)' : ''}</span> <br />
                                                </Select.Option>)
                                            } />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Número" form={formConsultarTermo} name="numero">
                                            <Input placeholder="Informe o Número" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Status Assinatura" name="statusAssinatura">
                                            <Select showSearch optionFilterProp="children" placeholder="Selecione a assinatura" name="statusAssinatura" form={formConsultarTermo}>
                                                {listaAssinatura.map((sts) => (
                                                    <Select.Option value={sts.valor} key={sts.valor}>{sts.descricao}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify="end" gutter={[8, 0]}>
                                    <Col>
                                        <Button icon={<CloseOutlined />} onClick={removerFiltros} block>
                                            Limpar Filtros
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button type="primary" icon={<SearchOutlined />} onClick={aplicaFiltros} block>
                                            Aplicar Filtros
                                        </Button>
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url={"/GedInfo"} condicao={true}
                        colunas={[
                            {
                                title: 'Descrição',
                                width: 150,
                                render: ({ descricao }) => (
                                    <div>
                                        <b>{descricao} </b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Data Geração',
                                width: 100,
                                render: ({ dataGeracao }) => (
                                    <div>
                                        <b>{moment(dataGeracao).format('DD/MM/YYYY HH:mm')} </b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Usuário Enviou',
                                width: 120,
                                render: ({ usuarioEnviou }) => (
                                    <div>
                                        {usuarioEnviou}
                                    </div>
                                ),
                            },
                            {
                                title: 'Número OS',
                                width: 50,
                                render: ({ numero }) => (
                                    <div>
                                        <b>{numero}</b>
                                    </div>
                                )
                            },
                            {
                                title: 'Tipo Termo Aceite',
                                width: 120,
                                render: ({ tipoAnexo }) => (
                                    <div>
                                        {tipoAnexo === 1 && <b>Ordem de Serviço</b>}
                                        {tipoAnexo === 2 && <b>Implantação</b>}
                                        {tipoAnexo === 3 && <b>Definitivo</b>}
                                        {tipoAnexo === 4 && <b>Migração</b>}
                                    </div>
                                ),
                            },
                            {
                                title: 'Técnico',
                                width: 120,
                                render: ({ tecnico }) => (
                                    <div>
                                        {tecnico}
                                    </div>
                                ),
                            },
                            {
                                title: 'Assinado',
                                width: 120,
                                render: ({ assinaturaGed }) => (
                                    <div>
                                        {assinaturaGed === 1 && <b className="c-red">Sem Assinatura</b>}
                                        {assinaturaGed === 2 && <b className="c-blue">Aguardando </b>}
                                        {assinaturaGed === 3 && <b className="c-green">Assinado</b>}
                                        {assinaturaGed === 4 && <b className="c-red">Rejeitado</b>}
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                width: 100,
                                align: 'center',
                                fixed: 'right',
                                render: (record) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<EyeOutlined />} onClick={async () => {
                                                    if (!!record.anexo) {
                                                        window.open('https://gedapi.abase.com.br/Ged/docPublico?RowGuid={' + record.anexo + '}', '_blank');
                                                    }
                                                }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]} />
                </div>
            </Form>
        </div>
    );
}