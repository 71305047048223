import React, { useState } from "react";
import { Row, Col, Form, Button, Drawer, Modal } from "antd";
import { SaveOutlined, CloseOutlined, ExclamationCircleOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

import { ManutencaoRecursoPage } from "../../components";
import { useStateValue } from '../../state/stateProvider';
import { drawerActions, manutencaoActions } from '../../actions';
import { useEffect } from "react";

export default function DrawerRecurso({ form, listaRecursos, coordenadas }) {

    const [formRecursos] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [tagPages, setTagPages] = useState(null);
    const [proximaPag, setProximaPag] = useState(0);


    if (!!manutencao.dados) {
        formRecursos.setFieldsValue(manutencao.dados);
    }

    function fecharDrawer() {
        formRecursos.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerRecurso: false } });
        setTagPages(0);
        setProximaPag(null);
    }

    useEffect(() => { console.log(proximaPag) }, [tagPages]);

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer
            width="65%"
            title="Manutenção Recursos"
            open={ui.showDrawerRecurso}
            onClose={() => { fecharDrawer() }}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={onClose} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button disabled={tagPages === 0} icon={<LeftOutlined />} size="large" onClick={() => {
                            setProximaPag(tagPages - 1)
                        }} type="primary">
                            Voltar
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" hidden={tagPages === 2} size="large" onClick={() => setProximaPag(tagPages + 1)}>
                            Avançar <RightOutlined />
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => formRecursos.submit()} hidden={tagPages == 1} loading={carregando} icon={<SaveOutlined />} size="large" type="primary">
                            Salvar
                        </Button>
                    </Col>
                </Row>
            }>
            <ManutencaoRecursoPage formAgenda={form} form={formRecursos} listaRecursos={listaRecursos} aoSalvar={fecharDrawer} carregando={setCarregando} pages={{ tagPages, setTagPages }} proximaPag={{ proximaPag, setProximaPag }} coordenadas={coordenadas} />
        </Drawer>
    );
}