import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Col, Divider } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import api from '../../services/api';
import Emitter from '../../services/emitter';
import { manutencaoActions, listagemActions } from '../../actions';
import { useStateValue } from '../../state/stateProvider';
function TabelaDados({ colunas, url, paginacao = true, expandir, selecionarItem = false, ordenar = true, parametrosSelecionarItens, condicao = undefined, footerTabela, headerTabela, selecionados, colunaClassName }) {

    const [carregando, setCarregando] = useState(false);
    const [lista, setLista] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(100);

    const [alterouPag, setAlterouPag] = useState(false);

    const [{ listagem, manutencao }, dispatch] = useStateValue();

    function onPopUpScroll(e) {
        const { target } = e;

        if (parseFloat(Math.round(target.scrollTop + target.offsetHeight).toFixed(2)) === target.scrollHeight) {
            setPagina(pagina + 1);
            setAlterouPag(true);
        }
    };

    const configPaginacao = paginacao ? {
        current: pagina,
        pageSize: registrosPorPagina,
        total: lista.totalItems,
        showSizeChanger: true,
        onChange: (pg) => setPagina(pg),
        onShowSizeChange: (atual, porPagina) => setRegistrosPorPagina(porPagina)
    } : null;

    const configSelecionarItem = selecionarItem ?
        setCondicaoSelecionarItem()
        : null;

    function setCondicaoSelecionarItem() {
        if (!!parametrosSelecionarItens && !!parametrosSelecionarItens.tipo) {
            if (parametrosSelecionarItens.tipo === "change") {
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                        parametrosSelecionarItens.funcao(selectedRowKeys, selectedRows);
                    }
                }
            } else if (parametrosSelecionarItens.tipo === "select") {
                return {
                    onSelect: (record, selected, selectedRows) => {
                        parametrosSelecionarItens.funcao(record, selected, selectedRows);
                    }
                }
            } else if (parametrosSelecionarItens.tipo === "selectall") {
                return {
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        parametrosSelecionarItens.funcao(selected, selectedRows, changeRows);
                    }
                }
            }
        }
        return "";

    };

    function carregarDados() {
        setCarregando(true);
        let parametrosUrl = '';
        if (paginacao === true) parametrosUrl = `&pageNumber=${pagina}&pageSize=${registrosPorPagina}`;
        if (ordenar === true && listagem?.ordem) parametrosUrl += `&order=${listagem.ordem}`;
        if (listagem?.filtro) parametrosUrl += `&${listagem.campoFiltro}=${listagem.filtro}`;
        if (listagem?.outrosFiltros) parametrosUrl += listagem.outrosFiltros;
        if (!!parametrosUrl) parametrosUrl = parametrosUrl.replace('&', '?');

        api.get(`${url}${parametrosUrl}`).then((res) => {
            if (res.status === 200) {
                let indice = 0;
                let dados = [];

                if (paginacao) {

                    if (url == "Agenda/ListarAgendas") {
                        let i = 0;
                        let cor = "grey";
                        let item = res.data?.items;
                        if (pagina > 1) {
                            indice = (pagina - 1) * registrosPorPagina;
                            while (i < item.length) {
                                var dataI = item[i].dataExibir.substring(0, 10);
                                if (cor == "white") {
                                    cor = "grey";
                                } else {
                                    cor = "white"
                                }
                                while (i < item.length && dataI == item[i].dataExibir.substring(0, 10)) {
                                    item[i].key = indice;
                                    item[i].cor = cor;
                                    dados.push(item[i]);
                                    indice++;
                                    i++;
                                }

                            }
                            setLista(dados);
                        } else {
                            while (i < item.length) {
                                let dataI = item[i].dataExibir.substring(0, 10);
                                if (cor == "white") {
                                    cor = "grey";
                                } else {
                                    cor = "white"
                                }
                                while (i < item.length && dataI == item[i].dataExibir.substring(0, 10)) {
                                    item[i].key = indice;
                                    item[i].cor = cor;
                                    dados.push(item[i]);
                                    indice++;
                                    i++;
                                }

                            }

                            setLista(dados);
                        }

                    }
                    else {
                        let itens = res.data?.items;
                        if (pagina > 1) {
                            indice = (pagina - 1) * registrosPorPagina;

                        } else {
                           
                            itens.map((item, idx) => {
                                item.key = indice;
                                if (selecionados) {
                                    if (selecionados.filter(key => key.key === indice).length > 0) {
                                        item.marcado = selecionados.filter(key => key.key === indice)[0].value;
                                    } else {
                                        item.marcado = false;
                                    }
                                }
                                dados.push(item);
                                indice++;
                            });
                            setLista(dados);
                        }

                    }
                    setAlterouPag(false);
                } else {
                    let itens = res.data?.items;
                    itens.map((item, idx) => {
                        item.key = indice;

                        if (selecionados) {
                            if (selecionados.filter(key => key.key === indice).length > 0) {
                                item.marcado = selecionados.filter(key => key.key === indice)[0].value;
                            } else {
                                item.marcado = false;
                            }
                        }
                        dados.push(item);
                        indice++;
                    });
                    setLista(dados);
                }
                //dispatch({ type: listagemActions.CHANGE, data: { listaTabelaDados: dados } });
                setLista(res.data);
            }
        }).finally(() => {
            setCarregando(false)
            console.log('dados finally', lista);
        });
    }

    useEffect(() => {

        if (condicao && listagem?.filtro || condicao && listagem?.ordem || !condicao) {
            if (!!listagem?.pagina) {
                setPagina(listagem.pagina);
                dispatch({ type: listagemActions.CHANGE, data: { filtro: listagem.filtro, pagina: null } })
            } else if ((!paginacao || !!pagina)) {
                carregarDados();
            }
        }
    }, [listagem, pagina, registrosPorPagina])


    return (

        <Table sticky small loading={carregando} columns={colunas} dataSource={paginacao ? lista.items : lista} pagination={configPaginacao} expandedRowRender={expandir}

            expandIcon={({ record, expanded, onExpand }) => {
                return expanded ? (
                    <ArrowUpOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                    <ArrowDownOutlined onClick={(e) => onExpand(record, e)} />
                );
            }}

            locale={{
                emptyText: (
                    <Row>
                        <Col span={24} >
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>)
            }}


            rowSelection={configSelecionarItem} scroll={{ x: 920 }} footer={footerTabela} showHeader={headerTabela} columnWidth={30}
            rowClassName={colunaClassName}
        />
    )
}

TabelaDados.propTypes = {
    colunas: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired,
    paginacao: PropTypes.bool

}

export default TabelaDados;