import React, { useState } from "react";
import { Row, Col, Form, Button, notification, Modal } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import DrawerTipoAgenda from "./drawer";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbIncluirDados, Search, TabelaDados } from "../../components";
import api from "../../services/api";

export default function TipoAgenda() {

    const [formTipoAgenda] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();


    function excluir(record) {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja excluir o Tipo de Agenda?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                api.delete(`AgendaTipo/${record.id}`).then(
                    res => {
                        notification.success({ message: 'Sucesso!', description: 'Tipo de Agenda excluido com sucesso!' });
                    }
                ).catch(
                    error => {
                        notification.warning({ message: 'Erro!', description: 'Não foi possivel excluir o Tipo de Ajuda' });
                    }
                ).finally(() => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+id' } });
                })
            }
        });

    }

    return (
        <div className="col-paginas">
            <BreadcrumbIncluirDados pagina="Tipo de Agenda" />
            <Form layout="vertical" form={formTipoAgenda} name="tipo_agenda">
                <Row>
                    <Col span={24}>
                        <Search />
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url='AgendaTipo' colunas={[
                        {
                            title: 'Descrição',
                            render: ({ descricao }) => (
                                <div>
                                    <b>{descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Empresa',
                            render: ({ empresa }) => (
                                <div>
                                    <b>{empresa.nomeFantasia}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => excluir(record)} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
                </div>
            </Form>
            <DrawerTipoAgenda />
        </div>
    );
}