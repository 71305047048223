import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Table, Button, Col, Divider, Row, Typography } from "antd";
import { drawerActions, manutencaoActions } from "../../actions";
import { useStateValue } from "../../state/stateProvider";
import DrawerItemCobranca from "./drawer";

export default function ItensCobranca({ listaCobranca, formOS, pessoaId }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();

    function removerCobranca(dados) {
        let lista = [...listaCobranca.listaCobranca];
        lista = lista.filter(c => c != dados);
        listaCobranca.setListaCobranca(lista);
    };

    return (
        <>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={5}>
                        <img src={require("../../assets/i_cobranca.png").default} alt="Itens de Cobrança" /> Itens de Cobrança
                    </Typography.Title>
                    <Divider orientation="left" plain className="m-t-0 m-b-5">
                        Informe os Itens abaixo.
                    </Divider>
                </Col>
                <Col>
                    <div className="i-Position">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => {
                            if (!!formOS.getFieldValue().pessoaId) {
                                pessoaId.setPessoaId(formOS.getFieldValue().pessoaId);
                            }
                            let dados = {};
                            dados.observacao = formOS.getFieldValue().observacao;
                            dados.sequencia = parseInt(listaCobranca.listaCobranca.length) + parseInt(1);
                            dispatch({ type: manutencaoActions.CHANGE, data: { dadosCobranca: dados } });
                            dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerExtra: true } });

                        }} />
                    </div>
                </Col>
            </Row>
            <div className="tabela-pages">
                <Table dataSource={listaCobranca.listaCobranca.sort()} columns={[
                    {
                        title: 'Sequência',
                        align: 'center',
                        width: 100,
                        render: ({ sequencia }) => (
                            <div>
                                <b>
                                    {sequencia}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Contrato',
                        render: ({ contrato }) => (
                            <div>
                                <b>
                                    {contrato?.contratoItem?.map(p => p.produto.descricao).join(',')}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Serviço',
                        render: ({ produto }) => (
                            <div>
                                <b>
                                    {produto?.descricao}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Qtde.',
                        width: 100,
                        align: 'center',
                        render: ({ quantidade }) => (
                            <div>
                                <b>
                                    {quantidade || '-'}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor Unitário',
                        width: 120,
                        align: 'right',
                        render: ({ valorUnitario }) => (
                            <div>
                                <b>
                                    {valorUnitario}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Total',
                        width: 120,
                        align: 'right',
                        render: ({ valorTotal }) => (
                            <div>
                                <b>
                                    {valorTotal}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        width: 65,
                        align: 'center',
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => {
                                            let dataAnoMes = new Date()
                                            if (!!record.anoMesCobranca && record.anoMesCobranca.toString().length === 6) {
                                                dataAnoMes.setMonth((record.anoMesCobranca.toString().substring(4, 6) - 1));
                                                dataAnoMes.setYear(record.anoMesCobranca.toString().substring(0, 4));
                                                record.anoMesCobranca = dataAnoMes;
                                            }
                                            if (!record.observacao) {
                                                record.observacao = formOS.getFieldValue().observacao;
                                            }

                                            dispatch({ type: manutencaoActions.CHANGE, data: { dadosCobranca: record } });
                                            dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerExtra: true } });

                                        }} />
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => { removerCobranca(record) }} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                </Divider>
                            </Col>
                        </Row>
                    )
                }} scroll={{ x: 920 }}
                />
            </div>
            <DrawerItemCobranca listaCobranca={listaCobranca} pessoaId={pessoaId} />
        </>
    )
}
