import React, { useEffect, useState } from "react";
import { Row, Col, Form, Menu, Button, Divider, Collapse, Select, Input, Checkbox, Tag, notification, Radio, Dropdown } from "antd";
import { EyeOutlined, MoreOutlined, HighlightOutlined, EditOutlined, PrinterOutlined, ExclamationCircleOutlined, ArrowUpOutlined, ArrowDownOutlined, CloseOutlined, SearchOutlined, PlusOutlined, MailOutlined, DollarOutlined } from "@ant-design/icons";
import moment from 'moment';

import DrawerOrdemServico from "./drawer";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbIncluirDados, Search, SelectPaginado, TabelaDados, Data, EnviarOS, AssinarOS, AssinarTermoGeral, BreadcrumbPage } from "../../components";
import api from "../../services/api";
import { converterDatasParaMoment, downloadRelatorioJasper, imprimirRelatorioJasper } from "../../services/funcoes";
import { getLocal, setIdOrdem } from "../../services/auth";
import DrawerDetalhe from "../../components/ordemServicoDetalhe/drawerDetalhe";

export default function OrdemServico() {

    const [{ ui }, dispatch] = useStateValue();
    const [formOrdemServico] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [openDrawerAssinar, setOpenDrawerAssinar] = useState(false);
    const [openDrawerAssinarTermo, setOpenDrawerAssinarTermo] = useState(false);
    const [form] = Form.useForm();
    const [listaStatus, setListaStatus] = useState([]);
    const [listaAssinatura, setListaAssinatura] = useState([]);
    const [objListaStatus, setObjListaStatus] = useState([]);
    const [listaCobranca, setListaCobranca] = useState([]);
    const [objListaCobranca, setObjListaCobranca] = useState([]);
    const [listaTipo, setListaTipo] = useState({ null: "" });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [participantes, setParticipantes] = useState([]);
    const [pessoaEmail, setPessoaEmail] = useState([]);
    const [emailCliente, setEmailCliente] = useState([]);
    const [listaContato, setListaContato] = useState([]);
    const [idPessoa, setIdPessoa] = useState(0);
    const [ordensServicoEnviar, setOrdensServicoEnviar] = useState([]);
    const [ordenar, setOrdenar] = useState([]);
    const [dadosRecord, setDadosRecord] = useState([]);
    const [dadosGedInfo, setSetDadosGedInfo] = useState([]);
    const [openDetalhes, setOpenDetalhes] = useState(false);
    let [listaItemSis] = useState([]);
    let [dadosRelatorios] = useState([]);
    let [selecionados] = useState([]);
    const [listaUser, setListaUser] = useState([]);

    const redirectToExternalSite = () => {
        window.location.href = 'https://gedapi.abase.com.br/Ged/docPublico?RowGuid='; // Substitua 'https://www.example.com' pelo URL do site para o qual deseja redirecionar o usuário.
    }
    const togglePesquisa = () => {
        setOpen(!open);
    };


    useEffect(() => {
        buscarOrdemEmail();
        setObjOrder();
        buscarDadosStatus();
        buscarAprovarCobranca();
        buscarDadosTipo();
        buscarDadosAssinar();
        carregarDados();

    }, []);

    async function buscarOrdemEmail() {
        let url = window.location.href;
        if (url.indexOf('?') > -1) {
            let IdOrdem = url.substr(url.lastIndexOf('?') + 1);
            setIdOrdem(IdOrdem);
            let res = await api.get(`OrdemServico/${atob(IdOrdem)}`);
            if (!!res.data) {
                let dados = res.data;
                dados = converterDatasParaMoment(dados);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                setOpenDetalhes(true);
            }

        }
    }

    function carregarDados() {
        let date = new Date();
        let dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);
        let dataFinal = new Date(new Date().setDate(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()));
        formOrdemServico.setFieldsValue({ dataInicial: moment(dataInicial.setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm") });
        formOrdemServico.setFieldsValue({ dataFinal: moment(dataFinal.setHours(23, 59, 0, 0)).format("YYYY-MM-DD HH:mm") });
        aplicarFiltrosAvancados();
    };

    const configSelecionarItem =
    {

        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            //setDadosRelatorios(selectedRowKeys);
        }
    };

    function buscarDadosStatus() {
        api.get("Enumeravel/StatusOrdemServico").then(
            res => {
                let dados = res.data;
                let indice = 0;
                dados.forEach(dado => {
                    dado.key = indice++;
                })
                setListaStatus(res.data);

                let objeto = {};
                for (let item of res.data) {
                    objeto[item.valor] = item.descricao;
                }
                setObjListaStatus(objeto);

            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    function buscarAprovarCobranca() {
        api.get("Enumeravel/CobrancaAprovar").then(
            res => {
                let dados = res.data;
                let indice = 0;
                dados.forEach(dado => {
                    dado.key = indice++;
                })
                setListaCobranca(res.data);

                let objeto = {};
                for (let item of res.data) {
                    objeto[item.valor] = item.descricao;
                }
                setObjListaCobranca(objeto);

            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    function buscarDadosAssinar() {
        api.get("Enumeravel/AssinaturaGed").then(
            res => {
                let dados = res.data;
                let indice = 0;
                dados.forEach(dado => {
                    dado.key = indice++;
                })
                setListaAssinatura(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    function buscarDadosTipo() {
        let objeto = {
            1: "Externo",
            2: "Interno",
            3: "Remoto"
        }
        setListaTipo(objeto);
    };

    function setObjOrder() {
        let obj = [{ "id": "-Numero", "descricao": "- Número OS" },
        { "id": "+Numero", "descricao": "+ Número OS" },
        { "id": "-Data", "descricao": "- Data" },
        { "id": "+Data", "descricao": "+ Data" },];
        setOrdenar(obj);

    }


    async function alterarOS(record) {
        let res = await api.get(`OrdemServico/${record.idOrdemServico}`);
        if (!!res.data) {
            let dados = res.data;
            dados = converterDatasParaMoment(dados);
            dados.gedInfos = record.gedInfos;
            dados.assinado = record.assinado;
            let desabilitar = (dados.status == 4 ? true : false);
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true, desabilitar: desabilitar } });
        } else {
            notification.warning({ description: "Não foi possível editar o registro selecionado", message: "Aviso" });
        }
    };

    function aplicarFiltrosAvancados() {
        let ordem = (!!formOrdemServico.getFieldValue().ordenar) ? formOrdemServico.getFieldValue().ordenar : '-Numero';

        let filtros = '';
        filtros += (!!formOrdemServico.getFieldValue().filtro) ? `&Filtro=${formOrdemServico.getFieldValue().filtro}` : '';
        filtros += !!formOrdemServico.getFieldValue().dataInicial ? `&DataInicial=${moment(formOrdemServico.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : '';
        filtros += !!formOrdemServico.getFieldValue().dataFinal ? `&DataFinal=${moment(formOrdemServico.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : '';
        filtros += (!!formOrdemServico.getFieldValue().empresaId) ? `&Empresa=${formOrdemServico.getFieldValue().empresaId}` : '';
        filtros += (!!formOrdemServico.getFieldValue().tipoAtendimento) ? `&TipoAtendimento=${formOrdemServico.getFieldValue().tipoAtendimento}` : '';
        filtros += (!!formOrdemServico.getFieldValue().usuarioId) ? `&Usuario=${formOrdemServico.getFieldValue().usuarioId}` : '';
        filtros += (!!formOrdemServico.getFieldValue().sistemaId) ? `&Sistema=${formOrdemServico.getFieldValue().sistemaId}` : '';
        filtros += (!!formOrdemServico.getFieldValue().clienteId) ? `&Pessoa=${formOrdemServico.getFieldValue().clienteId}` : '';
        filtros += (!!formOrdemServico.getFieldValue().numero) ? `&NumeroOrdemServico=${formOrdemServico.getFieldValue().numero}` : '';
        filtros += (!!formOrdemServico.getFieldValue().statusValor) ? `&StatusOrdemServico=${formOrdemServico.getFieldValue().statusValor}` : '';
        filtros += (!!formOrdemServico.getFieldValue().possuiCobranca) ? `&PossuiCobranca=${formOrdemServico.getFieldValue().possuiCobranca}` : '';
        filtros += (!!formOrdemServico.getFieldValue().statusAssinatura) ? `&StatusAssinatura=${formOrdemServico.getFieldValue().statusAssinatura}` : '';
        filtros += (!!formOrdemServico.getFieldValue().aprovacaoCobranca) ? `&AprovacaoCobranca=${formOrdemServico.getFieldValue().aprovacaoCobranca}` : '';

        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtros, ordem: ordem, pagina: 1 } });

    };

    function removerFiltros() {
        formOrdemServico.setFieldsValue({
            empresaId: null, tipoAtendimento: null, usuarioId: null, sistemaId: null, clienteId: null,
            numero: null, statusValor: null, possuiCobranca: false, statusAssinatura: null
        });
        let filtros = '';
        filtros += !!formOrdemServico.getFieldValue().dataInicial ? `&DataInicial=${moment(formOrdemServico.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : '';
        filtros += !!formOrdemServico.getFieldValue().dataFinal ? `&DataFinal=${moment(formOrdemServico.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : '';
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtros, filtro: '', ordem: "-Numero" } });
    }

    function imprimirSelecionados() {
        var ordensServico = "";
        let relatoriosImprimir = dadosRelatorios.filter(key => key.value === true);
        if (relatoriosImprimir.length > 0) {
            ordensServico = buscarOSDownload(relatoriosImprimir);
            downloadRelatorioJasper(42, [{ "nome": "ordemservico", "valor": ordensServico }, { "nome": "IdLocal", "valor": getLocal() }], "PDF", "ordemServico", true);

        } else {
            notification.warning({ description: "Nenhuma Ordem de Serviço selecionada!", message: "Aviso" });
        }
    }

    function enviarSelecionados() {
        let usuario = [];
        let usuarioVirgula = "";
        let ordensServico = "";
        for (var j = 0; j < listaItemSis.length; j++) {
            if (listaItemSis[j].marcado) {
                for (var i = 0; i < listaItemSis[j].ordensTarefas.length; i++) {
                    let validar = usuario.filter(key => key.value === listaItemSis[j].ordensTarefas[i].tarefa.idResponsavel);
                    if (validar.length === 0) {
                        usuario.push(listaItemSis[j].ordensTarefas[i].tarefa.idResponsavel)
                        usuarioVirgula += listaItemSis[j].ordensTarefas[i].tarefa.idResponsavel + ","
                    }
                }
                ordensServico += listaItemSis[j].numero + ",";
            }
        }

        buscarParticipantes(usuarioVirgula.substring(0, usuarioVirgula.length - 1));
        setOrdensServicoEnviar(ordensServico.substring(0, ordensServico.length - 1));

        dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerOS: true } });
        aplicarFiltrosAvancados();

    }

    function buscarParticipantes(idParticipantes) {
        api.get(`Usuarios/ListarParticipantes?sac=true&ativo=true&listaAgendaParticipantes=${idParticipantes}`).then(
            res => {
                if (!!res.data) {
                    setParticipantes(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    async function buscarEmails(idPessoa) {
        api.get(`PessoaEmail/ListarEmailContato?Pessoa=` + idPessoa).then(
            res => {
                if (!!res.data) {
                    setPessoaEmail(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    async function buscarOSGedInfo(idOrdemServico) {
        let req = await api.get(`Ged/BuscarOsGedInfo?idOrdemServico=${idOrdemServico}&tipoAnexo=1`);
        if (req.status === 200) {
            return req.data;
        }
        return false;
    };

    async function buscarRowGrid(idOrdemServico) {
        let req = await api.get(`Ged/BuscarRowGrid?idOrdemServico=${idOrdemServico}&tipoAnexo=1`);
        if (req.status === 200) {
            return req.data;
        }
        return false;
    };

    async function buscarGedInfo(idOrdemServico) {
        let req = await api.get(`Ged/BuscarGedInfo?idOrdemServico=${idOrdemServico}`);
        if (req.status === 200) {
            setSetDadosGedInfo(req.data);
            ///return req.data;
        }
        //return false;
    }

    function buscarOSDownload(relatoriosImprimir) {
        var ordensServico = "";
        for (let dado in relatoriosImprimir) {
            if (relatoriosImprimir[dado].value) {
                ordensServico += relatoriosImprimir[dado].key + ","
            }
        }
        return ordensServico.substring(0, ordensServico.length - 1);

    }

    function menuTable(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1" hidden={record.assinado !== 1}>
                    <Button type="text" icon={<HighlightOutlined style={{ color: '#015801' }} />} onClick={async () => {
                        let req = await buscarOSGedInfo(record.idOrdemServico);
                        if (!req) {
                            if (record.status === 5) {
                                await buscarEmails(record?.pessoaId);
                                await buscarParticipantes(record?.usuarioId);
                                setDadosRecord(record);
                                setOpenDrawerAssinar(true);

                            } else {
                                notification.warning({ description: "Apenas é possível assinar OS Fechadas.", message: "Aviso" });
                            }
                        } else {
                            notification.warning({ description: "OS já foi enviada para assinar.", message: "Aviso" });
                        }

                    }}>Assinar OS</Button>
                </Menu.Item>
                <Menu.Item key="2" hidden={record.assinado === 1}>
                    <Button type="text" icon={<EyeOutlined style={{ color: '#0693e3' }} />} onClick={async () => {
                        let req = await buscarRowGrid(record.idOrdemServico);
                        if (req) {
                            window.open('https://gedapi.abase.com.br/Ged/docPublico?RowGuid={' + req + '}', '_blank');
                        } else {
                            notification.warning({ description: "Esta ordem de serviço não foi enviada para assinar ainda.", message: "Aviso" });
                        }
                    }}>Visualizar OS</Button>
                </Menu.Item>
                <Menu.Item key="3">
                    <Button type="text" icon={<HighlightOutlined style={{ color: '#015801' }} />} onClick={async () => {
                        if (record.status === 5) {
                            await buscarGedInfo(record.idOrdemServico);
                            await buscarEmails(record?.pessoaId);
                            setDadosRecord(record);
                            setOpenDrawerAssinarTermo(true);
                        } else {
                            notification.warning({ description: "Apenas é possível assinar OS Fechadas.", message: "Aviso" });
                        }
                    }}>Termo de Aceite Geral</Button>
                </Menu.Item>
                <Menu.Item key="4" hidden={record.status !== 6}>
                    <Button type="text" icon={<DollarOutlined style={{ color: '#a89c0f' }} />} onClick={async () => {
                        let res = await api.get(`OrdemServico/${record.idOrdemServico}`);
                        if (!!res.data) {
                            debugger;
                            let dados = res.data;
                            dados = converterDatasParaMoment(dados);
                            dados.gedInfos = record.gedInfos;
                            dados.assinado = record.assinado;
                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });;
                        } else {
                            notification.warning({ description: "Não foi possível editar o registro selecionado", message: "Aviso" });
                        }
                        setOpenDetalhes(true);
                    }}>Verificar Pendências</Button>
                </Menu.Item>
            </Menu>
        )
    }

    return (
        <div className="col-paginas">
            <BreadcrumbIncluirDados pagina={"Ordem de Serviço"} />
            <Form layout="vertical" form={formOrdemServico} name="ordemServico" onFinish={aplicarFiltrosAvancados}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={11} lg={12} xl={13} xxl={14}>
                        <Search />
                    </Col>
                    <Col xs={12} md={8} lg={8} xl={3}>
                        <Data label="Data Inicial" name="dataInicial" format="DD/MM/YYYY HH:mm" onBlur={aplicarFiltrosAvancados} />
                    </Col>
                    <Col xs={12} md={8} lg={8} xl={3}>
                        <Data label="Data Final" name="dataFinal" format="DD/MM/YYYY HH:mm" onBlur={aplicarFiltrosAvancados} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Collapse className="collapse-pages col-ordem" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa}>
                                    {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Mais Filtros
                                </Button>
                            }>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Empresa" name="empresaId">
                                            <SelectPaginado url="EmpresasUsuario" placeholder="Selecione a Empresa" form={formOrdemServico} name="empresaId" conteudo={
                                                emp => (<Select.Option value={emp.id} key={emp.id}>{emp.nomeFantasia} - {emp.razaoSocial}</Select.Option>)
                                            } />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                                        <Form.Item label="Tipo de Atendimento" name="tipoAtendimento">
                                            <Radio.Group>
                                                <Radio value={1}>Externo</Radio>
                                                <Radio value={2}>Interno</Radio>
                                                <Radio value={3}>Remoto</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Colaborador" name="usuarioId">
                                            <SelectPaginado url="Usuarios?Ativo=true&Sac=true" placeholder="Selecione o Participantes" form={formOrdemServico} name="usuarioId" conteudo={
                                                usu => (<Select.Option value={usu.id} key={usu.id}>{usu.nome}</Select.Option>)
                                            } />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Cliente" name="clienteId">
                                            <SelectPaginado url="Cliente" placeholder="Selecione o Cliente" form={formOrdemServico} name="clienteId" conteudo={
                                                cli =>
                                                (<Select.Option value={cli.pessoaId} key={cli.id}>
                                                    <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'Razão Social:' : 'Nome:'} </span> {cli.pessoa?.nome} <br />
                                                    <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'Nome Fantasia:' : 'Apelido:'} </span> {cli.pessoa?.pessoaJuridica ? cli.pessoa?.pessoaJuridica?.nomeFantasia : cli.pessoa?.pessoaFisica?.apelido}  <br />
                                                    <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'CNPJ:' : 'CPF:'} </span> {cli.pessoa?.pessoaJuridica ? cli.pessoa?.pessoaJuridica?.cnpj : cli.pessoa?.pessoaFisica?.cpf} <span style={{ fontWeight: "bold" }}> {!cli.ativo ? '(Inativo)' : ''}</span> <br />
                                                </Select.Option>)
                                            } />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Número" form={formOrdemServico} name="numero">
                                            <Input placeholder="Informe o Número" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Status" name="statusValor">
                                            <Select showSearch optionFilterProp="children" placeholder="Selecione um Status" name="statusValor" form={formOrdemServico}>
                                                {listaStatus.map((sts) => (
                                                    <Select.Option value={sts.valor} key={sts.valor}>{sts.descricao}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Ordenar" name="ordenar">
                                            <Select showSearch optionFilterProp="children" placeholder="Ordenar por" name="statusValor" form={formOrdemServico}>
                                                {ordenar.map((ord) => (
                                                    <Select.Option value={ord.id} key={ord.id}>{ord.descricao}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Status Assinatura" name="statusAssinatura">
                                            <Select showSearch optionFilterProp="children" placeholder="Selecione a assinatura" name="statusAssinatura" form={formOrdemServico}>
                                                {listaAssinatura.map((sts) => (
                                                    <Select.Option value={sts.valor} key={sts.valor}>{sts.descricao}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Aprovação Cobrança" name="aprovacaoCobranca">
                                            <Select showSearch optionFilterProp="children" placeholder="Selecione um Status" name="aprovacaoCobranca" form={formOrdemServico}>
                                                {listaCobranca.map((sts) => (
                                                    <Select.Option value={sts.valor} key={sts.valor}>{sts.descricao}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {/*<Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                        <Form.Item label="Sistema" name="sistemaId">
                                            <SelectPaginado url="Sistema" placeholder="Selecione um Sistema" form={formOrdemServico} name="sistemaId" conteudo={
                                                sis => (<Select.Option value={sis.id} key={sis.id}>{sis.descricao}</Select.Option>)
                                            } />
                                        </Form.Item>
                                        </Col>*/}
                                    <Col className="m-t-21">
                                        <Form.Item valuePropName="checked" name="possuiCobranca">
                                            <Checkbox> Cobrança </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify="end" gutter={[8, 0]}>
                                    <Col>
                                        <Button icon={<CloseOutlined />} onClick={removerFiltros} block>
                                            Limpar Filtros
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button type="primary" icon={<SearchOutlined />} onClick={aplicarFiltrosAvancados} block>
                                            Aplicar Filtros
                                        </Button>
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <Col span={24} className="tabela ver-aling">
                        <Form name="FormularioItem" form={form} >
                            <TabelaDados url={"OrdemServico/ListarOS"} selecionados={selecionados} condicao={true}
                                colunas={[
                                    {
                                        fixed: 'left',
                                        width: 30,
                                        render: ({ numero }, record) => (
                                            <div>
                                                <Checkbox key={record.numero} checked={record.marcado}
                                                    onClick={() => {
                                                        if (dadosRelatorios.filter(key => key.key === numero).length === 0) {

                                                            dadosRelatorios.push({ key: numero, value: true });
                                                            selecionados.push({ key: record.key, value: true });
                                                        } else {
                                                            dadosRelatorios.filter(key => key.key === numero)[0].value = !selecionados.filter(key => key.key === record.key)[0].value;
                                                            selecionados.filter(key => key.key === record.key)[0].value = !selecionados.filter(key => key.key === record.key)[0].value;
                                                        }
                                                        record.marcado = !record.marcado;
                                                        listaItemSis.push(record);
                                                    }}
                                                    onChange={() => {
                                                        console.log(record.marcado);
                                                        aplicarFiltrosAvancados();

                                                    }}
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Nº',
                                        fixed: 'left',
                                        width: 90,
                                        render: ({ numero }) => (
                                            <div>
                                                <Tag color="processing" className="w-100">
                                                    <b className="w-100"> {numero || '-'} </b>
                                                </Tag>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Data',
                                        width: 95,
                                        render: ({ data }) => (
                                            <div>
                                                <b>{moment(data).format('DD/MM/YYYY')}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Cliente',
                                        render: ({ pessoa }) => (
                                            <div>
                                                <b>{pessoa?.nome || '-'} </b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Usuário',
                                        render: ({ usuario }) => (
                                            <div>
                                                <b> {usuario?.nome || '-'} </b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Status',
                                        width: 120,
                                        render: ({ status }) => (
                                            <div>
                                                {status === 1 && <b className="c-dark-blue">OS em Aberto</b>}
                                                {status === 2 && <b className="c-yellow">OS à Faturar</b>}
                                                {status === 3 && <b className="c-dark-red">OS Cancelada</b>}
                                                {status === 4 && <b className="c-orange">OS Faturada</b>}
                                                {status === 5 && <b className="c-dark-green">OS Fechada</b>}
                                                {status === 6 && <b className="c-dark-red">OS Pendente</b>}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Assinado',
                                        width: 120,
                                        render: ({ assinado }) => (
                                            <div>
                                                {assinado === 1 && <b className="c-red">Sem Assinatura</b>}
                                                {assinado === 2 && <b className="c-blue">Aguardando </b>}
                                                {assinado === 3 && <b className="c-green">Assinado</b>}
                                                {assinado === 4 && <b className="c-red">Rejeitado</b>}
                                            </div>
                                        ),
                                    },
                                    {
                                        width: 120,
                                        title: 'Tipo',
                                        render: ({ tipoAtendimento }) => (
                                            <div>
                                                <b> {listaTipo[tipoAtendimento] || '-'} </b>
                                            </div>
                                        ),
                                    },
                                    {
                                        width: 75,
                                        title: 'Tarefas',
                                        render: ({ ordensTarefas }) => (
                                            <div>
                                                <b> {ordensTarefas?.length || '0'} </b>
                                            </div>
                                        ),
                                    },
                                    {
                                        width: 75,
                                        title: 'Cobrança',
                                        render: ({ possuiCobranca }) => (
                                            <div>
                                                <b> {possuiCobranca ? "Sim" : "Não" || '-'} </b>
                                            </div>
                                        ),
                                    },
                                    {
                                        width: 100,
                                        title: 'Co. Aprovada',
                                        render: ({ cobrancaAprovada }) => (
                                            <div>
                                                <b> {objListaCobranca[cobrancaAprovada] || 'Sem cobrança'} </b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Ações',
                                        width: 100,
                                        align: 'center',
                                        fixed: 'right',
                                        render: (record) => (
                                            <div>
                                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                                    <Col>
                                                        <Button icon={<PrinterOutlined />} onClick={async () => {
                                                            if (record.pessoa?.nome) {
                                                                let req = await api.get(`Agenda/ConsultarOS?OrdemServicoId=${record?.idOrdemServico}`);
                                                                if (req.data === true) {
                                                                    downloadRelatorioJasper(53, [{ "nome": "ordemservico", "valor": record.numero }, { "nome": "IdLocal", "valor": getLocal() }], "PDF", "ordemServico");
                                                                } else {
                                                                    downloadRelatorioJasper(42, [{ "nome": "ordemservico", "valor": record.numero }, { "nome": "IdLocal", "valor": getLocal() }], "PDF", "ordemServico");
                                                                }
                                                            } else {
                                                                notification.warning({ description: "Não foi possível realizar download da OS. Cliente não foi preenchido!", message: "Aviso" });
                                                            }

                                                        }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Button icon={<EditOutlined />} onClick={() => {
                                                            alterarOS(record)
                                                        }} />
                                                    </Col>
                                                    <Col>
                                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                                        </Dropdown>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ),
                                    },
                                ]}
                                locale={{
                                    emptyText: (
                                        <Row>
                                            <Col span={24}>
                                                <Divider orientation="center">
                                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                </Divider>
                                            </Col>
                                        </Row>
                                    )
                                }} scroll={{ x: 920 }} rowSelection={configSelecionarItem}
                            />
                        </Form>
                    </Col>
                </div>
            </Form>
            <Form>
                <Row align="" gutter={[2, 8]}>
                    <Col>
                        <Button type="primary" icon={<MailOutlined />} size="middle" onClick={enviarSelecionados}
                        >
                            Enviar Selecionados
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" icon={<PrinterOutlined />} size="middle" onClick={imprimirSelecionados}>
                            Imprimir Selecionados
                        </Button>
                    </Col>

                </Row>
            </Form>
            <DrawerOrdemServico />
            <EnviarOS listaEmail={participantes} listaOrdens={ordensServicoEnviar} />
            <AssinarOS openDrawerAssinar={openDrawerAssinar} pessoaEmail={pessoaEmail} listaEmail={participantes} listaContato={listaContato} dadosRecord={dadosRecord} closeDrawerAssinar={() => setOpenDrawerAssinar(false)} />
            <AssinarTermoGeral openDrawerAssinarTermo={openDrawerAssinarTermo} pessoaEmail={pessoaEmail} dadosRecord={dadosRecord} closeDrawerAssinarTermo={() => setOpenDrawerAssinarTermo(false)} dadosGedInfo={dadosGedInfo} />
            <DrawerDetalhe openDrawerDetalhe={openDetalhes} closeDrawerDetalhe={() => setOpenDetalhes(false)} />
        </div>
    );
}