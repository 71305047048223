import React, { useEffect } from "react";
import { Row, Col, Descriptions, Tag, Divider, Checkbox, Alert } from "antd";
import { useStateValue } from "../../state/stateProvider";
import moment from "moment";
import numeral from 'numeral';

export default function Detalhe() {

    const [{ manutencao }] = useStateValue();

    function retornarParticipante(listaParticipantes) {
        let participantes = '';
        if (listaParticipantes?.length > 0) {
            listaParticipantes.forEach((participante) => {
                participantes += (!!participante?.usuario?.nome ? participante.usuario.nome + ", " : '');
            });
            participantes = participantes.substring(0, participantes.length - 2)
        }
        return participantes;
    };

    function retornarRecursos(r) {
        let recurso = '';
        if (!r.recurso.formulario) {
            recurso = r.recurso.descricao;
        } else {
            let recursoForm = JSON.parse(r.recurso.formulario);
            let recursoTipoForm = JSON.parse(r.recurso?.recursoTipo?.formulario);
            if (recursoTipoForm?.length > 0) {
                recursoTipoForm.map((campo) => {
                    recurso += " " + recursoForm.filter(res => !!res[campo.id])[0][campo.id];
                })
            }
            recurso = recurso.trim();
        }

        return (
            <div className="divDetalhes" >
                <Col span={24}>
                    <Row align="middle">
                        <Col>
                            <Descriptions column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }} size="small">
                                <Descriptions.Item label="Tipo">
                                    <b>{r.recurso?.recursoTipo?.descricao}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Nome">
                                    <b>{recurso}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </Col>
            </div>
        )
    };

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Descriptions column={1} size="small" title={
                        <Row gutter={[8, 0]} className="tituloDetalhe">
                            <Col>{manutencao.dados?.titulo}</Col>
                        </Row>
                    }>
                    </Descriptions>
                </Col>
            </Row>
            <Row>
                <div className="divDetalhes" >
                    <Col span={24}>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Empresa">
                                <b>{manutencao.dados?.empresa?.razaoSocial}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Participantes">
                                <Row>
                                    <Col span={24}>
                                        <b>{manutencao.dados?.agendaParticipantes ? retornarParticipante(manutencao.dados?.agendaParticipantes) : ""}</b>
                                    </Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>

                    <Col span={24}>
                        <Descriptions size="small" >
                            <Descriptions.Item label="Área">
                                <Tag color={manutencao.dados?.sistema?.cor} className="tag-w-100">
                                    <b>{manutencao.dados?.sistema?.descricao}</b>
                                </Tag>
                            </Descriptions.Item>
                            <Descriptions.Item label="Status">
                                {manutencao.dados?.status === 1 && <b className="c-primary">Aguardando</b>}
                                {manutencao.dados?.status === 2 && <b className="c-green">Confirmada</b>}
                                {manutencao.dados?.status === 3 && <b className="c-red">Cancelada</b>}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Usuário">
                                <b>{manutencao.dados?.usuario?.nome}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Tipo de Agenda" className="w-50">
                                <b>{manutencao.dados?.agendaTipo?.descricao}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions column={1} size="small">
                            {/**/}
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }} size="small">

                        </Descriptions>
                    </Col>
                </div>
            </Row>
            {
                manutencao.dados?.agendaRecursos?.length > 0 ?
                    <Row align="middle" className="m-t-16" >
                        <Col >
                            <Descriptions size="small" title={
                                <Row gutter={[8, 0]} className="tituloDetalhe" >
                                    <Col>RECURSOS: </Col>
                                </Row>
                            }>
                            </Descriptions>
                        </Col>
                        {manutencao.dados?.agendaRecursos.map(r =>
                            retornarRecursos(r)
                        )}
                    </Row>
                    : <Row></Row>
            }
            {manutencao.dados?.agendaTimeline?.length > 0 ?
                <Row align="middle" className="m-t-16">
                    <Col>
                        <Descriptions size="small" title={
                            <Row gutter={[8, 0]} className="tituloDetalhe">
                                <Col>HISTÓRICO: </Col>
                            </Row>
                        }>
                        </Descriptions>
                    </Col>
                    <div className="divDetalhes" >
                        {manutencao.dados?.agendaTimeline?.map(item => (
                            <Col span={24}>
                                <Row align="middle">
                                    <Col>
                                        <Descriptions column={{ xxl: 5, xl: 5, lg: 4, md: 4, sm: 3, xs: 3 }} size="middle" >
                                            <Descriptions.Item span={3} label="Ação">
                                                <b>{item.agendaAcao}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Momento">
                                                <b>{moment(item.data).format('DD/MM/YYYY HH:mm:ss')}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Usuário">
                                                <b>{item?.usuario?.nome}</b>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </div>
                </Row>
                : <Row></Row>}
        </div>
    );
}