import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Checkbox, Tabs, notification } from "antd";
import moment from "moment";

import api from "../../services/api";
import { listagemActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import { TabUsuarioGrupo, TabPermissoesGrupo } from "./tabPage";

export default function ManutencaoGrupoUsuario({ formulario, carregando, aoSalvar }) {

    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [usuarios, setUsuarios] = useState([]);
    const [listPermissoes, setListPermissoes] = useState([]);
    const [load, setLoad] = useState(false);


    const handleChangeBloqueioAll = (option, list = null) => {
        let array = (list != null) ? list : [...listPermissoes];
        for (let i in array) {
            array[i].acesso = option;
            for (let y in array[i].permissoes) {
                array[i].permissoes[y].acesso = option;
            }
        }
        setListPermissoes(array);
    };

    useEffect(() => {
        formulario.setFieldsValue({ listaPermissoesPaginas: listPermissoes });
    }, [listPermissoes]);

    const transformList = (array) => {
        let obj = {};
        for (let i in array) {
            if (array[i].disabled)
                obj[array[i].name] = [{ horaInicial: null, horaFinal: null, bloqueado: true }];
            else
                obj[array[i].name] = array[i].day.length > 0 ? array[i].day.map((x) => (
                    {
                        horaInicial: new Date(x.horaInicial),
                        horaFinal: new Date(x.horaFinal),
                        bloqueado: x.bloqueado
                    })) : [];

        }
        formulario.setFieldsValue({ listaDefinicaoHorario: { ...obj } });
    }

    return (
        <div className="pages-col">
            <Form layout="vertical" form={formulario} name="manutencaoGrupoUsuario">
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Form.Item name="gru_id" hidden />
                        <Form.Item name="duplicar" hidden />
                        <Form.Item name="usuarios" hidden />
                        <Form.Item name="listaPermissoesPaginas" hidden />
                        <Form.Item label="Nome do Grupo" name="gru_nome" rules={[{ required: true }]}>
                            <Input placeholder="Informe o Nome do Grupo de Usuário" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Form.Item label="Descrição do Grupo" name="gru_descricao">
                            <Input.TextArea placeholder="Informe a Descrição Detalhada do Grupo (Opcional)" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Form.Item name="gru_ativo" valuePropName="checked">
                            <Checkbox>Grupo Ativo?</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[8, 16]}>
                    <Col span={24}>
                        <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                            <Tabs.TabPane tab="Definição das Permissões" key="1">
                                <TabPermissoesGrupo listPermissoes={{ listPermissoes, setListPermissoes }} handleChangeBloqueioAll={handleChangeBloqueioAll} load={load} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Usuários do Grupo" key="2">
                                <TabUsuarioGrupo formulario={formulario} usuarios={{ usuarios, setUsuarios }} />
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Form>
        </div>
    );

}