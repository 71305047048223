import React from "react";
import { Row, Col, Form, Modal, Button, notification } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from '../../services/api';
import DrawerLocalizacao from './drawer';
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbIncluirDados, Search, TabelaDados } from "../../components";
import { drawerActions, manutencaoActions, listagemActions } from "../../actions";

export default function Localizacao() {

    const [formLocalizacao] = Form.useForm();
    const [{ }, dispatch] = useStateValue();

    function excluirRegistro(id) {
        api.delete(`Localizacao/${id}`).then(
            (res) => {

                if (res.data) {
                    notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" });
                } else {
                    notification.warn({ message: 'Operação falhou', description: "Registro não foi excluído!" });
                }
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+descricao' } });
            }
        ).catch(
            erro => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            }
        );
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    return (
        <div className="col-paginas">
            <BreadcrumbIncluirDados pagina="Localização" />
            <Form layout="vertical" form={formLocalizacao} name="localizacao">
                <Row>
                    <Col span={24}>
                        <Search campoFiltro="Descricao" />
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url="Localizacao" colunas={[
                        {
                            title: 'CEP',
                            align: 'center',
                            width: 85,
                            render: ({ cep }) => (
                                <div>
                                    <b>{cep || 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Descrição',
                            render: ({ endereco }) => (
                                <div>
                                    <b>{endereco} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => { modalExcluir(record.id) }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
                </div>
            </Form>
            <DrawerLocalizacao />
        </div>
    );
}