import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Drawer, Modal } from "antd";
import { SaveOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';
import { useStateValue } from '../../state/stateProvider';
import { drawerActions, manutencaoActions, selectPaginadoActions } from '../../actions';
import ManutencaoItemCobranca from "./manutencao";

export default function DrawerItemCobranca({ listaCobranca, pessoaId}) {

    const [formularioItemCobranca] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();

    if (!!manutencao?.dadosCobranca) {
    
        if (!!manutencao.dadosCobranca.anoMesCobranca) {
            manutencao.dadosCobranca.anoMesCobranca = moment(manutencao.dadosCobranca?.anoMesCobranca);
        }
        formularioItemCobranca.setFieldsValue(manutencao.dadosCobranca);
    }
    function fecharDrawer() {
        formularioItemCobranca.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerExtra: false } });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer
            width="60%"
            title="Item Cobrança"
            open={ui.showDrawerExtra}
            onClose={() => { onClose() }}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={fecharDrawer} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit" loading={carregando} onClick={() => { formularioItemCobranca.submit() }}>
                            Salvar
                        </Button>
                    </Col>
                </Row>
            }>
            <ManutencaoItemCobranca form={formularioItemCobranca} listaCobranca={listaCobranca} aoFechar={fecharDrawer} pessoaId={pessoaId}/>
        </Drawer>
    );
}