import React, { useState } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { drawerActions } from '../../actions';
import { useStateValue } from '../../state/stateProvider';

export default function BotaoIncluirDados(props) {

    const [{ ui }, dispatch] = useStateValue();
    const [desabilita, setDesabilita] = useState(false);

    return (
        <Button disabled={desabilita} type="primary" icon={<PlusOutlined />} size="middle" onClick={() => {
            if (!!props.onClick) {
                props.onClick();
            }
            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true, editItem: false } });
        }}>
            {!!props.children ? props.children : "ADICIONAR"}
        </Button>
    );

}