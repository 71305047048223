import React, { useState } from "react";
import { Row, Col, Form, Button, notification, Modal } from "antd";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import DrawerTipoRecurso from "./drawer";
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbIncluirDados, Search, TabelaDados } from "../../components";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";

export default function TipoRecurso() {

    const [formLog] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();

    function excluirRecursoTipo(record) {
        Modal.confirm({
            title: 'Deseja excluir esse Tipo de Recurso?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                api.delete(`RecursoTipo/${record.id}`).then(
                    res => {
                        notification.success({ message: 'Sucesso!', description: `Tipo de Recurso excluido com sucesso!` });
                    }
                ).catch(
                    error => {
                        console.log(error);
                        notification.warning({ message: 'Aviso!', description: 'Erro ao excluir Tipo de Recurso' });
                    }
                ).finally(() => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+id' } });
                })
            }
        });
    }

    return (
        <div className="col-paginas">
            <BreadcrumbIncluirDados pagina="Tipo de Recurso"/>
            <Form layout="vertical" form={formLog} name="log">
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <Search />
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url="RecursoTipo" colunas={[
                        {
                            title: 'Descrição',
                            render: ({ descricao }) => (
                                <div>
                                    <b> {descricao} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => {
                                                excluirRecursoTipo(record);
                                            }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
                </div>
            </Form>
            <DrawerTipoRecurso />
        </div>
    );
}
