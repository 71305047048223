import { Form } from "antd";
import React, { useEffect, useState } from "react";
import InputMask from 'react-input-mask';

function InputTelefone({label, name, rules, placeholder}){

    const [maskTelefone, setMaskTelefone] = useState("(99) 99999-9999");

    function VerificaMascara(telefone) {
        if (telefone[6]<=5){
            //residencial
            setMaskTelefone("(99) 9999-9999")
        }else{                             
            //moveis
            setMaskTelefone("(99) 99999-9999")
        };        
    };

    const inputRef = React.createRef();

    useEffect(() => {
        setTimeout(() => {
            if (!!inputRef.current) {
                inputRef.current.onFocus({cursor: 'end',})
            };
        },500);
    }, [inputRef.current])
    
    return(
        <Form.Item label={label} name={name} rules={rules}>
            <InputMask 
                ref={inputRef}
                mask={maskTelefone} 
                placeholder={!!placeholder?placeholder:"Informe o nº"}
                className="ant-input ant-input-sm" 
                onBlur={(telefone) => VerificaMascara(telefone.target.value)}
            /> 
        </Form.Item>
    );
}

export default InputTelefone;