import React, { useState } from "react";
import { Row, Col, Form, Button, Table, Tag, notification, Modal } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import DrawerRecurso from "./drawer";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbIncluirDados, BreadcrumbPage, Search, TabelaDados } from "../../components";
import api from "../../services/api";

export default function Recurso() {

    const [formRecurso] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();

    function excluirRecurso(record) {
        Modal.confirm({
            title: 'Excluir?',
            content: `Deseja realmente excluir o recurso ${record.descricao}?`,
            centered: true,
            okText: "Remover",
            okType: "danger",
            cancelText: "Cancelar",
            onOk: () => {
                api.delete(`Recurso/${record.id}`).then(
                    res => {
                        if (res.data) {
                            notification.success({ description: "Recurso removido com sucesso!", message: "Aviso" });
                            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+descricao' } });
                        }
                    }
                ).catch(
                    error => {
                        console.log(error);
                        notification.error({ description: "Não foi possivel remover o recurso!", message: "Aviso" })
                    }
                )
            }
        });
    };

    return (
        <div className="col-paginas">
            <BreadcrumbIncluirDados pagina="Recursos" />
            <Form layout="vertical" form={formRecurso} name="recurso">
                <Row>
                    <Col span={24}>
                        <Search />
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url="Recurso" colunas={[
                        {
                            title: 'Nome',
                            render: ({ descricao }) => (
                                <div>
                                    <b> {descricao || 'Não Informado'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Empresa',
                            render: ({ empresa }) => (
                                <div>
                                    <b>{empresa.razaoSocial}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Tipo',
                            width: 130,
                            render: ({ recursoTipo }) => (
                                <div>
                                    <b> {recursoTipo?.descricao || 'Não Informado'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Dados Formulário',
                            render: ({ formulario }) => (
                                <div>
                                    <Row align="middle" gutter={[5, 0]}>
                                        {JSON.parse(formulario)?.map((res) => (
                                            <Col>
                                                <Tag>
                                                    {Object.keys(res)[0]}: <b>{Object.values(res)[0]}</b>
                                                </Tag>
                                            </Col>
                                        )
                                        )}


                                    </Row>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => excluirRecurso(record)} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} scroll={{ x: 920 }} />
                </div>
            </Form>
            <DrawerRecurso />
        </div>
    );
}