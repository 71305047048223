import React, { useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Modal, Row } from 'antd';
import { useStateValue } from '../../state/stateProvider';
import { drawerActions, manutencaoActions } from '../../actions';
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import ManutencaoLocalizacao from '../localizacao';


export default function DrawerLocalizacao({ vincularLocal, vincularCoordenadas, coordenadasCEP }) {
    const [formLocalizacao] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();

    function fecharDrawer(retorno) {
        debugger;
       // formLocalizacao.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerLocalizacao: false } });
        if (!!retorno) {
            const arrayDeValores = Object.keys(retorno.posicaoMapa).map(chave => retorno.posicaoMapa[chave]);
            vincularLocal(retorno.id);
            vincularCoordenadas(arrayDeValores);
        }
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };


    return (
        <Drawer
            width="65%"
            height="100%"
            title="Manutenção Local"
            open={ui.showDrawerLocalizacao}
            onClose={() => { fecharDrawer() }}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={onClose} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit" onClick={() => formLocalizacao.submit()} loading={carregando}>
                            Salvar
                        </Button>
                    </Col>
                </Row>
            }>
            <ManutencaoLocalizacao form={formLocalizacao} carregando={setCarregando} fecharDrawer={fecharDrawer} coordenadasCEP={coordenadasCEP} />
        </Drawer>
    );
}