import React, { useEffect, useState } from 'react';
import { Calendar, DateLocalizer, momentLocalizer, Views } from 'react-big-calendar';
import { Row, Col, Form, Collapse, Button, Dropdown, Select, Radio, Divider, Tag, Menu, notification } from "antd";
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';
import api from '../../services/api';
import { AgendaCadastro, BreadcrumbPage, SelectPaginado } from '../../components';
import { drawerActions, manutencaoActions } from '../../actions';
import { useStateValue } from '../../state/stateProvider';
import DrawerAgenda from '../../components/agendaCadastro/drawerAgenda';
import { ArrowUpOutlined, CloseOutlined, SearchOutlined, ArrowDownOutlined, PlusOutlined, FilterOutlined } from '@ant-design/icons';
import { ModalDetalheReserva } from '../../components/modals';
moment.locale('pt-br');

const RecursoLocacao = () => {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [openAgenda, setOpenAgenda] = useState(false);
    const [open, setOpen] = useState(false);
    const [listaDadosCalendario, setListaDadosCalendario] = useState([]);
    const [events, setEvents] = useState([]);
    const localizer = momentLocalizer(moment);
    const [novaData, setNovaData] = useState();
    const [mes, setMes] = useState(0);
    const [dateStart, setDateStart] = useState([]);
    const [date, setDate] = useState(new Date());
    const [views, setViews] = useState(Views.MONTH);
    const [formRecursoLocacao] = Form.useForm();
    const [openModalDetalheReserva, setOpenModalDetalheReserva] = useState(false);
    const [detalhes, setDetalhes] = useState([]);
    const [recursoColor, setRecursoColor] = useState([]);
    const [cores] = useState(['rgb(126, 55, 148)', 'rgb(214, 26, 127)', 'rgb(215, 61, 50)', 'yellow', 'orange', 'red', 'blue']);

    const StyledCalendar = styled(Calendar)`
   color: black !important;
  font-size: 14px; 
  .rbc-header {
   
    font-weight: bold;
  }
  .rbc-today {
    background-color: #e8f0fe;
  }
  .rbc-event {
    background-color: #3174ad;
    color: #fff;
    border-radius: 4px;
    padding: 2px 5px;
    font-size: 13px;
    cursor: pointer;
    font-weight: 500;
  }
  .rbc-selected {
    background-color: #b3d4fc !important;
  }
`;

    function buscarRecursosColor() {
        api.get("Recurso/RecursosColor").then(
            res => {
                let objeto = {};
                for (let item of res.data) {
                    objeto[item.id] = item.color;
                }
                setRecursoColor(objeto);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    useEffect(() => {
        buscarRecursosColor();
    }, []);

    useEffect(() => {
        let date = new Date();
        buscarDadosAgenda(date.getMonth() + 1, date.getFullYear());
    }, [recursoColor]);


    function buscarDadosAgenda(mes, ano) {
        api.get('Agenda/BuscarRecursos?TemRecurso=true&ano=' + ano + '&mes=' + mes).then(
            res => {
                res.data.forEach(element => {
                    let dayStart = new Date(element.start).getDate();
                    let dayEnd = new Date(element.end).getDate();
                    let monthStart = new Date(element.start).getMonth();
                    let monthEnd = new Date(element.end).getMonth();
                    let yearStart = new Date(element.start).getFullYear();
                    let yearEnd = new Date(element.end).getFullYear();
                    let hourStart = new Date(element.start).getHours();
                    let hourEnd = new Date(element.end).getHours();
                    let minuteStart = new Date(element.start).getMinutes();
                    let minuteEnd = new Date(element.end).getMinutes();
                    element.color = recursoColor[element.recursoId];
                    element.start = new Date(yearStart, monthStart, dayStart, hourStart, minuteStart, 0);
                    element.end = new Date(yearEnd, monthEnd, dayEnd, hourEnd, minuteEnd, 0);

                });
                setEvents(res.data);
            }
        ).catch(error => {
            console.log(error);
        });
    }


    function buscarRecursos() {
        let filtros = '';
        filtros += (!!formRecursoLocacao.getFieldValue().tipoRecursoId) ? `&recursoTipo=${formRecursoLocacao.getFieldValue().tipoRecursoId}` : '';
        filtros += (!!formRecursoLocacao.getFieldValue().recursoId) ? `&recursoLocado=${formRecursoLocacao.getFieldValue().recursoId}` : '';

        api.get('Agenda/BuscarRecursos?TemRecurso=true&ano=' + date.getFullYear() + '&mes=' + (date.getMonth() + 1) + filtros).then(
            res => {
                res.data.forEach(element => {
                    let dayStart = new Date(element.start).getDate();
                    let dayEnd = new Date(element.end).getDate();
                    let monthStart = new Date(element.start).getMonth();
                    let monthEnd = new Date(element.end).getMonth();
                    let yearStart = new Date(element.start).getFullYear();
                    let yearEnd = new Date(element.end).getFullYear();
                    let hourStart = new Date(element.start).getHours();
                    let hourEnd = new Date(element.end).getHours();
                    let minuteStart = new Date(element.start).getMinutes();
                    let minuteEnd = new Date(element.end).getMinutes();
                    element.color = recursoColor[element.recursoId];
                    element.start = new Date(yearStart, monthStart, dayStart, hourStart, minuteStart, 0);
                    element.end = new Date(yearEnd, monthEnd, dayEnd, hourEnd, minuteEnd, 0);
                });
                setEvents(res.data);
            }
        ).catch(error => {
            console.log(error);
        });
    }



    const handleNavigate = (date, view) => {
        setMes(date.getMonth() + 1);
        if (view === 'month') {
            const start = moment(date).startOf('month').toDate();
            buscarDadosAgenda(start.getMonth() + 1, 2023);
            setDate(date);
        }
    };


    function handleDrillDown(date) {
        setViews(Views.DAY);
        setDate(date);
    }

    function handleViewChange(view) {
        switch (view) {
            case 'agenda':
                setViews(Views.AGENDA);
                break;
            case 'day':
                setViews(Views.DAY);
                break;
            case 'week':
                setViews(Views.WEEK);
                break;
            default:
                setViews(Views.MONTH);
        }
        console.log(`Nova visualização: ${view}`);
    }

    async function abrirDrawer(event) {
        let req = await api.get(`Agenda/${event.id}`);
        if (req.status === 200 && !!req.data) {
            let dados = req.data;
            dados.dataFinal = !!dados.dataFinal ? moment(dados.dataFinal) : null;
            dados.dataInicial = !!dados.dataInicial ? moment(dados.dataInicial) : null;
            dados.recurso = !!dados.agendaRecursos?.length > 0 ? dados.agendaRecursos[0]?.recurso?.descricao : '';
            return dados;
        }
        return {};
    };

    const togglePesquisa = () => {
        setOpen(!open);
    };

    function removerFiltros() {
        formRecursoLocacao.setFieldsValue({
            tipoRecursoId: null, recursoId: null
        });
        buscarRecursos();
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        let style = {
            backgroundColor: event.color,
            heigth: '12%'

        };
        return {
            style: style
        };
    };


    return (
        <>
            <div class="padding10">
                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                    <Col>
                        <BreadcrumbPage pagina="Reserva de Recursos" />
                    </Col>
                    <Col span={3}  xs={24} sm={9} md={9} lg={6} xl={6} xxl={3}>
                        <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={() => {
                            setOpenAgenda(true);
                        }}>
                            Adicionar
                        </Button>
                    </Col>
                </Row>
                <Form form={formRecursoLocacao}>
                    <Row justify="end" align="middle">
                        <Col>
                            <Collapse className="collapse-calendar col-ordem" accordion ghost destroyInactivePanel={true}>
                                <div className="colDet"></div>
                                <Collapse.Panel key="1" showArrow={false} extra={
                                    <Button icon={<FilterOutlined />} onClick={togglePesquisa}>
                                        Filtros
                                    </Button>
                                }>
                                    <Row>
                                        <Col >
                                            <Form.Item label="Tipo de Recursos" name="tipoRecursoId">
                                                <SelectPaginado url="RecursoTipo" placeholder="Selecione o Tipo de Recurso" form={formRecursoLocacao} name="tipoRecursoId" conteudo={
                                                    rtp => (<Select.Option value={rtp.id} key={rtp.key}>{rtp.descricao}</Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col >
                                            <Form.Item label="Recursos" name="recursoId">
                                                <SelectPaginado url="Recurso" placeholder="Selecione o Recurso" form={formRecursoLocacao} name="recursoId" conteudo={
                                                    rec => (<Select.Option value={rec.id} key={rec.key}>{rec.descricao}</Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col className="col-w">
                                            <Row align="middle" justify="end" gutter={[8, 2]} className="btn-col-21">
                                                <Col>
                                                    <Button type="primary" icon={<SearchOutlined />} onClick={buscarRecursos} block>
                                                        Aplicar Filtros
                                                    </Button>
                                                </Col>
                                                <Col>
                                                    <Button icon={<CloseOutlined />} onClick={removerFiltros} block>
                                                        Limpar Filtros
                                                    </Button>
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Form>
            </div>
            <StyledCalendar
                popupOffset={{ x: 100, y: 50 }}
                messages={{
                    week: 'Semana',
                    work_week: 'Dias úteis',
                    day: 'Dia',
                    month: 'Mês',
                    previous: 'Anterior',
                    next: 'Próximo',
                    today: `Hoje`,
                    agenda: 'Agenda',

                    showMore: (total) => `+${total} plus`,
                }}
                showAllEvents={true}
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                selectable
                date={date}
                view={views}
                onView={handleViewChange}
                eventPropGetter={eventStyleGetter}
                onSelectEvent={async (event) => {
                    console.log(event);
                    let dados = await abrirDrawer(event);
                    setDetalhes(dados);
                    setOpenModalDetalheReserva(true);
                }}
                onNavigate={handleNavigate}
                showMultiDayTimes={true}
                onDrillDown={handleDrillDown}
                onSelectSlot={(slotInfo) => {
                    console.log(
                        `selected slot: \n\nstart ${slotInfo.start.toLocaleString()} ` +
                        `\nend: ${slotInfo.end.toLocaleString()} ` +

                        `\naction: ${slotInfo.action}`
                    );

                }
                }
            />
            <DrawerAgenda openDrawerAgenda={openAgenda} closeDrawerAgenda={() => { setOpenAgenda(false); buscarRecursos(); }} />
            <ModalDetalheReserva exibirModalDetalheReserva={openModalDetalheReserva} fecharModalDetalheReserva={() => setOpenModalDetalheReserva(false)} detalhes={detalhes} />
        </>
    );
};

export default RecursoLocacao;
