import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Input, Checkbox, notification } from "antd";

import api from "../../services/api";
import { useStateValue } from "../../state/stateProvider";

export default function ManutencaoTipoAgenda({ form, aoSalvar, carregando, listaVisiveis, setItensVisiveis, itensVisiveis }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [listaEmpresasUsuario, setListasEmpresasUsuario] = useState([]);


    useEffect(() => {
        carregarDados();
    }, [])

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.formulario) {
            manutencao.dados.formulario = JSON.parse(manutencao.dados.formulario);
            setItensVisiveis(manutencao.dados.formulario);
            form.setFieldsValue(manutencao.dados);
        }
    }, [manutencao.dados])

    function carregarDados() {
        api.get(`EmpresasUsuario`).then(
            res => {
                let key = 0;
                res.data.items.forEach((item) => {
                    item.key = key++;
                });
                setListasEmpresasUsuario(res.data.items);
            }
        ).catch(error => { console.log(error) });
    }

    async function salvarManutencao(values) {
        values.formulario = JSON.stringify(itensVisiveis);
        carregando(true);
        if (!!values.id) {
            api.put(`AgendaTipo`, values).then(
                res => {
                    notification.success({ message: `Sucesso!`, description: `Tipo de Agenda editado com sucesso!` });
                }
            ).catch(
                error => {
                    notification.warning({ message: `Aviso!`, description: `Não foi possivel editar o Tipo de Agenda` });
                }
            ).finally(() => {
                aoSalvar();
            });
        } else {
            api.post(`AgendaTipo`, values).then(
                res => {
                    notification.success({ message: `Sucesso!`, description: `Tipo de Agenda salvo com sucesso!` });
                }
            ).catch(
                error => {
                    notification.warning({ message: `Aviso!`, description: `Não foi possivel salvar o Tipo de Agenda` });
                }
            ).finally(() => {
                aoSalvar();
            });
        }


    }

    function alterarVisivel(record) {
        setItensVisiveis(record);
    }

    return (
        <Form layout="vertical" name="formManutencaoTipoAgenda" form={form} onFinish={salvarManutencao}>
            <Row>
                <Col span={24}>
                    <Form.Item name='id' hidden />
                    <Form.Item label="Empresa" name="empresaId">
                        <Select allowClear placeholder="Selecione a Empresa">
                            {listaEmpresasUsuario.map((emp) => (
                                <Select.Option key={emp.key} value={emp.id}>{emp.razaoSocial}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Descrição" name="descricao">
                        <Input.TextArea placeholder="Informe uma Descrição" />
                    </Form.Item>
                </Col>
            </Row>
            <Checkbox.Group className="w-100" options={listaVisiveis} value={itensVisiveis} onChange={(record) => alterarVisivel(record)} />
           {/* <div className="tabela-pages">
                  <Table columns={[
                    {
                        title: 'Descrição',
                        render: ({ descricao }) => (
                            <div>
                                <b>{descricao || 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Visível',
                        width: 85,
                        align: 'center',
                        render: ({ visivel }, record) => (
                            <div>
                                <Checkbox onChange={() => alterarVisivel(record)} name="visivel"/>
                            </div>
                        ),
                    }
                ]} dataSource={listaVisiveis} pagination={false} scroll={{ x: 920 }}
                /> 
            </div>*/}
        </Form>
    );
}
