import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Button, Avatar, Tag, Dropdown, Badge, Menu, Tooltip, Typography } from "antd";
import { LogoutOutlined, BellFilled, InfoCircleTwoTone, CheckOutlined, ExpandOutlined } from "@ant-design/icons";
import { useFullScreen } from 'react-browser-hooks';

import { DrawerPerfil, DrawerLocal } from "./pagesDrawer";

import "./index.less";
import { getFotoUsuario, getNomeUsuario, logout } from "../../services/auth";
import { logOut } from "../../services/firebase";
import { drawerActions } from "../../actions";
import { useStateValue } from "../../state/stateProvider";

export default function Header() {

    const [openLocal, setOpenLocal] = useState(false);
    const [openPerfil, setOpenPerfil] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();


    const { toggle } = useFullScreen();

    const notificacoes = (
        <Menu className="col-notificacoes">
            <Menu.Item key="1">
                <Typography.Title level={4} className="text-center">
                    Notificações
                    <Button type="link" size="size" icon={<CheckOutlined />} />
                </Typography.Title>
            </Menu.Item>
            <Menu.Divider />
            <Menu>
                <Menu.Item>
                    <Tooltip title="notificacoes">
                        <Link to="/notificacao">
                            <InfoCircleTwoTone /> notificacoes
                        </Link>
                    </Tooltip>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item className="menu-notificacoes">
                    <Link to="/notificacao">
                        Ver todas as notificações
                    </Link>
                </Menu.Item>
            </Menu>
        </Menu>
    );

    return (
        <Layout.Header>
            <Row align="middle" justify="center" gutter={[8, 0]}>
                <Col span={6}>
                    <Link to="/">
                        <img src={require("../../assets/logo_sacg_white.svg").default} width="90" alt="SacG" />
                    </Link>
                </Col>
                <Col span={18} align="right">
                    <Row align="middle" justify="end">
                        <Col xs={7} xl={6} className="txt-overflow m-r-5">
                           <p style={{fontSize:16, margin:5}}> {getNomeUsuario()}</p>
                        </Col>
                        <Col>
                            <Avatar  src={!!getFotoUsuario() ? getFotoUsuario() : require("../../assets/logo_abase.svg").default} className="cursor-pointer" onClick={() => {   dispatch({ type: drawerActions.CHANGE, data: { showDrawerPerfil: true } }); }} />
                        </Col>
                        <Col className="hide">
                            <Button type="text" className="auto-h" onClick={toggle}>
                                <Row justify="center">
                                    <Col>
                                        <ExpandOutlined />
                                    </Col>
                                    <Col span={24}>
                                        Tela Cheia
                                    </Col>
                                </Row>
                            </Button>
                        </Col>
                        {/*  <Col align="center">
                            <div className="cursor-pointer" onClick={() => {setOpenLocal(true);}}>
                                Onde Estou? <br />
                                <Tag color="#013d79" className="txt-overflow"> 
                                    <b>Abase</b>
                                </Tag>
                            </div>
                        </Col> 
                        <Col>
                            <Dropdown overlay={notificacoes} trigger={['click']} placement="bottomRight" arrow>
                                <Badge count={`1`}>
                                    <Button type="text" size="large" icon={<BellFilled />} />
                                </Badge>
                            </Dropdown>
                        </Col>*/}
                        <Col>
                            <Button type="text" className="auto-h" onClick={() => {
                                logout();
                                logOut();
                            }}>
                                <Row justify="center">
                                    <Col>
                                        <LogoutOutlined />
                                    </Col>
                                    <Col span={24}>
                                        Sair
                                    </Col>
                                </Row>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <DrawerPerfil />
            <DrawerLocal exibirLocal={openLocal} fecharLocal={() => { setOpenLocal(false) }} />
        </Layout.Header>
    );
}