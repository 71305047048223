import React, { useState } from "react";
import { Row, Col, Form, Checkbox, Input, Typography, Table, Button, Divider } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { ModalTipoRequisicao } from "../../components/modals";

export default function ManutencaoTipoRequisicao({form}) {

    const [tabelaTipoRequisicao, setTabelaTipoRequisicao] = useState([]);
    const [openModalTipoRequisicao, setOpenModalTipoRequisicao] = useState(false);  

    for (let a = 0; a < 1; a++) {
        tabelaTipoRequisicao.push({
            key: a,
            label: 'Hospedagem',
            tipo: 'text'
        });
    }

    return (
        <>
            <Form layout="vertical" name="formManutencaoTipoRequisicao" form={form}>
                <Row gutter={[8, 0]}>    
                    <Col span={24}>
                        <Form.Item label="Descrição" name="descricao">
                            <Input.TextArea placeholder="Informe um Título" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item valuePropName="checked" name="precisa_hotel">
                            <Checkbox> Precisa de Hotel </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle">
                    <Col span={24}>
                        <Typography.Title level={5}>
                            <img src={require("../../assets/i_settings.png").default} alt="Tipo de Requisição" /> Tipo de Requisição
                        </Typography.Title>
                        <Divider orientation="left" plain className="m-t-5 m-b-5"/>
                    </Col>
                    <Col>
                        <div className="i-Position">
                            <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => {setOpenModalTipoRequisicao(true);}}/>
                        </div>
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <Table columns={[                      
                        {
                            title: 'Label',
                            render: ({ label }) => (
                                <div>
                                    <b>{label} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Tipo',
                            render: ({ tipo }) => (
                                <div>
                                    <b>{tipo} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {setOpenModalTipoRequisicao(true);}}/>
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]}
                        locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} dataSource={tabelaTipoRequisicao} scroll={{ x: 920 }}
                    />            
                </div>
            </Form>              
            <ModalTipoRequisicao form={form} exibirModalTipoRequisicao={openModalTipoRequisicao} fecharModalTipoRequisicao={() => setOpenModalTipoRequisicao(false)} />
        </>
    );
}