import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Input, DatePicker, Checkbox, Radio, notification, Alert } from "antd";
import moment from "moment";

import { manutencaoActions, selectPaginadoActions } from "../../actions";
import { Data, ItensCobranca, ItensTarefas } from "../../components/";
import { useStateValue } from '../../state/stateProvider';
import api from "../../services/api";
import SelectPaginado from "../../components/selectPaginado";
import { converterDatasParaMomentLista } from "../../services/funcoes";
import { getEmpresaLogada } from "../../services/auth";
import { Icon } from "@ant-design/compatible";


export default function ManutencaoOrdemServico({ desabilitar = false, form, aoSalvar, carregando, salvarOS, fechandoDrawer }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [exibeItensCobranca, setExibeItensCobranca] = useState(false);
    const [exibeAprovacao, setExibeAprovacao] = useState(false);
    const [cobrancaRejeitada, setCobrancaRejeitada] = useState(false);
    const [listaStatus, setListaStatus] = useState([]);
    const [listaContato, setListaContato] = useState([]);
    const [contatosEmail, setContatosEmail] = useState([]);
    const [listaContrato, setListaContrato] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [listaCobranca, setListaCobranca] = useState([]);
    const [listaAtividade, setListaAtividade] = useState([]);
    const [dataAtual] = useState(moment(new Date()));
    const [temContato, setTemContato] = useState(false);
    const [listaTipo, setListaTipo] = useState([]);
    const [listaTermoAceite, setListaTermoAceite] = useState([]);
    const [listaObsPessoa, setListaObsPessoa] = useState([]);
    const [exibeInfo, setExibeInfo] = useState(false);
    const [exibeDadosOs, setExibeDadosOs] = useState(false);
    const [dadosOs, setDadosOS] = useState("");
    const [pessoaId, setPessoaId] = useState(0);
    const [filtroParticipantes, setFiltroParticipantes] = useState(0);
    const [exibirTodos, setExibirTodos] = useState(true);
    const [assinado, setAssinado] = useState(1);
    const [gedInfo, setGedInfo] = useState([]);
    const [numeroOS, setNumeroOS] = useState(0)
    const [desabilitarStatus, setDesabilitarStatus] = useState(false);
    let dadosOk = true;

    useEffect(() => {
        carregarDados();
    }, []);

    useEffect(() => {
        debugger;
        setTemContato(false);
        setExibeInfo(false);
        setExibeDadosOs(false);
        setExibeItensCobranca(false);
        setExibeAprovacao(false);
        setCobrancaRejeitada(false);
        popularTipo();
        setPessoaId(0);
        setNumeroOS(0);
        if (manutencao?.dados?.gedInfos?.find(x => x.tipoAnexo === 2)) {
            setGedInfo(manutencao?.dados?.gedInfos?.find(x => x.tipoAnexo === 2));
        }
        if (manutencao?.dados?.assinado) {
            setAssinado(manutencao?.dados?.assinado);
        }
        if (!!manutencao.dados && !!manutencao.dados?.idOrdemServico) { //estava entrando no editar do item
            let dados = manutencao.dados;
            manutencao.dados.usuarioId = form.getFieldValue().usuarioId;
            //manutencao.dados.possuiCobranca = form.getFieldValue().possuiCobranca;
            if (!!dados.ordensTarefas && dados.ordensTarefas.length > 0 && listaItens.length == 0) {
                let ordemTarefa = [...dados.ordensTarefas];
                popularListaTarefa(ordemTarefa);
                //  buscarTermoAceite(manutencao.dados.idOrdemServico);
            }
            if (!!manutencao?.dados?.dadosOSGestor) {
                setDadosOS(manutencao?.dados?.dadosOSGestor);
                setExibeDadosOs(true);
            }

            if (!!manutencao.dados.pessoaContatoId && !!manutencao.dados.pessoaId) {
                buscarDadosContato(manutencao.dados.pessoaId);
            }

            if (!!dados.itensCobranca && dados.itensCobranca.length > 0) {
                let cobrancas = [...dados.itensCobranca];
                popularListaCobranca(cobrancas)
            }

            setExibeAprovacao(dados.cobrancaAprovada === 3);
            setCobrancaRejeitada(dados.cobrancaAprovada === 4);
            setPessoaId(dados.pessoaId);
            setNumeroOS(dados.numero);
            buscarObsPessoa(dados.pessoaId);
            buscarEmails(dados.pessoaId);
            if (dados.cobrancaAprovada === 4) {
                setExibeItensCobranca(false);
                manutencao.dados.possuiCobranca = false;
            } else {
                setExibeItensCobranca(dados.possuiCobranca);
            }

            if (dados.status == 4) {
                notification.error({
                    icon: <Icon type="close-circle" style={{ fontSize: '30px', color: 'red' }} />,
                    message: `Atenção`,
                    description:
                        'A ordem não pode ser alterada porque já esta faturada.',
                    placement: 'top',
                    duration: 0,
                });
            }
            if (dados.status === 6 && dados.cobrancaAprovada === 1) {
                setDesabilitarStatus(true);
            } else {
                setDesabilitarStatus(false);
            }
            let lista = [];
            if (!!dados.usuarioId) {
                lista.push({ name: "usuarioId", campo: "FiltroIdUsuario", value: dados.usuarioId });
            }
            if (!!dados.pessoaId) {
                lista.push({ name: "pessoaId", campo: "PessoaId", value: dados.pessoaId });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: lista });
        }
    }, [ui.showDrawer]);

    function buscarObsPessoa(pessoaId) {
        api.get(`ObservacaoPessoa/BuscarPorPessoa?idPessoa=${pessoaId}`).then(
            res => {
                if (!!res.data) {
                    setListaObsPessoa(res.data);
                    setExibeInfo(true);
                    if (!manutencao?.dados?.idOrdemServico && form.getFieldValue("cobrancaAprovada") !== 3 && form.getFieldValue("cobrancaAprovada") !== 4) {
                        if (res.data?.observacaoTipoId === 13) {
                            form.setFieldsValue({ "possuiCobranca": true });
                            form.setFieldsValue({ "status": 6 });
                            setExibeItensCobranca(true);
                            setDesabilitarStatus(true);
                        } else {
                            form.setFieldsValue({ "possuiCobranca": false });
                            form.setFieldsValue({ "status": 1 });
                            setExibeItensCobranca(false);
                            setDesabilitarStatus(false);
                        }
                    }

                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    }

    useEffect(() => {
        form.setFieldsValue({ "possuiCobranca": exibeItensCobranca });
        if (exibeItensCobranca && !exibeAprovacao) {
            form.setFieldsValue({ "status": 6 });
            setDesabilitarStatus(true);
        } else {
            form.setFieldsValue({ "status": 1 });
            setDesabilitarStatus(false);
        }
    }, [exibeItensCobranca]);

    useEffect(() => {
        if (fechandoDrawer) {
            setListaCobranca([]);
            setListaItens([]);
            setListaAtividade([]);
            setListaTermoAceite([])
            setPessoaId();
            setNumeroOS();
            setListaCobranca([]);
            setListaObsPessoa([]);
            setDadosOS("");
            setAssinado(1);
            form.resetFields();
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        }
    }, [fechandoDrawer])

    function popularTipo() {
        api.get("TarefaTipo").then(
            res => {
                if (!!res.data?.items) {
                    setListaTipo(res.data.items);
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    };


    async function popularListaTarefa(ordemTarefaDados) {
        let itens = [];
        ordemTarefaDados = ordemTarefaDados.sort(ordenarPorId);
        for (let i = 0; i < ordemTarefaDados.length; i++) {
            let lista = {};
            lista.tarefaTipo = ordemTarefaDados[i].tarefa.tarefaTipo;
            lista.produto = ordemTarefaDados[i].produto;
            lista.responsavel = ordemTarefaDados[i].tarefa.responsavel;

            lista.status = ordemTarefaDados[i].tarefa.status;
            lista.key = i + 1;
            lista.ordemServicoId = ordemTarefaDados[i].ordemServicoId;
            lista.idOrdemServico = ordemTarefaDados[i].ordemServicoId;
            lista.idOrdemTarefa = ordemTarefaDados[i].idOrdemTarefa;
            lista.produtoId = ordemTarefaDados[i].produtoId;
            lista.tarefaId = ordemTarefaDados[i].tarefaId;
            lista.tarefaTipoId = ordemTarefaDados[i].tarefa.tarefaTipoId;
            lista.idResponsavel = ordemTarefaDados[i].tarefa.idResponsavel;

            let tar = {};
            tar.idTarefa = ordemTarefaDados[i].tarefa.idTarefa;
            tar.descricao = ordemTarefaDados[i].tarefa.descricao;
            tar.idResponsavel = ordemTarefaDados[i].tarefa.idResponsavel;
            tar.usuarioId = ordemTarefaDados[i].tarefa.usuarioId;
            tar.data = ordemTarefaDados[i].tarefa.data;
            tar.dataInicio = ordemTarefaDados[i].tarefa.dataInicio;
            tar.dataFim = ordemTarefaDados[i].tarefa.dataFim;
            tar.status = ordemTarefaDados[i].tarefa.status;
            tar.dataLimite = null;
            tar.porcentagemAndamento = ordemTarefaDados[i].tarefa.porcentagemAndamento;
            tar.tempoPrevisto = ordemTarefaDados[i].tarefa.tempoPrevisto;
            tar.produtoId = ordemTarefaDados[i].produtoId;
            tar.tarefaTipoId = ordemTarefaDados[i].tarefa.tarefaTipoId;
            lista.tarefa = tar;
            lista.termoAceite = ordemTarefaDados[i].termoAceite;


            lista.idTarefa = ordemTarefaDados[i].tarefa.idTarefa;
            lista.descricao = ordemTarefaDados[i].tarefa.descricao;
            lista.idResponsavel = ordemTarefaDados[i].tarefa.idResponsavel;
            lista.usuarioId = ordemTarefaDados[i].tarefa.usuarioId;
            lista.data = ordemTarefaDados[i].tarefa.data;
            lista.dataInicio = ordemTarefaDados[i].tarefa.dataInicio;
            lista.dataFim = ordemTarefaDados[i].tarefa.dataFim;
            lista.status = ordemTarefaDados[i].tarefa.status;
            lista.dataLimite = null;
            lista.porcentagemAndamento = ordemTarefaDados[i].tarefa.porcentagemAndamento;
            lista.tempoPrevisto = ordemTarefaDados[i].tarefa.tempoPrevisto;
            lista.produtoId = ordemTarefaDados[i].produtoId;

            itens.push(lista);
        }

        setListaItens(itens);
        setListaAtividade(itens);
    }


    function ordenarPorId(a, b) {
        return a.tarefaId - b.tarefaId;
    }

    async function popularListaCobranca(cobrancas) {
        let cobranca = await converterDatasParaMomentLista(cobrancas);
        let listaCobranca = [];
        for (let cob of cobranca) {
            listaCobranca.push(cob);
        }
        setListaCobranca(listaCobranca);
    };

    function validarDadosOS(record) {
        if (!!record.possuiCobranca && listaCobranca.length < 1 && record.status == 2) {
            notification.warning({ description: "Não foram informados os itens da cobrança!", message: "Aviso" });
            return false;
        }
        if (listaItens.length < 1) {
            notification.warning({ description: "Não foram informados os itens da ordem de serviço", message: "Aviso" });
            return false;
        }
        salvarManutencao(record);
    };

    async function salvarManutencao(record) {
        debugger
        dadosOk = true;
        let dados = { ...record };
        let ordemServico = {};
        ordemServico.IdOrdemServico = dados.idOrdemServico;
        ordemServico.Numero = dados.numero;
        ordemServico.PessoaId = dados.pessoaId;
        ordemServico.DadosOSGestor = dados.dadosOSGestor;
        ordemServico.CodigoIntegracao = dados.codigoIntegracao;
        ordemServico.TipoAtendimento = dados.TipoAtendimento;
        ordemServico.Status = dados.status;
        ordemServico.CobrancaAprovada = dados.cobrancaAprovada;
        ordemServico.Observacao = dados.observacao;
        ordemServico.PossuiCobranca = dados.possuiCobranca;
        ordemServico.Data = moment(dados.data);
        ordemServico.dataCadastro = moment(dados?.dataCadastro) ? moment(dados.dataCadastro) : moment(new Date());
        if (ordemServico.PossuiCobranca) {
            ordemServico.itensCobranca = await montaDadosCobranca(dados);
        } else {
            ordemServico.itensCobranca = [];
        }


        /*let termo = { ...listaTermoAceite }
        if (!!termo) {
            ordemServico.TermoAceite = termo;
        }*/
        ordemServico.PessoaContatoId = dados.pessoaContatoId;
        ordemServico.OrdensTarefas = await montaDadosTarefa();
        ordemServico.UsuarioId = dados.usuarioId;
        ordemServico.dataAlteracao = moment(new Date());
        console.log(ordemServico);
        undefinedParaNull(ordemServico);
        if (dadosOk) {
            if (!!record.idOrdemServico) {
                editarOrdemServico(ordemServico);
            } else {
                incluirOrdemServico(ordemServico);
            }
        }
    };

    function incluirOrdemServico(dados) {
        api.post("OrdemServico", dados).then(
            res => {
                notification.success({ description: "Ordem de serviço incluída com sucesso", message: "Sucesso" })
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                carregando(false);
                aoSalvar();
            }
        );
    };

    function editarOrdemServico(dados) {
        api.put("ordemservico", dados).then(
            res => {
                //idOrdemServico = res.data.idOrdemServico;
                notification.success({ description: "Ordem de serviço editada com sucesso", message: "Sucesso" })
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                carregando(false);
                aoSalvar();
            }
        );
    };

    async function montaDadosTarefa() {
        let ordensTarefas = [];
        for (let i = 0; i < listaItens.length; i++) {
            listaItens[i].tarefa.dataInicio = moment(listaItens[i].tarefa.dataInicio);
            listaItens[i].tarefa.dataFim = moment(listaItens[i].tarefa.dataFim);

            if (listaItens[i].ordensTarefas?.length > 0) {
                for (let j = 0; j < listaItens[i].ordensTarefas.length; j++) {
                    listaItens[i].ordensTarefas[j].produto = null;

                    ordensTarefas.push(listaItens[i].ordensTarefas[j]);
                }
            } else {
                listaItens[i].produto = null;
                ordensTarefas.push(listaItens[i]);
            }
        }
        ordensTarefas = ordensTarefas.sort(ordenarPorKey);
        return ordensTarefas;
    }

    function ordenarPorKey(a, b) {
        return a.key - b.key;
    }


    async function montaDadosCobranca(record) {
        let dados = [...listaCobranca];
        let listaCob = []
        for (let cobranca of dados) {
            let cob = { ...cobranca };
            let dataAnoMes = new Date()
            if (!!cob.anoMesCobranca && cob.anoMesCobranca.toString().length === 6) {
                dataAnoMes.setMonth((cob.anoMesCobranca.toString().substring(4, 6) - 1));
                dataAnoMes.setYear(cob.anoMesCobranca.toString().substring(0, 4));
                cob.anoMesCobranca = dataAnoMes;
            }
            if (cob.anoMesCobranca) {
                cob.anoMesCobranca = moment(cob.anoMesCobranca).format("YYYYMM");
            } else {
                cob.anoMesCobranca = null;
            }
            if ((!cob.anoMesCobranca || !cob.produtoId || !cob.quantidade || !cob.valorUnitario || !cob.sequencia) && record.status == 2) {
                notification.warning({ description: "Campos obrigatórios não foram preenchidos nos Itens de Cobrança!", message: "Aviso" });
                dadosOk = false;
                return false;
            }
            cob.produto = null;
            cob.contrato = null;
            undefinedParaNull(cob);
            listaCob.push(cob);
        }
        return listaCob;
    };

    function undefinedParaNull(objeto) {
        for (let dado in objeto) {
            if (!!!objeto[dado] && objeto[dado] == undefined) {
                objeto[dado] = null;
            }
        }
    }

    function itemCobranca() {
        setExibeItensCobranca(!exibeItensCobranca);
    };

    function carregarDados() {
        buscarDadosStatus();
    };

    function buscarDadosStatus() {
        api.get("Enumeravel/StatusOrdemServico").then(
            res => {
                if (!!res.data) {
                    setListaStatus(res.data);
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    function buscarDadosContato(pesId) {
        setPessoaId(pesId);
        api.get(`PessoaContato/${pesId}`).then(
            res => {
                if (!!res.data) {
                    if (res.data.length > 0) {
                        setTemContato(true);
                    } else {
                        setTemContato(false);
                    }
                    setListaContato(res.data);
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    function buscarEmails(pesId) {
        api.get(`pessoaEmail/ListarEmailContato?Pessoa=` + pesId).then(
            res => {
                if (!!res.data) {
                    setContatosEmail(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    return (
        <Form layout="vertical" name="formManutencaoOrdemServico" form={form} onFinish={validarDadosOS}>
            {exibeDadosOs && <div className="m-t-8">
                <Alert message="Informações do Gestor" description={dadosOs} type="info" />
            </div>}
            {exibeItensCobranca && !exibeAprovacao && !cobrancaRejeitada && <Row align="middle" gutter={[0, 8]}>
                <Col span={24}>
                    <Alert message="Ao selecionar que possui cobrança na OS será encaminhado um e-mail para o supervisor autorizar esta cobrança." type="warning" showIcon />
                </Col>
            </Row>}
            {exibeItensCobranca && exibeAprovacao && <Row align="middle" gutter={[0, 8]}>
                <Col span={24}>
                    <Alert message="Cobrança aprovada para essa ordem de serviço." type="info" showIcon />
                </Col>
            </Row>}
            {cobrancaRejeitada && <Row align="middle" gutter={[0, 8]}>
                <Col span={24}>
                    <Alert message="Cobrança rejeitada para essa ordem de serviço." type="error" showIcon />
                </Col>
            </Row>}

            <Row gutter={[8, 0]}>
                <Form.Item name="idOrdemServico" hidden></Form.Item>
                <Form.Item name="numero" hidden></Form.Item>
                <Form.Item name="codigoIntegracao" hidden></Form.Item>
                <Form.Item name="dadosOSGestor" hidden></Form.Item>
                <Form.Item name="cobrancaAprovada" hidden></Form.Item>
                <Data name="dataCadastro" format="DD/MM/YYYY HH:mm" hidden />
                <Col xs={24} sm={12} md={4} lg={4} xl={5}>
                    <Form.Item label="Data" name="data" initialValue={dataAtual} rules={[{ required: true, message: "Favor informar a data da ordem de serviço" }]}>
                        <DatePicker showTime placeholder="DD/MM/YYYY" format={"DD/MM/YYYY HH:mm:ss"} disabled={desabilitar} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={7} lg={6} xl={6}>
                    <Form.Item label="Tipo de Atendimento" name="TipoAtendimento" initialValue={1} rules={[{ required: true, message: "Favor informar o tipo de atendimento" }]}>
                        <Radio.Group>
                            <Radio value={1}>Externo</Radio>
                            <Radio value={2}>Interno</Radio>
                            <Radio value={3}>Remoto</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <Form.Item label="Status" name="status" initialValue={1} rules={[{ required: true, message: "Favor informar o status" }]}>
                        <Select disabled={desabilitarStatus} allowClear placeholder="Selecione o Status" >
                            {listaStatus.map(sta => (<Select.Option disabled={sta.valor === 4 || sta.valor === 6 ? true : false} value={sta.valor} key={sta.valor}>{sta.descricao}</Select.Option>))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item label="Usuário" name="usuarioId" rules={[{ required: true, message: "Favor informar o usuário" }]}>
                        <SelectPaginado disabled={desabilitar} url="Usuarios?Ativo=true&Sac=true" name="usuarioId" form={form} conteudo={
                            usu => (<Select.Option value={usu.id} key={usu.id}>{(usu.nome).normalize('NFD').replace(/[\u0300-\u036f]/g, "")}</Select.Option>)
                        } />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item label="Cliente" name="pessoaId" rules={[{ required: true, message: "Favor informar o cliente" }]}>
                        <SelectPaginado disabled={desabilitar} url="Cliente" name="pessoaId" form={form} onChangeFunction={(value) => { buscarDadosContato(value); buscarEmails(value); buscarObsPessoa(value); }} conteudo={
                            (cli) =>
                            (<Select.Option value={cli.pessoaId} key={cli.id}>
                                <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'Razão Social:' : 'Nome:'}</span> {cli.pessoa?.nome} <br />
                                <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'Nome Fantasia:' : 'Apelido:'} </span> {cli.pessoa?.pessoaJuridica ? cli.pessoa?.pessoaJuridica?.nomeFantasia : cli.pessoa?.pessoaFisica?.apelido}  <br />
                                <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'CNPJ:' : 'CPF:'} </span> {cli.pessoa?.pessoaJuridica ? cli.pessoa?.pessoaJuridica?.cnpj : cli.pessoa?.pessoaFisica?.cpf} <span style={{ fontWeight: "bold" }}> {!cli.ativo ? '(Inativo)' : ''}</span> <br />
                            </Select.Option>)
                        } />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item label="Contato" name="pessoaContatoId" hidden={!temContato}>
                        <Select disabled={desabilitar} allowClear placeholder="Selecione o Contato" showSearch optionFilterProp="children">
                            {listaContato.map((contato) => (
                                <Select.Option value={contato.contatoId} key={contato.contatoId}>{contato.contato?.nome}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Observação" name="observacao" initialValue={""}>
                        <Input.TextArea style={{
                            height: 80,
                        }} placeholder="Observação" disabled={desabilitar} />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item valuePropName="checked" name="possuiCobranca" initialValue={false} >
                        <Checkbox disabled={cobrancaRejeitada} onChange={() => { itemCobranca() }}> Possui Cobrança </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <div className="m-t-8">
                <ItensTarefas contatosEmail={contatosEmail} listaItens={{ listaItens, setListaItens }} listaAtividade={{ listaAtividade, setListaAtividade }}
                    formOS={form} listaTermoAceite={{ listaTermoAceite, setListaTermoAceite }} listaCobranca={{ listaCobranca, setListaCobranca }}
                    filtroParticipantes={{ filtroParticipantes, setFiltroParticipantes }} pessoaId={{ pessoaId, setPessoaId }} exibirTodos={{ exibirTodos, setExibirTodos }}
                    assinado={assinado} gedInfo={gedInfo} numeroOS={numeroOS} />
            </div>
            {exibeItensCobranca &&
                <div className="m-t-16">
                    <ItensCobranca listaCobranca={{ listaCobranca, setListaCobranca }} formOS={form} pessoaId={{ pessoaId, setPessoaId }} />
                </div>
            }
            {exibeInfo &&
                <div className="m-t-16">
                    <Alert message={<span style={{ fontSize: '12px', fontWeight: 'bold' }}>{listaObsPessoa.observacaoTipo?.descricao}</span>}
                        description={<span style={{ fontSize: '12x' }}>{listaObsPessoa.descritivo}</span>} type="info" showIcon />
                </div>
            }

        </Form>


    );
}  