import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Drawer, Modal } from "antd";

import { CloseOutlined, FileDoneOutlined, ExclamationCircleOutlined, SendOutlined } from "@ant-design/icons";
import ManutencaoEnviarOS from './detalhe';
import { drawerActions, manutencaoActions } from '../../actions';
import { useStateValue } from '../../state/stateProvider';

export default function DrawerEnviarOS({listaEmail, listaOrdens}) {

    const [formularioEnviarOS] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    

    function fecharDrawer() {
        dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerOS: false } });
        formularioEnviarOS.resetFields();
    }
    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };
   

    return (
        <Drawer
            width="40%"
            title="Enviar Ordem de Serviço"
            open={ui.showDrawerOS}
            onClose={() => { fecharDrawer() }}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={onClose} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<SendOutlined />} size="large" type="primary" htmlType="submit" onClick={() => formularioEnviarOS.submit()}>
                            Enviar 
                        </Button>
                    </Col>
                </Row>
            }>
            <ManutencaoEnviarOS form={formularioEnviarOS} listaEmail={listaEmail} listaOrdens={listaOrdens} aoSalvar={fecharDrawer}/>
        </Drawer>
    );
}