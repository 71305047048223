import React, { useState } from "react";
import { Row, Col, Form, Button, Tag, Table, Collapse, Select, Checkbox } from "antd";
import { ArrowUpOutlined, CloseOutlined, EyeOutlined, SearchOutlined, ArrowDownOutlined } from "@ant-design/icons";

import DrawerDetalheCliente from "./drawer";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import { FormatFone, MaskFormat } from "../../valueObjects";
import { BreadcrumbPage, Search, SelectPaginado, TabelaDados } from "../../components";

export default function Cliente() {

    const [formUsuario] = Form.useForm();
    const [formPesquisaAvancada] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [open, setOpen] = useState(false);

    function aplicarFiltrosAvancados() {
        let filtros = '';
        filtros += (!!formPesquisaAvancada.getFieldValue().filtro) ? `&Filtro=${formPesquisaAvancada.getFieldValue().filtro}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().pessoaContatoId) ? `&PessoaContatoId=${formPesquisaAvancada.getFieldValue().pessoaContatoId}` : '';
        filtros += (!!formPesquisaAvancada.getFieldValue().contratoAtivo) ? `&ContratoAtivo=${formPesquisaAvancada.getFieldValue().contratoAtivo}` : '';
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtros, ordem: "", pagina: 1 } });
    };

    function removerFiltros() {
        formPesquisaAvancada.setFieldsValue({
            filtro: '', pessoaContatoId: '', contratoAtivo: false
        });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '' } });
    }
    return (
        <div className="col-paginas m-t-2">
            <BreadcrumbPage pagina="Clientes" />
            <Form layout="vertical" form={formUsuario} name="usuario" className="m-t-2">
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={11} lg={12} xl={13} xxl={14}>
                        <Search/>
                    </Col>
                </Row>
                <Form name="formularioPesqAvancadaClientes" form={formPesquisaAvancada} onFinish={aplicarFiltrosAvancados}>
                    <Row>
                        <Col span={24}>
                            <Collapse className="collapse-pages" accordion ghost destroyInactivePanel={true}>
                                <div className="colDet"></div>
                                <Collapse.Panel key="1" showArrow={false} extra={
                                    <Button type="primary" onClick={() => { }}>
                                        {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Mais Filtros
                                    </Button>
                                }>
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                            <Form.Item label="Contato Pessoa" name="pessoaContatoId">
                                                <SelectPaginado url="Pessoa/ListarPessoaContato" placeholder="Selecione um contato" form={formPesquisaAvancada} name="pessoaContatoId" conteudo={
                                                    pes => (<Select.Option value={pes.id} key={pes.key}>{pes.nome}</Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                                            <Form.Item valuePropName="checked" name="contratoAtivo" className="t-mob-573">
                                                <Checkbox> Contratos Ativos </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col className="col-w">
                                            <Row align="middle" justify="end" gutter={[8, 0]} className="btn-col-21">
                                                <Col>
                                                    <Button icon={<CloseOutlined />} onClick={removerFiltros} block>
                                                        Limpar Filtros
                                                    </Button>
                                                </Col>
                                                <Col>
                                                    <Button type="primary" icon={<SearchOutlined />} onClick={aplicarFiltrosAvancados} block>
                                                        Aplicar Filtros
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Form>
            </Form>
            <div className="tabela-pages">
                <TabelaDados url={"Cliente"}
                    colunas={[
                        {
                            title: 'Empresa',
                            render: ({ pessoa }) => (
                                <div>
                                    <b>{pessoa?.empresa?.razaoSocial} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Cód. Gestor',
                            width: 85,
                            render: ({ codigo }) => (
                                <div>
                                    <Tag color="processing" className="tag-w-100">
                                        <b> {codigo || '-'} </b>
                                    </Tag>
                                </div>
                            ),
                        },
                        {
                            title: 'CPF/CNPJ',
                            render: ({ pessoa }) => (
                                <div>
                                    <b>{!!pessoa?.pessoaFisica?.cpf ? MaskFormat(pessoa?.pessoaFisica?.cpf, 'Não Informado', true) : MaskFormat(pessoa?.pessoaJuridica?.cnpj, 'Não Informado', true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Nome',
                            render: ({ pessoa }) => (
                                <div>
                                    <b> {pessoa?.pessoaJuridica?.nomeFantasia || pessoa?.pessoaFisica?.apelido} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Razão Social',
                            render: ({ pessoa }) => (
                                <div>
                                    <b> {pessoa?.nome || 'Não Informado'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Atividade',
                            width: 100,
                            render: ({ atividade }) => (
                                <div>
                                    <b> {atividade?.descricao || 'Não Informado'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Categoria',
                            render: ({ categoria }) => (
                                <div>
                                    <b> {categoria?.descricao || 'Não Informado'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Telefone',
                            width: 115,
                            render: ({ pessoa }) => (
                                <div>
                                    <b>{pessoa?.pessoaTelefone?.length > 0 ? FormatFone(pessoa?.pessoaTelefone?.find(p => p.principal == true)?.numero) : 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Cidade',
                            render: ({ pessoa }) => (
                                <div>
                                    <b>{pessoa?.pessoaEndereco?.length > 0 ? pessoa?.pessoaEndereco?.find(p => p.enderecoPrincipal == true)?.logradouroCidade?.cidade?.descricao : 'Não Informado'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EyeOutlined />} onClick={() => {
                                                let dados = { ...record };
                                                dados.nomeFantasia = dados.pessoa?.pessoaJuridica?.nomeFantasia || dados?.pessoaFisica?.apelido;
                                                dados.cpfCnpj = dados.pessoa?.pessoaFisica?.cpf || dados.pessoa?.pessoaJuridica?.cnpj;
                                                dados.tipoPessoa = dados.pessoa?.fisicaJuridica == 2 ? "Juridica" : "Fisica";
                                                dados.email = dados.pessoa?.pessoaEmail?.length > 0 ? dados.pessoa?.pessoaEmail?.find(p => p.principal == true)?.email : "";
                                                dados.endereco = dados.pessoa?.pessoaEndereco?.length > 0 ? dados.pessoa?.pessoaEndereco?.find(p => p.enderecoPrincipal == true) : [];
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados, ordem: '' } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
            </div>
            <DrawerDetalheCliente />
        </div>
    );
}