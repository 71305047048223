import React, { useEffect, useState } from "react";
import { Row, Col, Form, DatePicker, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Map from "../mapa";
import api from "../../services/api";
import moment from "moment";

export default function Sugestoes({ form, formAgenda, exibirConteudo, listaRecursos, aoSalvar, coordenadas}) {

    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        form.setFieldsValue({ dataInicial: moment(formAgenda.getFieldValue().dataInicial) });
        form.setFieldsValue({ dataFinal: moment(formAgenda.getFieldValue().dataFinal) });
        buscarMarkers();
    }, [exibirConteudo]);

    function buscarMarkers() {
        if (moment(form.getFieldValue('dataInicial'), "DD/MM/YYYY", true).isValid() && moment(form.getFieldValue('dataFinal'), "DD/MM/YYYY", true).isValid()) {
            api.get(`Localizacao/BuscarSugestoes?dataInicial=${moment(form.getFieldValue().dataInicial).format('YYYY-MM-DD 00:00:00')}&dataFinal=${moment(form.getFieldValue().dataFinal).format('YYYY-MM-DD 23:59:59')}`).then(
                res => {
                    if (!!res.data) {
                        setMarkers(res.data);
                    }
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            );
        }

    }

    return (
        <Row justify="center" gutter={[8, 8]}>
            <Col span={24}>
                Verifique as locações deste tipo de recurso para esta data.
                Caso haja alguma agenda que pode se encaixar no seu roteiro clique no marcador e clique em participar.
                Você também pode filtrar as datas da busca.
            </Col>
            <Col span={12}>
                <Form.Item label="Data Inicial" name="dataInicial" rules={[{ required: true }]}>
                    <DatePicker placeholder="DD/MM/YYYY" format={"DD/MM/YYYY"} onChange={buscarMarkers} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Data Final" name="dataFinal" rules={[{ required: true }]}>
                    <DatePicker placeholder="DD/MM/YYYY" format={"DD/MM/YYYY"} onChange={buscarMarkers} />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Map markers={markers} listaRecursos={listaRecursos} aoSalvar={aoSalvar} coordenadas={coordenadas}/>
            </Col>
        </Row>
    );
}