import React, { useEffect } from "react";
import { Row, Col, Descriptions, Alert } from "antd";
import { useStateValue } from "../../state/stateProvider";
import moment from "moment";
import numeral from 'numeral';
import api from "../../services/api";
import { useState } from "react";


export default function DetalheOS({openDrawerDetalhe}) {

    const [{ manutencao }] = useStateValue();
    const [listaObsPessoa, setListaObsPessoa] = useState([]);
    const [exibeInfo, setExibeInfo] = useState(false);


    useEffect(() => {
        debugger;
        buscarObsPessoa(manutencao.dados?.pessoaId);

    }, [openDrawerDetalhe]);

    function buscarObsPessoa(pessoaId) {
        api.get(`ObservacaoPessoa/BuscarPorPessoa?idPessoa=${pessoaId}`).then(
            res => {
                if (!!res.data) {
                    setListaObsPessoa(res.data);
                    setExibeInfo(true);
                } else {
                    setListaObsPessoa([]);
                    setExibeInfo(false);
                }
            } 
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    }


    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Descriptions column={1} size="small" title={
                        <Row gutter={[8, 0]} className="tituloDetalhe">
                            <Col>ORDEM SERVICO - {manutencao.dados?.numero} </Col>
                        </Row>
                    }>
                    </Descriptions>
                </Col>
            </Row>
            <Row>
                <div className="divDetalhes" >
                    <Col span={24}>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Empresa">
                                <b>{manutencao.dados?.empresa?.razaoSocial} </b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Cliente">
                                <Row>
                                    <Col span={24}>
                                        <b>{manutencao.dados?.pessoa?.nome}</b>
                                    </Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Usuário">
                                <Row>
                                    <Col span={24}>
                                        <b>{manutencao.dados?.usuario?.nome}</b>
                                    </Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>

                    <Col span={24}>
                        <Descriptions size="small" >
                            <Descriptions.Item label="Status">
                                {manutencao.dados?.status === 1 && <b className="c-blue">OS em Aberto</b>}
                                {manutencao.dados?.status === 2 && <b className="c-yellow">OS à Faturar</b>}
                                {manutencao.dados?.status === 3 && <b className="c-red">OS Cancelada</b>}
                                {manutencao.dados?.status === 4 && <b className="c-orange">OS Faturada</b>}
                                {manutencao.dados?.status === 5 && <b className="c-green">OS Fechada</b>}
                                {manutencao.dados?.status === 6 && <b className="c-red">OS Pendente</b>}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    {!!manutencao.dados?.observacao ? <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Observação">
                                <Row>
                                    <Col span={24}>
                                        <b>{manutencao.dados?.observacao}</b>
                                    </Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col> : <Col></Col>}

                </div>
            </Row>
            {
                manutencao?.dados?.ordensTarefas?.length > 0 ?
                    <Row align="middle" className="m-t-16" >
                        <Col >
                            <Descriptions size="small" title={
                                <Row gutter={[8, 0]} className="tituloDetalhe" >
                                    <Col>SERVIÇOS: </Col>
                                </Row>
                            }>
                            </Descriptions>
                        </Col>
                        <div className="divDetalhes" >
                            {manutencao?.dados?.ordensTarefas?.map(o => {
                                return <Row align="middle">
                                    <Col>
                                        <Descriptions column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }} size="small">
                                            <Descriptions.Item label="Tipo Tarefa">
                                                <b>{o.tarefa?.tarefaTipo?.descricao}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Serviço">
                                                <b>{o.produto?.descricao}</b>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            }
                            )}
                        </div>
                    </Row>
                    : <Row></Row>
            }

            {
                manutencao?.dados?.itensCobranca?.length > 0 ?
                    <Row align="middle" className="m-t-16" >
                        <Col >
                            <Descriptions size="small" title={
                                <Row gutter={[8, 0]} className="tituloDetalhe" >
                                    <Col>ITENS DE COBRANÇA: </Col>
                                </Row>
                            }>
                            </Descriptions>
                        </Col>
                        <div className="divDetalhes" >
                            {manutencao?.dados?.itensCobranca?.map(o => {
                                return <Row >
                                    <Col style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Descriptions column={{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 2, xs: 1 }} >
                                            <Descriptions.Item label="Serviço">
                                                <b>{o.produto?.descricao}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Qtde">
                                                <b>{o.quantidade}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Valor Unitário">
                                                <b>{numeral(o?.valorUnitario).format('0,0.00')}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Valor Total">
                                                <b>{numeral(o?.valorTotal).format('0,0.00')}</b>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            }
                            )}
                        </div>
                    </Row>
                    : <Row></Row>
            }
            {exibeInfo &&
                <div className="m-t-16">
                    <Alert message={<span style={{ fontSize: '12px', fontWeight: 'bold' }}>{listaObsPessoa?.observacaoTipo?.descricao}</span>}
                        description={<span style={{ fontSize: '12x' }}>{listaObsPessoa?.descritivo}</span>} type="info" showIcon />
                </div>
            }

        </div>
    );
}