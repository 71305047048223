import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Modal, DatePicker, Select, TimePicker, Slider, notification } from "antd";
import { ExclamationCircleOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import moment from 'moment';
import api from "../../../services/api";
import { useStateValue } from "../../../state/stateProvider";
import SelectPaginado from "../../selectPaginado";
import { selectPaginadoActions } from "../../../actions";
import Data from "../../data";
import { isNotNullOrEmpty } from "../../../services/funcoes";

export default function ModalAtividadeTarefa({ formAnt, exibirModalAtividadeTarefa, fecharModalAtividadeTarefa, listaAtividade, statusAtual, porcentagemAtual }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const format = 'HH:mm';
    const valueMaximo = 100;
    const [valueTarefaSlider, setValueTarefaSlider] = useState(0);
    const [valorPorcentagem, setValorPorcentagem] = useState(0);
    const [listaStatus, setListaStatus] = useState([]);
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [form] = Form.useForm();
    const dataAtual = new Date();

    const handleChange = (value) => {
        if (value === 100) {
            form.setFieldsValue({ status: 4 });
            setTimeout(() => { alterarStatus(4) }, 300);
        } else if (value === 0) {
            form.setFieldsValue({ status: 1 });
        } else {
            form.setFieldsValue({ status: 2 });
        }
        form.setFieldValue({ porcentagemAndamento: value });
        porcentagemAtual.setPorcentagemAtual(value);
        setValueTarefaSlider(value);
    };

    const statusChange = (status) => {
        let descricao = (status == 1 ? "Tarefa Pendente" : (status == 2 ? "Tarefa em execução" : (status == 3 ? "Tarefa Cancelada" : "Tarefa Concluída")));
        let value;
        if (status === 4) {
            value = 100;
        } else if (status === 1 || status === 3) {
            value = 0;
        } else {
            value = 50;
        }
        form.setFieldValue({ porcentagemAndamento: value });
        porcentagemAtual.setPorcentagemAtual(value);
        setValueTarefaSlider(value);

    }

    const onCloseModalAtividadeTarefa = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                fecharModalAtividadeTarefa();
                form.resetFields();
            }

        });
    };

    useEffect(() => {
        console.log(formAnt);
        carregarDados();
        form.setFieldsValue({ dataInicial: moment(dataAtual) });
    }, []);

    useEffect(() => {
        if (exibirModalAtividadeTarefa && !!manutencao.atividade) {
            manutencao.atividade.usuarioId = manutencao.atividade.idResponsavel;
            form.setFieldsValue(manutencao.atividade);
            porcentagemAtual.setPorcentagemAtual(manutencao.atividade.porcentagemAndamento);
            let lista = [];
            if (!!manutencao.atividade.usuarioId) {
                lista.push({ name: "usuarioId", campo: "FiltroIdUsuario", value: manutencao.atividade.usuarioId });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: lista });
        } else if (exibirModalAtividadeTarefa && !!manutencao.dados) {
            manutencao.dados.tarefaTipoId = formAnt?.getFieldsValue().tarefaTipoId;
            manutencao.dados.produtoId = formAnt?.getFieldsValue().produtoId;
            porcentagemAtual.setPorcentagemAtual(manutencao.dados.porcentagemAndamento);
            form.setFieldsValue({ usuarioId:  manutencao.dados.idResponsavel});

            let lista = [];
            if (!!manutencao.dados.usuarioId) {
                lista.push({ name: "usuarioId", campo: "FiltroIdUsuario", value: manutencao.dados.usuarioId });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: lista });
        } 

    }, [exibirModalAtividadeTarefa]);

    function carregarDados() {
        buscarStatus();
        buscarResponsaveis();
    };

    function buscarResponsaveis() {
        api.get("Usuarios?Ativo=true").then(
            res => {
                if (!!res.data?.items) {
                    setListaUsuarios(res.data.items);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function buscarStatus() {
        api.get("/Enumeravel/StatusTarefa").then(
            res => {
                if (!!res.data) {
                    setListaStatus(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function adicionarAtividade() {
        let enviar = validarCampos();
        if (enviar) {
            let dados = { ...form.getFieldValue(), ...form.getFieldsValue() };
            dados.tarefaTipoId = manutencao.dados.tarefaTipoId;
            dados.porcentagemAndamento = porcentagemAtual.porcentagemAtual;
            if (!!dados.usuarioId) {
                dados.usuario = listaUsuarios.find((usu) => usu.usu_is === dados.usuarioId);
            }
            dados.key = (!!dados.key ? dados.key : (!!manutencao?.dados?.key ? manutencao.dados.key : listaAtividade.length));
            if (manutencao.dados?.tarefa) {
                manutencao.dados.tarefa = manutencao.atividade;
            }
            form.resetFields();
            porcentagemAtual.setPorcentagemAtual(dados.porcentagemAndamento);
            statusAtual.setStatusAtual(dados.status);
            fecharModalAtividadeTarefa(dados);
        }
    };

    function alterarStatus(idStatus) {
        if (idStatus === 4 && !!!form.getFieldValue().dataFim) {
            notification.warning({ description: "Favor informar uma data final para definir o status como tarefa concluída!", message: "Aviso" });
            form.setFieldsValue({ status: 2 });
        } else {
            statusChange(idStatus);
        }
    };

    function validarCampos() {
        let enviar = true;
        if (!!!form.getFieldsValue().usuarioId) {
            notification.warning({ description: "Informe a Pessoa que executou a Tarefa", message: "Aviso" });
            enviar = false;
        }

        if (!!!form.getFieldsValue().descricao) {
            notification.warning({ description: "Informe uma Descrição", message: "Aviso" });
            enviar = false;
        }

        if (!!!form.getFieldsValue().dataInicio) {
            notification.warning({ description: "Informe a Data Inicial", message: "Aviso" });
            enviar = false;
        }

        if (!!!form.getFieldsValue().dataFim) {
            notification.warning({ description: "Informe a Data Final", message: "Aviso" });
            enviar = false;
        }

        if (!!!form.getFieldsValue().status) {
            notification.warning({ description: "Informe o Status", message: "Aviso" });
            enviar = false;
        }

        return enviar;
    }

    return (
        <Modal centered
            title="Atividade na Tarefa"
            open={exibirModalAtividadeTarefa}
            onCancel={onCloseModalAtividadeTarefa}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            onOk={() => adicionarAtividade()}
            maskClosable={false}
            destroyOnClose
            width={700}
        >
            <Form layout="vertical" name="manutencaoAtividadeTarefa" form={form}>
                <Form.Item hidden name="key"></Form.Item>
                <Form.Item hidden name="idTarefa"></Form.Item>
                <Form.Item hidden name="idOrdemTarefa"></Form.Item>
                <Form.Item hidden name="tarefaTipoId"></Form.Item>
                <Form.Item hidden name="idResponsavel"></Form.Item>
                <Row gutter={[0, 8]}>
                    <Col span={24}>
                        Preencha as informações abaixo para realizar seu cadastro.
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Pessoa que executou a tarefa" name="usuarioId" rules={[{ required: true, message: "Informe a Pessoa que executou a Tarefa" }]}>
                            <SelectPaginado url="Usuarios?Ativo=true&Sac=true" name="usuarioId" form={form} laceholder="Selecione a Pessoa que executou a Tarefa" conteudo={
                                user => (<Select.Option value={user.id} key={user.id}>{(user.nome).normalize('NFD').replace(/[\u0300-\u036f]/g, "")}</Select.Option>)
                            } />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <Form.Item label="Descrição" name="descricao" rules={[{ message: 'Informe uma Descrição', required: true }]}>
                            <Input.TextArea placeholder="Informe uma Descrição" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Data rules={[{ required: true }]} label="Data Inicial" name="dataInicio" format="DD/MM/YYYY HH:mm" />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Data rules={[{ required: true }]} label="Data Final" name="dataFim" format="DD/MM/YYYY HH:mm" />
                    </Col>
                    {/*<Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item label="Intervalo" name="tempoIntervalo">
                            <TimePicker defaultValue={moment('00:00', format)} format={format} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item label="Deslocamento" name="tempoDeslocamento">
                            <TimePicker defaultValue={moment('00:00', format)} format={format} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item label="Ocioso" name="tempoOcioso">
                            <TimePicker defaultValue={moment('00:00', format)} format={format} />
                        </Form.Item>
                        </Col>*/}
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="Status" name="status" rules={[{ message: 'Informe um status', required: true }]}>
                            <Select allowClear placeholder="Selecione o Status" onChange={(record) => { alterarStatus(record) }} >
                                {listaStatus.map(sta => <Select.Option value={sta.valor} key={sta.valor}>{sta.descricao}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item name="PorcentagemAndamento">
                            <label>
                                Porcentagem concluída <span>{porcentagemAtual.porcentagemAtual}%</span>
                            </label>
                            <Slider tipFormatter={null} min={0} max={valueMaximo} onChange={handleChange} value={porcentagemAtual.porcentagemAtual} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}