import React, { useState } from "react";
import { Row, Col, Form, Button, Drawer } from "antd";
import { SaveFilled, CloseOutlined } from "@ant-design/icons";

import { Local } from "../../components";

import "./index.less";

export default function DrawerLocal({exibirLocal, fecharLocal}) {

    const [formularioLocal] = Form.useForm();
    const [ carregando, setCarregando ] = useState(false);

    return (
        <Drawer 
            width="50%"
            title="Onde Estou?"
            open={exibirLocal}
            onClose={fecharLocal}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={fecharLocal} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<SaveFilled />} size="large" type="primary" htmlType="submit" onClick={() => formularioLocal.submit()} loading={carregando}>
                            Salvar
                        </Button>
                    </Col>
                </Row>
            }>
            <Local formulario={formularioLocal} carregando={setCarregando}/>
        </Drawer>
    );
}