import React from "react";
import { Row, Col, Form, Select, Input, notification, Checkbox } from "antd";
import api from '../../services/api';
import { useStateValue } from "../../state/stateProvider";
import { listagemActions } from "../../actions";
import { parseToken } from "../../services/funcoes";
import { MultiSelectPaginacao, SelectPaginacao } from "../../components";

export default function ManutencaoSolucoesModulo({ form, carregando, aoSalvar }) {

    const [, dispatch] = useStateValue();

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+Descricao' } });
    };

    const salvarRegistro = async (values) => {
        let token = parseToken();
        if (!values.id) values.id = null;
        if (!values.sistemaId) values.sistemaId = null;
        values.EmpresaId = token.Empresa;
        carregando(true);
        let retorno = values.id ? await api.put('/SolucaoModulo', values) : await api.post('/SolucaoModulo', values);
        if (retorno.status === 200) {
            notification.success({ message: 'Operação concluída', description: "Registro salvo com sucesso!" });
            retorno = retorno.data.id;
        }
        else notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(retorno.data) });
        atualizarPagina();
        carregando(false);
        aoSalvar(retorno);
    }

    return (
        <Form layout="vertical" name="formManutencaoSolucoesModulo" form={form} onFinish={salvarRegistro}>
            <Row gutter={[8, 0]}>
                <Col xs={24} md={8} lg={8} xl={8}>
                    <Form.Item name="id" hidden />
                    <Form.Item label="Sistema" name="sistemaId" rules={[{ required: true, message: 'Informe um Sistema' }]}>
                        <SelectPaginacao
                            allowClear
                            placeholder="Selecione um Sistema"
                            nameLabel="descricao"
                            nameValue="id"
                            idValue="sistemaId"
                            url="Sistema?Ativo=true"
                            form={form}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={16} lg={16} xl={16}>
                    <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Informe a descrição' }]}>
                        <Input placeholder="Informe a Descrição" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="ativo" initialValue={true} valuePropName="checked" hidden={(!(form.getFieldValue(['id']) > 0))}>
                        <Checkbox>
                            Ativo?
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}