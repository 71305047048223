import React, { useState } from "react";
import { Row, Col, Form, Input, Checkbox, notification } from "antd";

import { useStateValue } from '../../state/stateProvider';
import api from '../../services/api';
import { listagemActions } from "../../actions";
import { MultiSelectPaginacao } from "../../components";

export default function ManutencaoEquipe({ form, carregando, aoSalvar }) {

    const [, dispatch] = useStateValue();

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+descricao' } });
    };

    const salvarRegistro = async (values) => {
        if (!values.id) values.id = null;
        values.areasEquipe = values.areasEquipe?.map(area => {
            return { areaId: area }
        });
        carregando(true);
        let retorno = values.id ? await api.put('/Equipe', values) : await api.post('/Equipe', values);
        if (retorno.status === 200) {
            notification.success({ message: 'Operação concluída', description: "Registro salvo com sucesso!" });
            retorno = retorno.data.id;
        }
        else notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(retorno.data) });
        atualizarPagina();
        carregando(false);
        aoSalvar(retorno);
    }

    return (
        <Form layout="vertical" name="formManutencaoEquipe" form={form} onFinish={salvarRegistro}>
            <Form.Item name="id" hidden />
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={12} lg={14} xl={14}>
                    <Form.Item label="Descrição"
                        name="descricao" rules={[
                            {
                                required: true,
                                message: 'Informe o nome da equipe.',
                            },
                        ]}>
                        <Input placeholder="Informe o Nome" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Áreas" name="areasEquipe">
                        <MultiSelectPaginacao
                            nomeCampoForm="areasEquipe"
                            descricaoProp="descricao"
                            idProp="id"
                            parametroFiltro="descricao"
                            url='Area?Ativa=true'
                            form={form}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="ativa" valuePropName="checked"
                        hidden={(!(form.getFieldValue(['id']) > 0))} className="t-mob-573">
                        <Checkbox>Equipe Ativa?</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}