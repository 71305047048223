import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Input, Table, notification } from "antd";
import { listagemActions, manutencaoActions, selectPaginadoActions } from "../../actions";
import api from "../../services/api";
import { getLocal } from "../../services/auth";
import SelectPaginado from "../selectPaginado";
import { useStateValue } from "../../state/stateProvider";
import { AssinarRelatorio } from "../../services/funcoes";

export default function ManutencaoAssinarOS({ form, listaEmail, carregando, pessoaEmail, dadosRecord, aoSalvar }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [usuarios, setUsuarios] = useState([]);
    const [pessoas, setPessoas] = useState([]);

    async function enviarEmail(values) {
        let dados = { ...form.getFieldValue() };
        let clienteEmail = pessoaEmail.find(x => x.id === dados.cliente);
        let signatures = [];
        let emails = dados?.emails?.split(";");
        let nomes = dados?.nomes?.split(";");
        let emailSalvar = !!dados?.emails ? dados?.emails : clienteEmail?.email;
        let nomeSalvar = !!dados?.nomes ? dados?.nomes : clienteEmail?.nome;

        debugger;
        if (!emails && !nomes && !clienteEmail) {
            notification.warn({ message: "Aviso", description: "Favor selecionar um contato ou informar os nomes e respectivos e-mails para o envio da assinatura." });
            carregando(false);
            return;
        } else if (emails?.length !== nomes?.length) {
            notification.warn({ message: "Aviso", description: "Informar mesma quantidade de nomes e e-mails" });
            carregando(false);
            return;
        }

        if (!!clienteEmail) {
            const obj = {
                "Email": clienteEmail.email,
                "PartName": clienteEmail.nome
            }
            signatures.push(obj);
        } else {
            for (var i = 0; i < emails.length; i++) {
                const obj = {
                    "Email": emails[i],
                    "PartName": nomes[i]
                }
                signatures.push(obj);
            }
        }

    /*    if (listaEmail.length > 0) {
            const obj = {
                "Email": listaEmail[0]?.email,
                "PartName": listaEmail[0]?.nome
            }
            signatures.push(obj);
        }*/


        let req = await api.get(`Agenda/ConsultarOS?OrdemServicoId=${dadosRecord?.idOrdemServico}`);
        if (req.data === true) {
            try {
                await AssinarRelatorio(53, [{ "nome": "ordemservico", "valor": dadosRecord.numero }, { "nome": "IdLocal", "valor": getLocal() }], "PDF", "OrdemServico_" + dadosRecord.numero, signatures, dadosRecord.idOrdemServico, 1, null, nomeSalvar, emailSalvar);
                notification.success({ message: 'Enviado para assinar.', description: "Será encaminhado um e-mail para realizar a assinatura." });
            } catch (e) {
                //  carregando(false);
                console.error(e);
            } finally {
                //  carregando(false);
                aoSalvar();
            }

        } else {
            try {
                await AssinarRelatorio(42, [{ "nome": "ordemservico", "valor": dadosRecord.numero }, { "nome": "IdLocal", "valor": getLocal() }], "PDF", "OrdemServico_" + dadosRecord.numero, signatures, dadosRecord.idOrdemServico, 1, null, nomeSalvar, emailSalvar);
                notification.success({ message: 'Enviado para assinar.', description: "Será encaminhado um e-mail para realizar a assinatura." });
            } catch (e) {
                // carregando(false);
                console.error(e);
            } finally {
                //  carregando(false);
                aoSalvar();
            }
        }

    };

    useEffect(() => {
        debugger;
        setUsuarios(listaEmail);
    }, []);


    return (
        <Form layout="vertical" name="manutencaoEnviarOS" form={form} onFinish={enviarEmail}>
            <Row gutter={[8, 0]}>
                <Col span={24}>
                    <Form.Item label="Contato:" name="cliente"  >
                        <Select form={form} name="cliente" allowClear placeholder="Selecione um e-mail" showSearch optionFilterProp="children" >
                            {pessoaEmail.map((email) => (
                                <Select.Option value={email.id} key={email.id}>
                                    {email.nome + " - " + email.email}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label="Nome (separar por ; )" name="nomes">
                        <Input placeholder="Informe o Nome" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label="E-mail (separar por ; )" name="emails">
                        <Input placeholder="Informe o E-mail" />
                    </Form.Item>
                </Col>
            </Row>
        </Form>

    );
}