import React, { useState } from "react";
import { Row, Col, Form, Button, Drawer, Modal } from "antd";
import { SaveOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state/stateProvider';
import { drawerActions, manutencaoActions } from '../../actions';
import ManutencaoUsuario from "../usuario/manutencao";

export default function AutorizarUsuario({openDrawerAutorizarUsuario, closeDrawerAutorizarUsuario}) {

    const [formularioUsuario] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();
    

    if (manutencao.dados !== null) {
        formularioUsuario.setFieldsValue(manutencao.dados);
    }
    else {
        formularioUsuario.setFieldsValue({ id: null });
    }

    function fecharDrawer() {
        window.location.href = (window.location.href.substring(0, window.location.href.indexOf('?')));
        closeDrawerAutorizarUsuario();
    };

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer 
            width="65%"
            title="Manutenção Usuário"
            open={openDrawerAutorizarUsuario}
            onClose={() => { fecharDrawer() }}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={onClose} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit" onClick={() => formularioUsuario.submit()} loading={carregando}>
                            Salvar
                        </Button>
                    </Col>
                </Row>
            }>
            <ManutencaoUsuario form={formularioUsuario} carregando={setCarregando} aoSalvar={fecharDrawer}/>
        </Drawer>
    );
}