import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Input, InputNumber, DatePicker, Checkbox, Button, Table, Divider, Avatar, Tooltip, Modal, notification } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from 'moment';

import { useStateValue } from '../../state/stateProvider';
import { ModalAtividadeTarefa } from "../modals/"
import api from "../../services/api";
import { manutencaoActions, selectPaginadoActions } from "../../actions";
import { isNotNullOrEmpty } from "../../services/funcoes";
import SelectPaginado from "../selectPaginado";

export default function ManutencaoTarefa({ form, listaItens, aoFechar, listaAtividade, statusAtual, porcentagemAtual, filtroParticipantes, pessoaId, exibirTodos, listaTermoAceite }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [openModalAtividadeTarefa, setOpenModalAtividadeTarefa] = useState(false);
    const [listaTipo, setListaTipo] = useState([]);
    const [listaStatus, setListaStatus] = useState([]);
    const [atualizar, setAtualizar] = useState(false);


    useEffect(() => {
        setAtualizar(false);
        exibirTodos.setExibirTodos(true);
        carregarDados();
        let lista = [];
        if (!!manutencao.dados?.responsavel?.id) {
            lista.push({ name: "idResponsavel", campo: "FiltroIdUsuario", value: manutencao.dados.responsavel.id });
        }
        if (!!manutencao.dados?.produtoId) {
            lista.push({ name: "produtoId", campo: "Id", value: manutencao.dados.produtoId });
        }

        if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: lista });
    }, [ui.showDrawerItens]);


    useEffect(() => {
        if (!!!manutencao.dados) {
            let dados = listaStatus.find(s => s.valor == 1);
            form.setFieldsValue({ observacao: dados?.titulo });
        }
    }, [listaStatus]);

    function carregarDados() {
        definirDataAtual();
        popularTipo();
        popularListaStatus();
    };

    function definirDataAtual() {
        if (!manutencao.dados?.data) {
            form.setFieldsValue({ "data": moment(new Date()) });
        }
    };

    function popularTipo() {
        api.get("TarefaTipo").then(
            res => {
                if (!!res.data?.items) {
                    setListaTipo(res.data.items);
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    };

    function popularListaStatus() {
        api.get("/Enumeravel/StatusTarefa").then(
            res => {
                if (!!res.data) {
                    setListaStatus(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function onCloseModalAtividade(dados) {
        if (!!dados) {
            if (isNotNullOrEmpty(dados.key) || !!dados.idTarefa) {
                alterarAtividade(dados);
            } else {
                popularListaAtividade(dados);
            }
        }
        setOpenModalAtividadeTarefa(false);
    };

    function popularListaAtividade(dados) {
        let lista = [...listaAtividade.listaAtividade];
        if (!dados.key) { dados.key = lista.length; }
        lista.push(dados);
        listaAtividade.setListaAtividade(lista);
    };

    function alterarAtividade(record) {
        let lista = [...listaAtividade.listaAtividade];
        let indice = lista.findIndex((a) => (isNotNullOrEmpty(record.idTarefa) && a.idTarefa == record.idTarefa) || (a.key === record.key && !isNotNullOrEmpty(record.idTarefa)));
        let dadosDaLista = lista[indice];
        if (!!dadosDaLista) {
            dadosDaLista = { ...record, dadosDaLista };
            lista[indice] = dadosDaLista;
            listaAtividade.setListaAtividade(lista);
        } else {
            popularListaAtividade(record);
        }
    };



    function excluirAtividade(record) {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir a Atividade ${record.descricao}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                removerAtividade(record);
            }
        });
    };

    function removerAtividade(record) {
        let lista = [...listaAtividade.listaAtividade];
        lista = lista.filter((atividade) => atividade !== record);
        listaAtividade.setListaAtividade(lista);
    };

    function editarAtividade(record) {
        let dados = { ...record };
        dados.idResponsavel = form.getFieldsValue().idResponsavel;
        porcentagemAtual.setPorcentagemAtual(record.porcentagemAndamento);
        setOpenModalAtividadeTarefa(true);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados }, atividade: dados } });
    };

    async function buscarServico(proId) {
        let req = await api.get(`Produto/${proId}`);
        if (req.status === 200 && !!req.data) {
            return req.data;
        }
        return {};
    };

    async function buscarResponsavel(usuId) {
        let req = await api.get(`Usuarios/${usuId}`);
        if (req.status === 200 && !!req.data) {
            return req.data;
        }
        return {};
    };


    async function salvarTarefa(record) {

        record.OrdemServicoId = manutencao.dados?.idOrdemServico;
        record.idOrdemServico = manutencao.dados?.idOrdemServico;
        let dadosAtividade = [...listaAtividade.listaAtividade];
        let ordensTarefas = [];
        let dados = [...listaItens.listaItens];
        let dadosAtualizado = [];
        let dadosRecord = record;

        //Inclui oq esta no record, se for o primeiro registro ou um além do já existente 
        /*let ordemTarefa = {};
        ordemTarefa.ordemServicoId = !!dadosRecord.OrdemServicoId ? dadosRecord.OrdemServicoId : dadosRecord.ordemServicoId;
        ordemTarefa.idOrdemTarefa = dadosRecord.idOrdemTarefa;
        ordemTarefa.produtoId = dadosRecord.produtoId;
        ordemTarefa.tarefaId = dadosRecord.tarefaId;
        ordensTarefas.push(ordemTarefa);
        if (!dadosRecord.key) {
            dadosRecord.key = 1;
        }
        dadosRecord.ordensTarefas = ordensTarefas;*/
        // for (var j = 0; j < ordensTarefas.length; j++) {
        let dadosAtividadeAtualizar = dadosAtividade.filter(a => a.key === dadosRecord.key)
        for (var i = 0; i < dadosAtividadeAtualizar.length; i++) {
            let tar = {};
            if (moment(dadosAtividadeAtualizar[i].dataInicio).isValid()) {
                tar.descricao = dadosAtividadeAtualizar[i].descricao;
            } else {
                tar.descricao = record.descricao;
            }
            tar.ordemServicoId = !!dadosRecord.OrdemServicoId ? dadosRecord.OrdemServicoId : dadosRecord.ordemServicoId;
            tar.idOrdemTarefa = dadosRecord.idOrdemTarefa;
            tar.produtoId = dadosRecord.produtoId;
            tar.tarefaId = dadosRecord.tarefaId;
            tar.idTarefa = dadosAtividadeAtualizar[i].idTarefa;
            tar.idResponsavel = dadosRecord.idResponsavel;
            tar.usuarioId = dadosAtividadeAtualizar[i].usuarioId;
            tar.tarefaTipoId = record.tarefaTipoId;
            tar.data = moment(dadosAtividadeAtualizar[i].data).toISOString();
            tar.status = dadosAtividadeAtualizar[i].status;
            tar.dataInicio = moment(dadosAtividadeAtualizar[i].dataInicio).toISOString();
            tar.dataFim = moment(dadosAtividadeAtualizar[i].dataFim).toISOString();
            tar.dataLimite = null;
            tar.tempoPrevisto = dadosAtividadeAtualizar[i].tempoPrevisto;
            tar.porcentagemAndamento = dadosAtividadeAtualizar[i].porcentagemAndamento;
            dadosRecord.tarefa = tar;
            dadosRecord.termoAceite = dadosAtividadeAtualizar[i].termoAceite ? dadosAtividadeAtualizar[i].termoAceite : [];
        }
        if (dadosAtividadeAtualizar.length === 0) {
            let tar = {};
            tar.ordemServicoId = !!dadosRecord.OrdemServicoId ? dadosRecord.OrdemServicoId : dadosRecord.ordemServicoId;
            tar.idOrdemTarefa = dadosRecord.idOrdemTarefa;
            tar.produtoId = dadosRecord.produtoId;
            tar.tarefaId = dadosRecord.tarefaId;
            tar.idTarefa = dadosRecord.tarefaId;
            tar.idResponsavel = record.idResponsavel;
            tar.data = moment(record.data).toISOString();
            tar.status = statusAtual.statusAtual;
            tar.tarefaTipoId = record.tarefaTipoId;
            tar.descricao = record.descricao;
            tar.dataFim = null;
            tar.dataInicio = null;
            tar.dataLimite = null;
            dadosRecord.tarefa = tar;
            dadosRecord.termoAceite = [];
        }
        //  }
        // dadosRecord.termoAceite = record.termoAceite;
        dadosRecord.tarefaHistoricos = [...listaAtividade.listaAtividade];
        dadosRecord.tarefaTipo = listaTipo.find(t => t.id === record.tarefaTipoId);
        dadosRecord.produto = await buscarServico(record.produtoId);
        dadosRecord.responsavel = await buscarResponsavel(record.idResponsavel);
        dadosRecord.status = statusAtual.statusAtual;
        dadosRecord.porcentagemAndamento = porcentagemAtual.porcentagemAtual;
        dadosAtualizado.push(dadosRecord);

        if (dados.length > 0 && !!record) {

            for (let i = 0; i < dados.length; i++) {
                ordensTarefas = [];
                dadosRecord = {};
                if (dados[i].key != record.key) {
                    dadosRecord.idOrdemServico = dados[i].idOrdemServico;
                    dadosRecord.key = dados[i].key;
                    dadosRecord.ordemServicoId = dados[i].idOrdemServico;
                    dadosRecord.idOrdemTarefa = dados[i].idOrdemTarefa;
                    dadosRecord.produtoId = dados[i].produtoId;
                    dadosRecord.tarefaTipoId = dados[i].tarefaTipoId;
                    dadosRecord.idResponsavel = dados[i].idResponsavel;
                    dadosRecord.descricao = dados[i].descricao;

                    /* let ordemTarefa = {};
                     ordemTarefa.ordemServicoId = dados[i].ordemServicoId;
                     ordemTarefa.idOrdemTarefa = dados[i].idOrdemTarefa;
                     ordemTarefa.produtoId = dados[i].produtoId;
                     ordemTarefa.tarefaId = dados[i].tarefaId;
                     ordensTarefas.push(ordemTarefa);*/
                    //dadosRecord.ordensTarefas = ordensTarefas;

                    let dadosAtividadeAtualizar = dadosAtividade.filter(a => a.key === dados[i].key);

                    for (var j = 0; j < dadosAtividadeAtualizar.length; j++) {
                        let tar = {};
                        tar.ordemServicoId = dados[i].ordemServicoId;
                        tar.idOrdemTarefa = dados[i].idOrdemTarefa;
                        tar.produtoId = dados[i].produtoId;
                        tar.tarefaId = dados[i].tarefaId;
                        tar.idTarefa = dadosAtividadeAtualizar[j].idTarefa;
                        tar.idResponsavel = dados[i].idResponsavel;
                        tar.usuarioId = dadosAtividadeAtualizar[j].usuarioId;
                        tar.tarefaTipoId = dadosAtividadeAtualizar[j].tarefaTipoId;
                        tar.data = moment(dadosAtividadeAtualizar[j].data).toISOString();
                        tar.descricao = dadosAtividadeAtualizar[j].descricao;
                        tar.status = dadosAtividadeAtualizar[j].status;
                        tar.dataInicio = moment(dadosAtividadeAtualizar[j].dataInicio).toISOString();
                        tar.dataFim = moment(dadosAtividadeAtualizar[j].dataFim).toISOString();
                        tar.dataLimite = null;
                        tar.tempoPrevisto = dadosAtividadeAtualizar[j].tempoPrevisto;
                        tar.porcentagemAndamento = dadosAtividadeAtualizar[j].porcentagemAndamento;

                        dadosRecord.tarefa = tar;

                    }


                    if (dadosAtividadeAtualizar.length === 0) {
                        let tar = {};
                        tar.ordemServicoId = dados[i].ordemServicoId;
                        tar.idOrdemTarefa = dados[i].idOrdemTarefa;
                        tar.produtoId = dados[i].produtoId;
                        tar.tarefaId = dados[i].tarefaId;
                        tar.idTarefa = dados[i].tarefaId;
                        tar.idResponsavel = dados[i].idResponsavel;
                        tar.data = moment(dados[i].data).toISOString();
                        tar.status = dados[i].status;
                        tar.tarefaTipoId = dados[i].tarefaTipoId;
                        tar.descricao = dados[i].descricao;
                        tar.dataFim = null;
                        tar.dataInicio = null;
                        tar.dataLimite = null;
                        dadosRecord.tarefa = tar;
                    }

                    if (!!dados[i].tarefaTipoId) {
                        dadosRecord.tarefaTipo = listaTipo.find(t => t.id === dados[i].tarefaTipoId);
                    }
                    if (!!dados[i].produtoId) {
                        dadosRecord.produto = await buscarServico(dados[i].produtoId);
                    }

                    if (!!dados[i].idResponsavel) {
                        dadosRecord.responsavel = await buscarResponsavel(dados[i].idResponsavel);
                    }
                    dadosRecord.termoAceite = dados[i]?.termoAceite ? dados[i]?.termoAceite : [];
                    dadosRecord.status = dados[i].status;
                    dadosRecord.porcentagemAndamento = dados[i].porcentagemAndamento;
                    dadosAtualizado.push(dadosRecord);
                }

            }
        }
        dadosAtualizado = dadosAtualizado.sort(ordenarPorKey);
        listaItens.setListaItens(dadosAtualizado);
        aoFechar();
    };

    function ordenarPorKey(a, b) {
        return a.key - b.key;
    }

    useEffect(() => {
        if (atualizar) { dispatch({ type: selectPaginadoActions.CHANGE, data: [{ atualizar: true }] }); } else { setAtualizar(true); }
    }, [exibirTodos.exibirTodos]);

    return (
        <>
            <Form layout="vertical" name="manutencaoTarefa" form={form} onFinish={salvarTarefa}>
                <Row>
                    <Col span={24}>
                        Preencha as informações abaixo para realizar seu cadastro.
                    </Col>
                </Row>
                <Form.Item hidden name="key"></Form.Item>
                <Form.Item hidden name="idTarefa"></Form.Item>
                <Form.Item hidden name="idOrdemTarefa"></Form.Item>
                <Row gutter={[8, 0]} className="m-t-8">
                    <Col xs={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Serviço" name="produtoId" rules={[{ message: "Informe o serviço", required: true }]}>
                            <SelectPaginado campoFiltro="buscarProduto" url={`Produto?Servico=true&ContratoAtivo=true&PessoaId=${pessoaId.pessoaId}&Todos=${exibirTodos.exibirTodos}`} placeholder="Selecione o Produto" name="produtoId" form={form} conteudo={
                                prod =>
                                    (<Select.Option key={prod.id} value={prod.id}>{prod.codigo} - {prod.descricao} </Select.Option>)
                            } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12} xl={12} className="m-t-21">
                        <Form.Item valuePropName="checked">
                            <Checkbox name="exibirTodos" checked={exibirTodos.exibirTodos} onChange={(value) => {
                                exibirTodos.setExibirTodos(value.target.checked);
                            }}> Exibir todos Serviços </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Responsável" name="idResponsavel" rules={[{ required: true, message: "Informe o responsavel" }]}>
                            <SelectPaginado url={"Usuarios?Ativo=true&Sac=true&listaParticipantes=" + filtroParticipantes.filtroParticipantes} name="idResponsavel" form={form} laceholder="Selecione o Responsável" conteudo={
                                res => (<Select.Option value={res.id} key={res.id}>{(res.nome).normalize('NFD').replace(/[\u0300-\u036f]/g, "")}</Select.Option>)
                            } />
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={8} xl={7}>
                        <Form.Item label="Tipo de Tarefa" name="tarefaTipoId" rules={[{ message: "Informe o tipo de tarefa", required: true }]}>
                            <SelectPaginado url="TarefaTipo" form={form} placeholder="Selecione o Tipo de Tarefa" name="tarefaTipoId" conteudo={
                                (tpt) => (<Select.Option key={tpt.id} value={tpt.id}>{tpt.descricao}</Select.Option>)
                            } />
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={5}>
                        <Form.Item label="Data" name="data" rules={[{ message: "Informe a data", required: true }]}>
                            <DatePicker showTime placeholder="DD/MM/YYYY" format={"DD/MM/YYYY HH:mm:ss"} />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="mt0 m-t-21">
                        <Form.Item hidden valuePropName="checked" name="urgencia" initialValue={false}>
                            <Checkbox> Urgência </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Observação" name="descricao">
                            <Input.TextArea style={{
                                height: 80,
                            }} placeholder="Observação" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[8, 0]} justify="space-between">
                            {/*<Col xs={24} md={6} lg={6} xl={6} xxl={5}>
                                <Form.Item label="Tempo em Horas" name={"tempoPrevisto"}>
                                    <InputNumber placeholder="Informe o Tempo em Horas" />
                                </Form.Item>
                        </Col>*/}
                            <Col xs={12} md={5} lg={5} xl={6} xxl={7} className="mt0 m-t-25">
                                Status: <b> {listaStatus.find(s => s.valor === statusAtual.statusAtual)?.descricao} </b>
                            </Col>
                            {<Col xs={12} md={4} lg={4} xl={6} xxl={6} className="mt0 m-t-25">
                                Progresso: <b>{porcentagemAtual.porcentagemAtual} %</b>
                            </Col>}
                            <Col xs={24} md={8} lg={8} xl={6} xxl={5} className="m-t-21 mt8" align="right">
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => {
                                    let dados = {};
                                    if (!!manutencao.dados) {
                                        manutencao.dados.key = !!manutencao.dados.key ? manutencao.dados.key : listaItens.listaItens.length + 1;
                                        manutencao.dados.tarefaTipoId = form?.getFieldsValue()?.tarefaTipoId;
                                        manutencao.dados.produtoId = form?.getFieldsValue()?.produtoId;
                                        manutencao.dados.idResponsavel = form?.getFieldsValue().idResponsavel;
                                        manutencao.dados.usuarioId = form?.getFieldsValue().idResponsavel;
                                        dados = manutencao.dados;
                                    } else {
                                        dados.key = listaItens.listaItens.length;
                                        dados.tarefaTipoId = form?.getFieldsValue()?.tarefaTipoId;
                                        dados.idOrdemServico = form?.getFieldsValue()?.idOrdemServico;
                                        dados.produtoId = form?.getFieldsValue()?.produtoId;
                                        dados.idResponsavel = form?.getFieldsValue().idResponsavel;
                                    }
                                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...dados } } })
                                    setOpenModalAtividadeTarefa(true)
                                }} block>
                                    Adicionar Atividades da Tarefa
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="tabela-pages m-t-12">
                    <Table columns={[
                        {
                            title: 'Informações',
                            render: ({ key, descricao, porcentagemAndamento, status, tempoIntervalo, dataInicio, dataFim }) => (
                                <Row gutter={[0, 5]} align="middle">
                                    <Col xs={3} md={2} lg={1} xl={1} align="center">
                                        <Tooltip title="Participante">
                                            <Avatar size="small" src={require("../../assets/logo_abase.svg").default} />
                                        </Tooltip>
                                    </Col>
                                    <Col xs={21} md={22} lg={23} xl={23} className="space-white">
                                        <b>
                                            {descricao}
                                        </b>
                                    </Col>
                                    <Col xs={12} md={8} lg={8} xl={8}>
                                        <Row gutter={[5, 0]}>
                                            <Col> Progresso: </Col>
                                            <Col><b>{porcentagemAndamento} % </b></Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={8} lg={8} xl={8}>
                                        <Row gutter={[5, 0]}>
                                            <Col> Status: </Col>
                                            <Col><b>     {listaStatus.find(s => s.valor === status)?.descricao}</b></Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={8} lg={8} xl={8}>
                                    </Col>
                                    <Col xs={12} md={8} lg={8} xl={8}>
                                        <Row gutter={[5, 0]}>
                                            <Col> Inicial: </Col>
                                            <Col><b>{moment(dataInicio).format('DD/MM/YYYY HH:mm:ss')}</b></Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={8} lg={8} xl={8}>
                                        <Row gutter={[5, 0]}>
                                            <Col> Final: </Col>
                                            <Col><b>{moment(dataFim).format('DD/MM/YYYY HH:mm:ss')}</b></Col>
                                        </Row>
                                    </Col>
                                    {/*<Col xs={12} md={8} lg={8} xl={8}>
                                        <Row gutter={[5, 0]}>
                                            <Col> Intervalo: </Col>
                                            <Col><b>{moment(tempoIntervalo).format('HH:mm:ss')}</b></Col>
                                        </Row>
                            </Col>*/}
                                </Row>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                editarAtividade(record);
                                            }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => excluirAtividade(record)} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]}
                        locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} dataSource={listaAtividade.listaAtividade.filter(a => a.key === form.getFieldsValue().key)} scroll={{ y: 188 }}
                    />
                </div>
            </Form>
            <ModalAtividadeTarefa formAnt={form} exibirModalAtividadeTarefa={openModalAtividadeTarefa} fecharModalAtividadeTarefa={(dados) => onCloseModalAtividade(dados)} listaAtividade={listaAtividade.listaAtividade} statusAtual={statusAtual} porcentagemAtual={porcentagemAtual} />
        </>
    );
}