import React, { useEffect, useState } from "react";
import { Table } from "antd";
import api from "../../services/api";
import { parseToken } from "../../services/funcoes";
import { useStateValue } from "../../state/stateProvider";

export default function TabEmpresa({ formulario }) {
    const [{ ui }, dispatch] = useStateValue();
    const [load, setLoad] = useState(false);
    const [locais, setLocais] = useState({});
    const [pagina, setPagina] = useState(1);
    const [nroRegistros, setNroRegistros] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        if (ui.showDrawer) {
            setSelectedRowKeys([]);
            loadLocais();
        }
    }, [pagina, nroRegistros, ui.showDrawer]);

    function loadLocais() {
        setLoad(true);
        let token = parseToken();
        api.get(`LocaisUsuario/ListarLocais?EmpresaId=${token.Empresa}&Ativo=true&pagina=${pagina}&nroRegistros=${nroRegistros}`).then(res => {
            if (res.status === 200) {
                res.data.items = res.data.items.map((x) => ({ key: x.id, ...x }));
                setLocais(res.data);
                let dadosLocais = res.data.items;
                if (!!formulario.getFieldValue('id')) {
                    setLoad(true);
                    api.get(`LocaisUsuario/ListarLocais?UsuarioId=${formulario.getFieldValue('id')}&EmpresaId=${token.Empresa}&Ativo=true&pagina=${pagina}&nroRegistros=${nroRegistros}`).then(res => {
                        if (res.status === 200) {
                            let array = [];
                            for (let i in dadosLocais) {
                                if (res.data.items.findIndex(x => x.id == dadosLocais[i].id) > -1)
                                    array.push(dadosLocais[i].id);
                            }
                            formulario.setFieldsValue({ locais: array.map((x) => ({ LocalId: x })) });
                            setSelectedRowKeys(array);
                        }
                    }, err => {
                        console.log(err.response);
                    }).finally(() => setLoad(false));
                }
            }
        }).catch((err) => {
            console.log(err.response);
        }).finally(() => setLoad(false));
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (keys, selectedRows) => {
            setSelectedRowKeys(keys);
            let list = selectedRows.map((x) => ({ LocalId: x.id }));
            formulario.setFieldsValue({ locais: list });
        },
    };

    return (
        <div className="tabela-pages">
            <Table
                rowSelection={{
                    ...rowSelection,
                }}
                loading={load}
                dataSource={locais.items}
                columns={[
                    {
                        title: 'Empresa',
                        render: ({ descricao }) => (
                            <b>{descricao}</b>
                        ),
                    }
                ]}
                pagination={{
                    current: pagina,
                    pageSize: nroRegistros,
                    total: locais.total,
                    onChange: (pg) => setPagina(pg),
                    onShowSizeChange: (current, page) => setNroRegistros(page)
                }} scroll={{ x: 900 }}
            />
        </div>
    );
}