import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import api from '../../services/api';
import { manutencaoActions } from '../../actions';
import { useStateValue } from '../../state/stateProvider';

export default function MultiSelectPaginacao({ url, parametroFiltro, idProp = "id", descricaoProp, nomeCampoForm, onBlurFunction, placeholder, form, allowClear = false, disabled = false }) {
	const [{ manutencao }, dispatch] = useStateValue();
	const [carregando, setCarregando] = useState(false);
	const [lista, setLista] = useState([]);
	const [filtro, setFiltro] = useState('');
	const [pagina, setPagina] = useState(1);
	const [registrosPorPagina, setRegistrosPorPagina] = useState(10);
	const [totalReg, setTotalReg] = useState(0);
	const [usouFiltro, setUsouFiltro] = useState(false);

	useEffect(() => {
		if (!!lista && lista.length > 0) {
			dispatch({ type: manutencaoActions.CHANGE, data: { dados: manutencao.dados, listaSelectPaginacao: lista } });
		}
	}, [lista]);

	function carregarDados() {
		setCarregando(true);
		let parametrosUrl = '';

		!!filtro ? parametrosUrl = `&${parametroFiltro}=${filtro}` : parametrosUrl = "";
		parametrosUrl += `&PageNumber=${pagina}&PageSize=${registrosPorPagina}`;
		if (url.search(/[?]/g) === -1) {
			parametrosUrl = parametrosUrl.replace('&', '?');
		}
		api.get(url + parametrosUrl).then(
			(res) => {
				if (res.status === 200) {
					let dados = [];
					res.data.items.map((item) => {
						dados.push({
							label: item[descricaoProp],
							value: item[idProp]
						});
					})
					setTotalReg(res.data.totalItems)
					if (filtro === '') {
						if (usouFiltro === true) {
							setUsouFiltro(false);
							setLista(dados);
						} else {
							setLista([...lista, ...dados]);
						}
					} else {
						setPagina(1)
						setUsouFiltro(true);
						setLista(dados);
					}
				}
			}
		).finally(
			() => {
				setCarregando(false)
			}
		);

	}

	function recarregaDados() {
		let totalPag = Math.trunc(totalReg / registrosPorPagina) + 1
		if (totalPag > pagina) {
			setPagina(pagina + 1)
		}
	}

	useEffect(() => {
		carregarDados();
	}, [filtro, pagina]);

	return (
		<Select
			loading={carregando}
			mode="multiple"
			showSearch
			defaultValue={() => {
				let valoresIniciais = form.getFieldValue(nomeCampoForm);
				return valoresIniciais?.map(item => { return { value: item[idProp], label: item[descricaoProp] } });
			}}
			allowClear={allowClear}
			placeholder={placeholder}
			disabled={disabled}
			filterOption={false}
			onSearch={(buscaPor) => { setFiltro(buscaPor); }}
			onPopupScroll={e => {
				const { target } = e;
				if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
					recarregaDados();
				}
			}}
			onChange={(valor) => {
				form.setFieldsValue({ [nomeCampoForm]: valor });
			}}
			onBlur={(valor) => {
				!!onBlurFunction ? onBlurFunction(valor) : console.log();
			}}
			options={lista}
		/>
	)
}