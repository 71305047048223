import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Modal, Typography, Select, notification, Button } from "antd";
import { ExclamationCircleOutlined, SendOutlined } from "@ant-design/icons";
import MaskedInput from 'antd-mask-input';
import SelectPaginado from "../../selectPaginado";
import { getEmpresa, getLocal, getIdUsuario } from "../../../services/auth";
import { useStateValue } from "../../../state/stateProvider";

import 'moment/locale/pt-br';
import moment from 'moment';
import api from "../../../services/api";
import { listagemActions, manutencaoActions } from "../../../actions";
import { AssinarRelatorio, downloadRelatorioJasper } from "../../../services/funcoes";

export default function ModalAssinarTermo({ form, exibirModal, fecharModal, listaTermoAceite, contatosEmail, responsavel, numeroOS, formOS, listaItens, botaoVisivel }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [formTermoAceite] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        formTermoAceite.resetFields();
        if (validarObjeto(listaTermoAceite.listaTermoAceite)) {
            formTermoAceite.setFieldsValue(listaTermoAceite.listaTermoAceite);
        }
    }, [exibirModal]);

    function validarObjeto(objeto) {
        // Verifica se o objeto não é nulo e não é indefinido
        if (objeto && typeof objeto === 'object' && Object.keys(objeto).length > 0) {
            return true; // O objeto tem dados válidos
        }

        return false; // O objeto não tem dados válidos
    }

    const onCloseModalTermoAceite = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                formTermoAceite.resetFields();
                fecharModal();
            }
        });
    };

    async function salvarTermo(values) {
        setCarregando(true);
        let arrayDadosTermo = [];
        let dadosTermoAceite = {};
        debugger;
        console.log(numeroOS);

        dadosTermoAceite.id = formTermoAceite.getFieldValue().id;
        dadosTermoAceite.idOrdemServico = !!formTermoAceite?.getFieldValue().idOrdemServico ? formTermoAceite?.getFieldValue().idOrdemServico : formOS?.getFieldValue().idOrdemServico;
        dadosTermoAceite.idOrdemTarefa = !!formTermoAceite?.getFieldValue().idOrdemTarefa ? formTermoAceite?.getFieldValue().idOrdemTarefa : formOS?.getFieldValue().idOrdemServico;
        dadosTermoAceite.empresaId = getEmpresa();
        dadosTermoAceite.modeloId = formTermoAceite.getFieldValue().modeloId;
        dadosTermoAceite.pessoaId = !!form?.getFieldValue().pessoaId ? form?.getFieldValue().pessoaId : formOS.getFieldValue().pessoaId;
        dadosTermoAceite.usuarioId = !!form?.getFieldValue().usuarioId ? form?.getFieldValue().usuarioId : formOS.getFieldValue().usuarioId;
        dadosTermoAceite.nomeSistema = formTermoAceite.getFieldValue().nomeSistema;
        dadosTermoAceite.tecnicoResponsavel = formTermoAceite.getFieldValue().tecnicoResponsavel;
        dadosTermoAceite.dataGeracao = moment(new Date());
        /*listaTermoAceite.setListaTermoAceite(dadosTermoAceite);
        arrayDadosTermo.push(dadosTermoAceite);
        let dados = [...listaItens.listaItens];
        for (var i = 0; i < dados.length; i++) {
            if (dados[i].key === values.key) {
                dados[i].termoAceite = arrayDadosTermo;
            }
        }
        listaItens.setListaItens(dados);*/

        gerarRelatorio(dadosTermoAceite);

    };

    async function gerarRelatorio(values) {
        let dados = { ...formTermoAceite.getFieldValue() };
        let relatorioId = "", nomeRelatorio = "";
       
        let clienteEmail = contatosEmail.find(x => x.id === dados.cliente);
        let signatures = [];

        let emails = dados?.emails?.split(";");
        let nomes = dados?.nomes?.split(";");

        let emailSalvar = !!dados?.emails ? dados?.emails : clienteEmail?.email;
        let nomeSalvar = !!dados?.nomes ? dados?.nomes : clienteEmail?.nome;
        let filtros = retornaFiltros(values,nomeSalvar);

        if (!!emails && !!nomes && !!clienteEmail) {
            setCarregando(false);
            return notification.warn({ message: "Aviso", description: "Favor selecionar um contato ou informar os nomes e respectivos e-mail's para o envio da assinatura." });
        } else if (emails?.length !== nomes?.length) {
            setCarregando(false);
            return notification.warn({ message: "Aviso", description: "Informar mesma quantidade de nomes e e-mails" });
        }

        if (!!clienteEmail) {
            const obj = {
                "Email": clienteEmail.email,
                "PartName": clienteEmail.nome
            }
            signatures.push(obj);
        } else {
            for (var i = 0; i < emails.length; i++) {
                const obj = {
                    "Email": emails[i],
                    "PartName": nomes[i]
                }
                signatures.push(obj);
            }
        }
        debugger;

        if (validarObjeto(responsavel)) {
            const obj = {
                "Email": responsavel?.email,
                "PartName": responsavel?.nome
            }
            signatures.push(obj);
        }


        switch (values.modeloId) {
            case 1:
                relatorioId = 44;
                nomeRelatorio = "Termo Aceite - Abase";
                break;
            case 3:
                relatorioId = 46;
                nomeRelatorio = "Termo Aceite - GOV";
                break;
        }

        try {
            await AssinarRelatorio(relatorioId, filtros, "PDF", "TermoAceiteImplantacao_" + numeroOS + "_" + values?.idOrdemTarefa, signatures, values?.idOrdemServico, 2, values, nomeSalvar, emailSalvar);
            notification.success({ message: 'Enviado para assinar.', description: "Será encaminhado um e-mail para realizar a assinatura." });
            botaoVisivel.setBotaoVisivel(true);
        } catch (error) {
            console.error('Ocorreu um erro:', error);
            setCarregando(false);
        } finally {
            setCarregando(false);
            form?.setFieldsValue({ nomeSistema: null, modeloId: null });
            formOS?.setFieldsValue({ nomeSistema: null, modeloId: null });
            fecharModal();
        }


    };
    function validarObjeto(objeto) {
        // Verifica se o objeto não é nulo e não é indefinido
        if (objeto && typeof objeto === 'object' && Object.keys(objeto).length > 0) {
            return true; // O objeto tem dados válidos
        }

        return false; // O objeto não tem dados válidos
    }


    function retornaFiltros(values, nomeSalvar) {
        return [{ "nome": "pessoa_id", "valor": values.pessoaId },
        { "nome": "tecnico_responsavel", "valor": values.tecnicoResponsavel },
        { "nome": "sistema_nome", "valor": values.nomeSistema },
        { "nome": "emp_id", "valor": values.empresaId },
        {"nome": "contato_assinou", "valor": nomeSalvar}, 
        {"nome":"data", "valor": moment(new Date()).format('DD/MM/YYYY')}];
    }


    return (
        <Modal centered
            title="Assinar Termo de Aceite"
            open={exibirModal}
            onCancel={onCloseModalTermoAceite}
            onOk={() => formTermoAceite.submit()}
            width={700}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button key="cancel" onClick={onCloseModalTermoAceite} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<SendOutlined />} loading={carregando} key="ok" size="large" type="primary" htmlType="submit" onClick={() => formTermoAceite.submit()}>
                            Enviar
                        </Button>
                    </Col>
                </Row>}
        >
            <Form layout="vertical" name="manutencaoTermoAceite" form={formTermoAceite} onFinish={salvarTermo} >
                <Row gutter={[0, 8]}>
                    <Col span={24}>
                        <Form.Item hidden name="key" form={formTermoAceite}></Form.Item>
                        <Form.Item name="id" hidden form={formTermoAceite} />
                        <Form.Item name="idOrdemServico" hidden form={formTermoAceite} />
                        <Form.Item name="idOrdemTarefa" hidden form={formTermoAceite} />
                        <Form.Item name="tecnicoResponsavel" hidden form={formTermoAceite} />
                        <Form.Item label="Modelo Termo" name="modeloId" rules={[{ required: true, message: 'Informe o Modelo do termo de aceite' }]}>
                            <SelectPaginado url="ModeloTermoAceite/Listar" form={formTermoAceite} name="modeloId" conteudo={
                                modelo => (<Select.Option value={modelo.id} key={modelo.id}>{modelo.descricao}</Select.Option>)
                            } />
                        </Form.Item>
                        <Form.Item form={formTermoAceite} label="Nome Sistema" name="nomeSistema" rules={[{ required: true, message: 'Informe o Nome do Sistema' }]}>
                            <Input placeholder="Informe o nome do sistema" />
                        </Form.Item>
                        <Form.Item label="Contato:" name="cliente">
                            <Select form={formTermoAceite} name="cliente" allowClear placeholder="Selecione um e-mail" showSearch optionFilterProp="children" >
                                {contatosEmail?.map((email) => (
                                    <Select.Option value={email.id} key={email.id}>
                                        {email.nome + " - " + email.email}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item form={formTermoAceite} label="Nome (separar por ; )" name="nomes">
                            <Input placeholder="Informe o Nome" />
                        </Form.Item>
                        <Form.Item form={formTermoAceite} label="E-mail (separar por ; )" name="emails">
                            <Input placeholder="Informe o E-mail" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>

    );
}