import React from "react";
import { Row, Col, Descriptions, Divider } from "antd";

export default function Detalhes() {

    return (
        <Row gutter={[8, 8]}>
            <Col span={24}>
                <Descriptions column={2} size="small">
                    <Descriptions.Item label="Usuário">
                        <b>usuário</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Última atualização">
                        <b>14/10/2021 07:05</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Local">
                        <b>Abase Sistemas e Soluções LTDA</b>
                    </Descriptions.Item>
                </Descriptions>
                <Divider />
            </Col>
            <Col span={24}>
                <Descriptions column={1} size="small" title={
                    <Row gutter={[8, 0]}>
                        <Col>Título: </Col>
                        <Col>titulo </Col>
                    </Row>
                }>
                    <Descriptions.Item label="Cliente">
                        <b>Não Informado</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions column={2} size="small">
                    <Descriptions.Item label="Data Inicial">
                        <b>14/10/2021 07:05</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Data Final">
                        <b>14/10/2021 07:05</b>
                    </Descriptions.Item>
                </Descriptions>
                <Divider />
            </Col>
            <Col span={24}>
                <Descriptions column={1} size="small" title={
                    <Row gutter={[8, 0]}>
                        <Col>Título: </Col>
                        <Col>titulo </Col>
                    </Row>
                }>
                    <Descriptions.Item label="Cliente">
                        <b>Não Informado</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions column={2} size="small">
                    <Descriptions.Item label="Data Inicial">
                        <b>14/10/2021 07:05</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Data Final">
                        <b>14/10/2021 07:05</b>
                    </Descriptions.Item>
                </Descriptions>
                <Divider />
            </Col>
        </Row>
    );
}