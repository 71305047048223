import React, { useEffect, useState } from "react";
import { Row, Col, Form, notification, Input, Typography, Table, Button, Divider } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from '../../state/stateProvider';
import { ModalTipoRequisicao } from "../../components/modals";

export default function ManutencaoTipoRecurso({ form, carregando, aoSalvar }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [tabelaTipoRequisicao, setTabelaTipoRequisicao] = useState([]);
    const [openModalTipoRequisicao, setOpenModalTipoRequisicao] = useState(false);

    useEffect(() => {
        if (!!manutencao.dados) {
            form.setFieldsValue(manutencao.dados);
            if (manutencao.dados.formulario) {
                setTabelaTipoRequisicao(JSON.parse(manutencao.dados.formulario));
            }
        }
    }, [manutencao.dados]);

    function salvarManutencao(values) {
        carregando(true);
        if (tabelaTipoRequisicao.length == 0) {
            notification.warning({ description: "Informe ao menos um item para o tipo de requisição!", message: "Aviso!" });
            return false;
        }

        values.formulario = JSON.stringify(tabelaTipoRequisicao);
        if (!!values.id) {
            api.put(`RecursoTipo`, values).then(
                res => {
                    notification.success({ message: `Sucesso!`, description: `Tipo de Recurso editado com sucesso!` });
                }
            ).catch(
                error => {
                    notification.warning({ message: `Aviso!`, description: `Erro ao editar o Tipo de Recurso` });
                }
            ).finally(() => {
                aoSalvar();
                setTabelaTipoRequisicao([]);
                carregando(false);
            })

        } else {
            values.ativo = true;
            api.post(`RecursoTipo`, values).then(
                res => {
                    notification.success({ message: `Sucesso!`, description: `Tipo de Recurso salvo com sucesso!` });
                }
            ).catch(
                error => {
                    notification.warning({ message: `Aviso!`, description: `Erro ao salvar o Tipo de Recurso` });
                }).finally(() => {
                    aoSalvar();
                    setTabelaTipoRequisicao([]);
                    carregando(false);
                });
        }
    }

    return (
        <Form layout="vertical" name="formManutencaoTipoRecurso" form={form} onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Col span={24}>
                    <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: "Informe a descrição" }]}>
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item name="id" hidden />
                    <Form.Item name="ativo" hidden />
                </Col>
            </Row>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={5}>
                        <img src={require("../../assets/i_settings.png").default} alt="Tipo de Requisição" /> Tipo de Requisição
                    </Typography.Title>
                    <Divider orientation="left" plain className="m-t-5 m-b-5" />
                </Col>
                <Col>
                    <div className="i-Position">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => { setOpenModalTipoRequisicao(true); }} />
                    </div>
                </Col>
            </Row>
            <div className="tabela-pages">
                <Table columns={[
                    {
                        title: 'Label',
                        render: ({ label }) => (
                            <div>
                                <b>{label} </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Tipo',
                        render: ({ type }) => (
                            <div>
                                <b>{type} </b>
                            </div>
                        ),
                    },
                    /*{
                        title: 'Ações',
                        width: 65,
                        align: 'center',
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => { setOpenModalTipoRequisicao(true); }} />
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },*/
                ]}
                    locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }} dataSource={tabelaTipoRequisicao} scroll={{ x: 920 }}
                />
            </div>
            <ModalTipoRequisicao form={form} exibirModalTipoRequisicao={openModalTipoRequisicao} fecharModalTipoRequisicao={() => setOpenModalTipoRequisicao(false)} listaFormulario={{ tabelaTipoRequisicao, setTabelaTipoRequisicao }} />
        </Form>
    );
}