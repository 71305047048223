import React from 'react';
import { Route, Switch, HashRouter } from "react-router-dom";
import { Layout } from "antd";

import { Login, Inicial, Agenda, OrdemServico, Calendario, Notificacao, UsuarioLocal, PaginaLogs, TermoDeAceite, Area, Usuario, TipoTarefa, TipoRequisicao, TipoAgenda, 
    RecursoLocacao, Cliente, Requisicao, TipoRecurso, Restaurante, ModeloTermoAceite, Hotel, Equipe, Recurso, SolucoesModulo, Parceiros, Sistema, Localizacao, GrupoUsuario, AgendaEmail, AutorizarUsuario, ConsultarTermoAceite } from "../";
import { MenuPage, HeaderPage } from "../../components";
import Mapa from '../map';

export default function Home() {
    const latitude = 51.505;
    const longitude = -0.09;
    return (
        <HashRouter>
            <div className="App">
                <Layout className="vh100">
                    <HeaderPage />
                    <Layout className="vh100 m-t-55">
                        <MenuPage />
                        <Layout className="site-layout">
                            <Layout.Content>
                                <Switch>
                                    <Route exact path="/login" component={Login} />    
                                    <Route exact path="/" component={Inicial} />   
                                    <Route exact path="/agenda" component={Agenda} /> 
                                    <Route exact path="/agendaEmail" component={AgendaEmail} />       
                                    <Route exact path="/ordemServico" component={OrdemServico} />
                                    <Route exact path="/consultarTermosAceite" component={ConsultarTermoAceite} />
                                    <Route exact path="/calendario" component={Calendario} />  
                                    <Route exact path="/notificacao" component={Notificacao} />  
                                    <Route exact path="/usuarioLocal" component={UsuarioLocal} />  
                                    <Route exact path="/paginaLogs" component={PaginaLogs} />  
                                    <Route exact path="/termoDeAceite" component={TermoDeAceite} />  
                                    <Route exact path="/area" component={Area} />    
                                    <Route exact path="/usuario" component={Usuario} />  
                                    <Route exact path="/autorizarUsuario" component={AutorizarUsuario} />  
                                    <Route exact path="/tipoTarefa" component={TipoTarefa} />  
                                    <Route exact path="/tipoRequisicao" component={TipoRequisicao} />  
                                    <Route exact path="/tipoAgenda" component={TipoAgenda} />  
                                    <Route exact path="/recursoLocacao" component={RecursoLocacao} />
                                    <Route exact path="/cliente" component={Cliente} />
                                    <Route exact path="/requisicao" component={Requisicao} />
                                    <Route exact path="/tipoRecurso" component={TipoRecurso} />                                    
                                    <Route exact path="/restaurante" component={Restaurante} />                              
                                    <Route exact path="/modeloTermoAceite" component={ModeloTermoAceite} />                  
                                    <Route exact path="/hotel" component={Hotel} />                          
                                    <Route exact path="/equipe" component={Equipe} />   
                                    <Route exact path="/recurso" component={Recurso} /> 
                                    <Route exact path="/solucoesModulo" component={SolucoesModulo} /> 
                                    <Route exact path="/parceiros" component={Parceiros} />          
                                    <Route exact path="/sistema" component={Sistema} />                                             
                                    <Route exact path="/localizacao" component={Localizacao} />                                          
                                    <Route exact path="/grupoUsuario" component={GrupoUsuario} />                                                               
                                    

                                </Switch>
                            </Layout.Content>
                        </Layout>
                    </Layout>
                </Layout>
            </div>
        </HashRouter>
    )
}