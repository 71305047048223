import React, { useEffect, useState } from "react";
import { Row, Col, Form, Steps, Button, Input } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { Tipo, Sugestoes, Recurso } from "./pages";
import api from "../../services/api";
import { validaForm } from "../../services/funcoes";
import moment from "moment";

export default function ManutencaoRecursoPage({ formAgenda, form, listaRecursos, aoSalvar, carregando, pages, proximaPag, coordenadas }) {

    const [tagPageRecurso, setTagPageRecurso] = useState(0);
    const [listaTodosRecursos, setListaTodosRecursos] = useState([]);
    const [permiteValidar, setPermiteValidar] = useState(true);

    function salvarRecurso() {
        carregando(true)
        if (!!form.getFieldsValue().recursoId) {
            let lista = [...listaRecursos.listaRecursos]
            let agendaRecurso = {};
            let recurso = {};
            agendaRecurso.RecursoId = form.getFieldsValue().recursoId;
            recurso.descricao = form.getFieldsValue().recurso;

            agendaRecurso.recurso = recurso;
            lista.push(agendaRecurso);
            listaRecursos.setListaRecursos(lista);
            
        }
        carregando(false);
        aoSalvar();
    }

    useEffect(() => {
        if (!!form.getFieldValue().recursoTipoId) {
            api.get(`Recurso/ListarAtivos?RecursoTipo=${form.getFieldValue().recursoTipoId}&dataInicial=${moment(formAgenda.getFieldValue().dataInicial).format('YYYY-MM-DD HH:mm:ss')}&dataFinal=${moment(formAgenda.getFieldValue().dataFinal).format('YYYY-MM-DD HH:mm:ss')}`).then(
                res => {
                    if (!!res.data) {
                        setListaTodosRecursos(res.data);
                    }
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            );
        }
    }, [form.getFieldValue().recursoTipoId]);

    function validarTrocaPg(pagina) {
        let paginaAtual = pages.tagPages;
        let listaValidacoes = [];

        switch (paginaAtual) {
            case 0:
                listaValidacoes = [
                    { nome: 'recursoTipoId', obrigatorio: true, label: 'Tipo de Recurso' }
                ];
                break;
        }
        if (validaForm(form, listaValidacoes)) {
            if (paginaAtual != null && form.getFieldValue("recursoTipo").toLowerCase() !== 'veículo') {
                switch (paginaAtual) {
                    case 0:
                        pagina = 2
                        break;
                    case 2:
                        pagina = 0;
                        break;
                }
                proximaPag.setProximaPag(pagina);
                setPermiteValidar(false);
            }
            pages.setTagPages(pagina);
        } else {
            setPermiteValidar(false);
            proximaPag.setProximaPag(paginaAtual);
        }
    };

    useEffect(() => {
        if ((!!proximaPag.proximaPag || proximaPag.proximaPag === 0) && permiteValidar) {
            validarTrocaPg(proximaPag.proximaPag);
        } else {
            setPermiteValidar(true);
        }
    }, [proximaPag.proximaPag]);

    return (
        <Form layout="vertical" name="manutencaoRecurso" form={form} onFinish={salvarRecurso}>
            <Row align="middle" gutter={[8, 8]} className="m-t-8">
                <Col span={24}>
                    <Steps size="small" onChange={(e) => proximaPag.setProximaPag(e)} current={pages.tagPages}>
                        <Steps.Step title="Tipo" />
                        <Steps.Step title="Sugestões" />
                        <Steps.Step title="Recurso" />
                    </Steps>
                </Col>
                <Form.Item name="recurso" hidden>
                    <Input />
                </Form.Item>
                <Col span={24}>
                    {pages.tagPages === 0 &&
                        <Tipo form={form} />
                    }
                    {pages.tagPages === 1 && //não implementado ainda
                        <Sugestoes form={form} formAgenda={formAgenda} listaRecursos={listaRecursos} exibirConteudo={() => { proximaPag.setProximaPag(1) }} aoSalvar={aoSalvar} coordenadas={coordenadas} />
                    }
                    {pages.tagPages === 2 &&
                        <Recurso form={form} listaTodosRecursos={listaTodosRecursos} />
                    }
                </Col>
            </Row>
        </Form>
    );
}