import firebase from "firebase/compat/app";
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBwHwHXieH7x909seNsaAgdI7a5Kiuu5IE",
    authDomain: "sacg-347711.firebaseapp.com",
    projectId: "sacg-347711",
    storageBucket: "sacg-347711.appspot.com",
    messagingSenderId: "269695508886",
    appId: "1:269695508886:web:5f47c33158e0d5e689fdb7"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const logOut = () => auth.signOut();

export const getTokenId = async () => {
    let defaultAuth = firebase.auth();
    if (defaultAuth.currentUser !== null) return await defaultAuth.currentUser.getIdToken();
    return null;
}

export default firebase;