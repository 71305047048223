import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Checkbox, Alert, notification } from 'antd';

import "./index.less";
import { getEmailUsuario, getIdUsuario, getNomeUsuario } from "../../services/auth";
import api from "../../services/api";
import { useStateValue } from "../../state/stateProvider";

export default function Perfil({ formulario, aoSalvar }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [dadosUsuario, setDadosUsuario] = useState({});

    useEffect(() => {
        api.get(`Usuarios/${getIdUsuario()}`).then(
            (res) => {
                setDadosUsuario(res.data);
                formulario.setFieldsValue({ id: getIdUsuario(), usu_email: getEmailUsuario(), usu_nome: getNomeUsuario() });
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );

    }, [ui.showDrawerPerfil]);

    const salvarDadosPerfil = async (values) => {
        if (!values.senhanova || !values.senhanovaconfirma) {
            notification.warning({ message: `Aviso!`, description: `Favor preencher senhas`});
        } else 
        if (values.senhanova != values.senhanovaconfirma) {
            notification.warning({ message: `Aviso!`, description: `As senhas digitadas não coincidem` });
        } else {
            let dadosEnviar = { ...dadosUsuario };
            dadosEnviar.nome = values.usu_nome;
            dadosEnviar.senha = values.senhanova;
            let retorno = await api.put('Usuarios', dadosEnviar);
            if (retorno.status === 200) {
                notification.success({ message: 'Operação concluída', description: "Registro salvo com sucesso!" });
                retorno = retorno.data.id;
                aoSalvar(retorno);
            }
            else notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(retorno.data) });
        }
    };


    return (
        <Form layout="vertical" form={formulario} className="perfil" onFinish={salvarDadosPerfil}>
            <Form.Item name="Id" hidden></Form.Item>
            <Row align="middle" gutter={[0, 8]}>
                <Col span={24}>
                    <Alert message="Para alterar a foto basta alterar a foto do perfil da Google e refazer o login usando a sua conta Abase." type="warning" showIcon />
                </Col>
            </Row>
            <Row gutter={[8, 0]} className="m-t-8">
                <Col span={12}>
                    <Form.Item label="Nome" placeholder="Informe seu Nome" name="usu_nome">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="E-mail" placeholder="Informe seu E-mail" name="usu_email" >
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Nova Senha" placeholder="Informe a Nova Senha" name="senhanova" rules={[
                            () => ({
                                validator(_, value) {
                                    if (!value)
                                        return Promise.resolve();

                                    const regexNumber = /[0-9]/;
                                    const regexLetrasMaiuscula = /[A-Z]/;
                                    const regexLetrasMinuscula = /[a-z]/;

                                    if (regexNumber.test(value) === false) {
                                        return Promise.reject(new Error('A senha deve conter números!'));
                                    } else if (regexLetrasMaiuscula.test(value) === false) {
                                        return Promise.reject(new Error('A senha deve conter letras maiúsculas!'));
                                    } else if (regexLetrasMinuscula.test(value) === false) {
                                        return Promise.reject(new Error('A senha deve conter letras minúscula!'));
                                    } else if (value.length < 6) {
                                        return Promise.reject(new Error('A senha deve conter no mínimo de 6 caracteres!'));
                                    }
                                    else
                                        return Promise.resolve();
                                },
                            }),
                        ]}>
                        <Input.Password />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Confirme Nova Senha" placeholder="Confirme a Nova Senha" name="senhanovaconfirma" dependencies={['senha']}
                        hasFeedback
                        rules={[
                            ({ getFieldValue }) => ({
                                required: getFieldValue('senhanova'),
                                message: 'Informe a confirmação de sua senha!',
                            }),
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('senhanova') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('As senhas informadas não conferem!'));
                                },

                            })
                        ]}>
                        <Input.Password />
                    </Form.Item>
                </Col>
                {/*<Col>
                    <Form.Item name="receber_email_agenda" valuePropName="checked">
                        <Checkbox> Receber e-mail de agenda </Checkbox>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item name="receber_email_lembrete" valuePropName="checked">
                        <Checkbox> Receber e-mail de lembrete </Checkbox>
                    </Form.Item>
    </Col>*/}
            </Row>
        </Form>
    );
}