import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Drawer, Modal } from "antd";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import DetalheCliente from "./detalhe";
import { useStateValue } from '../../state/stateProvider';
import { drawerActions, manutencaoActions } from '../../actions';
import api from "../../services/api";

export default function DrawerDetalheCliente() {

    const [temContrato, setTemContrato] = useState(false);
    const [listaContatos, setListaContatos] = useState([]);
    const [formularioArea] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();

    useEffect(() => {
       
        if (manutencao.dados?.pessoa?.id){
            buscarDadosContato(manutencao.dados?.pessoa?.id);
            preencherTemContrato(manutencao.dados?.pessoa?.id);
        }
        console.log(listaContatos);
        
     
    }, [ui.showDrawer]);


    function fecharDrawer() {
        setListaContatos([]);
        formularioArea.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    function buscarDadosContato(pesId) {
        api.get(`PessoaContato/${pesId}`).then(
            res => {
                if (!!res.data) {
                    setListaContatos(res.data);
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    function preencherTemContrato(pesId) {
        api.get(`Contrato/TemContrato?pessoaId=${pesId}`).then(
            res => {
                if (!!res.data) {
                    setTemContrato(res.data);
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    return (
        <Drawer 
            width="85%"
            title="Cliente"
            open={ui.showDrawer}
            onClose={() => { fecharDrawer() }}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={onClose} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                </Row>
            }>
            <DetalheCliente listaContatos={{listaContatos, setListaContatos}} temContrato={{temContrato, setTemContrato}}/>
        </Drawer>
    );
}