import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select } from "antd";
import api from "../../services/api";
import SelectPaginacao from "../selectPaginacao";
import SelectPaginado from "../selectPaginado";

export default function Tipo({ form }) {

    function setarTipo(value, lista) {
        let descricao = lista.find(l => l.id == value).descricao;
        form.setFieldsValue({ recursoTipo: descricao });
    }

    return (
        <Row gutter={[0, 8]}>
            <Col span={24}>
                Informe o tipo de recurso que você deseja reservar.
            </Col>
            <Col span={24}>
                <Form.Item label="Tipo de Recurso" name="recursoTipoId" rules={[{ required: true, message: 'Selecione um recurso' }]} >
                    <SelectPaginado url="RecursoTipo" name="recursoTipoId" form={form} rules={[{ required: true, message: 'Selecione um recurso' }]}
                        onChangeList={(value, lista) => setarTipo(value, lista)}
                        conteudo={
                            (tipo) =>
                            (<Select.Option value={tipo.id} key={tipo.id} valueName={tipo.descricao}>
                                {tipo.descricao}
                            </Select.Option>)
                        } />
                </Form.Item>
            </Col>
        </Row>
    );
}