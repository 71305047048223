import React from "react";
import { Row, Col, Form, Input, Modal, Select } from "antd";
import { ExclamationCircleOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";

export default function ModalTipoRequisicao({ exibirModalTipoRequisicao, fecharModalTipoRequisicao, listaFormulario }) {
    const [form] = Form.useForm();

    const onCloseModalTipoRequisicao = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModalTipoRequisicao();
            }
        });
    };

    const salvarManutencao = (record) => {
        let dados = [...listaFormulario.tabelaTipoRequisicao];
        record.id = record.id.toLowerCase();
        dados.push(record);
        listaFormulario.setTabelaTipoRequisicao(dados);
        fecharModalTipoRequisicao();
        form.resetFields();
    };

    return (
        <Modal centered
            title="Tipo de Requisição"
            open={exibirModalTipoRequisicao}
            onCancel={onCloseModalTipoRequisicao}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            onOk={() => { form.submit() }}
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <Form layout="vertical" name="modalTipoRequisicao" form={form} onFinish={salvarManutencao}>
                <Row gutter={[8, 0]}>
                    <Col xs={12} sm={12} md={18} lg={18} xl={12}>
                        <Form.Item label="Id" name="id" rules={[{ required: true, message: "Informe o ID" }]}>
                            <Input placeholder="Informe o ID" />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={18} lg={18} xl={12}>
                        <Form.Item label="Label" name="label" rules={[{ required: true, message: "Informe o a Label" }]} >
                            <Input placeholder="Informe a Label" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item label="Tipo" name="type" hidden initialValue={"text"}>
                            <Select allowClear placeholder="Selecione um Tipo" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}