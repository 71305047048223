import React, { useState } from "react";
import { Row, Col, Form, Select, Input } from "antd";

export default function ManutencaoTermoAceite({form}) {

    return (
        <Form layout="vertical" name="formManutencaoTermoAceite" form={form}>
            <Row gutter={[8, 0]}>    
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item label="Nome do Sistema" name="nome_sistema">
                        <Input placeholder="Informe o Nome do Sistema" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item label="Cliente">
                        <Select allowClear placeholder="Selecione um Cliente"/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Form.Item label="Empresa">
                        <Select allowClear placeholder="Selecione um Empresa"/>
                    </Form.Item>
                </Col> 
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Form.Item label="Usuário">
                        <Select allowClear placeholder="Selecione um Usuário"/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Form.Item label="Modelo de Termo de Aceite">
                        <Input placeholder="Informe um Modelo de Termo de Aceite"/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        
    );
}