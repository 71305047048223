import React from "react";
import { Row, Col, Form, Input, Checkbox, notification, Select } from "antd";

import api from "../../services/api";
import { TabEmpresa } from "./tabPage";
import { listagemActions, selectPaginadoActions } from "../../actions";
import { useStateValue } from "../../state/stateProvider";
import { SelectPaginado } from "../../components";
import { useEffect } from "react";
import { getIdUsuario, setIdSupervisor } from "../../services/auth";

export default function ManutencaoUsuario({ form, carregando, aoSalvar }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        if (!!manutencao.dados?.id) {
            var lista = [];
            if (!!manutencao.dados.supervisor) {
                lista.push({ name: "supervisor", campo: "Id", value: manutencao.dados.supervisor });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: lista });
        }
    }, [ui.showDrawer]);

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+nome' } });
    };

    const salvarRegistro = async (values) => {
        if (!values.id) values.id = null;
        if (!!form.getFieldValue().locais) {
            values.UsuariosLocais = form.getFieldValue().locais;
            delete values.locais;
        }
        carregando(true);
        debugger;
        let retorno = values.id ? await api.put('/Usuarios', values) : await api.post('/Usuarios', values);
        if (retorno.status === 200) {
            notification.success({ message: 'Operação concluída', description: "Registro salvo com sucesso!" });
            var id = retorno.data.id;
            var idLogado = getIdUsuario();
            if (idLogado === id.toString()) {
                setIdSupervisor(retorno.data?.supervisor);
            }

            atualizarPagina();

            aoSalvar(retorno);

        } else
            if (retorno.status === 204) notification.warn({ message: 'Aviso', description: 'O endereço de e-mail já está em uso.' })
            else notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(retorno.data) });
        carregando(false);
    }

    return (
        <Form layout="vertical" name="formManutencaoUsuario" form={form} onFinish={salvarRegistro}>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                    <Form.Item name="id" hidden />
                    <Form.Item label="Nome" name="nome" rules={[{ required: true, message: "Informe o nome" }]}>
                        <Input placeholder="Informe o Nome" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                    <Form.Item label="E-mail" name="email"
                        rules={[
                            { type: 'email', message: "E-mail invalido!" },
                            { required: true, message: "Informe o e-mail" }
                        ]}>
                        <Input placeholder="Informe o E-mail" />
                    </Form.Item>
                </Col>
                {/*<Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <Form.Item label="Supervisor" name="supervisor" rules={[{ required: true, message: 'Informe um Supervisor' }]}>
                        <SelectPaginado url="Usuarios?Ativo=true&Sac=true" form={form} name="supervisor" allowClear placeholder="Selecione o Supervisor" order="+Id"
                            conteudo={
                                (sup) => (
                                    (<Select.Option value={sup.id} key={sup.id}>{(sup.nome).normalize('NFD').replace(/[\u0300-\u036f]/g, "")}</Select.Option>)
                                )} />
                    </Form.Item>
                                </Col>*/}
            </Row>
            {/*<Row gutter={[8, 0]}>
                <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <Form.Item label="Senha" name="senha"
                        hidden={(!(form.getFieldValue(['id']) <= 0))}
                        rules={[
                            ({ getFieldValue }) => ({ required: !getFieldValue('id') }),
                            () => ({
                                validator(_, value) {
                                    if (!value)
                                        return Promise.resolve();

                                    const regexNumber = /[0-9]/;
                                    const regexLetrasMaiuscula = /[A-Z]/;
                                    const regexLetrasMinuscula = /[a-z]/;

                                    if (regexNumber.test(value) === false) {
                                        return Promise.reject(new Error('A senha deve conter números!'));
                                    } else if (regexLetrasMaiuscula.test(value) === false) {
                                        return Promise.reject(new Error('A senha deve conter letras maiúsculas!'));
                                    } else if (regexLetrasMinuscula.test(value) === false) {
                                        return Promise.reject(new Error('A senha deve conter letras minúscula!'));
                                    } else if (value.length < 6) {
                                        return Promise.reject(new Error('A senha deve conter no mínimo de 6 caracteres!'));
                                    }
                                    else
                                        return Promise.resolve();
                                },
                            }),
                        ]}>
                        <Input.Password autoComplete="off" placeholder="Informe a Senha" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                    <Form.Item label="Confirmar Senha"
                        hidden={(!(form.getFieldValue(['id']) <= 0))}
                        dependencies={['senha']}
                        name="confirmaSenha"
                        hasFeedback
                        rules={[
                            ({ getFieldValue }) => ({
                                required: getFieldValue('senha'),
                                message: 'Informe a confirmação de sua senha!',
                            }),
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('senha') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('As senhas informadas não conferem!'));
                                },

                            })
                        ]}>
                        <Input.Password autoComplete="new-password" placeholder="Confirme a Senha" />
                    </Form.Item>
                </Col>
                    </Row>*/}
            <Row gutter={[8, 0]}>
                <Col>
                    <Form.Item valuePropName="checked" initialValue={true} name="ativo" >
                        <Checkbox>Usuário Ativo?</Checkbox>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item valuePropName="checked" name="sac" >
                        <Checkbox>Usuário SacG?</Checkbox>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item valuePropName="checked" name="admin">
                        <Checkbox> Este usuário irá possuir privilégios de administrador no sistema? </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row align="middle" gutter={[8, 16]}>
                <Col span={24}>
                    <TabEmpresa formulario={form} />
                </Col>
            </Row>
        </Form>
    );
}