import React, { useState } from "react";
import { Row, Col, Modal, Button, Drawer, Form } from "antd";
import { SaveOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import ManutencaoTarefa from "./manutencao";
import { useStateValue } from '../../state/stateProvider';
import { drawerActions, manutencaoActions, selectPaginadoActions } from '../../actions';
import { useEffect } from "react";

export default function DrawerManutencaoTarefa({ form, listaItens, listaAtividade, formOS, statusAtual, porcentagemAtual, filtroParticipantes, pessoaId = 0, exibirTodos}) {

    const [formItensTarefa] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        if (!!manutencao.dados) {
            if (form?.getFieldsValue()?.listaAgendaParticipantes?.length > 0) {
                formItensTarefa.setFieldsValue({ 'listaAgendaParticipantes': form.getFieldsValue().listaAgendaParticipantes });
            }
    
            formItensTarefa.setFieldsValue(manutencao.dados);
            if (!!manutencao.dados?.idTarefa) {
    
            }
            formItensTarefa.setFieldsValue({ id: manutencao.dados.tarefaTipoId });
        }    
    },[ui.showDrawerItens])
    
    function fecharDrawer() {
        console.log(listaItens.listaItens);
        formItensTarefa.resetFields();
        formOS?.setFieldsValue({ usuarioId: manutencao.dados?.usuarioId });
        formOS?.setFieldsValue({ possuiCobranca: manutencao.dados?.possuiCobranca });
        statusAtual.setStatusAtual(1);
        porcentagemAtual.setPorcentagemAtual(0);
        exibirTodos.setExibirTodos(true);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerItens: false } });
        dispatch({ type: selectPaginadoActions.CHANGE, data: { dados: null } });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer
            width="60%"
            title="Cadastro de Tarefas"
            open={ui.showDrawerItens}
            onClose={() => { onClose() }}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={fecharDrawer} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit" onClick={() => formItensTarefa.submit()} loading={carregando}>
                            Salvar
                        </Button>
                    </Col>
                </Row>
            }>
            <ManutencaoTarefa form={formItensTarefa} listaItens={listaItens} carregando={setCarregando} aoFechar={fecharDrawer} listaAtividade={listaAtividade} statusAtual={statusAtual} porcentagemAtual={porcentagemAtual} filtroParticipantes={filtroParticipantes} pessoaId={pessoaId} exibirTodos={exibirTodos} />
        </Drawer>
    );
}