import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Popup, CircleMarker } from 'react-leaflet';

function MapCadastro({ coordenadas }) {
  const [cidade, setCidade] = useState("");
  const redOptions = { color: 'red' };


  useEffect(() => {
    debugger
    // Função para buscar o nome da cidade com base nas coordenadas
    const buscarCidade = async () => {
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${coordenadas.coordenadas[0]}&lon=${coordenadas.coordenadas[1]}&format=json`);
        const data = await response.json();
        setCidade(data.address.city_district);
      } catch (error) {
        console.error('Erro ao buscar nome da cidade:', error);
      }
    };

    buscarCidade();
  }, [coordenadas.coordenadas]);


  return (
    <MapContainer center={[coordenadas.coordenadas[0], coordenadas.coordenadas[1]]} zoom={10} style={{ height: '400px', width: '100%' }}>
      <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <CircleMarker center={[coordenadas.coordenadas[0], coordenadas.coordenadas[1]]} pathOptions={redOptions} radius={10}>
        <Popup>{cidade}</Popup>
      </CircleMarker>
    </MapContainer>
  );
}

export default MapCadastro;
