import { Col, Form, Input, Row, notification } from "antd";
import api from "../../services/api";
import MapCEP from "../mapaCEP";
import ReactInputMask from "react-input-mask";


export default function ManutencaoLocalizacao({ form, carregando, fecharDrawer, coordenadasCEP }) {

    async function incluirLocalizacao(record) {
        api.post("Localizacao", record).then(
            res => {
                var dados = res.data;
                if (!!dados.endereco) {
                    notification.success({ description: "Localização inserida com sucesso!", message: "Aviso" });
                    fecharDrawer(res.data);
                } else {
                    notification.warn({ description: "O endereço já existe.", message: "Aviso" });
                    //limpar as coordenadas se não ele vincula na tela anterior
                }
                form.setFieldsValue({ cep: "" });
                form.setFieldsValue({ endereco: "" });
                coordenadasCEP.setCoordenadasCEP([]);
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                carregando(false);
            }
        )
    };

    function atualizarLocalizacao(cep) {
        if (cep.length === 9) {
            cep = cep.replaceAll("-", "");
            api.get(`Localizacao/BuscarPorCEP?cep=${cep}`).then(
                res => {
                    var dados = res.data;
                    if (!!dados.endereco) {
                        const arrayDeValores = Object.keys(dados.posicaoMapa).map(chave => dados.posicaoMapa[chave]);

                        coordenadasCEP.setCoordenadasCEP(arrayDeValores);
                        form.setFieldsValue({ endereco: dados.endereco });
                    } else {
                        notification.warn({ description: "Nenhum endereço encontrado para este CEP.", message: "Aviso" });
                        form.setFieldsValue({ cep: "" });
                        form.setFieldsValue({ endereco: "" });
                        coordenadasCEP.setCoordenadasCEP([]);
                    }
                }
            ).catch(
                error => {
                    console.log(error);
                }
            ).finally(
                () => {
                    carregando(false);
                }
            )

        }
    };


    return (
        <Form layout="vertical" name="manutencaoLocal" form={form} onFinish={incluirLocalizacao}>
            <Row gutter={[8, 0]}>
                <Col span={24}>
                    Informe o local que você deseja cadastrar.
                </Col>

                <Form.Item hidden name="id"></Form.Item>

                <Col xs={24} sm={8} md={5} lg={5} xl={5}>
                    <Form.Item label="CEP" name="cep" rules={[{ required: true, message: "Favor informar um CEP" }]}>
                        {/*   <MaskedInput mask='11111-111' placeholder="Informe o CEP" />*/}
                        <ReactInputMask
                            mask="99999-999"
                            maskChar=""
                            placeholder="Informe o CEP"
                            onBlur={(e) => atualizarLocalizacao(e.target.value)}
                        >
                            {(inputProps) => <Input {...inputProps} />}
                        </ReactInputMask>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={16} md={19} lg={19} xl={19}>
                    <Form.Item disabled={true} label="Descrição" name="endereco" rules={[{ required: true, message: "Favor informar um endereço" }]}>
                        <Input disabled={true} placeholder="Informe a Descrição" />
                    </Form.Item>
                </Col>
                {coordenadasCEP.coordenadasCEP.length > 0 &&
                    <Col span={24}>
                        <MapCEP coordenadasCEP={coordenadasCEP} />
                    </Col>}
            </Row>
        </Form>
    );
}