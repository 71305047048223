import React, { useState } from "react";
import { Row, Col, Form, Button, Drawer, Modal } from "antd";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import Detalhes from "./detalhes";
import { useStateValue } from '../../state/stateProvider';
import { drawerActions, manutencaoActions } from '../../actions';

export default function DrawerDetalhes() {

    const [formularioArea] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();

    function fecharDrawer() {
        formularioArea.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer 
            width="40%"
            title="Manutenção Área"
            open={ui.showDrawer}
            onClose={() => { fecharDrawer() }}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={onClose} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                </Row>
            }>
            <Detalhes />
        </Drawer>
    );
}