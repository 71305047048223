import React, { useState } from "react";
import { Row, Col, Button, Drawer, Modal, Form } from "antd";
import { EditOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import Detalhe from "./detalhe";
import { drawerActions, manutencaoActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import DrawerAgenda from "../agendaCadastro/drawerAgenda";
import DetalheEmail from "../../pages/agendaEmail/detalheEmail";

export default function DrawerDetalhe({ openDrawerDetalhe, closeDrawerDetalhe }) {

    const [carregando, setCarregando] = useState(false);
    const [{ ui }, dispatch] = useStateValue();
    const [openAgenda, setOpenAgenda] = useState(false);
    const [possuiCobranca, setPossuiCobranca] = useState(false);
    const [form] = Form.useForm();

    function fecharDrawer() {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        closeDrawerDetalhe();
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer
            width="65%"
            title="Detalhes"
            open={openDrawerDetalhe}
            onClose={fecharDrawer}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={fecharDrawer} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<EditOutlined />} size="large" type="primary" loading={carregando} onClick={() => {
                            //dispatch({ type: drawerActions.CHANGE, data: { showDrawerAgenda: true} });
                            closeDrawerDetalhe();
                            setOpenAgenda(true);

                        }}>
                            Editar
                        </Button>
                    </Col>
                </Row>
            }>
            <Detalhe/>
            <DrawerAgenda openDrawerAgenda={openAgenda} closeDrawerAgenda={() => setOpenAgenda(false)} />
        </Drawer>
    );
}