import React, { useState } from "react";
import { Row, Col, Form, Button, Drawer, Modal } from "antd";
import { SaveOutlined, CloseOutlined, FileDoneOutlined, ExclamationCircleOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";

import { ManutencaoAgenda } from "./pages";
import { useStateValue } from '../../state/stateProvider';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';
import moment from 'moment';

export default function DrawerAgenda({openDrawerAgenda, closeDrawerAgenda}) {

    const [formularioAgenda] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [tagPages, setTagPages] = useState(null);
    const [proximaPag, setProximaPag] = useState(0);
    const [listaRecursos, setListaRecursos] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [listaCobranca, setListaCobranca] = useState([]);
    const [listaAtividade, setListaAtividade] = useState([]);
    const [listaTermoAceite, setListaTermoAceite] = useState([]);
    const [listaContato, setListaContato] = useState([]);


    function fecharDrawer() {
        closeDrawerAgenda();
        let data = new Date().getDate();
        formularioAgenda.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: listagemActions.CHANGE, data: { filtro: `&dataInicial=${moment(new Date().setHours(0, 0, 0, 0)).format('YYYY-MM-DD HH:mm')}&dataFinal=${moment(new Date(new Date().setDate((data + 15))).setHours(23, 59, 0, 0)).format('YYYY-MM-DD HH:mm')}`, ordem: '+DataInicial,Id' } });
        setTagPages(0);
        setProximaPag(null);
        setListaRecursos([]);
        setListaItens([]);
        setListaAtividade([]);
        setListaTermoAceite([]);
        setListaContato([]);
      
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    }

    return (
        <Drawer
            width="75%"
            title="Manutenção Agenda"
            open={openDrawerAgenda}
            onClose={onClose}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col align="middle" hidden>
                        <Button icon={<FileDoneOutlined />} htmlType="button">
                            Salvar em Rascunho
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={fecharDrawer} icon={<CloseOutlined />} size="large" htmlType="button">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button disabled={tagPages === 0} icon={<LeftOutlined />} size="large" onClick={() => setProximaPag(tagPages - 1)} type="primary">
                            Voltar
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" hidden={tagPages === 3} size="large" onClick={() => setProximaPag(tagPages + 1)}>
                            Avançar <RightOutlined />
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => formularioAgenda.submit()} hidden={tagPages === 0} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                            Salvar
                        </Button>
                    </Col>
                </Row>
            }>
            <ManutencaoAgenda openAgenda={openDrawerAgenda} form={formularioAgenda} carregando={setCarregando} aoSalvar={fecharDrawer} pages={{ tagPages, setTagPages }} proximaPag={{ proximaPag, setProximaPag }}
                listaRecursos={{ listaRecursos, setListaRecursos }} listaItens={{ listaItens, setListaItens }} listaCobranca={{ listaCobranca, setListaCobranca}} listaAtividade={{ listaAtividade, setListaAtividade }} listaTermoAceite={{ listaTermoAceite, setListaTermoAceite }} listaContato={{ listaContato, setListaContato }} />
        </Drawer>
    );
}