import React, { useEffect } from "react";
import { Row, Col, Form, Button, notification, Modal, Popover, Tag, Radio, Checkbox, Input } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";

import DrawerUsuario from "./drawer";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbPage, Search, TabelaDados } from "../../components";
import api from "../../services/api";

export default function Usuario() {

    const [formUsuario] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        aplicaFiltros();
    }, []);

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o usuário: ${dados.nome}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados.id);
            }
        });
    };

    function excluirRegistro(id) {
        api.delete(`/Usuarios/${id}`).then((res) => {
            if (res.status === 200) notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" });
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        }, erro => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
        }).finally(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '%2BNome' } }));
    };

    function aplicaFiltros() {
        let outrosFiltros = '';
        outrosFiltros += (!!formUsuario.getFieldsValue().pesquisar ? `&filtro=${formUsuario.getFieldsValue().pesquisar}` : '');
        outrosFiltros += (formUsuario.getFieldsValue()?.status !== null ? `&Ativo=${formUsuario.getFieldsValue()?.status}` : '');
        outrosFiltros += (!!formUsuario.getFieldsValue().sac ? `&Sac=${formUsuario.getFieldsValue().sac}` : '');
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: outrosFiltros, ordem: '%2BNome' } });
    };

    return (
        <div className="col-paginas">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col>
                    <BreadcrumbPage pagina="Usuários" />
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={() => {
                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                    }}>
                        Adicionar
                    </Button>
                </Col>
            </Row>

            <Form layout="vertical" form={formUsuario} name="usuario" onFinish={aplicaFiltros}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={11} lg={12} xl={13} xxl={14}>
                        <Form.Item label="Pesquisar por" name="pesquisar">
                            <Input.Search placeholder="Pesquisar por..." onSearch={aplicaFiltros} allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={8} xl={3}>
                        <Form.Item label="Selecione Status do Usuário" initialValue={true} name="status">
                            <Radio.Group onChange={aplicaFiltros}>
                                <Radio value={''}>Todos</Radio>
                                <Radio value={true}>Ativo</Radio>
                                <Radio value={false}>Inativo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={8} xl={3} className="m-t-21">
                        <Form.Item valuePropName="checked" name="sac" initialValue={true} onChange={aplicaFiltros}>
                            <Checkbox >Apenas usuários SacG</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url="/Usuarios" ordem="nome" condicao={true} colunas={[
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ ativo, id }) => (
                                <Popover content={ativo ? 'Ativo' : 'Inativo'} placement="right">
                                    <Tag color={ativo ? 'processing' : 'default'} className="tag-w-100">
                                        <b>{id}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Nome',
                            render: ({ nome }) => (
                                <div>
                                    <b>{nome} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'E-mail',
                            render: ({ email }) => (
                                <div>
                                    <b> {email || '-'} </b>
                                </div>
                            ),
                        },
                        /* {
                             title: 'Supervisor',
                             render: ({ usuarioSupervisor }) => (
                                 <div>
                                     <b> {usuarioSupervisor?.nome || '-'} </b>
                                 </div>
                             ),
                         },*/
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                let dados = { ...record };
                                                dados.senha = '';
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} />
                                        </Col>
                                        {/* <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => modalExcluir(record)} />
                                        </Col> */}
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
                </div>
            </Form>
            <DrawerUsuario />
        </div>
    );
}