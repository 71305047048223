import { getEmpresaLogada, getLocalLogado, getToken } from "./auth";
import { Buffer } from 'buffer';
import { notification } from "antd";
import moment from 'moment';
import api from "./api";

export const removerCarcaterEspecial = (valor) => {
    valor = valor?.replaceAll(/[^a-zA-Z 0-9]+/g, '');
    return valor;
};

export const encodeBase64 = (data) => {
    return Buffer.from(data).toString('base64');
}
export const decodeBase64 = (data, type = 'ascii') => {
    return Buffer.from(data, 'base64').toString(type);;
}

export const parseToken = () => {
    var base64Url = getToken().split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(decodeBase64(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const isNotNullOrEmpty = (registro) => {
    let retorno = false;
    if (!!registro || registro === 0) {
        retorno = true;
    }
    return retorno;
};

export function compararDatas(dataInicial, dataFinal, operacao = '>=') {
    dataFinal = (!!dataFinal) ? dataFinal : new Date();
    dataInicial = new Date(dataInicial);
    dataFinal = new Date(dataFinal);
    dataInicial.setHours(0, 0, 0, 0);
    dataFinal.setHours(0, 0, 0, 0);
    dataInicial = dataInicial.getTime();
    dataFinal = dataFinal.getTime();
    let validacao = false;
    switch (operacao) {
        case '=':
            validacao = dataInicial === dataFinal;
            break;
        case '>':
            validacao = dataInicial > dataFinal;
            break;
        case '<':
            validacao = dataInicial < dataFinal;
            break;
        case '>=':
            validacao = dataInicial >= dataFinal;
            break;
        case '<=':
            validacao = dataInicial <= dataFinal;
            break;
    }

    return validacao;
}

export const validaForm = (formulario, listaValidacoes) => {
    let retorno = false;
    if (!!formulario && !!listaValidacoes) {
        let formObj = formulario.getFieldValue();
        let msgRetorno = [];
        listaValidacoes.forEach((validacao) => {
            if (validacao.obrigatorio && (!!formObj[validacao.nome] === false && formObj[validacao.nome] !== 0)) {
                msgRetorno.push('O campo ' + validacao.label + ' é obrigatório!');
            }
        });
        if (msgRetorno.length > 0) {
            msgRetorno.forEach((msg) =>
                notification.warning({ message: 'Aviso', description: msg })
            );
        } else {
            retorno = true;
        }
    }
    return retorno;
};

export const undefinedParaNull = (objeto) => {
    for (let dado in objeto) {
        if (!!!objeto[dado] && objeto[dado] == undefined) {
            objeto[dado] = null;
        }
    }
    return objeto;
};

export async function converterDatasParaMomentLista(lista, objEspecifico) {
    let novaLista = [];
    if (!!lista && lista.length > 0) {
        for (let obj of lista) {
            if (!!objEspecifico) {
                novaLista.push(converterDatasParaMoment(obj[objEspecifico]));
            } else {
                novaLista.push(converterDatasParaMoment(obj));
            }
        }
        return novaLista;
    }
};

export function converterDatasParaMoment(objeto) {
    let retorno = { ...objeto };
    for (let item in objeto) {
        if (!!objeto[item]) {
            if (typeof objeto[item] == "string" && moment(objeto[item]).isValid()) {
                retorno[item] = moment(objeto[item]);
            }
        }
    }
    return retorno;
};

export function isObjetoDiffVazio(obj = {}) {
    let valido = false;

    if (typeof obj === 'object') {
        if (!!obj && JSON.stringify(obj) !== '{}') {
            valido = true;
        }
    }
    return valido;
};

export const imprimirRelatorioJasper = (idRelatorio, parametros = {}) => {
    api.get(`RelatorioJasper/${idRelatorio}`).then(
        res => {
            let dadosRel = res.data;
            let filtro = [];
            let params = [];
            dadosRel.relatorioParametro.forEach((param) => {
                if (!!!parametros[param.nomeElemento]) {
                    if (!!param.obrigatorio) {
                        notification.warn({ message: "Parâmetro não preenchido!", description: `O parâmetro ${param.label} não foi preenchido!` });
                        return;
                    }
                } else {
                    params.push({ "nome": param.nomeElemento, "valor": parametros[param.nomeElemento] });
                    filtro.push(`${param.label}: ${parametros[param.nomeElemento]}`);
                }
            });
            params.push({ "nome": "filtros", "valor": filtro.join(', ') });
            let dados = {};
            dados.pathRelatorio = dadosRel.nomeArquivo;
            dados.parametros = JSON.stringify(params).replaceAll("\"", "'");
            dados.tipo = "PDF";
            dados.nomeRelatorio = dadosRel.descricao;
            api.post(`RelatorioJasper/RetornaDadosRelatorios`, dados).then(
                res => {
                    let byteCharacters = decodeBase64(res.data, 'latin1');
                    let byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    let byteArray = new Uint8Array(byteNumbers);
                    let file = new Blob([byteArray], { type: 'application/pdf;base64' });
                    let fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
            ).catch(
                erro => {
                    console.error(erro);
                }
            );
        }
    ).catch(
        erro =>
            console.error(erro)
    );



};
export const downloadRelatorioJasper = (relatorioId, parametros = {}, tipo, nomeRelatorio) => {
    api.get(`RelatorioJasper/${relatorioId}`).then(
        retorno => {
            let obj = { "pathRelatorio": retorno.data.nomeArquivo, "parametros": JSON.stringify(parametros), "tipo": tipo, "nomeRelatorio": nomeRelatorio }
            api.post(`RelatorioJasper/RetornaDadosRelatorios`, obj).then(
                res => {
                    var link = document.createElement('a');
                    link.href = "data:application/octet-stream;base64," + res.data;
                    link.download = nomeRelatorio + "." + tipo;

                    var clickEvent = new MouseEvent("click", {
                        "view": window,
                        "bubbles": true,
                        "cancelable": false
                    });
                    link.dispatchEvent(clickEvent);
                }
            ).catch(
                erro => {
                    console.error(erro);
                    alert('Erro ao gerar Relatório: ' + erro);
                }
            )
        }
    ).catch(
        erro => {
            console.log(erro);
        }
    )
};

export const AssinarRelatorio = async (relatorioId, parametros = {}, tipo, nomeRelatorio, signatures, IdOrdemServico, tipoAnexo, values, nomes, emails) => {
    return new Promise((resolve) => {
        api.get(`RelatorioJasper/${relatorioId}`).then(
            retorno => {
                let obj = { "pathRelatorio": retorno.data.nomeArquivo, "parametros": JSON.stringify(parametros), "tipo": tipo, "nomeRelatorio": nomeRelatorio, "signatures": JSON.stringify(signatures), "IdOrdemServico": IdOrdemServico, "TipoAnexo": tipoAnexo, "TermoAceite": values, "Nomes": nomes, "Emails": emails }
                api.post(`Ged/UploadRelatorio`, obj).then(
                    res => {
                        resolve();
                    }
                )
            }
        )
    })
};

