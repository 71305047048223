import React, { useState } from "react";
import { Row, Col, Form, Button, notification, Modal, Input } from "antd";
import { EditOutlined, DeleteOutlined, EnvironmentOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import DrawerHotel from "./drawer";
import { drawerActions, manutencaoActions, listagemActions } from "../../actions";
import { ModalMapa } from "../../components/modals";
import { useStateValue } from '../../state/stateProvider';
import { FormatFone, FormatNumber } from "../../valueObjects";
import { BreadcrumbIncluirDados, Search, TabelaDados } from "../../components";
import api from '../../services/api';

export default function Hotel() {

    const [formHotel] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [openModalMapa, setOpenModalMapa] = useState(false);

    function excluirRegistro(id) {
        api.delete(`/Hotel/${id}`).then((res) => {
            notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" });
        }).catch(erro => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
        }).finally(() => {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+Descricao' } })
        });
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    return (
        <div className="col-paginas">
            <BreadcrumbIncluirDados pagina="Hotel" />
            <Form layout="vertical" form={formHotel} name="hotel">
                <Row gutter={[8, 0]}>
                    <Col span={18}>
                        <Search campoFiltro="descricao" />
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Cidade" name="cidade">
                            <Input placeholder="Informe a Cidade" />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url="/Hotel" colunas={[
                        {
                            title: 'Nome',
                            render: ({ nome }) => (
                                <div>
                                    <b>{nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Tipo',
                            width: 115,
                            render: ({ tipo }) => (
                                <div>
                                    <b>{tipo || 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Telefone',
                            width: 110,
                            render: ({ telefone }) => (
                                <div>
                                    <b>{FormatFone(telefone) || 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Cidade',
                            render: ({ cidade }) => (
                                <div>
                                    <b>{cidade || 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor Standard (R$)',
                            align: 'right',
                            width: 130,
                            render: ({ valor_standard }) => (
                                <div>
                                    <b>{FormatNumber(!!valor_standard ? valor_standard : 0, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor Duplo (R$)',
                            align: 'right',
                            width: 130,
                            render: ({ valor_duplo }) => (
                                <div>
                                    <b>{FormatNumber(!!valor_duplo ? valor_duplo : 0, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor Triplo (R$)',
                            align: 'right',
                            width: 130,
                            render: ({ valor_triplo }) => (
                                <div>
                                    <b>{FormatNumber(!!valor_triplo ? valor_triplo : 0, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 98,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EnvironmentOutlined />} onClick={() => setOpenModalMapa(true)} />
                                        </Col>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => { modalExcluir(record.id) }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
                </div>
            </Form>
            <ModalMapa exibirModalMapa={openModalMapa} fecharModalMapa={() => setOpenModalMapa(false)} />
            <DrawerHotel />
        </div>
    );
}