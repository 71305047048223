import React, { useEffect, useState } from "react";
import { Row, Col, Button, Typography, Divider, Descriptions, Form, Checkbox, Input } from "antd";
import { PrinterOutlined } from "@ant-design/icons";

export default function OrdemServico({ form }) {


    return (
        <>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={5}>
                        <img src={require("../../assets/i_ordem.png").default} alt="Ordem de Serviço" /> Ordem de Serviço
                    </Typography.Title>
                    <Divider orientation="left" plain className="m-t-0 m-b-5">
                        Dados referentes a ordem de serviço.
                    </Divider>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="m-t-8">
                <Col>
                    <Form.Item hidden={!form.getFieldValue()?.numeroOS} label="Ordem de Serviço" name="numeroOS">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Observação" name="observacaoOS">
                        <Input.TextArea disabled={!!form.getFieldValue()?.numeroOS} placeholder="Informe uma Observação" />
                    </Form.Item>
                </Col>
            </Row>
            <Row align="middle" gutter={[8, 8]}>
                <Col>
                    <Row>
                        <Col>
                            <Form.Item valuePropName="checked" name="possui_cobranca" initialValue={false}>
                                <Checkbox> Possui Cobrança </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}