import React from "react";
import { Row, Col, Modal, Descriptions } from "antd";
import moment from "moment";

export default function ModalDetalheReserva({ exibirModalDetalheReserva, fecharModalDetalheReserva, detalhes }) {

    const onCloseModalDetalheReserva = () => {
        fecharModalDetalheReserva();
    };

    return (
        <Modal centered
            title="Detalhes"
            open={exibirModalDetalheReserva}
            onCancel={onCloseModalDetalheReserva}
            maskClosable={false}
            destroyOnClose
            footer={false}
        >   <div className="divDetalhes" >
                <Row className="reserva-localizacao">
                    <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Recurso">
                                <b> {detalhes?.recurso}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Usuário">
                                <b> {detalhes?.usuario?.nome}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Tipo de Agenda">
                                <b> {detalhes?.agendaTipo?.descricao}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Título">
                                <b>{detalhes?.titulo}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions column={2} size="small">
                            <Descriptions.Item label="Data Inicial">
                                <b> {moment(detalhes?.dataInicial).format('DD/MM/YYYY HH:mm:ss')}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Data Final">
                                <b> {moment(detalhes?.dataFinal).format('DD/MM/YYYY HH:mm:ss')}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Local">
                                <b> {detalhes?.local?.endereco}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}