import React from "react";
import { Row, Col, Form, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import DrawerDetalhe from "./drawer";
import { drawerActions, manutencaoActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbPage, Search, TabelaDados } from "../../components";

export default function Parceiros() {

    const [, dispatch] = useStateValue();
    const [formParceiro] = Form.useForm();

    return (
        <div className="col-paginas m-t-2">
            <BreadcrumbPage pagina="Parceiros" />
            <Form layout="vertical" form={formParceiro} name="parceiro" className="m-t-2">
                <Row>
                    <Col span={24}>
                        <Search campoFiltro="nome" />
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url="/ParceiroComercial" colunas={[
                        {
                            title: 'Nome',
                            render: ({ pessoa }) => (
                                <div>
                                    <b> {pessoa.nome || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Site',
                            render: ({ site }) => (
                                <div>
                                    {!!site ?
                                        <a href={site}> {site} </a> : '-'
                                    }
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EyeOutlined />} onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawerExtra: true } });
                                            }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
                </div>
            </Form>
            <DrawerDetalhe />
        </div>
    );
}