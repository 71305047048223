import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button, Typography, Form, Divider, Modal, notification} from "antd";
import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { drawerActions } from "../../actions";
import DrawerRecurso from "./drawerRecurso";
import { useStateValue } from '../../state/stateProvider';
import api from "../../services/api";

export default function Recursos({ form, listaRecursos, desabilitarData, coordenadas}) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    function modalExcluir(dados) {  
        Modal.confirm({
            title: 'Deseja deletar este registro?',
            icon: <ExclamationCircleOutlined />,
            async onOk() {
                let lista = [...listaRecursos.listaRecursos];
                lista = lista.filter((recurso) => recurso !== dados);
                listaRecursos.setListaRecursos(lista);
                desabilitarData.setDesabilitarData(lista.length > 0);
                if (dados.id){
                    let req = await api.delete(`AgendaRecurso/${dados.id}`);
                    if (req.status === 200 && !!req.data) {
                        return req.data;
                  
                    }
                    return {};
                }
                //aoSalvar();
            }
        });
    }

    function abrirDrawerRecurso(){
        if(!!form.getFieldValue().dataInicial && !!form.getFieldValue().dataFinal){
            dispatch({ type: drawerActions.CHANGE, data: { ...ui, showDrawerRecurso: true } });
        } else{
            notification.warn({ description: "Favor informar uma data inicial e final para a agenda!", message: "Aviso" });
        }
    }

    return (
        <>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={5}>
                        <img src={require("../../assets/i_recursos.png").default} alt="Recursos" /> Recursos
                    </Typography.Title>
                    <Divider orientation="left" plain className="m-t-0 m-b-5">
                        Informe o(s) recurso(s) que você deseja reservar.
                    </Divider>
                </Col>
                <Col>
                    <div className="i-Position">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={abrirDrawerRecurso} />
                    </div>
                </Col>
            </Row>
            <div className="tabela-pages">
                <Table columns={[
                    {
                        title: 'Recurso',
                        render: ({ recurso }) => (
                            <div>
                                <b>
                                    {recurso?.descricao}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        width: 65,
                        align: 'center',
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => { modalExcluir(record) }} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]}
                    locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }} scroll={{ x: 920 }} dataSource={listaRecursos.listaRecursos}
                />
            </div>
            <DrawerRecurso form={form} listaRecursos={listaRecursos} coordenadas={coordenadas}/>
        </>
    );
}