import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Input, InputNumber, DatePicker, message, notification } from "antd";
import moment from "moment";

import { InputPreco, SelectPaginado } from "../../components";
import api from "../../services/api";
import { useStateValue } from "../../state/stateProvider";
import { selectPaginadoActions } from "../../actions";

export default function ManutencaoItemCobranca({ form, listaCobranca, pessoaId, aoFechar }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();

    async function salvarManutencao(record) {
        let lista = [];
        record.valorUnitario = parseFloat(record.valorUnitario);
        if (record.contratoId) {
            record.contrato = await buscarContrato(record.contratoId);
        }

        record.produto = await buscarServico(record.produtoId);
        lista.push(record);

        let listaCobrar = [...listaCobranca.listaCobranca];

        listaCobrar.forEach(item => {
            if (item.sequencia != record.sequencia) {
                lista.push(item);
            }
        });

        lista = lista.sort(ordenarPorSequencia);
        listaCobranca.setListaCobranca(lista);
        aoFechar();
    }

    function ordenarPorSequencia(a, b) {
        return a.sequencia - b.sequencia;
    }

    useEffect(() => {
        if (!!manutencao.dadosCobranca?.id) {
            let lista = [];
            if (!!manutencao.dadosCobranca?.produtoId) {
                lista.push({ name: "produtoId", campo: "Id", value: manutencao.dadosCobranca.produtoId });
            }
            if (!!manutencao.dadosCobranca?.contratoId) {
                lista.push({ name: "contratoId", campo: "FiltroIdContrato", value: manutencao.dadosCobranca.contratoId });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: lista });
        } else {
            let lista = [];
            if (!!pessoaId.pessoaId) {
                lista.push({ name: "contratoId", campo: "PessoaId", value: pessoaId.pessoaId });
            }
            if (!!manutencao.dadosCobranca?.produtoId) {
                lista.push({ name: "produtoId", campo: "Id", value: manutencao.dadosCobranca.produtoId });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: lista })
        }
    }, [ui.showDrawerExtra]);



    function recalculaValorTotal() {
        let qtd = parseFloat(form.getFieldValue().quantidade);
        let valUn = parseFloat(form.getFieldValue().valorUnitario);
        let valorTotal = qtd * valUn;
        form.setFieldsValue({ valorTotal: valorTotal });
    };

    async function buscarContrato(conId) {
        let req = await api.get(`Contrato/${conId}`);
        if (req.status === 200 && !!req.data) {
            return req.data;
        }
        return {};
    };

    async function buscarServico(proId) {
        let req = await api.get(`Produto/${proId}`);
        if (req.status === 200 && !!req.data) {
            return req.data;
        }
        return {};
    };

    return (
        <Form layout="vertical" name="manutencaoItemCobranca" form={form} onFinish={salvarManutencao}>
            <Row>
                <Col span={24}>
                    Preencha as informações abaixo para realizar seu cadastro.
                </Col>
            </Row>
            <Row gutter={[8, 0]} className="m-t-8">
                <Form.Item name="id" hidden></Form.Item>
                <Form.Item name="ordemServicoId" hidden></Form.Item>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item label="Sequência" name="sequencia" rules={[{ message: "Informe a sequência da cobrança", required: true }]}>
                        <InputNumber placeholder="Informe a Sequência" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item label="Serviço" name="produtoId" rules={[{ message: "Informe o serviço", required: true }]}>
                        <SelectPaginado url="Produto?Servico=true" campoFiltro="buscarProduto" placeholder="Selecione o Serviço" name="produtoId" form={form} conteudo={
                            ser => (<Select.Option key={ser.id} value={ser.id}>{ser.codigo} - {ser.descricao}</Select.Option>)
                        } />
                        {/*<SelectPaginado url={"Contrato?Ativo=true&PessoaId=" + pessoaId.pessoaId} placeholder="Selecione o Contrato" name="contratoId" form={form} conteudo={
                            contrato => {
                                contrato.contratoItem?.map(p =>
                                    <Select.Option key={p.produto.id} value={p.produto.id}>{p.produto.descricao} - </Select.Option>
                                )
                            }
                        } />*/}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                    <Form.Item label="Contrato" name="contratoId">
                        <SelectPaginado url={"Contrato?Ativo=true&PessoaId=" + pessoaId.pessoaId} placeholder="Selecione o Contrato" name="contratoId" form={form} conteudo={
                            contrato => (<Select.Option key={contrato.id} value={contrato.id}>{contrato.numeroContrato} - {contrato.contratoItem?.map(p => p.produto.descricao).join(',')}</Select.Option>)
                        } />
                    </Form.Item>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Item label="Quantidade" name="quantidade" rules={[{ message: "Informe a quantidade", required: true }]}>
                        <InputNumber placeholder="Informe a Quantidade" onChange={() => { recalculaValorTotal() }} />
                    </Form.Item>
                </Col>
                <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                    <InputPreco placeholder="Informe o Valor Unitário" label="Valor Unitário" name="valorUnitario" onChange={() => { recalculaValorTotal() }} rules={[{ message: "Informe o valor unitário", required: true }]} />
                </Col>
                <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                    <InputPreco placeholder="Informe o Valor Total" label="Valor Total" name="valorTotal" disabled={true} rules={[{ message: "Informe o valor total", required: true }]} />
                </Col>
                <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item label="Mês de Cobrança" name="anoMesCobranca" rules={[{
                        message: "Informe o mês de cobrança", required: true,
                    },
                    ({ setFieldsValue }) => ({

                        validator(_, value) {
                            if (parseInt(moment(value).format("YYYYMM")) < parseInt(moment(new Date()).format("YYYYMM"))) {
                                setFieldsValue({ "anoMesCobranca": null });
                                notification.warn({ description: "Mês informado não pode ser anterior ao mês atual", message: "Aviso" });
                            }
                            return Promise.resolve();
                        },
                    }),
                    ]}>
                        <DatePicker format="MM/YYYY" placeholder="MM/YYYY" picker="month" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Observação" name="observacao">
                        <Input.TextArea style={{
                            height: 80,
                        }} placeholder="Observação" />
                    </Form.Item>
                </Col>
            </Row>
        </Form >
    );
}