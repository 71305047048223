import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, Typography } from 'antd';
import { SendOutlined, CloseOutlined, UserOutlined, UnlockFilled } from '@ant-design/icons';

export default function ClienteParceiro({ onAcessar, formulario }) {
    
    const [ carregando, setCarregando ] = useState(false);

    return (
        <Form layout="vertical" form={formulario} name="clienteParceiro">
             <Row className="m-t-12">
                <Col span={24}>
                    <Typography.Text>
                        Utilize seu usuário e senha do SAC ABASE.
                    </Typography.Text>
                </Col>
            </Row>
            <Row gutter={[0, 8]}>
                <Col span={24} className="m-t-12">
                    <Form.Item placeholder="Usuário" name="usuario" rules={[{ required: true, message: 'Necessário informar o usuário!' }]}>
                        <Input prefix={<UserOutlined />}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item placeholder="Informe sua senha" name="senha" rules={[{ required: true, message: 'Informe sua senha de acesso!'}]} >
                        <Input.Password prefix={<UnlockFilled />} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Row justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button icon={<CloseOutlined />} onClick={() => onAcessar()} size="middle">
                                Cancelar 
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" htmlType="submit" loading={carregando} onClick={() => formulario.submit()} size="middle">
                                Acessar <SendOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}