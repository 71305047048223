import React, { useEffect, useState } from "react";
import { Row, Col, Form } from 'antd';

import { SelectIncluir } from "../../components";

export default function Local({ formulario }) {

    return (
        <Form layout="vertical" form={formulario}>
            <Row align="middle" gutter={[0, 8]}>
                <Col span={24}>
                    <Form.Item>
                        <SelectIncluir 
                            campoDescricao={'pes_nome'}
                            campoValue={'pes_id'}
                            campokey={'cli_id'}
                            placeHolder={'Selecione o Local'}
                            labelFormItem={'Local'}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    MAPA
                </Col>
            </Row>
        </Form>
    );
}