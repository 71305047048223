import React, { useState } from "react";
import { Row, Col, Form, Button, Select, Table } from "antd";
import { EditOutlined, DeleteOutlined, PrinterOutlined } from "@ant-design/icons";
import moment from 'moment';

import DrawerTermodeAceite from "./drawer";
import { drawerActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbIncluirDados, Search, TabelaDados } from "../../components";

export default function TermoDeAceite() {

    const [formTermoAceite] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();    
    const [listaAgenda, setListaAgenda] = useState([]);
    const [carregando, setCarregando] = useState(false);

    for (let i = 0; i < 10; i++) {
        listaAgenda.push({
            key: i,
            descricao: 'Ação Social',
            data: '11012021', 
            cliente: 'PM Redentora',
            empresa: '',
            modelo: 'Termo de Aceite Abase',
            ordem_servico: '',
            usuario: '',
        });
    }

    return (
        <div className="col-paginas">
            <BreadcrumbIncluirDados />
            <Form layout="vertical" form={formTermoAceite} name="termo_aceite">
                <Row gutter={[8, 0]}>
                    <Col span={12}>
                        <Search />
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Usuário">
                            <Select placeholder="Selecione um Colaborador" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Cliente">
                            <Select placeholder="Selecione um Cliente" />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="tabela-pages m-t-16">
                    <Table loading={carregando} columns={[                      
                        {
                            title: 'Nome do Sistema',
                            width: 160,
                            render: ({ descricao }) => (
                                <div>
                                    <b>{descricao} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Data',
                            width: 120,
                            render: ({ data }) => (
                                <div>
                                    <b>
                                        {moment(data).format('DD/MM/YYYY HH:mm')}
                                    </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Cliente',
                            render: ({ cliente }) => (
                                <div>
                                    <b> {cliente || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Empresa',
                            render: ({ empresa }) => (
                                <div>
                                    <b> {empresa || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Modelo de Termo de Aceite',
                            width: 175,
                            render: ({ modelo }) => (
                                <div>
                                    <b>
                                        {modelo}
                                    </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ordem de Serviço',
                            render: ({ ordem_servico }) => (
                                <div>
                                    <b> {ordem_servico || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Usuário',
                            width: 152,
                            render: ({ usuario }) => (
                                <div>
                                    <b> {usuario || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 98,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 5]}>
                                        <Col>
                                            <Button icon={<PrinterOutlined />} />
                                        </Col>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true} });
                                            }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} scroll={{ x: 920 }} dataSource={listaAgenda}/>            
                </div>
            </Form>
            <DrawerTermodeAceite />     
        </div>
    );
}