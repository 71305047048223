import React, { useState } from "react";
import { Row, Col, Form, Modal, Select, Radio, Input, DatePicker } from "antd";
import { ExclamationCircleOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import MaskedInput from 'antd-mask-input';

export default function ModalContas({form, exibirModalContas, fecharModalContas}){

    const [tipopessoa, setTipopessoa] = useState(1);

    const onCloseModalContas = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                fecharModalContas();
            }
        });
    };

    return(
        <Modal centered
            title="Contas"
            open={exibirModalContas}
            onCancel={onCloseModalContas}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
            destroyOnClose
            width={700}
        >
            <Form layout="vertical" name="manutencaoContas" form={form}>
                <Row>
                    <Col span={24}>
                        Preencha as informações abaixo para realizar seu cadastro.
                    </Col> 
                </Row>
                <Row gutter={[8,0]} className="m-t-8">
                    <Col span={24}>
                        <Form.Item label="Empresa">
                            <Select allowClear placeholder="Selecione a Empresa" />
                        </Form.Item>
                    </Col>   
                    <Col span={12}>
                        <Form.Item label="Tipo de Pessoa">
                            <Radio.Group defaultValue={0} onChange={(e) => setTipopessoa(e.target.value)} value={tipopessoa}>
                                <Radio value={1}>Física</Radio>
                                <Radio value={2}>Jurídica</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    {tipopessoa === 1 &&     
                        <Col span={12}>
                            <Form.Item label="CPF" name="cpf" rules={[{ required: true, message: 'Informe o CPF' }]}>
                                <MaskedInput mask="111.111.111-11" placeholder="Informe o CPF" />
                            </Form.Item>
                        </Col>  
                    }
                    {tipopessoa === 2 &&     
                        <Col span={12}>
                            <Form.Item label="CNPJ" name="cnpj" rules={[{ required: true, message: 'Informe o CNPJ' }]}>
                                <MaskedInput mask="11.111.111/1111-11" placeholder="Informe o CNPJ" />
                            </Form.Item>
                        </Col> 
                    }
                </Row>
                {tipopessoa === 1 &&     
                    <Row>
                        <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                            <Form.Item label="Nome" name="nome" rules={[{ required: true, message: 'Informe o Nome' }]}>
                                <Input placeholder="Informe o Nome" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Apelido">
                                <Input placeholder="Informe o Apelido" />
                            </Form.Item>
                        </Col>
                    </Row>
                }
                {tipopessoa === 2 &&     
                    <Row gutter={[8,0]}>
                         <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Razão Social" name="razao_social" rules={[{ required: true, message: 'Informe a Razão Social' }]}>
                                <Input placeholder="Informe a Razão Social" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Nome Fantasia">
                                <Input placeholder="Informe o Nome Fantasia" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Inscrição Estadual">
                                <Input placeholder="Informe o Inscrição Estadual" />
                            </Form.Item>
                        </Col>
                    </Row>
                }
                <Row gutter={[8,0]}>
                    <Col xs={24} sm={16} md={16} lg={12} xl={12}>
                        <Form.Item label="E-mail">
                            <Input placeholder="Informe o E-mail" />
                        </Form.Item>
                    </Col> 
                    <Col xs={24} sm={8} md={8} lg={6} xl={6}>
                        <Form.Item label="Telefone">
                            <MaskedInput mask="(11) 11111-1111"  placeholder="Informe o Telefone" />
                        </Form.Item>
                    </Col> 
                    <Col xs={24} sm={8} md={12} lg={6} xl={6}>
                        <Form.Item label="Data">
                            <DatePicker format={"DD/MM/YYYY"} placeholder="DD/MM/YYYY" />
                        </Form.Item>
                    </Col> 
                    <Col xs={24} sm={8} md={12} lg={7} xl={7}>
                        <Form.Item label="CEP">
                            <MaskedInput mask="11111-111" placeholder="Informe o CEP" />
                        </Form.Item>
                    </Col> 
                    <Col xs={24} sm={8} md={12} lg={9} xl={9}>
                        <Form.Item label="Cidade">
                            <Input placeholder="Informe a Cidade" />
                        </Form.Item>
                    </Col> 
                    <Col xs={24} sm={8} md={12} lg={8} xl={8}>
                        <Form.Item label="Estado">
                            <Select allowClear placeholder="Informe o Estado" />
                        </Form.Item>
                    </Col>  
                    <Col xs={24} sm={8} md={17} lg={18} xl={18}>
                        <Form.Item label="Logradouro">
                            <Input placeholder="Informe o Logradouro" />
                        </Form.Item>
                    </Col> 
                    <Col xs={24} sm={8} md={7} lg={6} xl={6}>
                        <Form.Item label="Número">
                            <Input placeholder="Informe o Número" />
                        </Form.Item>
                    </Col>  
                    <Col xs={24} sm={12} md={12} lg={12} xl={9}>
                        <Form.Item label="Bairro">
                            <Input placeholder="Informe o Bairro" />
                        </Form.Item>
                    </Col>  
                    <Col xs={24} sm={8} md={12} lg={12} xl={9}>
                        <Form.Item label="Complemento">
                            <Input placeholder="Informe o Complemento" />
                        </Form.Item>
                    </Col> 
                    <Col span={24}>
                        <Form.Item label="Observação">
                            <Input.TextArea placeholder="Informe uma Observação" />
                        </Form.Item>
                    </Col> 
                </Row>
            </Form>
        </Modal>
    );
}