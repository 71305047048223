import React, { useState } from "react";
import { Row, Col, Form, Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import DrawerTermoAceite from "./drawer";
import { drawerActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbIncluirDados, Search, TabelaDados } from "../../components";

export default function ModeloTermoAceite() {

    const [formTermoAceite] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();    

    return (
        <div className="col-paginas">
            <BreadcrumbIncluirDados />
            <Form layout="vertical" form={formTermoAceite} name="termo_aceite">
                <Row>
                    <Col span={24}>
                        <Search />
                    </Col>
                </Row>
                <div className="tabela-pages m-t-16">
                    <TabelaDados url="" colunas={[                      
                        {
                            title: 'Descrição',
                            render: ({ descricao }) => (
                                <div>
                                    <b>{descricao} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true} });
                                            }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />            
                </div>
            </Form>
            <DrawerTermoAceite />     
        </div>
    );
}