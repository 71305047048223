import React, { useState } from "react";
import { Row, Col, Form, Button, DatePicker, Select } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from 'moment';

import DrawerTipoRequisicao from "./drawer";
import { drawerActions } from "../../actions";
import { useStateValue } from '../../state/stateProvider';
import { BreadcrumbIncluirDados, Search, TabelaDados } from "../../components";

export default function Requisicao() {

    const [formRequisicao] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();

    return (
        <div className="col-paginas">
            <BreadcrumbIncluirDados />
            <Form layout="vertical" form={formRequisicao} name="usuario_local">
                <Row gutter={[8,0]}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Search />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={10} xl={8}>
                        <Form.Item label="Status" name="status">
                            <Select mode="multiple" allowClear placeholder="Selecione um Status">
                                <Select.Option value="0">
                                    Pendente
                                </Select.Option>
                                <Select.Option value="1">
                                    Concluído
                                </Select.Option>
                                <Select.Option value="2">
                                    Cancelado
                                </Select.Option>
                                <Select.Option value="3">
                                    Adiado
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={3} xl={4}>
                        <Form.Item label="Data Inicial" name="dataInicial">
                            <DatePicker showTime placeholder="DD/MM/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={3} xl={4}>
                        <Form.Item label="Data Final" name="dataFinal">
                            <DatePicker showTime placeholder="DD/MM/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="tabela-pages">
                    <TabelaDados url="" colunas={[                      
                        {
                            title: 'Status',
                            render: ({ status }) => (
                                <div>
                                   <b>{status} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Dados Do Formulário',
                            render: ({ dadosFormulario }) => (
                                <div>
                                    <b> {dadosFormulario || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Título da Agenda',
                            render: ({ titulo }) => (
                                <div>
                                    <b> {titulo || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Requisição',
                            render: ({ requisicao }) => (
                                <div>
                                    <b> { requisicao || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Usuário',
                            render: ({ usuarios }) => (
                                <div>
                                    <b> { usuarios || '-'} </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Data Inicial',
                            width: 148,
                            render: ({ dataInicial }) => (
                                <div>
                                    <b>
                                        {moment(dataInicial).format('DD/MM/YYYY HH:mm:ss')}
                                    </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Data Final',
                            width: 148,
                            render: ({ dataFinal }) => (
                                <div>
                                    <b>
                                        {moment(dataFinal).format('DD/MM/YYYY HH:mm:ss')}
                                    </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            width: 65,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true} });
                                            }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />            
                </div>
            </Form>
            <DrawerTipoRequisicao/>  
        </div>
    );
}