import React, { useState } from "react";
import { Row, Col, Form, Button, Drawer, Modal } from "antd";
import { SaveOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import ManutencaoTermoAceite from "./manutencao";
import { useStateValue } from '../../state/stateProvider';
import { drawerActions, manutencaoActions } from '../../actions';

export default function DrawerTermodeAceite() {

    const [formularioTermoAceite] = Form.useForm();
    const [editando, setEditando] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();

    if (!!manutencao.dados && !editando) {
        if(!!manutencao.dados.cli_id)
        {
            setEditando(true);
        }
        formularioTermoAceite.setFieldsValue(manutencao.dados);
    } 

    function fecharDrawer() {
        formularioTermoAceite.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        setEditando(false);
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer 
            width="65%"
            title="Manutenção Termo de Aceite"
            open={ui.showDrawer}
            onClose={() => { fecharDrawer() }}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={onClose} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit" onClick={() => formularioTermoAceite.submit()} loading={carregando}>
                            Salvar
                        </Button>
                    </Col>
                </Row>
            }>
                <ManutencaoTermoAceite />
        </Drawer>
    );
}