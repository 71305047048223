import axios from 'axios';
import {  getEmpresa, getLocal, getToken, logout} from './auth';

const api = axios.create({ baseURL: process.env.REACT_APP_PUBLIC_URL || (window.location.href.indexOf('localhost') > -1 ? 'https://localhost:44332/' : 'https://192.168.172.16') });

api.interceptors.request.use(async config => {
  const empresa = getEmpresa();
  const local = getLocal();
  const token = getToken();
 // const id = getUsuarioLogado();
  if (token) config.headers.Authorization = `Bearer ${token}`;
  if (empresa) config.headers.empresa = `${empresa}`;
  if (local) config.headers.local = `${local}`;
 // if (id) config.headers.idUsuario = `${id}`;
  return config;
});

api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    logout();
  }
  return Promise.reject(error);
});

export default api;
