import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Input, Radio, Checkbox, Steps, Button, Alert, notification } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

import { ItensTarefas, Data } from "../../components/";
import { ModalContas } from "../../components/modals/";

import api from "../../services/api";
import { Local, Recursos, OrdemServico } from "./pages";
import { useStateValue } from "../../state/stateProvider";
import { converterDatasParaMomentLista, downloadRelatorioJasper, isNotNullOrEmpty, isObjetoDiffVazio, undefinedParaNull, validaForm } from "../../services/funcoes";
import SelectPaginado from "../selectPaginado";
import { manutencaoActions, selectPaginadoActions } from "../../actions";
import moment from 'moment';
import 'moment/locale/pt-br';
import { getLocal } from "../../services/auth";


export default function ManutencaoAgenda({ openAgenda, form, carregando, aoSalvar, pages, proximaPag, listaRecursos, listaItens, listaAtividade, listaCobranca, listaTermoAceite, listaContato }) {
    moment.locale('pt-br');

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [hideCampos, setHideCampos] = useState(true);
    const [openModalContas, setOpenModalContas] = useState(false);
    const [exibeTempoLembrete, setExibeTempoLembrete] = useState(false);
    const [listaStatus, setListaStatus] = useState([]);
    const [objTipoAgenda, setObjTipoAgenda] = useState([]);
    const [pendenciasFinanceiras, setPendenciasFinanceiras] = useState(false);
    const [permiteValidar, setPermiteValidar] = useState(true);
    const [temContato, setTemContato] = useState(false);
    const [participantes, setParticipantes] = useState([]);
    const [listaTempoLembrete, setListaTempoLembrete] = useState([]);
    const [filtroParticipantes, setFiltroParticipantes] = useState(0);
    const [desabilitarData, setDesabilitarData] = useState(false);
    const [pessoaId, setPessoaId] = useState(0);
    const [coordenadas, setCoordenadas] = useState([-27.772189914285715, -54.23928487142857]);
    const [coordenadasCEP, setCoordenadasCEP] = useState([]);
    const [exibirTodos, setExibirTodos] = useState(true);
    const [pessoaEmail, setPessoaEmail] = useState([]);
    const [listaObsPessoa, setListaObsPessoa] = useState([]);
    const [exibeInfo, setExibeInfo] = useState(false);
    const [exibeDadosOs, setExibeDadosOs] = useState(false);
    const [dadosOs, setDadosOS] = useState("");
    const [dataInicialBD, setDataInicialBD] = useState();

    let dadosOk = useState(true);

    useState(() => {
        buscarAgendaTipo();
        buscarStatus();
        buscarParticipantes();
        popularLembretes();
    }, []);

    useEffect(() => {
        setTemContato(false);
        setExibeInfo(false);
        setExibeDadosOs(false);
        setExibeTempoLembrete(false);
        setDesabilitarData(false);
        setPessoaId(0);
        if (openAgenda && !!manutencao.dados) {
            if (!!manutencao.dados.dataInicial) {
                manutencao.dados.dataInicial = moment(manutencao.dados.dataInicial).format("YYYY-MM-DD HH:mm");
                setDataInicialBD(moment(manutencao.dados.dataInicial).format("YYYY-MM-DD"));
            }
            if (!!manutencao.dados.dataFinal) {
                manutencao.dados.dataFinal = moment(manutencao.dados.dataFinal).format("YYYY-MM-DD HH:mm");
            }
            if (!!manutencao.dados.dataInclusao) {
                manutencao.dados.dataInclusao = moment(manutencao.dados.dataInclusao).format("YYYY-MM-DD HH:mm");
            }
            if (!!manutencao.dados.pessoaContatoId && manutencao.dados.pessoaId) {
                buscarDadosContato(manutencao.dados.pessoaId);
            }
            if (!!manutencao.dados.pessoaId) {
                buscarEmails(manutencao.dados.pessoaId);
                buscarObsPessoa(manutencao.dados.pessoaId);
            }

            if (!!manutencao.dados?.id) {
                let lista = [];

                if (!!manutencao.dados.agendaTipoId) {
                    lista.push({ name: "agendaTipoId", campo: "id", value: manutencao.dados.agendaTipoId });
                }
                if (!!manutencao.dados.sistemaId) {
                    lista.push({ name: "sistemaId", campo: "id", value: manutencao.dados.sistemaId });
                }
                if (!!manutencao.dados.solucaoModuloId) {
                    lista.push({ name: "solucaoModuloId", campo: "id", value: manutencao.dados.solucaoModuloId });
                }
                if (!!manutencao.dados.pessoaId) {
                    lista.push({ name: "pessoaId", campo: "PessoaId", value: manutencao.dados.pessoaId });
                }
                if (!!manutencao.dados.parceiroComercialId) {
                    lista.push({ name: "parceiroComercialId", campo: "id", value: manutencao.dados.parceiroComercialId });
                }
                if (!!manutencao.dados.localId) {
                    lista.push({ name: "localId", campo: "id", value: manutencao.dados.localId });
                }
                if (!!manutencao.dados.listaAgendaParticipantes) {
                    lista.push({ name: "listaAgendaParticipantes", campo: "listaParticipantes", value: manutencao.dados.listaAgendaParticipantes });
                }
                if (!!manutencao.dados.pessoaId) {
                    setPessoaId(manutencao.dados.pessoaId);
                }
                if (!!manutencao.dados.agendaRecursos) {
                    setDesabilitarData(manutencao.dados.agendaRecursos.length > 0);
                    listaRecursos.setListaRecursos(manutencao.dados.agendaRecursos);
                }
                if (!!manutencao.dados.ordemServicoId) {
                    form.setFieldsValue({ numeroOS: manutencao.dados.ordemServico?.numero, observacaoOS: manutencao.dados.ordemServico?.Observacao });
                }

                if (isObjetoDiffVazio(manutencao.dados.ordemServico) && !!manutencao.dados.ordemServico.ordensTarefas) {
                    let itens = [...manutencao.dados.ordemServico.ordensTarefas];
                    form.setFieldsValue({ "possui_cobranca": manutencao.dados.ordemServico.possuiCobranca });
                    popularListaTarefa(itens);
                    // buscarTermoAceite(manutencao.dados.ordemServico.idOrdemServico);
                }
                /*if (isObjetoDiffVazio(manutencao.dados.ordemServico) && !!manutencao.dados.ordemServico.itensCobranca) {
                    let cobrancas = [...manutencao.dados.ordemServico?.itensCobranca];
                    popularListaCobranca(cobrancas);
                }*/

                form.setFieldsValue(manutencao.dados);
                setExibeTempoLembrete(manutencao.dados.lembrete);
                if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: lista });
            }
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });

        } else {
            retornarDataAtual();
        }
    }, [openAgenda]);

    useEffect(() => {
        setDesabilitarData(listaRecursos.listaRecursos.length > 0);
    }, [listaRecursos])

    async function popularListaTarefa(ordemTarefaDados) {
        let itens = [];
        for (let i = 0; i < ordemTarefaDados.length; i++) {
            let lista = {};
            let info = {};
            lista.tarefaTipo = await buscarTarefa(ordemTarefaDados[i].tarefa.tarefaTipoId);
            if (!!ordemTarefaDados[i].produtoId) {
                lista.produto = await buscarServico(ordemTarefaDados[i].produtoId);
            }
            lista.responsavel = await buscarResponsavel(ordemTarefaDados[i].tarefa.idResponsavel);
            lista.status = ordemTarefaDados[i].tarefa.status;
            let ordemTarefa = {};
            lista.key = i + 1;
            lista.ordemServicoId = ordemTarefaDados[i].ordemServicoId;
            lista.idOrdemServico = ordemTarefaDados[i].ordemServicoId;
            lista.idOrdemTarefa = ordemTarefaDados[i].idOrdemTarefa;
            lista.produtoId = ordemTarefaDados[i].produtoId;
            lista.tarefaId = ordemTarefaDados[i].tarefaId;
            lista.tarefaTipoId = ordemTarefaDados[i].tarefa.tarefaTipoId;
            lista.idResponsavel = ordemTarefaDados[i].tarefa.idResponsavel;

            let tar = {};
            tar.idTarefa = ordemTarefaDados[i].tarefa.idTarefa;
            tar.descricao = ordemTarefaDados[i].tarefa.descricao;
            tar.idResponsavel = ordemTarefaDados[i].tarefa.idResponsavel;
            tar.data = ordemTarefaDados[i].tarefa.data;
            tar.dataInicio = ordemTarefaDados[i].tarefa.dataInicio;
            tar.dataFim = ordemTarefaDados[i].tarefa.dataFim;
            tar.status = ordemTarefaDados[i].tarefa.status;
            tar.dataLimite = null;
            tar.porcentagemAndamento = 0;
            tar.tempoPrevisto = ordemTarefaDados[i].tarefa.tempoPrevisto;
            tar.produtoId = ordemTarefaDados[i].produtoId;
            tar.tarefaTipoId = ordemTarefaDados[i].tarefa.tarefaTipoId;
            lista.tarefa = tar;

            lista.termoAceite = ordemTarefaDados[i].termoAceite;
            lista.idTarefa = ordemTarefaDados[i].tarefa.idTarefa;
            lista.descricao = ordemTarefaDados[i].tarefa.descricao;
            lista.idResponsavel = ordemTarefaDados[i].tarefa.idResponsavel;
            lista.data = ordemTarefaDados[i].tarefa.data;
            lista.dataInicio = ordemTarefaDados[i].tarefa.dataInicio;
            lista.dataFim = ordemTarefaDados[i].tarefa.dataFim;
            lista.status = ordemTarefaDados[i].tarefa.status;
            lista.dataLimite = null;
            lista.porcentagemAndamento = 0;
            lista.tempoPrevisto = ordemTarefaDados[i].tarefa.tempoPrevisto;
            lista.produtoId = ordemTarefaDados[i].produtoId;

            itens.push(lista);
        }
        listaItens.setListaItens(itens);
        listaAtividade.setListaAtividade(itens);

    }

    async function popularListaCobranca(cobrancas) {
        let cobranca = await converterDatasParaMomentLista(cobrancas);
        let listaCobranca = [];

        for (let cob of cobranca) {
            listaCobranca.push(cob);
        }
        listaCobranca.setListaCobranca(listaCobranca);
    };

    async function buscarServico(proId) {
        let req = await api.get(`Produto/${proId}`);
        if (req.status === 200 && !!req.data) {
            return req.data;
        }
        return {};
    };
    async function buscarTarefa(proId) {
        let req = await api.get(`TarefaTipo/${proId}`);
        if (req.status === 200 && !!req.data) {
            return req.data;
        }
        return {};
    };

    async function buscarResponsavel(usuId) {
        let req = await api.get(`Usuarios/${usuId}`);
        if (req.status === 200 && !!req.data) {
            return req.data;
        }
        return {};
    };

    function buscarEmails(pesId) {
        api.get(`PessoaEmail/ListarEmailContato?Pessoa=` + pesId).then(
            res => {
                if (!!res.data) {
                    setPessoaEmail(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function buscarTermoAceite(idOrdemServico) {
        api.get("TermoAceite/Listar?IdOrdemServico=" + idOrdemServico).then(
            res => {
                if (!!res.data) {
                    listaTermoAceite.setListaTermoAceite(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    }

    function buscarStatus() {
        api.get("Enumeravel/StatusAgenda").then(
            res => {
                if (!!res.data) {
                    setListaStatus(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function buscarAgendaTipo() {
        api.get("AgendaTipo/BuscarTipos").then(
            res => {
                let objeto = {};
                for (let item of res.data) {
                    objeto[item.id] = item.descricao.toUpperCase().trim();
                }
                setObjTipoAgenda(objeto);

            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };
    function retornarDataAtual() {
        var timeElapsed = Date.now();
        var data = new Date(timeElapsed);

        form.setFieldsValue({ dataInicial: moment(data.setHours(8, 0, 0, 0)).format("YYYY-MM-DD HH:mm") });
        form.setFieldsValue({ dataFinal: moment(data.setHours(18, 0, 0, 0)).format("YYYY-MM-DD HH:mm") });
    }

    function buscarParticipantes() {
        api.get("Usuarios/ListarTodos?sac=true&ativo=true").then(
            res => {
                if (!!res.data) {
                    setParticipantes(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function popularLembretes() {
        api.get(`Enumeravel/TempoLembrete`).then(
            res => {
                if (!!res.data) {
                    setListaTempoLembrete(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function buscarDadosContato(pesId) {
        setPessoaId(pesId);
        api.get(`PessoaContato/${pesId}`).then(
            res => {
                if (!!res.data) {
                    if (res.data.length > 0) {
                        setTemContato(true);
                    } else {
                        setTemContato(false);
                    }
                    listaContato.setListaContato(res.data);
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    function buscarObsPessoa(pessoaId = 0) {
        if (pessoaId === 0) {
            setListaObsPessoa([]);
            setExibeInfo(false);
            form.setFieldsValue({ "possui_cobranca": false });
        } else {
            api.get(`ObservacaoPessoa/BuscarPorPessoa?idPessoa=${pessoaId}`).then(
                res => {
                    if (!!res.data) {
                        setListaObsPessoa(res.data);
                        setExibeInfo(true);
                        if (!manutencao.dados?.id) {
                            if (res.data?.observacaoTipoId === 13) {
                                form.setFieldsValue({ "possui_cobranca": true });
                            } else {
                                form.setFieldsValue({ "possui_cobranca": false });
                            }
                        }

                    }
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            );
        }
    }

    function validarTrocaPg(pagina) {
        let paginaAtual = pages.tagPages;
        let listaValidacoes = [];
        switch (paginaAtual) {
            case 0:
                listaValidacoes = [
                    { nome: 'localId', obrigatorio: true, label: 'Local' }
                ];
                break;
            case 2:
                if (listaItens.length > 0) {
                    let valido = true;
                    for (const item of listaItens) {
                        if (!item.produtoId || !item.tarefaTipoId || !item.data || !item.idResponsavel) {
                            valido = false;
                            notification.warning({ message: 'Aviso', description: `Item '${item.key}' possui dados obrigatórios não preenchidos!` });
                        }
                    }

                    if (!valido) {
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;
        }
        if (validaForm(form, listaValidacoes)) {
            pages.setTagPages(pagina);
        } else {
            setPermiteValidar(false);
            proximaPag.setProximaPag(paginaAtual);
        }
    };

    useEffect(() => {
        carregando(false);
        if ((!!proximaPag.proximaPag || proximaPag.proximaPag === 0) && permiteValidar) {
            validarTrocaPg(proximaPag.proximaPag);
        } else {
            setPermiteValidar(true);
        }
    }, [proximaPag.proximaPag]);

    function ocultarCampos() {
        setHideCampos(!hideCampos);
    };

    function definirLembrete() {
        if (!!form.getFieldValue().dataInicial) {
            setExibeTempoLembrete(!exibeTempoLembrete);
        } else {
            notification.warn({ description: "Favor informar uma data inicial para a agenda!", message: "Aviso" });
            form.setFieldsValue({ lembrete: false });
        }
    };

    async function enviar(values) {
        dadosOk = true;
        let dados = { ...form.getFieldValue() };
        if (!!dados.id) {
            dados.dataAlteracao = moment(new Date());
        } else {
            dados.dataInclusao = moment(new Date());
        }
        dados.agendaParticipantes = await retornarParticipantes(dados);

        dados.agendaRecursos = [...listaRecursos.listaRecursos];

        dados.dataInicial = moment(dados.dataInicial);
        dados.dataFinal = moment(dados.dataFinal);

        dados.dataInclusao = moment(dados.dataInclusao);

        dados.agendaRecursos.map(p => {
            delete p.recurso;
        });

        dados.agendaParticipantes.map(p => {
            delete p.usuario;
        });


        delete dados.agendaTimeline;
        if (isNotNullOrEmpty(dados.tipoLembrete) && dados.dataInicial) {
            dados.lembrarEm = validarDataLembrete(dados.dataInicial, dados.tipoLembrete);
        }
        if ((listaItens.listaItens.length > 0) || (dados.ordemServicoId && listaItens.listaItens.length == 0)) {
            dados.OrdemServico = await retornarOrdemServico(dados);
            let termo = { ...listaTermoAceite.listaTermoAceite }
            if (validarObjeto(termo)) {
                dados.TermoAceite = termo;
            }
            delete dados.ordemServico;
        } else {
            delete dados.OrdemServico;
            delete dados.ordemServico;
        }

        carregando(true);
        console.log(dados);
        if (dadosOk) {
            let retorno = dados.id ? await api.put('/Agenda', dados) : await api.post('/Agenda', dados);
            if (retorno.status === 200) {

                if (retorno.data?.ordemServico?.ordensTarefas != null && retorno.data?.pessoaId) {
                    if (retorno.data?.parceiroComercialId) {
                        downloadRelatorioJasper(53, [{ "nome": "ordemservico", "valor": retorno.data.ordemServico.numero }, { "nome": "IdLocal", "valor": getLocal() }], "PDF", "ordemServico");
                    } else {
                        downloadRelatorioJasper(42, [{ "nome": "ordemservico", "valor": retorno.data.ordemServico.numero }, { "nome": "IdLocal", "valor": getLocal() }], "PDF", "ordemServico");
                    }
                    notification.success({ message: 'Operação concluída', description: "Registro salvo com sucesso! Realizado download da Ordem de Serviço!" });
                } else {
                    notification.success({ message: 'Operação concluída', description: "Registro salvo com sucesso!" });
                }
                retorno = retorno.data.id;
            }
            else notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(retorno.data) });
            carregando(false);
            aoSalvar();
        } else {
            notification.warn({ message: 'Aviso!', description: "Favor informar os campos obrigatórios dos itens da tarefa." });
            carregando(false);
            return;
        }
    };


    function validarObjeto(objeto) {
        // Verifica se o objeto não é nulo e não é indefinido
        if (objeto && typeof objeto === 'object' && Object.keys(objeto).length > 0) {
            return true; // O objeto tem dados válidos
        }

        return false; // O objeto não tem dados válidos
    }

    function validarDataLembrete(dataInicial, tipoLembrete) {
        let data = new Date(dataInicial);
        switch (tipoLembrete) {
            case 1:
                data.setMinutes(data.getMinutes() - 15);
                break;
            case 2:
                data.setMinutes(data.getMinutes() - 30);
                break;
            case 3:
                data.setHours(data.getHours() - 1);
                break;
            case 4:
                data.setHours(data.getHours() - 6);
                break;
            case 5:
                data.setHours(data.getHours() - 12);
                break;
            case 6:
                data.setDate(data.getDate() - 1);
                break;
        }
        return data;
    }

    async function retornarParticipantes(dados) {
        debugger;
        let lista = [];
        for (const participante of dados.listaAgendaParticipantes) {
            let item = dados.agendaParticipantes?.find(p => { return p.usuarioId === participante });
            lista.push(!!item ? item : { usuarioId: participante, agendaId: dados.id });
        }
        return lista;
    };

    async function retornarOrdemServico(dados, possuiCobranca) {
        let ordemServico = {};
        ordemServico.IdOrdemServico = dados.ordemServicoId;
        ordemServico.Numero = dados.numeroOS;
        ordemServico.PessoaId = dados.pessoaId;
        ordemServico.Data = dados.data ? dados.data : moment(new Date());
        ordemServico.DataCadastro = dados.dataInclusao ? dados.dataInclusao : moment(new Date());
        ordemServico.DataAlteracao = moment(new Date())
        ordemServico.TipoAtendimento = dados.tipoAtendimento;
        ordemServico.Observacao = dados.observacaoOS ? dados.observacaoOS : "";
        ordemServico.PossuiCobranca = dados.possui_cobranca ? dados.possui_cobranca : false;
        ordemServico.PessoaContatoId = dados.PessoaContatoId;
        ordemServico.OrdensTarefas = await montaDadosTarefa();
        ordemServico.ItensCobranca = await montaDadosCobranca(dados);
        ordemServico.UsuarioId = dados.usuarioResponsavelId;
        ordemServico.Status = 1;
        return ordemServico;
    };

    async function montaDadosCobranca(record) {
        let dados = [...listaCobranca.listaCobranca];
        let listaCob = []
        for (let cobranca of dados) {
            let cob = { ...cobranca };
            let dataAnoMes = new Date()
            if (!!cob.anoMesCobranca && cob.anoMesCobranca.toString().length === 6) {
                dataAnoMes.setMonth((cob.anoMesCobranca.toString().substring(4, 6) - 1));
                dataAnoMes.setYear(cob.anoMesCobranca.toString().substring(0, 4));
                cob.anoMesCobranca = dataAnoMes;
            }
            if (cob.anoMesCobranca) {
                cob.anoMesCobranca = moment(cob.anoMesCobranca).format("YYYYMM");
            } else {
                cob.anoMesCobranca = null;
            }
            if ((!cob.anoMesCobranca || !cob.produtoId || !cob.quantidade || !cob.valorUnitario || !cob.sequencia) && record.status == 2) {
                notification.warning({ description: "Campos obrigatórios não foram preenchidos nos Itens de Cobrança!", message: "Aviso" });
                dadosOk = false;
                return false;
            }
            cob.produto = null;
            cob.contrato = null;
            undefinedParaNull(cob);
            listaCob.push(cob);
        }
        return listaCob;
    };

    async function montaDadosTarefa() {
        let itens = [...listaItens.listaItens]
        let ordensTarefas = [];
        for (let i = 0; i < itens.length; i++) {
            if (itens[i].ordensTarefas?.length > 0) {
                for (let j = 0; j < itens[i].ordensTarefas.length; j++) {
                    itens[i].ordensTarefas[j].produto = null
                    itens[i].ordensTarefas[j].data = moment(itens[i].ordensTarefas[j].data).utcOffset(-3);
                    if (!!!itens[i].ordensTarefas[j].produtoId) {
                        dadosOk = false;
                    }
                    ordensTarefas.push(itens[i].ordensTarefas[j]);
                }
            } else {
                itens[i].produto = null
                itens[i].data = moment(itens[i].data).utcOffset(-3);
                if (!!!itens[i].produtoId) {
                    dadosOk = false;
                }
                ordensTarefas.push(itens[i]);
            }
        }
        if (itens.length > 0) {
            return ordensTarefas;
        } else {
            return [];
        }

    }


    function buscarEndereco(pessoaId) {
        api.get(`Cliente/BuscarEnderecoCliente/${pessoaId}`).then(
            res => {
                let dados = res.data;
                if (!!dados) {
                    let cep = dados.cep;
                    let endereco = dados.endereco;
                    setCoordenadas([dados.posicaoMapa.x, dados.posicaoMapa.y]);
                    dispatch({ type: selectPaginadoActions.CHANGE, data: [{ name: "localId", campo: "cep", value: cep }, { name: "localId", campo: "endereco", value: endereco.toUpperCase() }] });
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    function buscarTituloFerias() {
        if (form.getFieldValue('dataInicial') != '' &&
            form.getFieldValue('dataFinal') != '' &&
            (objTipoAgenda[form.getFieldValue('agendaTipoId')] == 'FÉRIAS' || objTipoAgenda[form.getFieldValue('agendaTipoId')] == 'FERIAS')) {
            form.setFieldsValue({ titulo: 'Férias de ' + moment(form.getFieldValue('dataInicial')).format("DD/MM/YYYY") + ' a ' + moment(form.getFieldValue('dataFinal')).format("DD/MM/YYYY") });
        }
    };

    return (
        <Form layout="vertical" name="formManutencaoAgenda" form={form} onFinish={enviar}>
            <Row justify="end" gutter={[8, 0]}>
                <Col>
                    <Button icon={hideCampos ? <EyeOutlined /> : <EyeInvisibleOutlined />} onClick={() => { ocultarCampos() }} className="btn-hd">
                        {hideCampos ? "Ocultar Campos" : "Exibir Campos"}
                    </Button>
                </Col>
            </Row>
            <Row gutter={[8, 0]} className="mt12-mobile">
                <Col xs={24} sm={12} md={5} lg={5} xl={5} xxl={6}>
                    <Form.Item name="id" hidden />
                    <Data name="dataInclusao" format="DD/MM/YYYY HH:mm" hidden />
                    <Form.Item label="Tipo de Agenda" name="agendaTipoId" rules={[{ required: true, message: 'Informe o Tipo de Agenda' }]}>
                        <SelectPaginado onBlurFunction={() => buscarTituloFerias()} form={form} url="AgendaTipo" name="agendaTipoId" placeholder="Selecione o Tipo de Agenda" conteudo={tipoAgenda => (
                            <Select.Option value={tipoAgenda.id} key={tipoAgenda.id}>
                                {tipoAgenda.descricao}
                            </Select.Option>)}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={5} lg={5} xl={5} xxl={6}>
                    <Form.Item label="Tipo de Atendimento" name="tipoAtendimento" initialValue={1}>
                        <Radio.Group>
                            <Radio value={1}>Externo</Radio>
                            <Radio value={2}>Interno</Radio>
                            <Radio value={3}>Remoto</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={7} lg={7} xl={7} xxl={6}>
                    <Data disabled={desabilitarData} rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                const currentDate = new Date();
                                const dataSemHoras = moment(getFieldValue('dataInicial')).format('YYYY-MM-DD');
                                console.log(dataSemHoras);
                                if (moment(dataSemHoras).isBefore(moment(currentDate).format('YYYY-MM-DD')) && dataSemHoras !== dataInicialBD) {
                                    return Promise.reject(new Error('A data da agenda não pode ser inferior que a data atual.'));
                                }
                                return Promise.resolve();
                            },
                        }),
                        ({ required: true })
                    ]} label="Data Inicial" name="dataInicial" format="DD/MM/YYYY HH:mm"
                        onBlur={() => {
                            form.setFieldsValue({ dataFinal: moment(new Date(form.getFieldValue("dataInicial")).setHours(18, 0, 0, 0)).format("YYYY-MM-DD HH:mm") });
                            buscarTituloFerias();
                        }} />
                </Col>
                <Col xs={24} sm={12} md={7} lg={7} xl={7} xxl={6}>
                    <Data disabled={desabilitarData} label="Data Final" name="dataFinal" format="DD/MM/YYYY HH:mm" rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (moment(getFieldValue('dataInicial')).isAfter(moment(getFieldValue('dataFinal')).format("YYYY-MM-DD HH:mm"))) {
                                    return Promise.reject(new Error('Data Final não pode ser menor que a Data Inicial.'));
                                }
                                return Promise.resolve();
                            },
                        }),
                        ({ required: true })
                    ]} onBlur={() => buscarTituloFerias()} />
                </Col>
                {/*<Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={9}>
                    <Form.Item label="Empresa" name="empresaId">
                        <SelectPaginado form={form} url="EmpresasUsuario" name="empresaId" placeholder="Selecione a Empresa" conteudo={
                            empresa => (<Select.Option value={empresa.id} key={empresa.id}>{empresa.razaoSocial}</Select.Option>)
                        } />-
                    </Form.Item>
                    </Col>*/}
            </Row>
            <>
                {hideCampos &&
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item label="Título" name="titulo" rules={[{ required: true, message: 'Informe um Título' }]}>
                                <Input.TextArea style={{
                                    height: 80,
                                }} maxlength={1000} placeholder="Informe um Título" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Sistema" name="sistemaId" rules={[{ required: true, message: 'Informe um Sistema' }]}>
                                <SelectPaginado form={form} url="Sistema" name="sistemaId" campoFiltro="descricao" placeholder="Selecione o Sistema" conteudo={sistema => (
                                    <Select.Option value={sistema.id} key={sistema.id}>
                                        {sistema.descricao}
                                    </Select.Option>)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Soluções / Módulos" name="solucaoModuloId">
                                <SelectPaginado form={form} url="SolucaoModulo" name="solucaoModuloId" campoFiltro="descricao" placeholder="Selecione uma Solução / Módulo" conteudo={
                                    solucaoModulo => (<Select.Option value={solucaoModulo.id} key={solucaoModulo.id}>{solucaoModulo.descricao}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Participantes" name="listaAgendaParticipantes" rules={[{ required: true, message: 'Informe um Participante' }]}>
                                <SelectPaginado url="Usuarios?Ativo=true&Sac=true" form={form} name="listaAgendaParticipantes" multiple={true} allowClear placeholder="Selecione os Participantes" order="+Id"
                                    conteudo={
                                        (participante) => (
                                            (<Select.Option value={participante.id} key={participante.id}>{(participante.nome).normalize('NFD').replace(/[\u0300-\u036f]/g, "")}</Select.Option>)
                                        )} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={14} md={16} lg={16} xl={16} xxl={16}>
                            <Form.Item label="Cliente" name="pessoaId">
                                <SelectPaginado url="Cliente" name="pessoaId" form={form}
                                    onChangeFunction={(value) => { buscarDadosContato(value); buscarEndereco(value); buscarEmails(value); buscarObsPessoa(value); }} conteudo={
                                        (cli) =>
                                        (<Select.Option value={cli.pessoa?.id} key={cli.pessoa?.id}>
                                            <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'Razão Social:' : 'Nome:'}</span> {cli.pessoa?.nome} <br />
                                            <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'Nome Fantasia:' : 'Apelido:'} </span> {cli.pessoa?.pessoaJuridica ? cli.pessoa?.pessoaJuridica?.nomeFantasia : cli.pessoa?.pessoaFisica?.apelido}  <br />
                                            <span style={{ fontWeight: "bold" }}> {cli.pessoa?.pessoaJuridica ? 'CNPJ:' : 'CPF:'} </span> {cli.pessoa?.pessoaJuridica ? cli.pessoa?.pessoaJuridica?.cnpj : cli.pessoa?.pessoaFisica?.cpf} <span style={{ fontWeight: "bold" }}> {!cli.ativo ? '(Inativo)' : ''}</span> <br />
                                        </Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={10} md={8} lg={8} xl={8} xxl={8}>
                            <Form.Item label="Contato" name="pessoaContatoId" hidden={!temContato} form={form}>
                                <Select allowClear placeholder="Selecione o Contato" showSearch optionFilterProp="children">
                                    {listaContato.listaContato.map((contato) => (
                                        <Select.Option value={contato.contatoId} key={contato.contatoId}>{contato.contato?.nome}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={5} xxl={5}>
                            <Form.Item label="Status" name="status" initialValue={1} >
                                <Select disabled={true} allowClear placeholder="Selecione o Status" >
                                    {listaStatus.map((status) => <Select.Option value={status.valor} key={status.valor}>{status.descricao}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={5} xxl={5}>
                            <Form.Item label="Parceiro" name="parceiroComercialId">
                                <SelectPaginado form={form} url="ParceiroComercial" name="parceiroComercialId" placeholder="Selecione um Parceiro" conteudo={
                                    parceiro => (<Select.Option value={parceiro.id} key={parceiro.id}>{parceiro.pessoa.nome}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                        <Col className="t-top">
                            <Form.Item valuePropName="checked" name="lembrete" initialValue={false}>
                                <Checkbox onChange={() => { definirLembrete() }}> Lembrar os Envolvidos </Checkbox>
                            </Form.Item>
                        </Col>
                        {exibeTempoLembrete &&
                            <Col xs={24} sm={10} md={8} lg={8} xl={6} xxl={5}>
                                <Form.Item label="Tempo de Lembrete" name="tipoLembrete">
                                    <Select allowClear placeholder="Selecione o Tempo de Lembrete">
                                        {listaTempoLembrete.map(tempo => (
                                            <Select.Option value={tempo.valor} key={tempo.valor}>{tempo.descricao}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                            <Form.Item label="Usuário Responsável" name="usuarioResponsavelId" hidden   >
                                <SelectPaginado disabled={true} url="Usuarios?Ativo=true&Sac=true" name="usuarioResponsavelId" form={form} conteudo={
                                    usu => (<Select.Option value={usu.id} key={usu.id}>{(usu.nome).normalize('NFD').replace(/[\u0300-\u036f]/g, "")}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                    </Row>
                }
                <Row align="middle" gutter={[0, 8]} className="m-t-8">
                    {pendenciasFinanceiras && <Col span={24}>
                        <Alert message={
                            <Row gutter={[8, 0]}>
                                <Col>
                                    <b>Pendências Financeiras.</b>
                                </Col>
                                <Col>
                                    Este cliente possui pendências financeiras no sistema gestor.
                                </Col>
                            </Row>
                        } type="error" showIcon closable />
                    </Col>}
                    <Col span={24}>
                        {exibeInfo &&
                            <div className="m-t-16">
                                <Alert message={<span style={{ fontSize: '12px', fontWeight: 'bold' }}>{listaObsPessoa.observacaoTipo?.descricao}</span>}
                                    description={<span style={{ fontSize: '12x' }}>{listaObsPessoa.descritivo}</span>} type="info" showIcon />
                            </div>
                        }
                    </Col>
                </Row>
            </>
            <Row gutter={[8, 8]} className="m-t-16">
                <Col span={24}>
                    <Steps size="small" current={pages.tagPages}>
                        <Steps.Step tagPages="0" title="Local" />
                        <Steps.Step tagPages="1" title="Recursos" />
                        <Steps.Step tagPages="2" title="Itens" />
                        <Steps.Step tagPages="3" title="Ordem de Serviço" />
                    </Steps>
                </Col>
                <Col span={24} className="m-t-8">
                    {pages.tagPages === 0 &&
                        <Local form={form} coordenadas={{ coordenadas, setCoordenadas }} coordenadasCEP={{ coordenadasCEP, setCoordenadasCEP }} />
                    }
                    {pages.tagPages === 1 &&
                        <Recursos form={form} listaRecursos={listaRecursos} desabilitarData={{ desabilitarData, setDesabilitarData }} coordenadas={{ coordenadas, setCoordenadas }} />
                    }
                    {pages.tagPages === 2 &&
                        <ItensTarefas formAgenda={form} pessoaEmail={pessoaEmail} listaItens={listaItens} listaAtividade={listaAtividade} listaTermoAceite={listaTermoAceite} filtroParticipantes={{ filtroParticipantes, setFiltroParticipantes }} pessoaId={{ pessoaId, setPessoaId }} exibirTodos={{ exibirTodos, setExibirTodos }} />
                    }
                    {pages.tagPages === 3 &&
                        <OrdemServico form={form} />
                    }
                </Col>
            </Row>
            <ModalContas form={form} exibirModalContas={openModalContas} fecharModalContas={() => setOpenModalContas(false)} />
        </Form>

    );
}