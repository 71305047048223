import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Drawer, Modal, notification } from "antd";

import { CloseOutlined, FileDoneOutlined, ExclamationCircleOutlined, SendOutlined, EyeOutlined } from "@ant-design/icons";
import ManutencaoEnviarOS from './detalhe';
import { drawerActions, manutencaoActions } from '../../actions';
import { useStateValue } from '../../state/stateProvider';
import ManutencaoAssinarTermo from "./detalhe";
import { getLocal, getNomeUsuario } from "../../services/auth";
import { AssinarRelatorio } from "../../services/funcoes";

export default function DrawerAssinarTermoGeral({ openDrawerAssinarTermo, listaEmail, pessoaEmail, dadosRecord, closeDrawerAssinarTermo, dadosGedInfo }) {

    const [formularioEnviarTermo] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregandoDefinido, setCarregandoDefinido] = useState(false);
    const [carregandoMigracao, setCarregandoMigracao] = useState(false);
    const [assinaturaDefi, setAssinaturaDefi] = useState(1);
    const [assinaturaMigra, setAssinaturaMigra] = useState(1);

    function fecharDrawer() {
        setCarregandoDefinido(false);
        setCarregandoMigracao(false);
        closeDrawerAssinarTermo();
        formularioEnviarTermo.resetFields();
    }
    useEffect(() => {
        console.log(dadosGedInfo);
        let req = dadosGedInfo.find(x => x.tipoAnexo === 3);
        if (!!req) {
            setAssinaturaDefi(req?.assinaturaGed);
        } else {
            setAssinaturaDefi(1);
        }
        req = dadosGedInfo.find(x => x.tipoAnexo === 4);
        if (!!req) {
            setAssinaturaMigra(req?.assinaturaGed)
        } else {
            setAssinaturaMigra(1);
        }


    }, [openDrawerAssinarTermo]);


    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    async function assinarTermoGeral(tipoAnexo) {
        let dados = { ...formularioEnviarTermo.getFieldValue() };
        let clienteEmail = pessoaEmail.find(x => x.id === dados.cliente);
        let signatures = [];
        let emails = dados?.emails?.split(";");
        let nomes = dados?.nomes?.split(";");
        let emailSalvar = !!dados?.emails ? dados?.emails : clienteEmail?.email;
        let nomeSalvar = !!dados?.nomes ? dados?.nomes : clienteEmail?.nome;

        debugger;
        if (!emails && !nomes && !clienteEmail) {
            notification.warning({ message: "Aviso", description: "Favor selecionar um contato ou informar os nomes e respectivos e-mails para o envio da assinatura." });
            setCarregandoDefinido(false);
            setCarregandoMigracao(false);
            return;
        } else if (emails?.length !== nomes?.length) {
            notification.warning({ message: "Aviso", description: "Informar mesma quantidade de nomes e e-mails" });
            setCarregandoDefinido(false);
            setCarregandoMigracao(false);
            return;
        }

        if (!!clienteEmail) {
            const obj = {
                "Email": clienteEmail.email,
                "PartName": clienteEmail.nome
            }
            signatures.push(obj);
        } else {
            for (var i = 0; i < emails.length; i++) {
                const obj = {
                    "Email": emails[i],
                    "PartName": nomes[i]
                }
                signatures.push(obj);
            }
        }

        try {
            if (tipoAnexo === 3) {
                await AssinarRelatorio(56, [{ "nome": "termoaceitedefinitivo", "valor": dadosRecord.numero }, { "nome": "IdLocal", "valor": getLocal() }, { "nome": "tecnico_responsavel", "valor": getNomeUsuario() }, { "nome": "contato_assinou", "valor": nomeSalvar }], "PDF", "TermoAceiteDefinitivo_" + dadosRecord.numero, signatures, dadosRecord.idOrdemServico, 3, null, nomeSalvar, emailSalvar);
                notification.success({ message: 'Enviado para assinar.', description: "Será encaminhado um e-mail para realizar a assinatura." });
            } else {
                await AssinarRelatorio(57, [{ "nome": "termoaceitemigracao", "valor": dadosRecord.numero }, { "nome": "IdLocal", "valor": getLocal() }, { "nome": "tecnico_responsavel", "valor": getNomeUsuario() }, { "nome": "contato_assinou", "valor": nomeSalvar }], "PDF", "TermoAceiteMigracao_" + dadosRecord.numero, signatures, dadosRecord.idOrdemServico, 4, null, nomeSalvar, emailSalvar);
                notification.success({ message: 'Enviado para assinar.', description: "Será encaminhado um e-mail para realizar a assinatura." });
            }

        } catch (e) {
            //  carregando(false);
            console.error(e);
        } finally {
            //  carregando(false);
            fecharDrawer();
        }

    };

    return (
        <Drawer
            width="50%"
            title="Assinar Termo Aceite Geral"
            open={openDrawerAssinarTermo}
            onClose={onClose}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={fecharDrawer} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button hidden={!!dadosGedInfo.find(x => x.tipoAnexo === 3)} icon={<SendOutlined />} loading={carregandoDefinido} size="large" type="primary" htmlType="submit" onClick={() => { setCarregandoDefinido(true); assinarTermoGeral(3) }}>
                            Assinar Termo Aceite Definitivo
                        </Button>
                    </Col>
                    <Col>
                        <Button hidden={!dadosGedInfo.find(x => x.tipoAnexo === 3)} icon={<EyeOutlined />} loading={carregandoDefinido} size="large" type="link" htmlType="submit"
                            onClick={async () => {
                                let req = dadosGedInfo.find(x => x.tipoAnexo === 3);
                                if (req) {
                                    window.open('https://gedapi.abase.com.br/Ged/docPublico?RowGuid={' + req.anexo + '}', '_blank');
                                } else {
                                    notification.warning({ description: "Esta ordem de serviço não foi enviada para assinar ainda.", message: "Aviso" });
                                }
                            }}>
                            Visualizar Termo Aceite Definitivo
                            {assinaturaDefi === 1 && <b className="c-red"> (Sem Assinatura) </b>}
                            {assinaturaDefi === 2 && <b className="c-blue"> (Aguardando) </b>}
                            {assinaturaDefi === 3 && <b className="c-green"> (Assinado)</b>}
                            {assinaturaDefi === 4 && <b className="c-red"> (Rejeitado)</b>}
                        </Button>
                    </Col>
                </Row>
            }>
            <ManutencaoAssinarTermo listaEmail={listaEmail} pessoaEmail={pessoaEmail} form={formularioEnviarTermo} dadosRecord={dadosRecord} aoSalvar={fecharDrawer} dadosGedInfo={dadosGedInfo} />
        </Drawer>
    );
}