import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";

export default function BreadcrumbPage({ pagina }) {

    return (
        <Row align="middle" gutter={[8, 0]} className="breadcrumb">
            <Col>
                <Breadcrumb>
                    <Breadcrumb.Item key="home">
                        <Link to="/">
                            <HomeOutlined />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item key="">
                        <b className="f-18">{pagina}</b>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
        </Row>
    );
}