import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions, Tag } from "antd";
import { useStateValue } from "../../state/stateProvider";
import formatFone from "../../valueObjects/formatFone";

export default function DetalheParceiro() {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [dadosParceiro, setDadosParceiro] = useState({});

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.id) {
            setDadosParceiro(manutencao.dados);
        }
    }, [manutencao.dados]);

    return (
        <Row align="middle" gutter={[8, 0]}>
            <Col span={24} className="tituloDetalhe">
                As informações dos clientes não podem ser alteradas a partir do SAC-G.
            </Col>
        <div className="divDetalhes">
            <Col span={24}>
                <Descriptions size="small">
                    <Descriptions.Item label="Nome">
                        <b>{!!dadosParceiro.pessoa && !!dadosParceiro.pessoa.nome ? dadosParceiro.pessoa.nome : 'Não Informado'}</b>
                    </Descriptions.Item>
                </Descriptions>
            </Col>
            <Col span={24}>
                <Descriptions size="small">
                    <Descriptions.Item label="Site">
                        {!!dadosParceiro.site ?
                            <a href={dadosParceiro.site}> {dadosParceiro.site} </a> : '-'
                        }
                    </Descriptions.Item>
                </Descriptions>
            </Col>
            <Col span={24}>
                <Descriptions size="small">
                    <Descriptions.Item label="Email">
                        <Row gutter={[8, 0]}>
                            {!!dadosParceiro.pessoa && !!dadosParceiro.pessoa.pessoaEmail && dadosParceiro.pessoa.pessoaEmail.length > 0 ? dadosParceiro.pessoa.pessoaEmail.map((pessoaEmail, indice) => (
                                <Col span={24}>
                                    <b>
                                        {pessoaEmail.email}
                                    </b>
                                </Col>
                            )) : 'Não Informado'}
                        </Row>
                    </Descriptions.Item>
                    <Descriptions.Item label="Telefone">
                        {!!dadosParceiro.pessoa && !!dadosParceiro.pessoa.pessoaTelefone && dadosParceiro.pessoa.pessoaTelefone.length > 0 ? dadosParceiro.pessoa.pessoaTelefone.map((pessoaTelefone, indice) => (
                            <Col span={24}>
                                <b>
                                    {formatFone(pessoaTelefone.numero)}
                                </b>
                            </Col>
                        )) : 'Não Informado'}
                    </Descriptions.Item>
                </Descriptions>
            </Col>
            <Col span={24}>
                <Descriptions size="small">
                    <Descriptions.Item label="Endereço">
                        {!!dadosParceiro.pessoa && !!dadosParceiro.pessoa.pessoaEndereco && dadosParceiro.pessoa.pessoaEndereco.length > 0 ? dadosParceiro.pessoa.pessoaEndereco.map((pessoaEndereco, indice) => (
                            <Col span={24}>
                                <b>
                                    {pessoaEndereco.logradouroCidade.logradouro.descricaoLogradouro || 'Não Informado'}
                                    {!!pessoaEndereco.numero ? (', ' + pessoaEndereco.numero) : ''}
                                    {!!pessoaEndereco.logradouroCidade.bairro.nome ? (', ' + pessoaEndereco.logradouroCidade.bairro.nome) : ''} 
                                    {!!pessoaEndereco.logradouroCidade.cep ? (' - ' + pessoaEndereco.logradouroCidade.cep) : ''} 
                                    {!!pessoaEndereco.logradouroCidade.cidade.descricao ? (' - ' + pessoaEndereco.logradouroCidade.cidade.descricao) : ''} 
                                    {!!pessoaEndereco.logradouroCidade.cidade.estado ? (' - ' + pessoaEndereco.logradouroCidade.cidade.estado) : ''}
                                </b>
                            </Col>
                        )) : 'Não Informado'}
                    </Descriptions.Item>
                </Descriptions>
            </Col>
            </div>
        </Row >
    );
}