import { Button } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Circle, CircleMarker } from 'react-leaflet';
import FullscreenExitOutlined from "@ant-design/icons";
import L from 'leaflet';



const Map = ({ markers, listaRecursos, aoSalvar, coordenadas }) => {
  useEffect(() => {
    console.log(coordenadas);
  },[])

  function salvarRecurso(recursoId, recursoName) {
    if (!!recursoId) {
      let lista = [...listaRecursos.listaRecursos]
      let agendaRecurso = {};
      let recurso = {};
      agendaRecurso.RecursoId = recursoId;
      recurso.descricao = recursoName;

      agendaRecurso.recurso = recurso;
      lista.push(agendaRecurso);
      listaRecursos.setListaRecursos(lista);

    }
    aoSalvar();
  }

  const redOptions = { color: 'red' }
  return (
    <MapContainer center={[coordenadas.coordenadas[0],coordenadas.coordenadas[1]]} zoom={13} style={{ height: '400px', width: '100%' }}>
      <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <CircleMarker center={[coordenadas.coordenadas[0],coordenadas.coordenadas[1]]} pathOptions={redOptions} radius={10}>
        <Popup>Seu destino</Popup>
      </CircleMarker>
      {markers.map((marker, index) => (
        <Marker key={index} position={marker.position}>
          <Popup>
            <b>Resumo:</b>
            <p style={{ borderBottom: '1px solid #878383' }}>Recurso: {marker.recurso}<br /></p>
            <p style={{ borderBottom: '1px solid #878383' }}>Responsável: {marker.responsavel} <br />
              Título: {marker.name} <br /> Período: {moment(marker.dataInicial).format("DD/MM/YYYY HH:mm")} - {moment(marker.dataFinal).format("DD/MM/YYYY HH:mm")} <br /></p>

            <Button onClick={() => salvarRecurso(marker.recursoId, marker.recurso)} type="primary">Participar</Button>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Map;