import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select, notification, Checkbox } from "antd";
import { useStateValue } from "../../state/stateProvider";
import api from "../../services/api";
import FormBuilder from 'antd-form-builder'

export default function ManutencaoRecurso({ form, carregando, aoSalvar }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [listaEmpresa, setListaEmpresa] = useState([]);
    const [listaTipoRecurso, setListaTipoRecurso] = useState([]);
    const [meta, setMeta] = useState({ fields: [] });

    const salvarDados = record => {
        let dados = {};
        let tipoRecurso = listaTipoRecurso.find((ret) => ret.id == record.recursoTipoId);
        let formulario = tipoRecurso?.formulario;
        if (!!formulario && formulario != "") {
            let retorno = [];
            formulario = JSON.parse(formulario);
            formulario.forEach((reg) => {
                retorno.push({ [reg.id]: record[reg.id] });
            });
            dados.formulario = JSON.stringify(retorno);
        }
        dados.id = record.id;
        dados.recursoTipoId = record.recursoTipoId;
        dados.empresaId = record.empresaId;
        dados.descricao = record.descricao;
        dados.ativo = !!dados.id ? record.ativo : true;
        carregando(true);
        if (!!dados.id) {
            editarRecurso(dados);
        } else {
            incluirRecurso(dados);
        }
    };

    useEffect(() => {
        carregarDados();
    }, []);

    useEffect(() => {
        if (!!ui.showDrawer && !!manutencao.dados?.recursoTipo?.formulario) {
            popularMeta(JSON.parse(manutencao.dados.recursoTipo.formulario));
            if (!!manutencao.dados.formulario) {
                popularTipoRecurso(manutencao.dados.formulario)
            }
        } else {
            limparMeta();
        }
    }, [ui.showDrawer]);

    function carregarDados() {
        buscarListaEmpresa();
        buscarListaTipoRecursos();
    };

    function incluirRecurso(dados) {
        api.post("Recurso", dados).then(
            res => {
                notification.success({ description: `Recurso incluído com sucesso!`, message: "Sucesso!" });
                aoSalvar();
            }
        ).catch(
            error => {
                notification.error({ description: `Houve um problema ao inserir o recurso!`, message: "Aviso" });
            }
        ).finally(
            () => {
                carregando(false);
            }
        );
    };

    function editarRecurso(dados) {
        api.put("Recurso", dados).then(
            res => {
                notification.success({ description: `Recurso ${dados.descricao} editado com sucesso!`, message: "Sucesso!" });
                aoSalvar();
            }
        ).catch(
            error => {
                notification.error({ description: `Houve um problema ao inserir o recurso!`, message: "Aviso" });
            }
        ).finally(
            () => {
                carregando(false);
            }
        )
    };

    function popularTipoRecurso(formulario) {
        let dados = JSON.parse(formulario);
        dados.forEach((rec) => {
            let obj = Object.entries(rec)[0];
            form.setFieldsValue({ [obj[0]]: obj[1] });
        });


    };

    function buscarListaEmpresa() {
        api.get("EmpresasUsuario").then(
            res => {
                if (!!res.data?.items) {
                    setListaEmpresa(res.data.items)
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    function buscarListaTipoRecursos() {
        api.get("RecursoTipo").then(
            res => {
                if (!!res.data?.items) {
                    setListaTipoRecurso(res.data.items);
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    function onChangeTipoRecurso(recursoTipoId) {
        let dadosFormulario = {};
        if (!!recursoTipoId) {
            dadosFormulario = listaTipoRecurso.find((ret) => ret.id == recursoTipoId).formulario;
            if (!!dadosFormulario && dadosFormulario !== "") {
                popularMeta(JSON.parse(dadosFormulario));
            }
        }
    };

    function popularMeta(dadosFormulario) {
        dadosFormulario.map((dados) => {
            if (dados.type != "text") {
                dados.widget = dados.type;
            }
            dados.key = dados.id;
            dados.required = true;
        })
        let retorno = {
            columns: dadosFormulario.length,
            fields: dadosFormulario
        };
        setMeta(retorno);
    };

    function limparMeta() {
        setMeta({ fields: [] });
    };

    return (
        <Form layout="vertical" name="formManutencaoRecurso" form={form} onFinish={salvarDados}>
            <Row gutter={[8, 0]}>
                <Form.Item name="id" hidden></Form.Item>
                <Col xs={24} sm={12} md={8} lg={6} xl={6} >
                    <Form.Item label="Nome" name="descricao">
                        <Input placeholder="Informe o nome" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={6} >
                    <Form.Item label="Empresa" name="empresaId">
                        <Select placeholder="Selecione a Empresa" allowClear showSearch optionFilterProp="children">
                            {listaEmpresa.map(emp => (<Select.Option value={emp.id} key={emp.id}>{emp.nomeFantasia} - {emp.razaoSocial}</Select.Option>))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Form.Item label="Tipo" name="recursoTipoId">
                        <Select placeholder="Selecione o Tipo" onChange={(id) => { onChangeTipoRecurso(id) }} showSearch optionFilterProp="children">
                            {listaTipoRecurso.map(ret => <Select.Option value={ret.id} key={ret.id}>{ret.descricao}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col  xs={24} sm={12} md={8} lg={6} xl={5}>
                    <Form.Item name="ativo" initialValue={true} valuePropName="checked"  className="t-mob-573">
                        <Checkbox>Recurso Ativo?</Checkbox>
                    </Form.Item>
                </Col>
              
                <Col>
                    <FormBuilder meta={meta} form={form} />
                </Col>
            </Row>
        </Form>
    );
}