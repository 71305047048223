import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Form, Input, Menu } from "antd";
import { Link } from "react-router-dom";
import { Icon } from '@ant-design/compatible';

import "./index.less";
import { getTelaAnterior, setTelaAnterior } from "../../services/auth";
import { listagemActions } from "../../actions";
import { useStateValue } from "../../state/stateProvider";

export default function MenuPage(incollapsed) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [openKeys, setOpenKeys] = useState([]);
    const rootKeys = ["sub0", "sub1", "sub2", "sub3", "sub4", "sub5", "sub6", "sub7", "sub8", "sub9", "sub10", "sub11", "sub12", "sub13", "sub14", "sub15", "sub16", "sub17", "sub18"];

    const onOpenChange = (items) => {
        const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1);
        if (rootKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(items);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : openKeys);
        }
    };

    useEffect(() => {
        if (!incollapsed) {
            setOpenKeys(openKeys);
        }
    }, [openKeys, incollapsed]);

    function onClick(menu) {
        let telaAnterior = getTelaAnterior();
        if (menu?.key !== telaAnterior) {
            dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '' } });
            setTelaAnterior(menu?.key);
        }
    };
    const latitude = 51.505;
    const longitude = -0.09;
    return (
        <Layout.Sider breakpoint="lg" collapsedWidth="0" className="menu-lateral">
            <Row justify="center" className="col-busca-menu">
                <Col span={21}>
                    <Form.Item>
                        <Input.Search placeholder="Pesquisar..." enterButton size="middle" allowClear />
                    </Form.Item>
                </Col>
            </Row>
            <Menu mode="inline" defaultSelectedKeys={['1']} onOpenChange={onOpenChange} onClick={onClick} openKeys={openKeys}>
                <Menu.Item key="1">
                    <Link to="/">
                        <Icon type={"Home"} />
                        <span> Home </span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="2">
                    <Link to="/agenda">
                        <Icon type={"Schedule"} />
                        <span> Agenda </span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="3">
                    <Link to="/calendario">
                        <Icon type={"Calendar"} />
                        <span> Calendário </span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="4">
                    <Link to="/ordemServico">
                        <Icon type={"Schedule"} />
                        <span> Ordem de Serviço </span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="5">
                    <Link to="/consultarTermosAceite">
                        <Icon type={"File"} />
                        <span>Termos de Aceite </span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="6">
                    <Link to="/recursoLocacao">
                        <Icon type={"CarryOut"} />
                        <span> Reservar Recurso </span>
                    </Link>
                </Menu.Item>
                {/* <Menu.Item key="6">
                    <Link to="/requisicao">
                        <Icon type={"Exception"} />
                        <span> Requisições </span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="7">
                   <Link to="/usuarioLocal">
                        <Icon type={"Environment"} />
                        <span> Encontrar Pessoa </span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="8">
                   <Link to="/termoDeAceite">
                        <Icon type={"FileText"} />
                        <span> Termo de Aceite </span>
                    </Link>
                </Menu.Item> */}
                <Menu.SubMenu key="sub1" id={`sub1`} title={
                    <span>
                        <Icon type={"Form"} />
                        <span> Cadastros </span>
                    </span>
                }>
                    <Menu.Item key="8">
                        <Link to="/sistema">
                            <Icon type={"DoubleRight"} />
                            Sistemas
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="9">
                        <Link to="/solucoesModulo">
                            <Icon type={"DoubleRight"} />
                            Soluções/Módulos
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="10">
                        <Link to="/cliente">
                            <Icon type={"DoubleRight"} />
                            Clientes
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="11">
                        <Link to="/hotel">
                            <Icon type={"DoubleRight"} />
                            Hotéis
                        </Link>
                    </Menu.Item>
                    {/* <Menu.Item key="12">
                        <Link to="/modeloTermoAceite">
                            <Icon type={"DoubleRight"} />
                            Termo de Aceite
                        </Link>
                    </Menu.Item> */}
                    <Menu.Item key="13">
                        <Link to="/parceiros">
                            <Icon type={"DoubleRight"} />
                            Parceiros
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="14">
                        <Link to="/recurso">
                            <Icon type={"DoubleRight"} />
                            Recursos
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="15">
                        <Link to="/restaurante">
                            <Icon type={"DoubleRight"} />
                            Restaurantes
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="16">
                        <Link to="/tipoRecurso">
                            <Icon type={"DoubleRight"} />
                            Tipo de Recurso
                        </Link>
                    </Menu.Item>
                    {/* <Menu.Item key="17">
                        <Link to="/tipoRequisicao">
                            <Icon type={"DoubleRight"} />
                            Tipo de Requisição
                        </Link>
                    </Menu.Item> */}
                    <Menu.Item key="18">
                        <Link to="/tipoTarefa">
                            <Icon type={"DoubleRight"} />
                            Tipo de Tarefa
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="19">
                        <Link to="/tipoAgenda">
                            <Icon type={"DoubleRight"} />
                            Tipo de Agenda
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="20">
                        <Link to="/usuario">
                            <Icon type={"DoubleRight"} />
                            Usuários
                        </Link>
                    </Menu.Item>
                    {/* <Menu.Item key="21">
                        <Link to="/paginaLogs">
                            <Icon type={"DoubleRight"} />
                            Log
                        </Link>
                    </Menu.Item> */}
                    {/* <  <Menu.Item key="22">
                        <Link to="/localizacao">
                            <Icon type={"DoubleRight"} />
                            Localização
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="23">
                        <Link to={`/map/${latitude}/${longitude}`}>
                            <Icon type={"DoubleRight"} />
                            Mapa
                        </Link>
                    </Menu.Item>*/}
                </Menu.SubMenu>
            </Menu>
        </Layout.Sider>
    );
}