import React from "react";
import { Row, Col, Form, Select, Input } from "antd";

export default function ManutencaoRequisicao(){
    return(
        <Form layout="vertical" name="manutencaoRequisicao">
            <Row gutter={[8,0]}>
                <Col span={24}>
                    Preencha as informações abaixo para realizar seu cadastro.
                </Col>
            </Row>
            <Row gutter={[8,0]} className="m-t-8">
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item label="Requisição" name="requisicao">
                        <Select allowClear placeholder="Selecione a Descrição">
                            <Select.Option value="0"> Passagem </Select.Option>
                            <Select.Option value="1"> Hospedagem </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>   
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Form.Item label="Hotel" name="hotel">
                        <Select placeholder="Selecione um Hotel" />
                    </Form.Item>
                </Col> 
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Form.Item label="Per Noite" name="per_noite">
                        <Input placeholder="Informe o Per Noite" />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="m-t-8">
                <Col span={24}>
                    MAPA
                </Col>
            </Row>
        </Form>
    );
}