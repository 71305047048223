import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { Row, Col, Form, Collapse, Button, Select, Radio, } from "antd";
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';
import api from '../../services/api';
import { BreadcrumbPage, SelectPaginado } from '../../components';
import { manutencaoActions } from '../../actions';
import { useStateValue } from '../../state/stateProvider';
import DrawerAgenda from '../../components/agendaCadastro/drawerAgenda';
import { CloseOutlined, SearchOutlined, PlusOutlined, FilterOutlined } from '@ant-design/icons';
moment.locale('pt-br');

const MyCalendar = () => {

  const [{ ui, manutencao }, dispatch] = useStateValue();
  const [openAgenda, setOpenAgenda] = useState(false);
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const localizer = momentLocalizer(moment);
  const [mes, setMes] = useState(0);
  const [date, setDate] = useState(new Date());
  const [views, setViews] = useState(Views.MONTH);
  const [formBuscarCalendario] = Form.useForm();

  const StyledCalendar = styled(Calendar)`

  font-size: 14px; 
  .rbc-header {
   
    font-weight: bold;
  }
  .rbc-today {
    background-color: #e8f0fe;
  }
  .rbc-event {
    background-color: #3174ad;
    color: #fff;
    border-radius: 4px;
    padding: 2px 5px;
    font-size: 13px;
    cursor: pointer;
    font-weight: 500;
  }
  .rbc-selected {
    background-color: #b3d4fc !important;
  }
  .avatarCalendario{
    width: 30px;
    border-radius: 50%;
  }
  

`;


  useEffect(() => {
    let date = new Date();
    buscarDadosAgenda(date.getMonth() + 1, date.getFullYear());
  }, []);


  function buscarDadosAgenda(mes, ano) {
    api.get('Agenda/BuscarCalendario?ano=' + ano + '&mes=' + mes).then(
      res => {
        res.data.forEach(element => {
          let dayStart = new Date(element.start).getDate();
          let dayEnd = new Date(element.end).getDate();
          let monthStart = new Date(element.start).getMonth();
          let monthEnd = new Date(element.end).getMonth();
          let yearStart = new Date(element.start).getFullYear();
          let yearEnd = new Date(element.end).getFullYear();
          let hourStart = new Date(element.start).getHours();
          let hourEnd = new Date(element.end).getHours();
          let minuteStart = new Date(element.start).getMinutes();
          let minuteEnd = new Date(element.end).getMinutes();

          element.start = new Date(yearStart, monthStart, dayStart, hourStart, minuteStart, 0);
          element.end = new Date(yearEnd, monthEnd, dayEnd, hourEnd, minuteEnd, 0);
          element.title = gerarIcones(element);
        });

        setEvents(res.data);
      }
    ).catch(error => {
      console.log(error);
    });
  }

  function gerarIcones(element) {
    {
      return <div title={element.title}>
        <span> {element.title} </span>
        {element?.participantes?.map(p => {
          if (!!p.avatarGmail) {
            return <img class="avatarCalendario" src={p.avatarGmail} alt={p.nome} title={p.nome} />
          } else {
            return <img class="avatarCalendario" src="https://ssl.gstatic.com/ui/v1/icons/mail/profile_mask2.png" alt={p.nome} title={p.nome} />
          }
        }
        )}

      </div>;
    }
  };




  function buscarCalendario() {
    let filtros = '';
    filtros += (!!formBuscarCalendario.getFieldValue().tipoAgendaId) ? `&agendaTipo=${formBuscarCalendario.getFieldValue().tipoAgendaId}` : '';
    filtros += (!!formBuscarCalendario.getFieldValue().tipoAtendimento) ? `&tipoAtendimento=${formBuscarCalendario.getFieldValue().tipoAtendimento}` : '';

    api.get('Agenda/BuscarCalendario?ano=' + date.getFullYear() + '&mes=' + (date.getMonth() + 1) + filtros).then(
      res => {
        res.data.forEach(element => {
          let dayStart = new Date(element.start).getDate();
          let dayEnd = new Date(element.end).getDate();
          let monthStart = new Date(element.start).getMonth();
          let monthEnd = new Date(element.end).getMonth();
          let yearStart = new Date(element.start).getFullYear();
          let yearEnd = new Date(element.end).getFullYear();
          let hourStart = new Date(element.start).getHours();
          let hourEnd = new Date(element.end).getHours();
          let minuteStart = new Date(element.start).getMinutes();
          let minuteEnd = new Date(element.end).getMinutes();

          element.start = new Date(yearStart, monthStart, dayStart, hourStart, minuteStart, 0);
          element.end = new Date(yearEnd, monthEnd, dayEnd, hourEnd, minuteEnd, 0);

          element.title = element.title = gerarIcones(element);
        });
        setEvents(res.data);
      }
    ).catch(error => {
      console.log(error);
    });
  }



  const handleNavigate = (date, view) => {
    setMes(date.getMonth() + 1);
    if (view === 'month') {
      const start = moment(date).startOf('month').toDate();
      buscarDadosAgenda(start.getMonth() + 1, 2023);
      setDate(date);
    }
  };


  function handleDrillDown(date) {
    setViews(Views.DAY);
    setDate(date);
  }

  function handleViewChange(view) {
    switch (view) {
      case 'agenda':
        setViews(Views.AGENDA);
        break;
      case 'day':
        setViews(Views.DAY);
        break;
      case 'week':
        setViews(Views.WEEK);
        break;
      default:
        setViews(Views.MONTH);
    }
    console.log(`Nova visualização: ${view}`);
  }

  async function abrirDrawer(event) {
    let req = await api.get(`Agenda/${event.id}`);
    if (req.status === 200 && !!req.data) {
      let dados = req.data;
      let participantes = [];
      dados.agendaParticipantes?.forEach(p => { if (!!p?.usuario?.id) participantes.push(p.usuario.id) });
      dados.listaAgendaParticipantes = participantes;
      dados.dataFinal = !!dados.dataFinal ? moment(dados.dataFinal) : null;
      dados.dataInicial = !!dados.dataInicial ? moment(dados.dataInicial) : null;
      dados.dataInclusao = !!dados.dataInclusao ? moment(dados.dataInclusao) : null;
      dados.dataAlteracao = !!dados.dataAlteracao ? moment(dados.dataAlteracao) : null;
      return dados;
    }
    return {};
  };

  const togglePesquisa = () => {
    setOpen(!open);
  };

  function removerFiltros() {
    formBuscarCalendario.setFieldsValue({
      tipoAgendaId: null, tipoAtendimento: null
    });
    buscarCalendario();
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {
      backgroundColor: '#3174ad',
      textAlign: 'center',

    };
    if (event.image) {
      style.content = event.image;

    }
    return {
      style: style
    };
  };

  return (
    <>
      <div class="padding10">
        <Row align="middle" justify="space-between" gutter={[8, 0]}>
          <Col>
            <BreadcrumbPage pagina="Calendário" />
          </Col>
          <Col span={3}  xs={24} sm={9} md={9} lg={6} xl={6} xxl={3}>
            <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={() => {
              setOpenAgenda(true);
            }}>
              Adicionar
            </Button>
          </Col>
        </Row>
        <Form form={formBuscarCalendario}>
          <Row justify="end" align="middle">
            <Col>
              <Collapse className="collapse-calendar col-ordem" accordion ghost destroyInactivePanel={true}>
                <div className="colDet"></div>
                <Collapse.Panel key="1" showArrow={false} extra={
                  <Button icon={<FilterOutlined />} onClick={togglePesquisa}>
                    Filtros
                  </Button>
                }>
                  <Row>
                    <Col>
                      <Form.Item label="Tipo de Agenda" name="tipoAgendaId">
                        <SelectPaginado url="AgendaTipo" placeholder="Selecione o Tipo de Agenda" form={formBuscarCalendario} name="tipoAgendaId" conteudo={
                          agt => (<Select.Option value={agt.id} key={agt.key}>{agt.descricao}</Select.Option>)
                        } />
                      </Form.Item>
                    </Col>
                    <Col >
                      <Form.Item label="Tipo de Atendimento" name="tipoAtendimento" >
                        <Radio.Group>
                          <Radio value={1}>Externo</Radio>
                          <Radio value={2}>Interno</Radio>
                          <Radio value={3}>Remoto</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col className="col-w">
                      <Row align="middle" justify="end" gutter={[8, 2]} className="btn-col-21">
                        <Col>
                          <Button type="primary" icon={<SearchOutlined />} onClick={buscarCalendario} block>
                            Aplicar Filtros
                          </Button>
                        </Col>
                        <Col>
                          <Button icon={<CloseOutlined />} onClick={removerFiltros} block>
                            Limpar Filtros
                          </Button>
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        </Form>
      </div>
      <StyledCalendar
        messages={{
          week: 'Semana',
          work_week: 'Dias úteis',
          day: 'Dia',
          month: 'Mês',
          previous: 'Anterior',
          next: 'Próximo',
          today: `Hoje`,
          agenda: 'Agenda',

          showMore: (total) => `+${total} mais`,
        }}
        showAllEvents={true}
        onNavigate={handleNavigate}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        selectable
        date={date}
        view={views}
        onView={handleViewChange}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={async (event) => {
          console.log(event);
          let dados = await abrirDrawer(event);
          dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados, ordem: '' } });
          setOpenAgenda(true);
        }}
        onDrillDown={handleDrillDown}
        onSelectSlot={(slotInfo) => {
          console.log(
            `selected slot: \n\nstart ${slotInfo.start.toLocaleString()} ` +
            `\nend: ${slotInfo.end.toLocaleString()} ` +

            `\naction: ${slotInfo.action}`
          );

        }
        }
      />
      <DrawerAgenda openDrawerAgenda={openAgenda} closeDrawerAgenda={() => { setOpenAgenda(false); buscarCalendario(); }} />
    </>
  );
};

export default MyCalendar;
