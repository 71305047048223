import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Input, Table, notification } from "antd";
import { listagemActions, manutencaoActions, selectPaginadoActions } from "../../actions";
import api from "../../services/api";
import { getLocal } from "../../services/auth";
import SelectPaginado from "../selectPaginado";

export default function ManutencaoEnviarOS({ form, listaEmail, listaOrdens, aoSalvar }) {

    const [usuarios, setUsuarios] = useState([]);
    const [usuariosCopia, setUsuariosCopia] = useState([]);

    async function enviarEmail(values) {
        let dados = { ...form.getFieldValue() };
        if (!!!form.getFieldValue().ComCopia)
        {
            dados.ComCopia = [];
        }
        let filtros = [{ "nome": "ordemservico", "valor": listaOrdens }, { "nome": "IdLocal", "valor": getLocal() }];
        dados.parametros = JSON.stringify(filtros);


        await api.post(`EnviaEmail/EnviarOS`, dados).then(
            res => {
                notification.success({ message: 'Operação concluída.', description: "E-mail enviado com sucesso!" });
            }
        ).catch(
            erro => {
                notification.error({ message: 'Erro ao enviar o e-mail.', description: JSON.stringify(erro.data) })
            }
        ).finally(
            () => {
                aoSalvar();
            }
        );
    };

    useEffect(() => {
        setUsuarios(listaEmail);
        buscarTodosUsuarios();
    }, []);

    function buscarTodosUsuarios() {
        api.get("Usuarios/ListarTodos?sac=true&ativo=true").then(
            res => {
                if (!!res.data) {
                    setUsuariosCopia(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    return (
        <Form layout="vertical" name="manutencaoEnviarOS" form={form} onFinish={enviarEmail}>
            <Row gutter={[8, 0]}>
                <Col span={24}>
                    <Form.Item label="Para:" name="destinatarios" rules={[{ required: true, message: 'Informe um e-mail' }]}>
                        <Select form={form} name="destinatarios" mode="multiple" allowClear placeholder="Selecione um e-mail" showSearch optionFilterProp="children" >
                            {listaEmail.map((participante) => (
                                <Select.Option value={participante.email} key={participante.email}>{participante.email}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Cópia:" name="ComCopia">
                        <SelectPaginado url="Usuarios?Ativo=true&Sac=true" form={form} name="ComCopia" multiple={true} allowClear placeholder="Selecione um e-mail" showSearch optionFilterProp="children"
                            conteudo={(participante) => (
                                <Select.Option value={participante.email} key={participante.email}>{participante.email}</Select.Option>
                            )} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item form={form} label="Descrição:" name="ConteudoEmail" rules={[{ required: true, message: 'Informe uma Descrição' }]}>
                        <Input.TextArea placeholder="Informe uma Descrição" style={{ height: 200 }} />
                    </Form.Item>
                </Col>

            </Row>

        </Form>

    );
}